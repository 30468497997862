<div class="header">
  <h2>S'inscrire à VeryMountain</h2>
  <p>Créez votre compte dès maintenant pour organiser vos vacances d'hiver en quelques clics!</p>
</div>

<p *ngIf="error" class="error">{{ error }}</p>

<form>
  <!-- Prénom -->
  <atc-input [control]="firstNameForm"
             [focus]="true"
             errorMessage="Champs obligatoire"
             id="id__input-step-connexion-firstname"
             label="Prénom"
             placeholder="Julie">
  </atc-input>

  <!-- Nom -->
  <atc-input [control]="lastNameForm"
             errorMessage="Champs obligatoire"
             id="id__input-step-connexion-lastname"
             label="Nom"
             placeholder="Dupont">
  </atc-input>

  <!-- Email -->
  <atc-input [control]="emailForm"
             errorMessage="Adresse email malformée"
             label="Email"
             placeholder="Votre adresse email"
             type="email">
  </atc-input>

  <!-- Phone -->
  <atc-input [control]="phoneForm"
             [dropSpecialCharacters]="false"
             [specialCharacters]="[' ']"
             errorMessage="Champs obligatoire ne pouvant contenir uniquement des chiffres*"
             label="Téléphone"
             mask="00 00 00 00 00"
             placeholder="06 12 34 56 78"></atc-input>

  <!-- Password -->
  <atc-input [control]="passwordForm"
             [errorMessage]="PasswordUtils.getPasswordErrorMessage(passwordForm)"
             label="Votre mot de passe"
             placeholder="***********"
             type="password">
  </atc-input>

  <!-- Password confirmation -->
  <atc-input [control]="passwordConfirmationForm"
             errorMessage="Les mots de passe ne sont pas similaires"
             id="id__input-step-connexion-password-confirmation"
             label="Confirmation"
             placeholder="***********"
             type="password">
  </atc-input>
</form>


<!-- CGU -->
<div class="checkbox-cgu">
  <atc-input-checkbox [border]="false"
                      [control]="cguCheckedForm"
                      [mandatory]="true"
                      [markAsTouched]="formTouched"
                      errorMessage="Champs obligatoire">
    <p>En cochant cette case, j'accepte les
      <a class="underline" href="/terms-of-use" target="_blank">Conditions générales d'utilisation</a> et les
      <a class="underline" href="/selling-conditions" target="_blank">Conditions générales de vente</a> de
      VeryMountain
    </p>
  </atc-input-checkbox>

  <atc-input-checkbox [border]="false"
                      [control]="newsletter">
    <p>Je souhaites recevoir les bons plans VeryMountain</p>
  </atc-input-checkbox>
</div>

<div class="cta">
  <atc-button (click)="registerAccount()"
              [disabled]="loading"
              size="large">Finaliser l'inscription
  </atc-button>
  <div class="new-client">
    <p>Déjà inscrit ?</p>
    <a *ngIf="redirectionAreLink"
       class="link"
       queryParamsHandling="preserve"
       routerLink="/connexion"
       routerLinkActive="true">
      Je me connecte
    </a>
    <button (click)="switchLoginTypeChange.emit()"
            *ngIf="!redirectionAreLink"
            class="link">
      Je me connecte
    </button>
  </div>
</div>
