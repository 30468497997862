import { Location } from '../location';
import { PointOfInterest } from './point-of-interest';
import { ResortLabel } from './resort-label';

export class Resort {
  id: number;
  name: string;
  urlLogo: string;
  urlsCover: string[];
  regionLabel: string;
  location: Location;
  description: string;
  labels: ResortLabel[];
  pointsOfInterest: PointOfInterest[];
  priceSkiPass: number;

  constructor(obj: Partial<Resort>) {
    Object.assign(this, obj);

    if (this.location) {
      this.location = new Location({ ...this.location });
    }

    if (this.labels) {
      this.labels = this.labels?.map((label) => new ResortLabel({ ...label }));
    }
  }
}
