import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LoginFormComponent } from '../../shared/login-form/login-form.component';

@Component({
  selector: 'vsk-login',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    GoogleSigninButtonModule,
    LoginFormComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {}
