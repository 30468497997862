<div class="content">
  <div class="buttons-navigation">
    <h3>Votre voyage en montagne commence ici</h3>
  </div>

  <ng-container *ngIf="participants.length">
    <h5>Voyageurs</h5>

    <vsk-card-participants [participants]="participants"
                           [sessionId]="sessionId">
    </vsk-card-participants>
  </ng-container>

  <h5>
    Réservez ici les essentiels,<br>
    créez un séjour unique à l'étape suivante.
  </h5>

  <!-- SKI PASS -->
  <div class="cart-card">
    <div class="card-header">
      <h5>Forfaits de ski</h5>

      <button (click)="addSkiPass = true">
        <p>Ajouter</p>

        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#add-circle'"></use>
        </svg>
      </button>
    </div>

    <ng-container *ngFor="let item of _cart?.skiPass; let lastItem = last">
      <div *ngFor="let participant of item.participants; let lastParticipant = last"
           [ngClass]="{'border-bottom': !lastItem || !lastParticipant}"
           class="card-expandable">
        <div class="line">
          <div class="participant">
            <p>{{ participant.firstname }} ({{ participant.age }} ans)</p>

            <button (click)="deleteActivityForParticipant(participant.uuid, item.uuid)">
              <svg>
                <use [attr.xlink:href]="'assets/icons/icons.svg#trash'"></use>
              </svg>
            </button>
          </div>

          <button (click)="expand(item.uuid + participant.uuid)"
                  class="action">
            <p>{{ (item.price / item.participants.length) | number : '1.2-2' }} €</p>

            <svg *ngIf="expanded !== (item.uuid + participant.uuid)">
              <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-down'"></use>
            </svg>
            <svg *ngIf="expanded === (item.uuid + participant.uuid)">
              <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-up'"></use>
            </svg>
          </button>
        </div>

        <div *ngIf="expanded === (item.uuid + participant.uuid)"
             class="detailed-line">
          <div class="left-side">
            <img [src]="item.item?.experience.pictures[0]" alt="Forfait de ski">

            <div class="information">
              <h6>{{ item.item?.experience.name }}</h6>
              <p>Du {{ item.startDate.format('dddd DD MMM') }} au {{ item.endDate.format('dddd DD MMM') }}</p>
            </div>
          </div>

          <div class="right-side">
            <p *ngIf="item.price !== item.pricePublic"
               class="public-price">
              {{ (item.pricePublic / item.participants.length) | number : '1.2-2' }} €
            </p>

            <div *ngIf="PricingUtils.reductionPercentage(item.pricePublic, item.price) as discount" class="discount">
              <p>-{{ discount }}%</p>
            </div>

            <p class="price">{{ (item.price / item.participants.length) | number : '1.2-2' }} €</p>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="tips">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#stars'"></use>
      </svg>

      <p>Éviter la queue des remontées mécaniques en réservant vos forfaits à l'avance.</p>
    </div>
  </div>

  <!-- EQUIPMENT -->
  <div class="cart-card">
    <div class="card-header">
      <h5>Location de matériel</h5>

      <button (click)="addEquipment = true">
        <p>Ajouter</p>

        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#add-circle'"></use>
        </svg>
      </button>
    </div>


    <div *ngFor="let item of _cart?.itemsSkiEquipment; let last = last"
         [ngClass]="{'border-bottom': !last}"
         class="card-expandable">

      <div class="line">
        <div class="participant">
          <p>{{ item.participantSession.firstname }} ({{ item.participantSession.age }} ans)</p>

          <button (click)="deleteEquipmentForParticipant(item.participantSession.sessionId, item.id)">
            <svg>
              <use [attr.xlink:href]="'assets/icons/icons.svg#trash'"></use>
            </svg>
          </button>
        </div>

        <button (click)="expand(item.id + item.participantSession.uuid)"
                class="action">
          <p>{{ item.totalPrice | number : '1.2-2' }} €</p>

          <svg *ngIf="expanded !== (item.id + item.participantSession.uuid)">
            <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-down'"></use>
          </svg>
          <svg *ngIf="expanded === (item.id + item.participantSession.uuid)">
            <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-up'"></use>
          </svg>
        </button>
      </div>

      <div *ngIf="expanded === (item.id + item.participantSession.uuid)"
           class="detailed-line">
        <div class="left-side">
          <img [src]="item.pack.pictureURL" alt="Matériel de ski" class="contain">

          <div class="information">
            <h6>
              {{ item.pack.name }} - {{ item.duration }} jours - ({{ item.pack.ageRange }})
              <ng-container *ngIf="item.shoes && !item.helmet">- avec chaussures</ng-container>
              <ng-container *ngIf="item.helmet && !item.shoes">- avec casque</ng-container>
              <ng-container *ngIf="item.helmet && item.shoes">- avec chaussures & casque</ng-container>
            </h6>
            <p>Du {{ item.startDate.format('dddd DD MMM') }} au {{ item.endDate.format('dddd DD MMM') }}</p>
          </div>
        </div>

        <div class="right-side">
          <p *ngIf="item.totalPublicPrice !== item.totalPrice"
             class="public-price">
            {{ item.totalPublicPrice | number : '1.2-2' }} €
          </p>

          <div *ngIf="PricingUtils.reductionPercentage(item.totalPublicPrice, item.totalPrice) as discount"
               class="discount">
            <p>-{{ discount }}%</p>
          </div>

          <p class="price">{{ item.totalPrice | number : '1.2-2' }} €</p>
        </div>
      </div>
    </div>

    <div class="tips">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#stars'"></use>
      </svg>

      <p>Économisez et gagnez du temps en magasin en réservant votre matériel dès maintenant.</p>
    </div>
  </div>

  <div *ngIf="hasSkiClasses"
       class="cart-card">
    <div class="card-header">
      <h5>Cours de ski</h5>

      <button (click)="addSkiClass = true">
        <p>Ajouter</p>

        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#add-circle'"></use>
        </svg>
      </button>
    </div>

    <ng-container *ngFor="let item of _cart?.skiClass; let lastItem = last">
      <div *ngFor="let participant of item.participants; let lastParticipant = last"
           [ngClass]="{'border-bottom': !lastItem || !lastParticipant}"
           class="card-expandable">
        <div class="line">
          <div class="participant">
            <p>{{ participant.firstname }} ({{ participant.age }} ans)</p>

            <button (click)="deleteActivityForParticipant(participant.uuid, item.uuid)">
              <svg>
                <use [attr.xlink:href]="'assets/icons/icons.svg#trash'"></use>
              </svg>
            </button>
          </div>

          <button (click)="expand(item.uuid + participant.uuid)"
                  class="action">
            <p>{{ (item.price / item.participants.length) | number : '1.2-2' }} €</p>

            <svg *ngIf="expanded !== (item.uuid + participant.uuid)">
              <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-down'"></use>
            </svg>
            <svg *ngIf="expanded === (item.uuid + participant.uuid)">
              <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-up'"></use>
            </svg>
          </button>
        </div>

        <div *ngIf="expanded === (item.uuid + participant.uuid)"
             class="detailed-line">
          <div class="left-side">
            <img [src]="item.item?.experience.pictures[0]" alt="Forfait de ski">

            <div class="information">
              <h6>{{ item.item?.experience.name }}</h6>
              <p *ngIf="!item.internshipId">Le {{ item.startDate.format('dddd DD MMM') }}
                à {{ item.startDate.format('HH:mm') }}</p>
              <p *ngIf="!!item.internshipId">Du {{ item.startDate.format('dddd DD MMM') }}
                au {{ item.endDate.format('dddd DD MMM') }}</p>
            </div>
          </div>

          <div class="right-side">
            <p *ngIf="item.price !== item.pricePublic"
               class="public-price">
              {{ (item.pricePublic / item.participants.length) | number : '1.2-2' }} €
            </p>

            <div *ngIf="PricingUtils.reductionPercentage(item.pricePublic, item.price) as discount" class="discount">
              <p>-{{ discount }}%</p>
            </div>

            <p class="price">{{ (item.price / item.participants.length) | number : '1.2-2' }} €</p>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="tips">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#stars'"></use>
      </svg>

      <p>Assurez une place en cours de ski à vos enfants.</p>
    </div>
  </div>


  <div class="buttons-navigation">
    <p></p>
    <atc-button (click)="nextChange.emit()"
                [iconRightSide]="true"
                class="hide-small-desktop-and-below"
                icon="chevron-right">
      Suivant
    </atc-button>
  </div>
</div>

<vsk-ski-pass-popup (closeChangeEvent)="addSkiPass = false"
                    *ngIf="addSkiPass"
                    [criteria]="criteria"
                    [participants]="participants"
                    [period]="period">
</vsk-ski-pass-popup>

<vsk-equipment-popup (closeChangeEvent)="addEquipment = false"
                     *ngIf="addEquipment"
                     [criteria]="criteria"
                     [participants]="participants"
                     [period]="period">
</vsk-equipment-popup>

<vsk-ski-class-popup (closeChangeEvent)="addSkiClass = false"
                     *ngIf="addSkiClass && hasSkiClasses"
                     [criteria]="criteria"
                     [participants]="participants"
                     [period]="period">
</vsk-ski-class-popup>
