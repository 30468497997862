<atc-dropdown (closeChanged)="closeEventChanged.emit()"
              [classes]="['no-padding']"
              [elementPosition]="_parent"
              [isOpen]="true"
              [left]="theme === 'thick' ? '150px' : '0px'"
              [position]="'bottom-left'"
              [top]="theme === 'thick' ? '60px' : '40px'"
              [width]="'650px'"
              class="hide-small-desktop-and-below">
  <div class="content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</atc-dropdown>

<div class="content hide-small-desktop-and-above">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
  <p>Sélectionner la durée de votre séjour</p>

  <atc-date-range-picker (dateRangeChanged)="datesPicked($event)"
                         [endDate]="_period?.endDate"
                         [maxDays]="28"
                         [max]="maxDate"
                         [min]="minDate"
                         [startDate]="_period?.startDate">
  </atc-date-range-picker>
</ng-template>
