<div *ngIf="(windowResource.width() | async | screen: 'tablet-above')" class="filters">
  <div class="flex flex-row items-center gap-x-2">
    <atc-select (selectChange)="applyFilter()"
                [checkbox]="true"
                [optionElements]="tripIncludeFilter"
                label="Inclus dans mon séjour">
    </atc-select>
    <atc-select (selectChange)="applyFilter()"
                [checkbox]="true"
                [optionElements]="regionsElements"
                label="Massif">
    </atc-select>
  </div>

  <div class="flex flex-row items-center gap-x-2">
    <atc-select (selectChange)="applyFilter()"
                [checkbox]="true"
                [optionElements]="accommodationTypeElements"
                label="Type d'hébergement">
    </atc-select>
    <atc-select (selectChange)="applyFilter()"
                *ngIf="(windowResource.width() | async | screen: 'small-desktop-above')"
                [checkbox]="true"
                [optionElements]="equipmentsFilter"
                label="Équipement">
    </atc-select>
    <atc-select (selectChange)="applyFilter()"
                *ngIf="(windowResource.width() | async | screen: 'small-desktop-above')"
                [checkbox]="true"
                [optionElements]="servicesFilter"
                label="Service">
    </atc-select>
  </div>
</div>

<atc-button (click)="openMapFiltersPopUp()"
            *ngIf="!(windowResource.width() | async | screen: 'tablet-above')"
            [color]="totalFilters() ? 'clicked' : 'ternary'"
            [text]="totalFilters()"
            atcBadge
            class="flex w-full"
            color="ternary"
            icon="filters"
            label="Voir les filtres">
</atc-button>

<div class="flex flex-row xl:gap-x-2 items-center">
  <atc-button (click)="openMapFiltersPopUp()"
              *ngIf="(windowResource.width() | async | screen: 'tablet-above')"
              [clicked]="openMapFilters"
              [color]="totalFilters() ? 'clicked' : 'black'"
              [iconRightSide]="true"
              [positionBadge]="'topRight'"
              [text]="totalFilters()"
              atcBadge
              class="relative"
              color="black"
              icon="filters"
              size="small">
    Plus de filtres
  </atc-button>
  <atc-button (click)="reset()"
              color="simple"
              icon="trash"></atc-button>
</div>

<vsk-search-trip-filters (clearFiltersChanged)="reset()"
                         (refreshChanged)="closePopup($event)"
                         *ngIf="openMapFilters"
                         [accessFilter]="accessFilter"
                         [accommodationTypeFilter]="accommodationTypeElements"
                         [equipmentsFilter]="equipmentsFilter"
                         [filters]="_filters"
                         [regionsFilter]="regionsElements"
                         [regions]="regions"
                         [resortLabelsFilter]="resortLabelsFilter"
                         [servicesFilter]="servicesFilter"
                         [tagsFilter]="tagsFilter"
                         [tripIncludeFilter]="tripIncludeFilter"
                         class="absolute">
</vsk-search-trip-filters>
