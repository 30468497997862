import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import moment, { Moment } from 'moment';
import { Participant } from 'src/app/shared/models/participant/participant';
import { Activity } from '../models/activity/activity';
import { Period } from '../models/period';
import {
  AddParticipant,
  ChangeDates,
  ChangeParticipant,
  ChangeSearchPackage,
  ChangeSearchType,
  ClearSearchBar,
  InitSearchBar,
  RemoveParticipant,
  SelectActivity,
  SelectResort,
  SetRegions,
  SetResorts,
  UnselectActivity
} from './search-bar.action';

export type SearchType = 'RESORT' | 'ACCOMMODATION' | 'TRIP';
export type SearchPackage = 'SINGLE' | 'HALF' | 'FULL';

export interface SearchBarModel {
  searchType: SearchType;
  searchPackage: SearchPackage;
  participants: Participant[];
  activities: Activity[];
  resorts: number[];
  regions: number[];
  resort: string | null;
  startDate: Moment | null;
  endDate: Moment | null;
}

@State<SearchBarModel>({
  name: 'searchBar',
  defaults: {
    searchType: 'ACCOMMODATION',
    searchPackage: 'FULL',
    participants: [],
    activities: [],
    resorts: [],
    regions: [],
    resort: null,
    startDate: null,
    endDate: null
  }
})
@Injectable()
export class SearchBarState {
  @Selector()
  static searchBar(state: SearchBarModel): SearchBarModel {
    return {
      ...state,
      activities: state.activities
        .filter((activity) => activity.selected)
        .sort((prev, curr) => (prev.name > curr.name ? 1 : -1))
    };
  }

  @Selector()
  static searchType(state: SearchBarModel): SearchType | null {
    return state.searchType;
  }

  @Selector()
  static searchPackage(state: SearchBarModel): SearchPackage | null {
    return state.searchPackage;
  }

  @Selector()
  static participants(state: SearchBarModel): Participant[] {
    return state.participants.sort((prev, curr) =>
      prev.index < curr.index ? -1 : 1
    );
  }

  @Selector()
  static activities(state: SearchBarModel): Activity[] {
    return state.activities
      .map((activity) => new Activity(activity))
      .sort((prev, curr) => (prev.name > curr.name ? 1 : -1));
  }

  @Selector()
  static period(state: SearchBarModel): Period {
    return new Period({
      startDate: state.startDate as Moment,
      endDate: state.endDate as Moment
    });
  }

  @Selector()
  static startDate(state: SearchBarModel): Moment | null {
    return state.startDate;
  }

  @Selector()
  static resorts(state: SearchBarModel): number[] {
    return state.resorts;
  }

  @Selector()
  static regions(state: SearchBarModel): number[] {
    return state.regions;
  }

  @Selector()
  static resort(state: SearchBarModel): string | null {
    return state.resort;
  }

  @Action(InitSearchBar)
  initSearchBar(
    ctx: StateContext<SearchBarModel>,
    action: InitSearchBar
  ): void {
    ctx.patchState({
      searchType: action.searchType,
      searchPackage: action.searchPackage,
      participants: action.participants,
      startDate: action.period.startDate,
      endDate: action.period.endDate,
      activities: action.activities,
      regions: action.regions,
      resorts: action.resorts,
      resort: action.resort
    });
  }

  @Action(ClearSearchBar)
  clearSearchBar(
    ctx: StateContext<SearchBarModel>,
    action: ClearSearchBar
  ): void {
    ctx.patchState({
      searchType: 'ACCOMMODATION',
      searchPackage: 'FULL',
      participants: [
        new Participant({
          index: 0,
          firstname: `Voyageur 1`,
          birthdate: moment().add(-25, 'year'),
          sessionId: action.sessionId
        }),
        new Participant({
          index: 1,
          firstname: `Voyageur 2`,
          birthdate: moment().add(-25, 'year'),
          sessionId: action.sessionId
        })
      ],
      startDate: moment(),
      endDate: moment().add(3, 'days'),
      resorts: [],
      regions: [],
      resort: null,
      activities: [
        ...ctx
          .getState()
          .activities.map(
            (activity) => new Activity({ ...activity, selected: false })
          )
      ]
    });
  }

  @Action(ChangeSearchType)
  changeSearchType(
    ctx: StateContext<SearchBarModel>,
    action: ChangeSearchType
  ): void {
    ctx.patchState({
      searchType: action.searchType
    });
  }

  @Action(ChangeSearchPackage)
  changeSearchPackage(
    ctx: StateContext<SearchBarModel>,
    action: ChangeSearchPackage
  ): void {
    ctx.patchState({
      searchPackage: action.searchPackage
    });
  }

  @Action(AddParticipant)
  addParticipant(
    ctx: StateContext<SearchBarModel>,
    action: AddParticipant
  ): void {
    ctx.patchState({
      participants: [...ctx.getState().participants, action.participant]
    });
  }

  @Action(ChangeParticipant)
  changeParticipant(
    ctx: StateContext<SearchBarModel>,
    action: ChangeParticipant
  ): void {
    ctx.patchState({
      participants: [
        ...ctx
          .getState()
          .participants.filter(
            (participant) => participant.uuid !== action.participant.uuid
          ),
        action.participant
      ]
    });
  }

  @Action(RemoveParticipant)
  removeParticipant(
    ctx: StateContext<SearchBarModel>,
    action: RemoveParticipant
  ): void {
    ctx.patchState({
      participants: [
        ...ctx
          .getState()
          .participants.filter(
            (participant) => participant.uuid !== action.participant
          )
          .sort((prev, curr) => (prev.index < curr.index ? -1 : 1))
          .map(
            (participant, i) =>
              new Participant({
                ...participant,
                index: i,
                firstname:
                  participant.firstname.indexOf('Voyageur') !== -1
                    ? `Voyageur ${i + 1}`
                    : participant.firstname
              })
          )
      ]
    });
  }

  @Action(SelectActivity)
  selectActivity(
    ctx: StateContext<SearchBarModel>,
    action: SelectActivity
  ): void {
    const state = ctx.getState();

    if (action.activity === null || action.activity === undefined) {
      return;
    }

    ctx.patchState({
      activities: [
        ...state.activities.map((activity) => {
          if (action.activity.name === activity.name) {
            return new Activity({ ...activity, selected: true });
          }

          return new Activity({ ...activity });
        })
      ]
    });
  }

  @Action(UnselectActivity)
  unselectActivity(
    ctx: StateContext<SearchBarModel>,
    action: UnselectActivity
  ): void {
    const state = ctx.getState();

    if (!action.activity) {
      ctx.patchState({
        activities: [
          ...state.activities.map(
            (activity) => new Activity({ ...activity, selected: false })
          )
        ]
      });
      return;
    }

    ctx.patchState({
      activities: [
        ...state.activities.map((activity) => {
          if (action.activity?.name === activity.name) {
            return new Activity({ ...activity, selected: false });
          }

          return new Activity({ ...activity });
        })
      ]
    });
  }

  @Action(ChangeDates)
  changeDates(ctx: StateContext<SearchBarModel>, action: ChangeDates): void {
    ctx.patchState({
      startDate: action.startDate,
      endDate: action.endDate
    });
  }

  @Action(SetResorts)
  setResorts(ctx: StateContext<SearchBarModel>, action: SetResorts): void {
    ctx.patchState({
      resorts: action.resorts
    });
  }

  @Action(SetRegions)
  setRegions(ctx: StateContext<SearchBarModel>, action: SetRegions): void {
    ctx.patchState({
      regions: action.regions
    });
  }

  @Action(SelectResort)
  selectResort(ctx: StateContext<SearchBarModel>, action: SelectResort): void {
    ctx.patchState({
      resort: action.resort
    });
  }
}
