import { NgForOf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DropdownComponent } from 'atomic-lib';
import { Moment } from 'moment';
import { DateUtils } from '../../../utils/date-utils';
import { Period } from '../../models/period';

@Component({
  selector: 'vsk-first-ski-date-dropdown',
  standalone: true,
  imports: [DropdownComponent, NgForOf],
  templateUrl: './first-ski-date-dropdown.component.html',
  styleUrl: './first-ski-date-dropdown.component.scss'
})
export class FirstSkiDateDropdownComponent {
  @ViewChild('firstDate') buttonElement: ElementRef<HTMLElement>;

  open = false;

  @Input() title = 'Premier jour de ski';
  @Input() firstDay: Moment;
  @Input() days: Moment[] = [];
  @Output() selectedDateChange: EventEmitter<Moment> =
    new EventEmitter<Moment>();

  @Input() set period(period: Period) {
    if (!period) {
      return;
    }

    this.days = DateUtils.allDatesBetween2Dates(
      period.startDate,
      period.endDate
    );
    this.firstDay = this.days[0];
    this.selectedDateChange.emit(this.firstDay);
  }

  changeFirstDay(day: Moment) {
    this.firstDay = day;
    this.selectedDateChange.emit(this.firstDay);
    this.open = false;
  }
}
