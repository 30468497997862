<p>{{ _participant.firstname }}</p>

<atc-select [control]="ageControl"
            [optionElements]="ageItems"></atc-select>

<button (click)="removeChanged.emit()">
  <img alt="Retirer voyageur"
       height="9"
       ngSrc="assets/new-design/img/search-bar/participant/minus.svg"
       width="10">
</button>
