import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { EcoChart } from '../shared/models/cart/eco-chart';
import { UserSession } from '../shared/models/user-session';

@Injectable()
export class LocalStorageResource {
  static readonly LANG = 'language';
  static readonly TOKEN_KEY = 'auth-token';
  static readonly REFRESH_TOKEN_KEY = 'auth-refresh-token';
  static readonly USER_KEY = 'auth-user';
  static readonly LAST_SESSION_TIMESTAMP = 'last-session-timestamp';
  static readonly CHARTE_ECO = 'eco-tourism-chart';

  static getUser(): UserSession | null {
    if (window.localStorage.getItem(LocalStorageResource.USER_KEY) === null) {
      return null;
    }
    return new UserSession(
      JSON.parse(localStorage.getItem(LocalStorageResource.USER_KEY) as string)
    );
  }

  static getToken(): string {
    return localStorage.getItem(LocalStorageResource.TOKEN_KEY) as string;
  }

  static getRefreshToken(): string {
    return localStorage.getItem(
      LocalStorageResource.REFRESH_TOKEN_KEY
    ) as string;
  }

  static getLang(): string {
    return window.sessionStorage.getItem(LocalStorageResource.LANG) !== null
      ? (window.sessionStorage.getItem(LocalStorageResource.LANG) as string)
      : 'fr';
  }

  static getLastSessionDate(): Moment | null {
    if (
      window.localStorage.getItem(
        LocalStorageResource.LAST_SESSION_TIMESTAMP
      ) === null
    ) {
      return null;
    }

    return moment(
      localStorage.getItem(
        LocalStorageResource.LAST_SESSION_TIMESTAMP
      ) as string
    );
  }

  static updateLastSessionDate() {
    window.localStorage.setItem(
      LocalStorageResource.LAST_SESSION_TIMESTAMP,
      moment().toISOString()
    );
  }

  static IsEcoChart(): EcoChart {
    return new EcoChart(
      window.localStorage.getItem(LocalStorageResource.CHARTE_ECO)
        ? Boolean(
            JSON.parse(
              window.localStorage.getItem(
                LocalStorageResource.CHARTE_ECO
              ) as string
            )
          )
        : // @TODO pass to false once the design is validated
          true
    );
  }
}
