<section class="left-side">
  <a class="logo"
     queryParamsHandling="preserve"
     routerLink="/"
     routerLinkActive="true">
    <img alt="Logo VeryMountain"
         height="25"
         ngSrc="assets/new-design/svg/logo-dark.svg" width="173">
  </a>

  <vsk-register-form></vsk-register-form>
</section>

<section class="right-side hide-small-desktop-and-below">
  <div class="background"></div>
</section>
