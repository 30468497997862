import {
  DecimalPipe,
  NgOptimizedImage,
  provideImgixLoader,
  registerLocaleData
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule, HammerModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateService } from '@ngx-translate/core';

import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from '@abacritt/angularx-social-login';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AtomicLibModule, ScreenPipe } from 'atomic-lib';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsModule } from './page/about-us/about-us.module';
import { SearchAccommodationsComponent } from './page/search-accommodations/search-accommodations.component';
import { HeaderFiltersComponent } from './page/search-trip/header-filters/header-filters.component';
import { SellingConditionsModule } from './page/selling-conditions/selling-conditions.module';
import { TermsAndConditionsModule } from './page/terms-and-conditions/terms-and-conditions.module';
import { TermsOfUseModule } from './page/terms-of-use/terms-of-use.module';
import { AccountResource } from './resource/account.resource';
import { LocalStorageResource } from './resource/local-storage.resource';
import { ParticipantResource } from './resource/participant.resource';
import { UrlManagerResource } from './resource/url-manager.resource';
import { WindowResource } from './resource/window.resource';
import { WebsocketService } from './service/websocket.service';
import { CardAccommodationComponent } from './shared/card-accommodation/card-accommodation.component';
import { CardResortComponent } from './shared/card-resort/card-resort.component';
import { CarouselComponent } from './shared/carousel/carousel.component';
import { FiltersAccommodationComponent } from './shared/filters-accommodation/filters-accommodation.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ToHourPipe } from './shared/pipe/to-hour.pipe';
import { SharedModule } from './shared/shared.module';
import { NgxsStoreModule } from './store/store.module';
import { NgxTranslateModule } from './store/translate.module';
import { authInterceptorProviders } from './utils/auth.interceptor';

registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent, SearchAccommodationsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    SocialLoginModule,
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxStripeModule.forRoot(environment.stripeKey),
    NgxTranslateModule,
    TermsOfUseModule,
    TermsAndConditionsModule,
    AboutUsModule,
    SellingConditionsModule,
    NgxsStoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AtomicLibModule,
    NgOptimizedImage,
    HammerModule,
    InfiniteScrollModule,
    ScreenPipe,
    HeaderFiltersComponent,
    CardResortComponent,
    CarouselComponent,
    FooterComponent,
    CardAccommodationComponent,
    NavbarComponent,
    FiltersAccommodationComponent
  ],
  providers: [
    ToHourPipe,
    DecimalPipe,
    Meta,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '325382149684-02e7jk7juhbgcolc5t0magk77p9hj99h.apps.googleusercontent.com',
              {
                oneTapEnabled: false
              }
            )
          }
        ]
      } as SocialAuthServiceConfig
    },
    authInterceptorProviders,
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-WVW3ZDD'
    },
    AccountResource,
    LocalStorageResource,
    ParticipantResource,
    WindowResource,
    TranslateService,
    WebsocketService,
    provideImgixLoader(`${environment.cdnUrl}/`),
    UrlManagerResource
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
