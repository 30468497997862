import { ParamMap } from '@angular/router';
import { Moment } from 'moment';
import { FiltersExperienceSearch } from './shared/models/activity/filters-experience-search';
import { OrderBy } from './shared/models/const/order-by';
import { WhiteLabel } from './shared/models/enum/white-label.enum';
import { FiltersAccommodationSearch } from './shared/models/filters/filters-accommodation-search';
import { Participant } from './shared/models/participant/participant';
import { Resort } from './shared/models/resort/resort';
import { SearchBarModel } from './shared/search-bar/search-bar.state';

export class UpdateFilters {
  static readonly type = '[FiltersState] Update filters';

  constructor(public queryParams: ParamMap) {}
}

export class UpsertParticipant {
  static readonly type = '[Filters] Upsert participant';

  constructor(
    public participant: Participant,
    public sessionId: string
  ) {}
}

export class AddParticipant {
  static readonly type = '[Filters] Add participant';

  constructor(public participant: Participant) {}
}

export class RemoveParticipant {
  static readonly type = '[Filters] Remove participant';

  constructor(public participant: string) {}
}

export class ChangeDates {
  static readonly type = '[Filters] Change dates';

  constructor(
    public startDate: Moment,
    public endDate: Moment
  ) {}
}

export class Bounds {
  static readonly type = '[Filters] Set bounds';

  constructor(public bounds: google.maps.LatLngBoundsLiteral | undefined) {}
}

export class FiltersStationChangeOrderBy {
  static readonly type = '[Filters] Filters change order by';

  constructor(public orderBy: OrderBy) {}
}

export class GeoBoundsActiveChange {
  static readonly type = '[Filters] Filters change GeoBounds active';

  constructor(public isActive: boolean) {}
}

export class SetResort {
  static readonly type = '[Filters] Set resort';

  constructor(public resort: Resort | null) {}
}

export class NewSearch {
  static readonly type = '[Filters] New search';

  constructor(public search: SearchBarModel) {}
}

export class GenerateNewSessionId {
  static readonly type = '[Filters] Generate new session id';
}

export class SetFiltersAccommodations {
  static readonly type = '[Filters] Set filters accommodations';

  constructor(public filters: FiltersAccommodationSearch) {}
}

export class SetFiltersExperiences {
  static readonly type = '[Filters] Set Filters Experience';

  constructor(public filtersExperiences: FiltersExperienceSearch | undefined) {}
}

export class SetWhiteLabel {
  static readonly type = '[Filters] Set WhiteLabel';

  constructor(public whiteLabel: WhiteLabel) {}
}
