import { NgForOf, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DropdownComponent } from 'atomic-lib';

@Component({
  selector: 'vsk-duration-equipment-dropdown',
  standalone: true,
  imports: [DropdownComponent, NgForOf, NgIf],
  templateUrl: './duration-equipment-dropdown.component.html',
  styleUrl: './duration-equipment-dropdown.component.scss'
})
export class DurationEquipmentDropdownComponent {
  @ViewChild('equipment') buttonElement: ElementRef<HTMLElement>;

  open = false;

  @Input() title = 'Matériel';
  @Input() nbDays: number[] = [];
  @Input() duration: number = 0;
  @Input() resortName: string;
  @Input() optionEmpty = true;

  @Output() selectDaysChange: EventEmitter<number> = new EventEmitter<number>();

  chooseDuration(day: number) {
    this.selectDaysChange.emit(day);
    this.open = false;
  }
}
