import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { DropdownComponent } from 'atomic-lib';
import { DropdownTemplateComponent } from '../dropdown-template.component';
import { ChangeSearchPackage } from '../search-bar.action';
import { SearchPackage } from '../search-bar.state';

@Component({
  selector: 'vsk-search-bar-type',
  standalone: true,
  imports: [DropdownComponent, NgClass, NgTemplateOutlet, NgIf],
  templateUrl: './search-bar-type.component.html',
  styleUrl: './search-bar-type.component.scss'
})
export class SearchBarTypeComponent extends DropdownTemplateComponent {
  readonly DEFAULT_TITLE: string = 'Choisir une expérience';

  @Input() theme: 'thick' | 'thin' = 'thick';
  _searchType: SearchPackage;

  constructor(private store: Store) {
    super();
  }

  @Input() set searchPackage(type: SearchPackage | null) {
    if (type) {
      this._searchType = type;
    }

    this.generateTitle();
  }

  chooseType(type: SearchPackage) {
    this.store.dispatch(new ChangeSearchPackage(type));
    this.nextChanged.emit();
  }

  protected generateTitle(): void {
    if (!this._searchType) {
      this.titleButtonChanged.emit(this.DEFAULT_TITLE);
      return;
    }

    switch (this._searchType) {
      case 'FULL':
        this.titleButtonChanged.emit('Hébergement, forfaits et matériel');
        break;
      case 'HALF':
        this.titleButtonChanged.emit('Hébergement et forfaits');
        break;
      case 'SINGLE':
        this.titleButtonChanged.emit('Hébergement seul');
        break;
    }
  }
}
