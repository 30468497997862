import {
  AsyncPipe,
  DecimalPipe,
  NgClass,
  NgForOf,
  NgIf
} from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ButtonComponent } from 'atomic-lib';
import { Observable, filter, switchMap } from 'rxjs';
import { FiltersState } from '../../../filters.state';
import { ExperienceService } from '../../../service/experience.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { Experience } from '../../../shared/models/activity/experience';
import { Cart } from '../../../shared/models/cart/cart';
import { ItemCart } from '../../../shared/models/package/item-cart';
import { Participant } from '../../../shared/models/participant/participant';
import { Period } from '../../../shared/models/period';
import { SharedModule } from '../../../shared/shared.module';
import { PackageUtils } from '../../../utils/package-utils';
import { RemoveItemsToCart } from '../cart.action';
import { CartState } from '../cart.state';
import { ExperiencePopupComponent } from './experience-popup/experience-popup.component';

@Component({
  selector: 'vsk-step-cart-activity',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    ButtonComponent,
    NgIf,
    SharedModule,
    ExperiencePopupComponent,
    AsyncPipe,
    DecimalPipe
  ],
  templateUrl: './step-cart-activity.component.html',
  styleUrl: './step-cart-activity.component.scss'
})
export class StepCartActivityComponent extends RxjsComponent {
  @Select(CartState.cart) cart$: Observable<Cart>;
  @Select(FiltersState.period) period$: Observable<Period>;

  selectedCategory: 'all' | string = 'all';
  categories: string[] = [];
  allExperiences: Experience[] = [];
  filteredExperiences: Experience[] = [];
  selectedExperience?: Experience;
  loading = true;

  @Input() activities: ItemCart[] = [];
  @Input() participants: Participant[] = [];
  @Input() resort: string;
  @Input() sessionId: string;

  @Output() previousChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextChange: EventEmitter<void> = new EventEmitter<void>();
  protected readonly PackageUtils = PackageUtils;

  constructor(
    private experienceService: ExperienceService,
    private store: Store
  ) {
    super();

    this.register(
      this.cart$
        .pipe(
          filter((cart) => !cart.isEmpty),
          switchMap((cart) =>
            this.experienceService.getExperiencesByResortName(cart.getResort())
          )
        )
        .subscribe((experiences) => {
          this.allExperiences = experiences;
          this.filteredExperiences = experiences;
          this.selectedCategory = 'all';
          this.categories = Array.from(
            new Set(
              experiences.map((experience) =>
                experience.activity.category.name.trim()
              )
            )
          ).sort();

          this.loading = false;
        })
    );
  }

  chooseCategory(category: 'all' | string) {
    this.selectedCategory = category;

    if (category === 'all') {
      this.filteredExperiences = this.allExperiences;
      return;
    }

    this.filteredExperiences = this.allExperiences.filter(
      (experience) =>
        experience.activity.category.name.trim() ===
        this.selectedCategory.trim()
    );
  }

  deleteActivity(id: string) {
    this.store.dispatch(new RemoveItemsToCart(this.sessionId, [id]));
  }

  participantsToTitle(participants: Participant[]) {
    if (!participants.length) {
      return '';
    }

    return participants
      .map(
        (participant) =>
          participant.firstname + ' (' + participant.age + ' ans)'
      )
      .join(', ');
  }
}
