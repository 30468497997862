<button (click)="chooseRegion(ALPES_DU_NORD)"
        (mouseenter)="hover = ALPES_DU_NORD"
        (mouseleave)="hover = 'none'"
        [ngClass]="{'selected': isRegionSelected(ALPES_DU_NORD)}"
        class="region">
  <img [ngClass]="{'hidden': !isRegionSelected(ALPES_DU_NORD)}"
       alt="Carte Alpes du Nord"
       height="144"
       src="assets/new-design/img/search-bar/region/selected/alpes-du-nord.svg"
       width="139">
  <img [ngClass]="{'hidden': isRegionSelected(ALPES_DU_NORD)}"
       alt="Carte Alpes du Nord"
       height="144"
       src="assets/new-design/img/search-bar/region/alpes-du-nord.svg"
       width="139">
  <p>Alpes du Nord</p>
</button>

<button (click)="chooseRegion(ALPES_DU_SUD)"
        (mouseenter)="hover = ALPES_DU_SUD"
        (mouseleave)="hover = 'none'"
        [ngClass]="{'selected': isRegionSelected(ALPES_DU_SUD)}"
        class="region">
  <img [ngClass]="{'hidden': !isRegionSelected(ALPES_DU_SUD)}"
       alt="Carte Alpes du Sud"
       height="144"
       src="assets/new-design/img/search-bar/region/selected/alpes-du-sud.svg"
       width="139">
  <img [ngClass]="{'hidden': isRegionSelected(ALPES_DU_SUD)}"
       alt="Carte Alpes du Sud"
       height="144"
       src="assets/new-design/img/search-bar/region/alpes-du-sud.svg"
       width="139">
  <p>Alpes du Sud</p>
</button>

<button (click)="chooseRegion(PYRENEES)"
        (mouseenter)="hover = PYRENEES"
        (mouseleave)="hover = 'none'"
        [ngClass]="{'selected': isRegionSelected(PYRENEES)}"
        class="region">
  <img [ngClass]="{'hidden': !isRegionSelected(PYRENEES)}"
       alt="Carte Pyrénées"
       height="144"
       src="assets/new-design/img/search-bar/region/selected/pyrenees.svg"
       width="139">
  <img [ngClass]="{'hidden': isRegionSelected(PYRENEES)}"
       alt="Carte Pyrénées"
       height="144"
       src="assets/new-design/img/search-bar/region/pyrenees.svg"
       width="139">
  <p>Pyrénées</p>
</button>

<button (click)="chooseRegion(JURA)"
        (mouseenter)="hover = JURA"
        (mouseleave)="hover = 'none'"
        [ngClass]="{'selected': isRegionSelected(JURA)}"
        class="region">
  <img [ngClass]="{'hidden': !isRegionSelected(JURA)}"
       alt="Carte Jura"
       height="144"
       src="assets/new-design/img/search-bar/region/selected/jura.svg"
       width="139">
  <img [ngClass]="{'hidden': isRegionSelected(JURA)}"
       alt="Carte Jura"
       height="144"
       src="assets/new-design/img/search-bar/region/jura.svg"
       width="139">
  <p>Jura</p>
</button>

<button (click)="chooseRegion(VOSGES)"
        (mouseenter)="hover = VOSGES"
        (mouseleave)="hover = 'none'"
        [ngClass]="{'selected': isRegionSelected(VOSGES)}"
        class="region">
  <img [ngClass]="{'hidden': !isRegionSelected(VOSGES)}"
       alt="Carte Vosges"
       height="144"
       src="assets/new-design/img/search-bar/region/selected/vosges.svg"
       width="139">
  <img [ngClass]="{'hidden': isRegionSelected(VOSGES)}"
       alt="Carte Vosges"
       height="144"
       src="assets/new-design/img/search-bar/region/vosges.svg"
       width="139">
  <p>Vosges</p>
</button>

<button (click)="chooseRegion(MASSIF_CENTRAL)"
        (mouseenter)="hover = MASSIF_CENTRAL"
        (mouseleave)="hover = 'none'"
        [ngClass]="{'selected': isRegionSelected(MASSIF_CENTRAL)}"
        class="region">
  <img [ngClass]="{'hidden': !isRegionSelected(MASSIF_CENTRAL)}"
       alt="Carte massif central"
       height="144"
       src="assets/new-design/img/search-bar/region/selected/massif-central.svg"
       width="139">
  <img [ngClass]="{'hidden': isRegionSelected(MASSIF_CENTRAL)}"
       alt="Carte massif central"
       height="144"
       src="assets/new-design/img/search-bar/region/massif-central.svg"
       width="139">
  <p>Massif central</p>
</button>
