<div class="wrapper">
  <button #skiClassButton
          (click)="open = !open"
          class="select">
    <div class="labels">
      <p class="label">Cours de ski</p>
      <p *ngIf="selectedSkiClass"
         class="sub-label">
        {{ selectedSkiClass.name }} · {{ getAgeRange(selectedSkiClass) }} · {{ selectedSkiClass.duration | toHour }}
      </p>
    </div>

    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#arrow-down'"></use>
    </svg>
  </button>

  <atc-dropdown (closeChanged)="open = false"
                [elementPosition]="buttonElement"
                [isOpen]="open"
                [left]="'0px'"
                [position]="'bottom-right'"
                [top]="'65px'"
                [width]="'100%'">
    <div class="content">
      <button (click)="changeSkiPass(skiClass)"
              *ngFor="let skiClass of skiClasses">
        {{ skiClass.name }} · {{ getAgeRange(skiClass) }} · {{ skiClass.duration | toHour }}
      </button>
    </div>
  </atc-dropdown>
</div>
