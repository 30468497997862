import { Establishment } from '../shared/models/accommodation/establishment';
import { Room } from '../shared/models/accommodation/room';

export interface Feature {
  icon: string;
  label: string;
}

export class AccommodationUtils {
  static getFeaturesRoom(room: Room): Feature[] {
    const features =
      room.features.map((feature) => {
        return {
          icon: feature.icon,
          label: feature.label
        } as Feature;
      }) || [];

    if (room.spa) {
      features.push({
        icon: 'spa',
        label: 'Spa'
      });
    }

    if (room.sauna) {
      features.push({
        icon: 'sauna',
        label: 'Sauna'
      });
    }

    if (room.hammam) {
      features.push({
        icon: 'hammam',
        label: 'Hammam'
      });
    }

    if (room.balconyTerrace) {
      features.push({
        icon: 'balcony',
        label: 'Balcon / Terrasse'
      });
    }

    if (room.tv) {
      features.push({
        icon: 'tv',
        label: 'Télévision'
      });
    }

    if (room.chimney) {
      features.push({
        icon: 'chimney',
        label: 'Cheminée'
      });
    }

    if (room.bbq) {
      features.push({
        icon: 'barbecue',
        label: 'Barbecue'
      });
    }

    if (room.pmr) {
      features.push({
        icon: 'pmr',
        label: 'PMR'
      });
    }

    if (room.parking) {
      features.push({
        icon: 'parking',
        label: 'Parking'
      });
    }

    if (room.animalsAdmitted) {
      features.push({
        icon: 'animals-admitted',
        label: 'Animaux admis'
      });
    }

    return features;
  }

  static getFeaturesEstablishment(establishment: Establishment): Feature[] {
    const features: Feature[] = [];

    if (establishment.pool) {
      features.push({
        icon: 'pool',
        label: 'Piscine'
      });
    }

    if (establishment.spa) {
      features.push({
        icon: 'spa',
        label: 'Spa'
      });
    }

    if (establishment.sauna) {
      features.push({
        icon: 'sauna',
        label: 'Sauna'
      });
    }

    if (establishment.hammam) {
      features.push({
        icon: 'hammam',
        label: 'Hammam'
      });
    }

    if (establishment.balconyTerrace) {
      features.push({
        icon: 'balcony',
        label: 'Balcon / Terrasse'
      });
    }

    if (establishment.tv) {
      features.push({
        icon: 'tv',
        label: 'Télévision'
      });
    }

    if (establishment.chimney) {
      features.push({
        icon: 'chimney',
        label: 'Cheminée'
      });
    }

    if (establishment.bbq) {
      features.push({
        icon: 'barbecue',
        label: 'Barbecue'
      });
    }

    if (establishment.pmr) {
      features.push({
        icon: 'pmr',
        label: 'PMR'
      });
    }

    if (establishment.parking) {
      features.push({
        icon: 'parking',
        label: 'Parking'
      });
    }

    if (establishment.animalsAdmitted) {
      features.push({
        icon: 'animals-admitted',
        label: 'Animaux admis'
      });
    }

    if (establishment.wifi) {
      features.push({
        icon: 'wifi',
        label: 'Wifi'
      });
    }

    if (establishment.childrenClub) {
      features.push({
        icon: 'baby-kit',
        label: 'Club enfants'
      });
    }
    return features;
  }
}
