import {
  GoogleSigninButtonModule,
  SocialAuthService
} from '@abacritt/angularx-social-login';
import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngxs/store';
import { ButtonComponent, InputComponent } from 'atomic-lib';
import moment from 'moment/moment';
import { AccountService } from '../../service/account.service';
import { NavigateService } from '../../service/navigate.service';
import { SigninComponent } from '../component/signin.component';
import { Account } from '../models/account';
import { Provider } from '../models/const/provider';

interface LoginForm {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}

@Component({
  selector: 'vsk-login-form',
  standalone: true,
  imports: [
    ButtonComponent,
    GoogleSigninButtonModule,
    InputComponent,
    NgIf,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss'
})
export class LoginFormComponent extends SigninComponent implements OnInit {
  error = '';
  loading = false;

  emailForm = new FormControl<string>('', [
    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
    Validators.required
  ]);
  passwordForm = new FormControl<string>('', [
    Validators.required,
    Validators.pattern(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*()-]).{8,}$'
    )
  ]);
  loginForm: FormGroup<LoginForm>;

  constructor(
    private authService: SocialAuthService,
    private accountService: AccountService,
    protected store: Store,
    protected navigateService: NavigateService
  ) {
    super(navigateService, store);
    document.title = 'Connexion - VeryMountain';
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup<LoginForm>({
      email: this.emailForm,
      password: this.passwordForm
    });

    this.register(
      this.authService.authState.subscribe((user) => {
        this.loading = true;
        this.register(
          this.accountService.isEmailExisting(user.email).subscribe(
            (provider) => {
              if (!provider) {
                const account: Account = new Account({
                  firstname: user.firstName,
                  lastname: user.lastName,
                  email: user.email,
                  birthdate: moment().add(-25, 'years'),
                  provider: Provider.GOOGLE,
                  password: 'vm' + user.id,
                  guestMode: false
                });
                this.signup(account);
                return;
              }

              if (provider === Provider.VERYSKI) {
                this.error =
                  'Ce compte ne peut pas être authentifié via Google';
                return;
              }

              if (provider === Provider.GOOGLE) {
                this.register(
                  this.dispatchConnect(user.email, 'vm' + user.id).subscribe(
                    () => this.afterConnect(),
                    (err) => this.afterConnectError(err)
                  )
                );
              }
            },
            (err) => this.afterConnectError(err)
          )
        );
      })
    );

    this.register(
      this.loginForm.valueChanges.subscribe(() => {
        if (this.loginForm.valid) {
          this.error = '';
        }
      })
    );
  }

  connect() {
    if (this.loginForm.invalid) {
      this.error = 'Des champs sont invalides';
      return;
    }

    this.loading = true;
    this.connexion();
  }

  signup(account: Account): void {
    this.register(
      this.accountService.createAccount(account).subscribe(
        () =>
          this.dispatchConnect(account.email, account.password).subscribe(
            () => this.afterConnect(),
            (err) => this.afterConnectError(err)
          ),
        (err) => this.afterConnectError(err)
      )
    );
  }
}
