import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';

export class PasswordUtils {
  public static passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const errors: any = {};
      if (!/[A-Z]/.test(control.value)) {
        errors.missingUpperCase =
          'Le mot de passe doit contenir au moins une majuscule';
      }
      if (!/[a-z]/.test(control.value)) {
        errors.missingLowerCase =
          'Le mot de passe doit contenir au moins une minuscule';
      }
      if (!/[0-9]/.test(control.value)) {
        errors.missingNumber =
          'Le mot de passe doit contenir au moins un chiffre';
      }
      if (!/[#?!@$%^&*()-]/.test(control.value)) {
        errors.missingSpecial =
          'Le mot de passe doit contenir au moins un caractère spécial (#?!@$%^&*()-)';
      }
      if (control.value.length < 8) {
        errors.minLength =
          'Le mot de passe doit contenir au moins 8 caractères';
      }

      return Object.keys(errors).length > 0 ? errors : null;
    };
  }

  public static getPasswordErrorMessage(control: FormControl<string | null>) {
    if (control.hasError('required')) {
      return 'Mot de passe obligatoire';
    }

    if (control.hasError('missingUpperCase')) {
      return 'Le mot de passe doit contenir au moins une majuscule';
    }

    if (control.hasError('missingLowerCase')) {
      return 'Le mot de passe doit contenir au moins une minuscule';
    }

    if (control.hasError('missingNumber')) {
      return 'Le mot de passe doit contenir au moins un chiffre';
    }

    if (control.hasError('missingSpecial')) {
      return 'Le mot de passe doit contenir au moins un caractère spécial (#?!@$%^&*()-)';
    }

    if (control.hasError('minLength')) {
      return 'Le mot de passe doit contenir au moins 8 caractères';
    }

    return '';
  }
}
