<div class="wrapper">
  <atc-button #userButton
              (click)="open = !open"
              [iconRightSide]="true"
              icon="chevron-down">
    Mon compte
  </atc-button>

  <atc-dropdown (closeChanged)="open = false"
                [elementPosition]="buttonElement"
                [isOpen]="open"
                [left]="'0px'"
                [position]="'bottom-right'"
                [top]="'35px'"
                [width]="'210px'">
    <div class="content">
      <a (click)="open = false"
         routerLink="/mon-compte/mes-commandes"
         routerLinkActive="true">
        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#activity'"></use>
        </svg>

        Mes commandes
      </a>
      <a (click)="open = false"
         routerLink="/mon-compte/mes-informations"
         routerLinkActive="true">
        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#information-circle'"></use>
        </svg>

        Mes informations
      </a>
      <a (click)="open = false"
         routerLink="/mon-compte/mes-cartes-physiques"
         routerLinkActive="true">
        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#credit-card'"></use>
        </svg>

        Mes cartes
      </a>
      <button (click)="disconnect()">
        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#logout'"></use>
        </svg>

        Se déconnecter
      </button>
    </div>
  </atc-dropdown>
</div>
