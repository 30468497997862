<atc-dropdown (closeChanged)="closeEventChanged.emit()"
              [classes]="['no-padding']"
              [elementPosition]="_parent"
              [isOpen]="true"
              [left]="theme === 'thick' ? '90px' : '0px'"
              [position]="'bottom-left'"
              [top]="theme === 'thick' ? '60px' : '40px'"
              [width]="'800px'"
              class="hide-small-desktop-and-below">
  <div class="content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</atc-dropdown>

<div class="content hide-small-desktop-and-above">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
  <div class="filters">
    <div class="filters-header">
      <p>Quelles expériences voulez-vous vivre ?</p>

      <div *ngIf="nbActivitiesSelected > 0"
           class="selected-activities">
        <p>{{ nbActivitiesSelected }} {{ nbActivitiesSelected > 1 ? 'activités sélectionnées' : 'activité sélectionnée' }}</p>
        <button (click)="unselectAllActivities()">Tout désélectionner</button>
      </div>
    </div>

    <div class="filters-buttons">
      <button (click)="chooseCategory('all')"
              [ngClass]="{'selected': selectedCategory === 'all'}"
              class="tag">
        Tout
      </button>

      <button (click)="chooseCategory(category)"
              *ngFor="let category of categories"
              [ngClass]="{'selected': category === selectedCategory}"
              class="tag">
        {{ category }}
      </button>
    </div>
  </div>

  <div class="activities">
    <button (click)="chooseActivity(activity)"
            *ngFor="let activity of filteredActivities; trackBy: identity"
            class="activity">
      <div [ngClass]="{'selected': activity.selected}"
           class="img-wrapper">
        <img [alt]="'Icône ' + activity.name" [src]="activity.urlIcon" height="80" width="80" />
      </div>
      <p>{{ activity.name }}</p>
    </button>
  </div>
</ng-template>
