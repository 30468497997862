<div *ngIf="promoCode"
     class="flex flex-row justify-between gap-4 self-stretch items-center">
  <p *ngIf="isPromoCodeApplied() as text" class="flex-1">{{ text }}</p>

  <div class="cta">
    <atc-tag [color]="colorMsg">
      {{ promoCode?.name }} : {{ promoCode?.displayAppliedNumber }}
    </atc-tag>

    <atc-button (click)="deletePromoCode()"
                *ngIf="paymentPage"
                color="white"
                icon="trash">
    </atc-button>
  </div>
</div>

<div *ngIf="!promoCode && paymentPage"
     class="cta">
  <atc-input (enterKeyPressed)="addPromoCode()"
             [control]="control"
             placeholder="Code promotionnel">
  </atc-input>

  <atc-button (click)="addPromoCode()"
              color="secondary"
              size="medium">
    Ajouter
  </atc-button>
</div>
