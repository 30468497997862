<button (click)="closeDrawer()"
        [ngClass]="{'closing': closing}"
        class="close-button">
  <svg>
    <use [attr.xlink:href]="'assets/icons/icons.svg#close'"></use>
  </svg>
</button>
<div [ngClass]="{'closing': closing}"
     class="drawer">
  <div class="wrapper">
    <div class="content">
      <atc-expansion-card (expandedChange)="stateChange.emit($event ? 'type' : 'none')"
                          [expanded]="state === 'type'"
                          [subtitle]="titleSearchType"
                          title="Type de recherche">
        <p header>Type de recherche</p>
        <div content>
          <ng-content select="[typesearch]"></ng-content>
        </div>
      </atc-expansion-card>

      <atc-expansion-card (expandedChange)="stateChange.emit($event ? 'destination' : 'none')"
                          [expanded]="state === 'destination'"
                          [subtitle]="titleDestination"
                          title="Destination">
        <div content>
          <ng-content select="[where]"></ng-content>
        </div>
      </atc-expansion-card>

      <atc-expansion-card (expandedChange)="stateChange.emit($event ? 'dates' : 'none')"
                          [expanded]="state === 'dates'"
                          [subtitle]="titleDates"
                          subtitle="Ajouter une date"
                          title="Dates">
        <div content>
          <ng-content select="[dates]"></ng-content>
        </div>
      </atc-expansion-card>

      <atc-expansion-card (expandedChange)="stateChange.emit($event ? 'participants' : 'none')"
                          [expanded]="state === 'participants'"
                          [subtitle]="titleParticipants"
                          title="Voyageurs">
        <div content>
          <ng-content select="[travelers]"></ng-content>
        </div>
      </atc-expansion-card>

      <atc-expansion-card (expandedChange)="stateChange.emit($event ? 'activities' : 'none')"
                          [expanded]="state === 'activities'"
                          [subtitle]="titleActivities"
                          title="Activités">
        <div content>
          <ng-content select="[experiences]"></ng-content>
        </div>
      </atc-expansion-card>
    </div>

    <div class="footer">
      <button (click)="clear()" class="clear">Tout effacer</button>
      <button (click)="search()" class="search">
        <img alt="Rechercher" height="24" ngSrc="assets/new-design/svg/icons/search.svg" width="24">
        Rechercher
      </button>
    </div>
  </div>
</div>
