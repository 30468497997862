import { NgIf, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { TriggerAlert } from '../../app.action';
import { ContactService } from '../../service/contact.service';
import { Alert } from '../models/alert';

@Component({
  selector: 'vsk-footer',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  error = '';
  emailForm: FormControl<string | null> = new FormControl<string>(
    '',
    Validators.email
  );

  constructor(
    private contactService: ContactService,
    private store: Store
  ) {}

  register() {
    if (this.emailForm.invalid || !this.emailForm.value?.length) {
      this.error = 'Email non valide';
      return;
    }

    this.contactService
      .registerEmail(this.emailForm.value as string, "Page d'accueil")
      .subscribe(
        () => {
          this.error = '';
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                level: 'success',
                message:
                  'Votre inscription à notre newsletter a bien été prise en compte !',
                timeout: 5000
              })
            )
          );
          this.emailForm.setValue('');
        },
        () =>
          (this.error =
            "Une erreur s'est produite, veuillez réessayer dans quelques instants")
      );
  }
}
