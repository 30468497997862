import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageDirective } from 'atomic-lib';
import { FooterComponent } from '../../shared/footer/footer.component';
import { NavbarComponent } from '../../shared/navbar/navbar.component';

@Component({
  selector: 'vsk-not-found',
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    LazyLoadImageDirective,
    NavbarComponent,
    FooterComponent
  ],
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  constructor() {
    document.title = 'Page introuvable - VeryMountain';
  }
}
