<section class="left-side">
  <a routerLink="/"
     routerLinkActive="true">
    <img alt="Logo VeryMountain"
         class="hide-small-desktop-and-below" height="25"
         ngSrc="assets/new-design/svg/logo-dark.svg" width="173">
  </a>

  <atc-stepper>
    <!-- Step 1 - PACKAGE -->
    <atc-step [done]="true"
              [order]="0"
              [show]="false"
              label="Panier">
    </atc-step>

    <!-- Step 2 - ACTIVITIES -->
    <atc-step [done]="true"
              [order]="1"
              [show]="false"
              label="Activités">
    </atc-step>

    <!-- Step 3 - PAYMENT -->
    <atc-step [done]="true"
              [order]="2"
              [show]="false"
              label="Paiement">
    </atc-step>

    <!-- Step 4 - CONFIRMATION -->
    <atc-step [done]="true"
              [last]="true"
              [order]="3"
              [show]="true"
              class="content"
              label="Confirmation">
      <vsk-step-confirmation [cart]="cart"
                             [loading]="loading"
                             [paymentIncomplete]="isPaymentIncomplete">
      </vsk-step-confirmation>
    </atc-step>
  </atc-stepper>
</section>

<section class="right-side hide-small-desktop-and-below">
  <a class="phone"
     href="tel:+33465847469">
    <img alt="Téléphone" height="13" ngSrc="assets/new-design/svg/icons/phone.svg" width="9">
    <span>04 65 84 74 69</span>
  </a>

  <vsk-cart-card-summary [cart]="cart"
                         [participants]="(participants$ | async) || []"
                         [showNext]="false"
                         [showPaymentInfo]="false">
  </vsk-cart-card-summary>
</section>

<vsk-cart-card-summary-mobile [cart]="cart"
                              [participants]="(participants$ | async) || []"
                              [showNext]="false"
                              [showPaymentInfo]="false"
                              class="hide-small-desktop-and-above">
</vsk-cart-card-summary-mobile>

