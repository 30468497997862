import { DecimalPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ButtonComponent } from 'atomic-lib';
import _ from 'lodash';
import { ItemAccommodation } from '../../../shared/models/accommodation/item-accommodation';
import { Cart } from '../../../shared/models/cart/cart';
import { Participant } from '../../../shared/models/participant/participant';
import { CartItemSkiEquipment } from '../../../shared/models/ski-equipment/cart-item-equipment';
import { SharedModule } from '../../../shared/shared.module';
import { UrlUtils } from '../../../utils/url-utils';

@Component({
  selector: 'vsk-cart-card-summary',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    RouterLinkActive,
    DecimalPipe,
    ButtonComponent,
    NgOptimizedImage,
    NgForOf,
    SharedModule
  ],
  templateUrl: './cart-card-summary.component.html',
  styleUrl: './cart-card-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartCardSummaryComponent {
  titleParticipants: string;

  @Input() cart: Cart | null;
  @Input() showNext = true;
  @Input() showPaymentInfo = true;
  @Output() nextChange: EventEmitter<void> = new EventEmitter<void>();

  @Input() set participants(participants: Participant[]) {
    this.titleParticipants = `${participants.length} voyageur${participants.length > 1 ? 's' : ''}`;
    const nbChildren = participants.filter(
      (participant) => participant.age < 18
    ).length;

    if (nbChildren > 0) {
      this.titleParticipants += ` (dont ${nbChildren} enfant${nbChildren > 1 ? 's' : ''})`;
    }
  }

  getRouterParamsFromItem(itemAccommodation: ItemAccommodation) {
    return {
      establishmentId: itemAccommodation.establishment.id,
      roomCode: itemAccommodation.room.codeRoom,
      partnerCode: itemAccommodation.establishment.partnerCode,
      startDate: itemAccommodation.startDate.format('YYYY-MM-DD'),
      endDate: itemAccommodation.endDate.format('YYYY-MM-DD')
    };
  }

  getRouterLinkFromItem(itemAccommodation: ItemAccommodation) {
    return `/station/${UrlUtils.encodeToURL(itemAccommodation.resort)}/logement/${UrlUtils.encodeToURL(itemAccommodation.establishment.name)}`;
  }

  groupBy(skiEquipments: CartItemSkiEquipment[]) {
    return _.chain(skiEquipments)
      .groupBy('hash')
      .map((value, key) => {
        return {
          pack: key,
          equipment: value[0],
          size: value.length,
          price: value
            .map((equipment) => equipment.totalPrice)
            .reduce((prev, curr) => prev + curr)
        };
      })
      .value();
  }
}
