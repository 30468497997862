import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Session } from '../shared/models/session/session';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService extends RepositoryService {
  private URL_SESSION = 'api/webapp/session';

  getSession(session: string): Observable<Session> {
    return this.get(this.URL_SESSION + `?sessionId=${session}`).pipe(
      map((session: Session) => new Session({ ...session }))
    );
  }

  upsert(session: Session): Observable<void> {
    return this.post(this.URL_SESSION + '/upsert', session);
  }
}
