import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { UrlUtils } from '../../utils/url-utils';

@Component({
  selector: 'vsk-card-resort',
  standalone: true,
  imports: [NgIf, RouterLinkActive, RouterLink],
  templateUrl: './card-resort.component.html',
  styleUrl: './card-resort.component.scss'
})
export class CardResortComponent {
  @Input() icon = '';
  @Input() background = '';
  @Input() name = '';
  @Input() bigPhrase = '';
  @Input() smallPhrase = '';
  @Input() phraseStyle: 'big-first' | 'small-first' = 'big-first';
  @Input() region = '';

  getLink() {
    return `/station/${UrlUtils.encodeToURL(this.name)}/informations`;
  }
}
