import { NgForOf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DropdownComponent } from 'atomic-lib';
import { Internship } from '../../models/partner/internship';
import { TimeSlotExperience } from '../../models/partner/time-slot-experience';

@Component({
  selector: 'vsk-timeslot-dropdown',
  standalone: true,
  imports: [DropdownComponent, NgForOf],
  templateUrl: './timeslot-dropdown.component.html',
  styleUrl: './timeslot-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeslotDropdownComponent {
  @ViewChild('timeSlotButton') buttonElement: ElementRef<HTMLElement>;

  open = false;

  @Input() selectedTimeSlot: TimeSlotExperience | Internship;
  @Input() timeSlots: (TimeSlotExperience | Internship)[] = [];

  @Output() selectedTimeSlotChange: EventEmitter<
    TimeSlotExperience | Internship
  > = new EventEmitter<TimeSlotExperience | Internship>();

  changeTimeSlot(timeSlot: TimeSlotExperience | Internship) {
    this.selectedTimeSlot = timeSlot;
    this.selectedTimeSlotChange.emit(timeSlot);
    this.open = false;
  }

  getSubLabel(timeSlot: TimeSlotExperience | Internship) {
    if (!timeSlot) {
      return '';
    }

    if (timeSlot instanceof Internship) {
      return (
        timeSlot.dateStart.format('DD MMM') +
        ' - ' +
        timeSlot.dateEnd.format('DD MMM')
      );
    }

    return (
      timeSlot.hourStart.format('HH:mm') +
      ' - ' +
      timeSlot.hourEnd.format('HH:mm')
    );
  }

  getTitle(timeSlot: TimeSlotExperience | Internship) {
    if (!timeSlot) {
      return 'Créneau';
    }

    if (timeSlot instanceof Internship) {
      return 'Stage';
    }

    return 'Créneau';
  }
}
