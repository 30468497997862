import { DecimalPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { ButtonComponent, LoaderComponent, PopupComponent } from 'atomic-lib';
import { Moment } from 'moment';
import { ExperienceService } from '../../../../service/experience.service';
import { FirstSkiDateDropdownComponent } from '../../../../shared/dropdown/first-ski-date-dropdown/first-ski-date-dropdown.component';
import { ParticipantDropdownComponent } from '../../../../shared/dropdown/participant-dropdown/participant-dropdown.component';
import { SkiClassDropdownComponent } from '../../../../shared/dropdown/ski-class-dropdown/ski-class-dropdown.component';
import { SkiCriteria } from '../../../../shared/dropdown/ski-pass-dropdown/ski-pass-dropdown.component';
import { TimeslotDropdownComponent } from '../../../../shared/dropdown/timeslot-dropdown/timeslot-dropdown.component';
import { Experience } from '../../../../shared/models/activity/experience';
import { Price } from '../../../../shared/models/cart/price';
import { Criteria } from '../../../../shared/models/criteria';
import { Participant } from '../../../../shared/models/participant/participant';
import { Internship } from '../../../../shared/models/partner/internship';
import { TimeSlotExperience } from '../../../../shared/models/partner/time-slot-experience';
import { Period } from '../../../../shared/models/period';
import { SkiClass } from '../../../../shared/models/ski-class/ski-class';
import { DateUtils } from '../../../../utils/date-utils';
import { AddToCart } from '../../cart.action';

@Component({
  selector: 'vsk-ski-class-popup',
  standalone: true,
  imports: [
    ButtonComponent,
    PopupComponent,
    LoaderComponent,
    NgIf,
    FirstSkiDateDropdownComponent,
    ParticipantDropdownComponent,
    SkiClassDropdownComponent,
    TimeslotDropdownComponent,
    DecimalPipe
  ],
  templateUrl: './ski-class-popup.component.html',
  styleUrl: './ski-class-popup.component.scss'
})
export class SkiClassPopupComponent {
  loading = true;
  error = '';
  price?: Price;
  allParticipants: Participant[] = [];
  eligibleParticipants: Participant[] = [];

  days: Moment[] = [];
  timeSlotsForSelectedDay: (TimeSlotExperience | Internship)[] = [];

  selectedSkiClass: SkiClass;
  selectedExperience: Experience;
  selectedParticipants: Participant[] = [];
  selectedFirstDay: Moment;
  selectedTimeSlot: TimeSlotExperience | Internship;

  @Output() closeChangeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() criteria: SkiCriteria;
  @Input() period: Period;

  constructor(
    private experienceService: ExperienceService,
    private store: Store
  ) {}

  @Input() set participants(participants: Participant[]) {
    this.allParticipants = participants;
    this.eligibleParticipants = participants;
  }

  changeSkiClass(skiClass: SkiClass) {
    this.selectedSkiClass = skiClass;
    this.loading = true;
    this.fetchExperience();
    this.fetchPricing();
  }

  changeFirstDay(firstDay: Moment) {
    this.selectedFirstDay = firstDay;
    this.timeSlotsForSelectedDay = DateUtils.combineTimeSlots(
      this.selectedExperience.timeSlots,
      this.selectedExperience.internships,
      firstDay
    );

    this.selectedTimeSlot = this.timeSlotsForSelectedDay[0];
    this.fetchPricing();
  }

  changeTimeSlot(timeSlot: TimeSlotExperience | Internship) {
    this.selectedTimeSlot = timeSlot;
    this.fetchPricing();
  }

  changeSelectedParticipants(participants: Participant[]) {
    this.selectedParticipants = participants;
    this.fetchPricing();
  }

  fetchPricing() {
    if (!this.selectedSkiClass) {
      return;
    }

    if (!this.selectedParticipants?.length) {
      return;
    }

    if (!this.selectedTimeSlot) {
      return;
    }

    this.loading = true;
    this.price = undefined;
    this.experienceService
      .getPricingForExperience(
        this.selectedExperience.id,
        this.selectedParticipants.map((participant) =>
          participant.birthdate.format('YYYY-MM-DD')
        ),
        this.selectedFirstDay
      )
      .subscribe(
        (price) => {
          this.price = price;
          this.loading = false;
        },
        () => (this.loading = false)
      );
  }

  addToCart() {
    if (!this.selectedExperience) {
      return;
    }

    if (!this.selectedParticipants?.length) {
      return;
    }

    if (!this.selectedFirstDay) {
      return;
    }

    if (!this.selectedTimeSlot) {
      return;
    }

    const participants = this.selectedParticipants.map(
      (participant) => participant.uuid
    );

    this.loading = true;

    this.store
      .dispatch(
        new AddToCart(
          this.selectedExperience?.id,
          participants,
          this.selectedFirstDay,
          this.criteria.resortName,
          this.selectedTimeSlot.id ||
            (this.selectedTimeSlot as TimeSlotExperience).timeSlotModelId
        )
      )
      .subscribe(
        () => this.closeChangeEvent.emit(),
        () => {
          this.error =
            'Une erreur est survenue, veuillez réessayer dans quelques instants';
          this.loading = false;
        }
      );
  }

  private fetchExperience() {
    this.experienceService
      .getExperienceById(
        {
          startDate: this.period.startDate,
          endDate: this.period.endDate
        } as Criteria,
        this.selectedSkiClass.experienceId
      )
      .subscribe(
        (experience) => {
          this.selectedExperience = experience;
          const dates = [
            ...this.selectedExperience.timeSlots.map(
              (timeSlot) => timeSlot.dateStart
            ),
            ...this.selectedExperience.internships.map(
              (internship) => internship.dateStart
            )
          ];

          this.days = DateUtils.uniqueMoment(dates, 'DD-MM-YYYY');

          this.eligibleParticipants = this.allParticipants.filter(
            (participant) =>
              !!this.selectedExperience.ageRanges.find((ageRange) =>
                DateUtils.isSameCategoryByAge(participant.age, ageRange)
              )
          );
          this.changeFirstDay(this.days[0]);
          this.changeSelectedParticipants(this.eligibleParticipants);
        },
        () => (this.loading = false)
      );
  }
}
