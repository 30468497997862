import { NgModule } from '@angular/core';
import { FooterComponent } from '../../shared/footer/footer.component';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { SharedModule } from '../../shared/shared.module';
import { AboutUsRoutingModule } from './about-us-routing.module';
import { AboutUsComponent } from './about-us.component';

@NgModule({
  declarations: [AboutUsComponent],
  imports: [
    SharedModule,
    AboutUsRoutingModule,
    FooterComponent,
    NavbarComponent
  ],
  exports: [AboutUsComponent]
})
export class AboutUsModule {}
