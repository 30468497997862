import { NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OptionElement, SelectComponent } from 'atomic-lib';
import { filter } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Participant } from '../../../models/participant/participant';

@Component({
  selector: 'vsk-search-bar-participant-form',
  standalone: true,
  imports: [SelectComponent, NgOptimizedImage],
  templateUrl: './search-bar-participant-form.component.html',
  styleUrl: './search-bar-participant-form.component.scss'
})
export class SearchBarParticipantFormComponent {
  @Output() ageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeChanged: EventEmitter<void> = new EventEmitter<void>();

  ageItems: OptionElement<number>[] = [
    {
      id: 0,
      label: '0 an'
    },
    {
      id: 1,
      label: '1 an'
    }
  ];
  ageControl: FormControl<number | null> = new FormControl<number>(
    25,
    Validators.required
  );
  age: number;

  constructor() {
    for (let i = 2; i < 18; i++) {
      this.ageItems.push({
        id: i,
        label: i + ' ans'
      });
    }

    this.ageItems.push({
      id: 25,
      label: '+ de 17 ans'
    });
  }

  _participant: Participant;

  @Input() set participant(participant: Participant) {
    this._participant = participant;
    this.ageControl.setValue(participant.age > 18 ? 25 : participant.age);
    this.age = participant.age;

    this.ageControl.valueChanges
      .pipe(
        filter((val) => !!val),
        distinctUntilChanged(),
        debounceTime(100),
        filter((val) => val !== this.age)
      )
      .subscribe((age) => this.ageChanged.emit(age as number));
  }
}
