export enum LocationType {
  ESF = 'ESF',
  CABLE_CAR = 'CABLE_CAR'
}

export class PointOfInterest {
  resortId: number;
  latitude: number;
  longitude: number;
  type: LocationType;

  constructor(obj: Partial<PointOfInterest>) {
    Object.assign(this, obj);
  }
}
