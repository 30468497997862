<vsk-navbar></vsk-navbar>

<div class="vsk__margin vsk__width-center content">
  <h1 class="vsk__text-center">Mentions légales</h1>
  <p class="vsk__text-center">
    Merci de lire attentivement les présentes modalités d’utilisation du présent site avant de le parcourir. <br>
    En vous connectant sur ce site, vous acceptez sans réserve les présentes modalités.
  </p>
  <h4>
    Editeur du site
  </h4>
  <p>
    VeryMountain<br>
    16 rue Paul Chenavard,<br>
    69001 Lyon<br>
    France<br>
    (+33) 4 65 84 74 69<br>
  </p>
  <h4>
    Conditions d’utilisation
  </h4>
  <p>
    Le site accessible par l'url suivante : www.verymountain.fr est exploité dans le respect de
    la législation française. L’utilisation de ce site est régie par les présentes conditions
    générales. En utilisant le site, vous reconnaissez avoir pris connaissance de ces
    conditions et les avoir acceptées. Celles-ci pourront êtres modifiées à tout moment
    et sans préavis par le propriétaire du site internet. Le propriétaire ne saurait être tenu
    pour responsable en aucune manière d’une mauvaise utilisation du service.
  </p>
  <h4>
    Responsable éditorial
  </h4>
  <p>
    VeryMountain<br>
    16 rue Paul Chenavard,<br>
    69001 Lyon<br>
    France<br>
    (+33) 6 75 01 88 48<br>
  </p>
  <h4>
    Limitation de responsabilité
  </h4>
  <p>
    Les informations contenues sur ce site sont aussi précises que possible et le site
    est périodiquement remis à jour, mais peut toutefois contenir des inexactitudes, des
    omissions ou des lacunes. Si vous constatez une lacune, erreur ou ce qui paraît
    être un dysfonctionnement, merci de bien vouloir le signaler par email ou via le
    formulaire de contact en décrivant le problème de la manière la plus précise
    possible (page posant problème, action déclenchante, type d’ordinateur et de
    navigateur utilisé, …). Tout contenu téléchargé se fait aux risques et périls de
    l’utilisateur et sous sa seule responsabilité. En conséquence, VeryMountain ne
    saurait être tenu responsable d’un quelconque dommage subi par l’ordinateur de
    l’utilisateur ou d’une quelconque perte de données consécutives au
    téléchargement. Les photos sont non contractuelles. Les liens hypertextes mis en
    place dans le cadre du présent site internet en direction d’autres ressources
    présentes sur le réseau Internet ne sauraient engager la responsabilité de
    VeryMountain.
  </p>
  <h4>
    Litiges
  </h4>
  <p>
    Les présentes conditions sont régies par les lois françaises et toute contestation ou litige qui pourraient naître
    de l’interprétation ou de l’exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le
    siège social de la société VeryMountain. La langue de référence, pour le règlement de contentieux éventuels, est le
    français.
  </p>
  <h4>
    Droit d’accès
  </h4>
  <p>
    En application de cette loi, les internautes disposent d’un droit d’accès, de
    rectification, de modification et de suppression concernant les données qui les
    concernent personnellement. Ce droit peut être exercé par voie postale auprès de
    VeryMountain, 16 rue Paul Chenavard, 69001 Lyon ou par voie électronique à
    l’adresse email suivante : hello&#64;verymountain.fr. Les informations personnelles
    collectées ne sont en aucun cas confiées à des tiers hormis pour l’éventuelle bonne
    exécution de la prestation commandée par l’internaute.
  </p>
  <h4>
    Confidentialité
  </h4>
  <p>
    Vos données personnelles sont confidentielles et ne seront en aucun cas communiquées à des tiers hormis pour la
    bonne exécution de la prestation.
  </p>
  <h4>
    Propriété intellectuelle
  </h4>
  <p>
    Tout le contenu du présent site, incluant, de façon non limitative, les graphismes,
    images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise
    en forme sont la propriété exclusive de VeryMountain à l’exception des marques,
    logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs. Toute
    reproduction, distribution, modification, adaptation, retransmission ou publication,
    même partielle, de ces différents éléments est strictement interdite sans l’accord
    exprès par écrit de VeryMountain. Cette représentation ou reproduction, par
    quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les
    articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect
    de cette interdiction constitue une contrefaçon pouvant engager la responsabilité
    civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés
    pourraient intenter une action en justice à votre encontre. VeryMountain est
    identiquement propriétaire des « droits des producteurs de bases de données »
    visés au Livre III, Titre IV, du Code de la Propriété Intellectuelle (loi n° 98-536 du 1er
    juillet 1998) relative aux droits d’auteur et aux bases de données. Les utilisateurs et
    visiteurs du site internet peuvent mettre en place un hyperlien en direction de ce
    site, mais uniquement en direction de la page d’accueil, accessible à l’URL
    suivante : www.verymountain.fr, à condition que ce lien s’ouvre dans une nouvelle
    fenêtre. En particulier un lien vers une sous page (« lien profond ») est interdit, ainsi
    que l’ouverture du présent site au sein d’un cadre (« framing »), sauf l’autorisation
    express et préalable de VeryMountain. Pour toute demande d’autorisation ou
    d’information, veuillez nous contacter par email : hello&#64;verymountain.fr. Des
    conditions spécifiques sont prévues pour la presse.
  </p>
  <h4>
    Hébergeur
  </h4>
  <p>
    OVH
    www.ovh.fr
  </p>
  <h4>
    Condition de service
  </h4>
  <p>
    Ce site est proposé en langages HTML5 et CSS3, pour un meilleur confort
    d’utilisation et un graphisme plus agréable, nous vous recommandons de recourir à
    des navigateurs modernes comme Safari, Firefox, Chrome...
  </p>
  <h4>
    Information et exclusion
  </h4>
  <p>
    VeryMountain met en œuvre tous les moyens dont il dispose, pour assurer une information fiable et une mise à jour
    fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L’internaute devra donc
    s’assurer de l’exactitude des informations auprès de VeryMountain, et signaler toutes modifications du site qu’il
    jugerait utile. VeryMountain n’est en aucun cas responsable de l’utilisation faite de ces informations, et de tout
    préjudice direct ou indirect pouvant en découler.
  </p>
  <h4>
    Cookies
  </h4>
  <p>
    Un « cookie » est un petit fichier texte qui est enregistré et/ou lu par votre
    navigateur Internet sur le disque dur de l’appareil que vous utilisez (par ex.
    ordinateur de bureau, ordinateur portable ou smartphone) par les sites Internet que vous visitez. <br />
    Les cookies sont placés par nos soins sur la plateforme VeryMountain.</p>
  <p>
    Les types de cookies suivants sont utilisés sur la plateforme :
  </p>
  <ul>
    <li>Cookies essentiels :</li>
    <p>
      Ces cookies sont indispensables pour utiliser VeryMountain et ses fonctionnalités de
      base comme la connexion au compte ou la réservation de forfaits. Ils permettent
      également de prévenir les éventuelles attaques de sécurité. Vous ne pouvez pas les
      désactiver, car ils sont essentiels au bon fonctionnement de VeryMountain.
    </p>
    <li>Cookies de performance :</li>
    <p>
      Ces cookies nous permettent d’identifier les pages les plus appréciées et les moins
      appréciées par nos utilisateurs, ainsi que les plus utiles. Grâce à eux, nous
      améliorons l’efficacité de l’appli en nous assurant, par exemple, que les utilisateurs
      trouvent facilement ce qu’ils recherchent. Si vous ne les autorisez pas nous ne
      seront pas informés de vos visites sur VeryMountain.
    </p>
    <li>Cookies publicitaires et de ciblage :</li>
    <p>
      Ces cookies nous informent de votre visite sur la plateforme, sur les pages
      consultées et sur les liens sur lesquels vous cliquez. Ils nous sont utiles pour vous
      proposer des publicités pertinentes, et nous aident à analyser l’efficacité de nos
      campagnes publicitaires. Ces cookies sont déposés par nous-mêmes à des fins
      d’analyse. Si vous ne les autorisez pas, les publicités proposées correspondront
      moins à vos intérêts.
    </p>
  </ul>
  <h4>
    Liens hypertextes
  </h4>
  <p>
    Les sites internet de VeryMountain peuvent offrir des liens vers d’autres sites internet
    ou d’autres ressources disponibles sur Internet. VeryMountain ne dispose d’aucun
    moyen pour contrôler les sites en connexion avec son site internet. VeryMountain
    ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Il
    ne peut être tenu pour responsable de tout dommage, de quelque nature que ce
    soit, résultant du contenu de ces sites ou sources externes, et notamment des
    informations, produits ou services qu’ils proposent, ou de tout usage qui peut être
    fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à
    l’internaute, qui doit se conformer à leurs conditions d’utilisation. Les utilisateurs, les
    abonnés et les visiteurs du site internet de VeryMountain ne peuvent mettre en
    place un hyperlien en direction de ce site sans l’autorisation express et préalable de
    VeryMountain. Dans l’hypothèse où un utilisateur ou visiteur souhaiterait mettre en
    place un hyperlien en direction du site internet de VeryMountain, il lui appartiendra
    d’adresser un email accessible sur le site afin de formuler sa demande de mise en
    place d’un hyperlien. VeryMountain se réserve le droit d’accepter ou de refuser un
    hyperlien sans avoir à en justifier sa décision.
  </p>
  <h4>
    Recherche
  </h4>
  <p>
    En outre, le renvoi sur un site internet pour compléter une information recherchée ne
    signifie en aucune façon que VeryMountain reconnaît ou accepte quelque
    responsabilité quant à la teneur ou à l’utilisation dudit site.
  </p>
  <h4>
    Précautions d’usage
  </h4>
  <p>
    Il vous incombe par conséquent de prendre les précautions d’usage nécessaires
    pour vous assurer que ce que vous choisissez d’utiliser ne soit pas entaché
    d’erreurs voire d’éléments de nature destructrice tels que virus, trojans, etc...
  </p>
  <h4>
    Responsabilités
  </h4>
  <p>
    Aucune autre garantie n’est accordée au client, auquel incombe l’obligation de
    formuler clairement ses besoins et le devoir de s’informer. Si des informations
    fournies par VeryMountain apparaissent inexactes, il appartiendra au client de
    procéder lui-même à toutes vérifications de la cohérence ou de la vraisemblance
    des résultats obtenus. VeryMountain ne sera en aucune façon responsable vis à vis
    des tiers de l’utilisation par le client des informations ou de leur absence contenues
    dans ses produits y compris son site Internet.
  </p>
  <h4>Assurance et caution :</h4>
  <p>VeryMountain est immatriculée au registre des opérateurs de voyages et de séjours
    sous le numéro IM069230013. Elle est assurée par Groupama (50 Rue de St Cyr, 69009 Lyon, France).
    Elle a pour garant Groupama Crédit assurance et caution (50 Rue de St Cyr, 69009 Lyon, France).
  </p>
  <h4>Assurance et caution :</h4>
  <p>VeryMountain est immatriculée au registre des opérateurs de voyages et de séjours
    sous le numéro IM069230013. Elle est assurée par Groupama (50 Rue de St Cyr, 69009 Lyon, France).
    Elle a pour garant Groupama Crédit assurance et caution (50 Rue de St Cyr, 69009 Lyon, France).
  </p>
  <h4>
    Contactez-nous
  </h4>
  <p>
    VeryMountain est à votre disposition pour tous vos commentaires ou suggestions.
    Vous pouvez nous écrire en français par courrier électronique à :
    <a class="vsk__mail-link" href="mailto:hello@verymountain.fr">hello&#64;verymountain.fr</a>.
  </p>

</div>

<vsk-footer></vsk-footer>
