import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Region } from '../models/region';

const ALPES_DU_SUD: Region = {
  id: 1,
  name: 'Alpes du Sud'
};
const ALPES_DU_NORD: Region = {
  id: 2,
  name: 'Alpes du Nord'
};
const PYRENEES: Region = {
  id: 3,
  name: 'Pyrénées'
};
const JURA: Region = {
  id: 4,
  name: 'Jura'
};
const MASSIF_CENTRAL: Region = {
  id: 5,
  name: 'Massif Central'
};
const VOSGES: Region = {
  id: 6,
  name: 'Vosges'
};
const REGIONS: Region[] = [
  ALPES_DU_SUD,
  ALPES_DU_NORD,
  PYRENEES,
  JURA,
  MASSIF_CENTRAL,
  VOSGES
];

@Component({
  selector: 'vsk-search-region-form',
  standalone: true,
  imports: [NgClass],
  templateUrl: './search-region-form.component.html',
  styleUrl: './search-region-form.component.scss'
})
export class SearchRegionFormComponent {
  readonly ALPES_DU_SUD = ALPES_DU_SUD;
  readonly ALPES_DU_NORD = ALPES_DU_NORD;
  readonly PYRENEES = PYRENEES;
  readonly JURA = JURA;
  readonly MASSIF_CENTRAL = MASSIF_CENTRAL;
  readonly VOSGES = VOSGES;

  hover: Region | 'none' = 'none';

  @Output() regionsSelectedChanged: EventEmitter<Region[]> = new EventEmitter<
    Region[]
  >();

  @Input() regionsSelected: Region[];

  static mapToRegion(regions: number[]): Region[] {
    return REGIONS.filter((region) => regions.includes(region.id));
  }

  chooseRegion(region: Region) {
    if (this.regionsSelected.indexOf(region) !== -1) {
      this.regionsSelected = [
        ...this.regionsSelected.filter(
          (regionSelected) => regionSelected.id !== region.id
        )
      ];
    } else {
      this.regionsSelected.push(region);
    }

    this.regionsSelectedChanged.emit(this.regionsSelected);
  }

  isRegionSelected(region: Region) {
    return this.hover === region || this.regionsSelected.indexOf(region) !== -1;
  }
}
