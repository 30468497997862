import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemCart } from '../shared/models/package/item-cart';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CartActivityService extends RepositoryService {
  private URL_CART_ACTIVITY = 'api/webapp/cart';

  constructor(http: HttpClient) {
    super(http);
  }

  addToCart(
    experienceId: number,
    participants: string[],
    firstDay: Moment,
    resort: string,
    timeSlotId: number | null = null
  ): Observable<ItemCart[]> {
    return this.post(
      `${this.URL_CART_ACTIVITY}/add-to-cart?experienceId=${experienceId}&resort=${resort}&participants=${participants}&firstDay=${firstDay.format('YYYY-MM-DD')}${timeSlotId ? `&timeSlotId=${timeSlotId}` : ''}`,
      {}
    ).pipe(
      map((itemCarts: ItemCart[]) =>
        itemCarts
          ? itemCarts.map((itemCart) => new ItemCart({ ...itemCart }))
          : []
      )
    );
  }

  getItems(sessionId: string, isPayed = false): Observable<ItemCart[]> {
    return this.get(
      `${this.URL_CART_ACTIVITY}/get-items-session?sessionId=${sessionId}&isPayed=${isPayed}`
    ).pipe(
      map((itemCarts: ItemCart[]) =>
        itemCarts
          ? itemCarts.map((itemCart) => new ItemCart({ ...itemCart }))
          : []
      )
    );
  }

  removeItems(sessionId: string, items: string[]): Observable<ItemCart[]> {
    return this.post(
      `${this.URL_CART_ACTIVITY}/delete-items-session?sessionId=${sessionId}`,
      items
    ).pipe(
      map((itemCarts: ItemCart[]) =>
        itemCarts
          ? itemCarts.map((itemCart) => new ItemCart({ ...itemCart }))
          : []
      )
    );
  }

  deleteItemForParticipant(
    participant: string,
    itemId: string
  ): Observable<ItemCart[]> {
    return this.delete(
      `${this.URL_CART_ACTIVITY}/delete-item-participant?participant=${participant}&item=${itemId}`
    ).pipe(
      map((itemCarts: ItemCart[]) =>
        itemCarts
          ? itemCarts.map((itemCart) => new ItemCart({ ...itemCart }))
          : []
      )
    );
  }
}
