<div class="sticky-nav-cart">
  <div *ngIf="showPaymentInfo"
       class="pricing">
    <p class="total-label">Total</p>

    <div class="prices">
      <p class="public">{{ cart?.getPublicPrice() | number: '1.2-2' : 'fr' }} €</p>
      <p class="net">{{ cart?.getPriceWithPromo() | number: '1.2-2' : 'fr' }} €</p>
    </div>
  </div>

  <div [ngClass]="{'center-button': !showNext, 'full-width': !showPaymentInfo}" class="group-cta">
    <atc-button (click)="openSummary = true"
                color="grey"
                size="large">
      Voir les détails
    </atc-button>

    <atc-button (click)="nextChange.emit()"
                *ngIf="showNext"
                size="large">
      Étape suivante
    </atc-button>
  </div>
</div>

<atc-popup (closeChanged)="openSummary = false"
           *ngIf="openSummary"
           [footer]="false"
           [noPadding]="true"
           [width]="'100%'">
  <vsk-cart-card-summary (nextChange)="nextChange.emit()"
                         [cart]="cart"
                         [participants]="participants"
                         [showNext]="showNext"
                         [showPaymentInfo]="showPaymentInfo"
                         body>
  </vsk-cart-card-summary>
</atc-popup>
