import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './page/cart/cart.component';
import { ConfirmationOrderComponent } from './page/confirmation-order/confirmation-order.component';
import { LoginComponent } from './page/login/login.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { RegisterComponent } from './page/register/register.component';
import { SearchAccommodationsComponent } from './page/search-accommodations/search-accommodations.component';
import { SearchResortsComponent } from './page/search-resorts/search-resorts.component';
import { SearchTripComponent } from './page/search-trip/search-trip.component';

export const URL_SEARCH_ACCOMMODATIONS = 'recherche-hebergements';
export const URL_SEARCH_TRIP = 'recherche-sejours';
export const URL_LOGIN = 'connexion';
export const URL_REGISTER = 'inscription';
export const URL_RESORT = 'station';
export const URL_USE_AND_TERMS = 'conditions-utilisation';
export const URL_CONFIRM_PAYMENT = 'confirmation-commande';
export const URL_ACCOUNT = 'mon-compte';
export const URL_TERMS_AND_CONDITIONS = 'termes-et-conditions';
export const URL_ECO_CHART = 'charte-de-l-eco-tourisme';
export const URL_SELLS_CONDITIONS = 'conditions-de-vente';
export const URL_ABOUT_US = 'a-propos-de-nous';
export const URL_SEARCH_RESORTS = 'recherche-stations';
export const URL_CART = 'panier';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./page/home/home-routing.module').then((m) => m.HomeRoutingModule)
  },
  {
    path: 'map',
    redirectTo: URL_SEARCH_TRIP
  },
  {
    path: URL_SEARCH_TRIP,
    component: SearchTripComponent
  },
  {
    path: 'details-station', // TO REMOVE
    redirectTo: URL_RESORT
  },
  {
    path: 'resort', // TO REMOVE
    redirectTo: URL_RESORT
  },
  {
    path: URL_RESORT,
    loadChildren: () =>
      import('./page/resort/resort-routing.module').then(
        (m) => m.ResortRoutingModule
      )
  },
  {
    path: 'confirm-payment', // TO REMOVE
    redirectTo: URL_CONFIRM_PAYMENT
  },
  {
    path: URL_CONFIRM_PAYMENT,
    component: ConfirmationOrderComponent
  },
  {
    path: URL_ACCOUNT,
    loadChildren: () =>
      import('./page/account/account-routing.module').then(
        (m) => m.AccountRoutingModule
      )
  },
  {
    path: 'terms-and-conditions', // TO REMOVE
    redirectTo: URL_TERMS_AND_CONDITIONS
  },
  {
    path: URL_TERMS_AND_CONDITIONS,
    loadChildren: () =>
      import('./page/terms-and-conditions/terms-and-conditions.module').then(
        (m) => m.TermsAndConditionsModule
      )
  },
  {
    path: 'eco-tourism-chart', // TO REMOVE
    redirectTo: URL_ECO_CHART
  },
  {
    path: URL_ECO_CHART,
    loadChildren: () =>
      import('./page/eco-tourism-chart/eco-tourisme-chart.module').then(
        (m) => m.EcoTourismChartModule
      )
  },
  {
    path: 'selling-conditions', // TO REMOVE
    redirectTo: URL_SELLS_CONDITIONS
  },
  {
    path: URL_SELLS_CONDITIONS,
    loadChildren: () =>
      import('./page/selling-conditions/selling-conditions.module').then(
        (m) => m.SellingConditionsModule
      )
  },
  {
    path: 'terms-of-use', // TO REMOVE
    redirectTo: URL_USE_AND_TERMS
  },
  {
    path: URL_USE_AND_TERMS,
    loadChildren: () =>
      import('./page/terms-of-use/terms-of-use.module').then(
        (m) => m.TermsOfUseModule
      )
  },
  {
    path: 'login',
    redirectTo: URL_LOGIN
  },
  {
    path: URL_LOGIN,
    component: LoginComponent
  },
  {
    path: URL_REGISTER,
    component: RegisterComponent
  },
  {
    path: 'about-us',
    redirectTo: URL_ABOUT_US
  },
  {
    path: URL_ABOUT_US,
    loadChildren: () =>
      import('./page/about-us/about-us.module').then((m) => m.AboutUsModule)
  },
  {
    path: 'accommodations', // TO REMOVE
    redirectTo: URL_SEARCH_ACCOMMODATIONS
  },
  {
    path: URL_SEARCH_ACCOMMODATIONS,
    component: SearchAccommodationsComponent
  },
  {
    path: URL_SEARCH_RESORTS,
    component: SearchResortsComponent
  },
  {
    path: URL_CART,
    component: CartComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
