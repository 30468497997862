import { NgForOf, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DropdownComponent, ToHourPipe } from 'atomic-lib';
import { SkiClassService } from '../../../service/ski-class.service';
import { SkiClass } from '../../models/ski-class/ski-class';
import { SkiCriteria } from '../ski-pass-dropdown/ski-pass-dropdown.component';

@Component({
  selector: 'vsk-ski-class-dropdown',
  standalone: true,
  imports: [DropdownComponent, NgForOf, NgIf, ToHourPipe],
  templateUrl: './ski-class-dropdown.component.html',
  styleUrl: './ski-class-dropdown.component.scss'
})
export class SkiClassDropdownComponent {
  @ViewChild('skiClassButton') buttonElement: ElementRef<HTMLElement>;

  open = false;
  skiClasses: SkiClass[] = [];
  selectedSkiClass?: SkiClass;
  @Output() selectSkiClassChange: EventEmitter<SkiClass> =
    new EventEmitter<SkiClass>();

  constructor(private skiClassService: SkiClassService) {}

  _criteria: SkiCriteria;

  @Input() set criteria(criteria: SkiCriteria) {
    if (!criteria) {
      return;
    }

    this._criteria = criteria;
    this.setSkiClass();
  }

  changeSkiPass(skiClass: SkiClass) {
    this.selectedSkiClass = skiClass;
    this.open = false;

    this.selectSkiClassChange.emit(this.selectedSkiClass);
    this.getPriceSkiClass();
  }

  getAgeRange(skiClass: SkiClass) {
    if (!skiClass) {
      return '';
    }

    const ageMin = skiClass.ageRanges
      .map((ageRange) => ageRange.start)
      .reduce((prev, curr) => (prev < curr ? prev : curr));

    const ageMax = skiClass.ageRanges
      .map((ageRange) => ageRange.end)
      .reduce((prev, curr) => (prev > curr ? prev : curr));

    return ageMin + ' ans à ' + ageMax + ' ans';
  }

  private setSkiClass() {
    this.skiClassService
      .getSkiClassForResort(this._criteria.resortName)
      .subscribe((skiClasses) => {
        this.skiClasses = skiClasses;

        if (!this.skiClasses?.length) {
          return;
        }

        this.selectedSkiClass = this.skiClasses[0];
        this.selectSkiClassChange.emit(this.selectedSkiClass);
        this.getPriceSkiClass();
      });
  }

  private getPriceSkiClass() {}
}
