import { Moment } from 'moment';
import { Activity } from '../models/activity/activity';
import { Participant } from '../models/participant/participant';
import { Period } from '../models/period';
import { SearchPackage, SearchType } from './search-bar.state';

export class InitSearchBar {
  static readonly type = '[SearchBarBa] Init state search bar';

  constructor(
    public searchType: SearchType,
    public searchPackage: SearchPackage,
    public participants: Participant[],
    public period: Period,
    public activities: Activity[],
    public regions: number[],
    public resorts: number[],
    public resort?: string
  ) {}
}

export class ChangeSearchType {
  static readonly type = '[SearchBarBa] Change search type';

  constructor(public searchType: SearchType) {}
}

export class ChangeSearchPackage {
  static readonly type = '[SearchBarBa] Change search package';

  constructor(public searchPackage: SearchPackage) {}
}

export class ClearSearchBar {
  static readonly type = '[SearchBarBa] Clear search type';

  constructor(public sessionId: string) {}
}

export class SelectActivity {
  static readonly type = '[SearchBarBa] Add activity';

  constructor(public activity: Activity) {}
}

export class UnselectActivity {
  static readonly type = '[SearchBarBa] Remove activity';

  constructor(public activity?: Activity) {}
}

export class AddParticipant {
  static readonly type = '[SearchBarBa] Add participant';

  constructor(public participant: Participant) {}
}

export class ChangeParticipant {
  static readonly type = '[SearchBarBa] Change participant';

  constructor(public participant: Participant) {}
}

export class RemoveParticipant {
  static readonly type = '[SearchBarBa] Remove participant';

  constructor(public participant: string) {}
}

export class ChangeDates {
  static readonly type = '[SearchBarBa] Change dates';

  constructor(
    public startDate: Moment,
    public endDate: Moment
  ) {}
}

export class SetResorts {
  static readonly type = '[SearchBarBa] Set resorts';

  constructor(public resorts: number[]) {}
}

export class SetRegions {
  static readonly type = '[SearchBarBa] Set regions';

  constructor(public regions: number[]) {}
}

export class SelectResort {
  static readonly type = '[SearchBarBa] Select one precise resort';

  constructor(public resort: string) {}
}
