import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { DropdownComponent } from 'atomic-lib';
import { Activity } from '../../models/activity/activity';
import { DropdownTemplateComponent } from '../dropdown-template.component';
import { SelectActivity, UnselectActivity } from '../search-bar.action';

@Component({
  selector: 'vsk-search-bar-activities',
  standalone: true,
  imports: [DropdownComponent, NgForOf, NgClass, NgIf, NgTemplateOutlet],
  templateUrl: './search-bar-activities.component.html',
  styleUrl: './search-bar-activities.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarActivitiesComponent extends DropdownTemplateComponent {
  readonly DEFAULT_TITLE = 'Ajouter une activité';

  selectedCategory: 'all' | string = 'all';
  nbActivitiesSelected = 0;
  categories: string[] = [];
  allActivities: Activity[] = [];
  filteredActivities: Activity[] = [];

  @Input() theme: 'thick' | 'thin' = 'thick';

  constructor(private store: Store) {
    super();
  }

  @Input() set activities(activities: Activity[]) {
    this.allActivities = activities;
    this.chooseCategory(this.selectedCategory);
    this.nbActivitiesSelected = this.totalActivitiesSelected();
    this.generateTitle();

    this.categories = Array.from(
      new Set(activities.map((activity) => activity.category.name))
    ).sort();
  }

  identity(index: number, activity: Activity) {
    return activity.id;
  }

  chooseCategory(category: 'all' | string) {
    this.selectedCategory = category;

    if (category === 'all') {
      this.filteredActivities = this.allActivities;
      return;
    }

    this.filteredActivities = this.allActivities.filter(
      (activity) => activity.category.name === this.selectedCategory
    );
  }

  chooseActivity(activity: Activity): void {
    if (!activity.selected) {
      this.store.dispatch(new SelectActivity(activity));
      return;
    }

    this.store.dispatch(new UnselectActivity(activity));
  }

  unselectAllActivities() {
    this.store.dispatch(new UnselectActivity());
  }

  protected generateTitle() {
    const activitiesSelected = this.getAllActivitiesSelected();

    if (!activitiesSelected.length) {
      this.titleButtonChanged.emit(this.DEFAULT_TITLE);
      return;
    }

    this.titleButtonChanged.emit(
      activitiesSelected
        .map((activity) => activity.name)
        .sort()
        .join(', ')
    );
  }

  private getAllActivitiesSelected() {
    return this.allActivities.filter((activity) => activity.selected);
  }

  private totalActivitiesSelected() {
    return this.getAllActivitiesSelected().length;
  }
}
