<div class="header">
  <img (click)="showPopup = true"
       [src]="establishment.pictures[0]"
       alt="Photo hébergement">
  <div class="info">
    <div class="title-container">
      <h3 [innerHTML]="establishment.name"></h3>

      <div *ngIf="!_pinpoint"
           class="info-line">
        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#mountain'"></use>
        </svg>

        <p>{{ establishment.resorts[0].name }}</p>
      </div>
    </div>

    <ng-container *ngIf="getFeaturesEstablishment(establishment) as features">
      <div *ngIf="!_pinpoint && features?.length"
           class="list">
      <span *ngFor="let feature of features; trackBy: featureId"
            class="badge-feature">
          <svg>
            <use [attr.xlink:href]="'assets/icons/icons.svg#' + feature.icon"></use>
          </svg>

          <p>{{ feature.label }}</p>
      </span>
      </div>
    </ng-container>

    <p *ngIf="!_pinpoint"
       [innerHTML]="establishment.description"></p>

    <div *ngIf="establishment.activities?.length && !_pinpoint"
         class="activities">
      <p>À vivre autour de votre hébergement</p>

      <div class="list">
      <span *ngFor="let activity of establishment.activities; trackBy: activityId"
            [ngClass]="{'disabled': activity?.price === 0}"
            class="badge-activity">
          <img [alt]="activity.name" [src]="activity.urlIcon || ''">

          <p>{{ activity.name }}
            <ng-container *ngIf="activity && (activity?.price || 0) > 0">
              ({{ activity.price | number:'1.0-0' }} €)
            </ng-container>
          </p>

          <ng-container *ngIf="activity && (activity?.price || 0) > 0">
            <div class="dot"></div>
          </ng-container>
      </span>
      </div>
    </div>

    <div class="info-price-included">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#information-circle'"></use>
      </svg>

      <div class="included">
        <p>Inclus dans le prix :</p>

        <img alt="Hébergement" src="assets/new-design/img/search-bar/type/chalet.svg">

        <img *ngIf="establishment?.priceSkiPass"
             alt="Forfaits"
             src="assets/new-design/img/search-bar/type/forfaits.svg">

        <img *ngIf="establishment?.priceMaterial"
             alt="Matériel"
             src="assets/new-design/img/search-bar/type/ski-alpin.svg">
      </div>
    </div>
  </div>
</div>

<div class="body">
  <vsk-generic-offer-small-card *ngFor="let proposal of establishment.linksCard; trackBy: establishmentId"
                                [badges]="getBadges(proposal.value)"
                                [ctaName]="_pinpoint || !(windowResource.width() | async | screen: 'tablet-above') ? 'Découvrir' : 'Découvrir le logement'"
                                [images]="proposal.value.pictures.slice(0, 3)"
                                [link]="proposal.link"
                                [params]="proposal.params"
                                [price]="proposal.value.price + establishment.priceMaterial + establishment.priceSkiPass"
                                [publicPrice]="proposal.value.publicPrice + establishment.priceMaterial + establishment.priceSkiPass"
                                [showImages]="!_pinpoint"
                                [target]="(windowResource.width() | async | screen: 'tablet-above') ? '_blank' : '_self'"
                                [title]="proposal.value.name"
                                [tooltip]="getTooltip(proposal.value.price, establishment)">
  </vsk-generic-offer-small-card>
</div>

<atc-popup (closeChanged)="showPopup = false"
           *ngIf="showPopup"
           width="100%">
  <p [innerHTML]="'Photos · ' + establishment?.name" header></p>
  <atc-carousel-images [slides]="establishment.pictures"
                       body
                       class="hide-small-desktop-and-below">
  </atc-carousel-images>

  <div body class="carousel-mobile hide-small-desktop-and-above">
    <img *ngFor="let picture of establishment.pictures; let i = index" [alt]="establishment?.name + ' - Photo ' + i"
         [src]="picture">
  </div>
</atc-popup>
