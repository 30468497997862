<vsk-navbar></vsk-navbar>

<atc-breadcrumb [items]="breadcrumbItems"></atc-breadcrumb>

<main>
  <section class="header">
    <div class="resorts-available">
      <div class="ellipse"></div>
      <p>{{ allResorts.length }} stations disponibles tout au long de l'année</p>
    </div>

    <h1>Découvrez nos stations de rêve</h1>

    <div class="input-search">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#search'"></use>
      </svg>
      <input [formControl]="searchControl"
             placeholder="Rechercher une station"
             type="text">
    </div>
  </section>

  <section class="resorts">
    <vsk-card-resort
      *ngFor="let resort of filteredResorts; trackBy: trackResortById"
      [background]="resort.urlCover"
      [icon]="resort.urlLogo"
      [name]="resort.name"
      [region]="resort.regionLabel">
    </vsk-card-resort>

    <ng-container *ngIf="!allResorts?.length">
      <div *ngFor="let index of [0, 1, 2, 3, 4, 5]"
           class="rounded-[16px] h-[474px] w-[474px]" vskSkeletonLoad>
      </div>
    </ng-container>
  </section>
</main>

<vsk-footer></vsk-footer>
