<div class="wrapper">
  <button #skiPass
          (click)="open = !open"
          class="select">
    <div class="labels">
      <p class="label">Forfaits</p>
      <p class="sub-label">{{ selectedSkiPass?.name || 'Pas de forfaits' }}</p>
    </div>

    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#arrow-down'"></use>
    </svg>
  </button>

  <atc-dropdown (closeChanged)="open = false"
                [elementPosition]="buttonElement"
                [isOpen]="open"
                [left]="'0px'"
                [position]="'bottom-right'"
                [top]="'65px'"
                [width]="'100%'">
    <div class="content">
      <button (click)="changeSkiPass()"
              *ngIf="optionEmpty">
        Pas de forfaits
      </button>
      <button (click)="changeSkiPass(skiPass)"
              *ngFor="let skiPass of skiPasses">
        {{ skiPass.name }}
      </button>
    </div>
  </atc-dropdown>
</div>
