import moment from 'moment';
import { Participant } from '../shared/models/participant/participant';

export class ParticipantUtils {
  public static mapToParticipant(nbAdults: number | null, children?: number[]) {
    let participants: Participant[] = [];

    if (nbAdults) {
      participants = ParticipantUtils.loopOverParticipants(
        new Array(nbAdults).fill(30),
        participants
      );
    }

    if (children?.length) {
      participants = ParticipantUtils.loopOverParticipants(
        children,
        participants
      );
    }

    return participants;
  }

  public static loopOverParticipants(
    ages: number[],
    participants: Participant[]
  ) {
    for (let i = 0; i < ages.length; i++) {
      participants.push(
        ParticipantUtils.initParticipant(ages[i], participants.length + 1)
      );
    }

    return participants;
  }

  public static initParticipant(age: number, index: number) {
    return new Participant({
      index,
      firstname: `Voyageur ${index}`,
      birthdate: moment().add(-age, 'years')
    });
  }

  static getNbAdults(participants: Participant[]) {
    return participants.filter((participant) => participant.age >= 18).length;
  }

  static getNbChildren(participants: Participant[]) {
    return participants.filter(
      (participant) => participant.age >= 0 && participant.age < 18
    ).length;
  }

  static getAgeChildren(participants: Participant[]) {
    return participants
      .filter((participant) => participant.age >= 0 && participant.age < 18)
      .map((p) => p.age);
  }
}
