import { NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  DateRange,
  DateRangePickerComponent,
  DropdownComponent
} from 'atomic-lib';
import moment, { Moment } from 'moment/moment';
import { Period } from '../../models/period';
import { DropdownTemplateComponent } from '../dropdown-template.component';
import { ChangeDates } from '../search-bar.action';

@Component({
  selector: 'vsk-search-bar-dates',
  standalone: true,
  imports: [DateRangePickerComponent, DropdownComponent, NgTemplateOutlet],
  templateUrl: './search-bar-dates.component.html',
  styleUrl: './search-bar-dates.component.scss'
})
export class SearchBarDatesComponent extends DropdownTemplateComponent {
  readonly DEFAULT_TITLE = 'Ajouter une date';
  @Input() minDate: Moment = moment();
  @Input() maxDate: Moment = moment().add(1, 'year');
  @Input() theme: 'thick' | 'thin' = 'thick';

  constructor(private store: Store) {
    super();
  }

  _period: Period | null;

  @Input() set period(period: Period | null) {
    this._period = period;
    this.generateTitle();
  }

  datesPicked(dateRange: DateRange) {
    if (!dateRange) {
      return;
    }

    this.store.dispatch(
      new ChangeDates(
        moment(dateRange.startDate.set('hour', 0).toDate()),
        moment(dateRange.endDate.set('hour', 0).toDate())
      )
    );
  }

  protected generateTitle(): void {
    if (!this._period?.isValid) {
      this.titleButtonChanged.emit(this.DEFAULT_TITLE);
      return;
    }

    const format = 'DD/MM';

    this.titleButtonChanged.emit(
      this._period?.startDate?.locale('fr').format(format) +
        ' - ' +
        this._period?.endDate?.locale('fr').format(format)
    );
  }
}
