<div *ngIf="connected"
     class="links">
  <a (click)="closeChange.emit()"
     queryParamsHandling="merge"
     routerLink="/mon-compte/mes-commandes"
     routerLinkActive="true">
    <h3>Mes commandes</h3>

    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-right'"></use>
    </svg>
  </a>

  <a (click)="closeChange.emit()"
     queryParamsHandling="merge"
     routerLink="/mon-compte/mes-informations"
     routerLinkActive="true">
    <h3>Mes informations</h3>

    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-right'"></use>
    </svg>
  </a>

  <a (click)="closeChange.emit()"
     queryParamsHandling="merge"
     routerLink="/mon-compte/mes-cartes-physiques"
     routerLinkActive="true">
    <h3>Mes cartes</h3>

    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-right'"></use>
    </svg>
  </a>
</div>

<div class="group-actions">
  <atc-button *ngIf="!connected"
              class="flex w-full"
              size="large">
    <a (click)="closeChange.emit()"
       queryParamsHandling="preserve"
       routerLink="/connexion"
       routerLinkActive="true">
      Se connecter
    </a>
  </atc-button>

  <atc-button class="flex w-full"
              color="grey"
              icon="phone"
              size="large">
    <a href="tel:+33465847469">
      04 65 84 74 69
    </a>
  </atc-button>
</div>
