<div class="wrapper">
  <button #participantsButton
          (click)="open = !open"
          class="select">
    <div class="labels">
      <p class="label">Voyageurs</p>
      <p class="sub-label">
        {{ getTitle() }}
      </p>
    </div>

    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#arrow-down'"></use>
    </svg>
  </button>

  <atc-dropdown (closeChanged)="open = false"
                [elementPosition]="buttonElement"
                [isOpen]="open"
                [left]="'0px'"
                [position]="'bottom-right'"
                [top]="'65px'"
                [width]="'100%'">
    <div class="content">
      <atc-input-checkbox *ngFor="let form of _participants"
                          [border]="false"
                          [control]="form.isSelectedForm">
        <p>{{ form.participant.firstname }} ({{ form.participant.age }} ans)</p>
      </atc-input-checkbox>
    </div>
  </atc-dropdown>
</div>
