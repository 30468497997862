import { AsyncPipe, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  BadgeDirective,
  ButtonComponent,
  OptionElement,
  ScreenPipe,
  SelectComponent,
  WindowResource
} from 'atomic-lib';
import { TriggerPopup } from '../../../app.action';
import { SetFiltersAccommodations } from '../../../filters.action';
import { AccommodationType } from '../../../shared/models/accommodation/accommodation-type.enum';
import { FiltersAccommodationSearch } from '../../../shared/models/filters/filters-accommodation-search';
import { FiltersStation } from '../../../shared/models/filters/filters-station';
import { FiltersTripInfo } from '../../../shared/models/filters/filters-trip-info';
import { Region } from '../../../shared/models/region';
import { ResortLabel } from '../../../shared/models/resort/resort-label';
import { Tag } from '../../../shared/models/tag';
import { SearchRegionFormComponent } from '../../../shared/search-region-form/search-region-form.component';
import { FormUtils } from '../../../utils/form-utils';
import { SearchTripFiltersComponent } from './search-trip-filters/search-trip-filters.component';

@Component({
  selector: 'vsk-header-filters',
  templateUrl: './header-filters.component.html',
  styleUrls: ['./header-filters.component.scss'],
  imports: [
    SelectComponent,
    NgIf,
    AsyncPipe,
    ScreenPipe,
    ButtonComponent,
    BadgeDirective,
    SearchTripFiltersComponent
  ],
  standalone: true
})
export class HeaderFiltersComponent {
  openMapFilters = false;
  // Included in trip
  skiPackageForm: FormControl<boolean | null> = new FormControl<boolean>(true);
  materialForm: FormControl<boolean | null> = new FormControl<boolean>(true);
  // Equipments establishment & room
  poolForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  spaForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  saunaForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  hammamForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  balconyTerraceForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  tvForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  chimneyForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  bbqForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  // Access
  pmrForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  // Services
  parkingForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  animalsAdmittedForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  wifiForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  childrenClubForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  // Accommodation type
  chaletForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  flatForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  studioForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  houseForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  hotelForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  residencyForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  holidaysVillageForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  tagsFilter: OptionElement<number>[] = [];
  resortLabelsFilter: OptionElement<number>[] = [];
  regionsElements: OptionElement<number>[] = [];
  regions: Region[] = [];
  tripIncludeFilter: OptionElement<number>[] = [
    {
      id: 2,
      label: 'Forfait',
      control: this.skiPackageForm
    },
    {
      id: 3,
      label: 'Matériel',
      control: this.materialForm
    }
  ];
  equipmentsFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Piscine',
      control: this.poolForm
    },
    {
      id: 2,
      label: 'Spa',
      control: this.spaForm
    },
    {
      id: 3,
      label: 'Sauna',
      control: this.saunaForm
    },
    {
      id: 4,
      label: 'Hammam',
      control: this.hammamForm
    },
    {
      id: 5,
      label: 'Balcon / Terrasse',
      control: this.balconyTerraceForm
    },
    {
      id: 6,
      label: 'TV',
      control: this.tvForm
    },
    {
      id: 7,
      label: 'Cheminée',
      control: this.chimneyForm
    },
    {
      id: 8,
      label: 'Barbecue',
      control: this.bbqForm
    }
  ];
  accessFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Accès PMR',
      control: this.pmrForm
    }
  ];
  servicesFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Parking',
      control: this.parkingForm
    },
    {
      id: 2,
      label: 'Animaux admis',
      control: this.animalsAdmittedForm
    },
    {
      id: 3,
      label: 'Wifi',
      control: this.wifiForm
    },
    {
      id: 4,
      label: 'Club enfants',
      control: this.childrenClubForm
    }
  ];
  accommodationTypeElements: OptionElement<AccommodationType>[] = [];
  @Output() filterApplied: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private store: Store,
    public windowResource: WindowResource,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  _filters: FiltersStation;

  @Input() set filters(filters: FiltersStation) {
    this._filters = filters;
    if (filters) {
      // Included in trip
      this.skiPackageForm.setValue(filters.packagesDisplayed);
      this.materialForm.setValue(filters.materialDisplayed);

      // Equipments establishment & room
      this.poolForm.setValue(filters.pool);
      this.spaForm.setValue(filters.spa);
      this.saunaForm.setValue(filters.sauna);
      this.hammamForm.setValue(filters.hammam);
      this.balconyTerraceForm.setValue(filters.balconyTerrace);
      this.tvForm.setValue(filters.tv);
      this.chimneyForm.setValue(filters.chimney);
      this.bbqForm.setValue(filters.bbq);

      // Access
      this.pmrForm.setValue(filters.pmr);

      // Services
      this.parkingForm.setValue(filters.parking);
      this.animalsAdmittedForm.setValue(filters.animalsAdmitted);
      this.wifiForm.setValue(filters.wifi);
      this.childrenClubForm.setValue(filters.childrenClub);

      // Establishment type
      if (filters.types) {
        this.chaletForm.setValue(
          filters.types.indexOf(AccommodationType.CHALET) !== -1
        );
        this.flatForm.setValue(
          filters.types.indexOf(AccommodationType.APPARTEMENT) !== -1
        );
        this.studioForm.setValue(
          filters.types.indexOf(AccommodationType.STUDIO) !== -1
        );
        this.houseForm.setValue(
          filters.types.indexOf(AccommodationType.HOUSE) !== -1
        );
        this.hotelForm.setValue(
          filters.types.indexOf(AccommodationType.HOTEL) !== -1
        );
        this.residencyForm.setValue(
          filters.types.indexOf(AccommodationType.RESIDENCE) !== -1
        );
        this.holidaysVillageForm.setValue(
          filters.types.indexOf(AccommodationType.HOLIDAYS_VILLAGE) !== -1
        );
      }

      // Tags
      if (filters.tags) {
        this.tagsFilter.forEach((tag) => {
          if (filters.tags.indexOf(tag.id as number) !== -1) {
            tag.control?.setValue(true);
          }
        });
      }

      // Labels
      if (filters.labels) {
        this.resortLabelsFilter.forEach((label) => {
          if (filters.labels.indexOf(label.id as number) !== -1) {
            label.control?.setValue(true);
          }
        });
      }

      // Regions
      if (filters.regions) {
        this.regions = SearchRegionFormComponent.mapToRegion(filters.regions);
        this.regionsElements.forEach((region) => {
          if (filters.regions.indexOf(region.id as number) !== -1) {
            region.control?.setValue(true);
          }
        });
      }

      // Regions
      if (filters.types) {
        this.accommodationTypeElements.forEach((type) => {
          if (filters.types.indexOf(type.id as AccommodationType) !== -1) {
            type.control?.setValue(true);
          }
        });
      }
    }
  }

  _filtersTripInfo: FiltersTripInfo;

  @Input() set filtersTripInfo(info: FiltersTripInfo) {
    this._filtersTripInfo = info;
    if (info) {
      this.regionsElements = FormUtils.upsertControlForFilter(
        this.regionsElements,
        info.regions,
        this._filters?.regions || []
      );

      this.accommodationTypeElements = FormUtils.upsertControlForFilter(
        this.accommodationTypeElements,
        info.types,
        this._filters?.types || []
      );

      FormUtils.disabledWithNoQuantity(this.poolForm, info.pool);
      FormUtils.disabledWithNoQuantity(this.spaForm, info.spa);
      FormUtils.disabledWithNoQuantity(this.saunaForm, info.sauna);
      FormUtils.disabledWithNoQuantity(this.hammamForm, info.hammam);
      FormUtils.disabledWithNoQuantity(
        this.balconyTerraceForm,
        info.balconyTerrace
      );
      FormUtils.disabledWithNoQuantity(this.tvForm, info.tv);
      FormUtils.disabledWithNoQuantity(this.chimneyForm, info.chimney);
      FormUtils.disabledWithNoQuantity(this.bbqForm, info.bbq);
      FormUtils.disabledWithNoQuantity(this.pmrForm, info.pmr);
      FormUtils.disabledWithNoQuantity(this.parkingForm, info.parking);
      FormUtils.disabledWithNoQuantity(
        this.animalsAdmittedForm,
        info.animalsAdmitted
      );
      FormUtils.disabledWithNoQuantity(this.wifiForm, info.wifi);
      FormUtils.disabledWithNoQuantity(
        this.childrenClubForm,
        info.childrenClub
      );
    }
  }

  @Input() set tags(tags: Tag[]) {
    if (tags) {
      this.tagsFilter = tags
        .sort((prev, curr) => (prev.label < curr.label ? -1 : 1))
        .map((tag) => {
          return {
            id: tag.id,
            label: tag.label,
            control: new FormControl<boolean>(false)
          };
        });
    }
  }

  @Input() set resortLabels(resortLabels: ResortLabel[]) {
    if (resortLabels) {
      this.resortLabelsFilter = resortLabels.map((resortLabel) => {
        return {
          id: resortLabel.id,
          label: resortLabel.name,
          control: new FormControl<boolean>(false)
        };
      });
    }
  }

  buildFilters() {
    return new FiltersAccommodationSearch({
      material: this.materialForm.value as boolean,
      packages: this.skiPackageForm.value as boolean,
      regions: this.regionsElements
        .filter((regionForm) => !!regionForm.control?.value)
        .map((regionForm) => regionForm.id as number),
      tags: this.tagsFilter
        .filter((tagForm) => !!tagForm.control?.value)
        .map((tagForm) => tagForm.id as number),
      labels: this.resortLabelsFilter
        .filter((labelForm) => !!labelForm.control?.value)
        .map((labelForm) => labelForm.id as number),
      pool: this.poolForm.value as boolean,
      spa: this.spaForm.value as boolean,
      hammam: this.hammamForm.value as boolean,
      sauna: this.saunaForm.value as boolean,
      balconyTerrace: this.balconyTerraceForm.value as boolean,
      tv: this.tvForm.value as boolean,
      chimney: this.chimneyForm.value as boolean,
      bbq: this.bbqForm.value as boolean,
      pmr: this.pmrForm.value as boolean,
      parking: this.parkingForm.value as boolean,
      animalsAdmitted: this.animalsAdmittedForm.value as boolean,
      wifi: this.wifiForm.value as boolean,
      childrenClub: this.childrenClubForm.value as boolean,
      types: this.accommodationTypeElements
        .filter((elem) => !!elem.control?.value)
        .map((elem) => elem.id as AccommodationType),
      priceRange: this._filters.priceRange
    });
  }

  applyFilter() {
    this.store.dispatch(new SetFiltersAccommodations(this.buildFilters()));
    this.filterApplied.emit();
  }

  reset() {
    this.regionsElements.forEach((element) => element.control?.setValue(false));
    this.accommodationTypeElements.forEach((element) =>
      element.control?.setValue(false)
    );
    this.store.dispatch(
      new SetFiltersAccommodations(new FiltersAccommodationSearch())
    );

    this.closePopup(false);
  }

  openMapFiltersPopUp(): void {
    this.openMapFilters = true;
    this.store.dispatch(new TriggerPopup(true));
    this.elementRef.nativeElement.setAttribute('style', 'z-index: 3');
  }

  closePopup(refresh: boolean) {
    this.openMapFilters = false;
    this.store.dispatch(new TriggerPopup(false));
    this.elementRef.nativeElement.setAttribute('style', '');

    if (refresh) {
      this.applyFilter();
    }
  }

  totalFilters() {
    if (!this._filters) {
      return 0;
    }

    let total = this._filters.labels?.length || 0;
    total += this._filters.pmr ? 1 : 0;
    return total;
  }
}
