import moment, { Moment } from 'moment';

export class Proposal {
  startDate: Moment;
  endDate: Moment;
  price: number;
  publicPrice: number;
  productCode: string;

  constructor(obj: Partial<Proposal>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }
  }
}
