import { AsyncPipe, NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Select } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { Observable } from 'rxjs';
import { AppState } from '../../app.state';
import { UserNavbarDropdownComponent } from '../dropdown/user-navbar-dropdown/user-navbar-dropdown.component';
import { UserSession } from '../models/user-session';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { MenuMobileComponent } from './menu-mobile/menu-mobile.component';

@Component({
  selector: 'vsk-navbar',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgClass,
    RouterLink,
    RouterLinkActive,
    NgIf,
    SearchBarComponent,
    AsyncPipe,
    UserNavbarDropdownComponent,
    MenuMobileComponent
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
  @Select(AppState.user) user$: Observable<UserSession | null>;

  @Input() position: 'absolute' | 'flex' = 'flex';
  @Input() theme: 'light' | 'dark' = 'dark';

  openMenu = false;
  closeAnim = false;

  constructor(
    public windowResource: WindowResource,
    private changeRef: ChangeDetectorRef
  ) {}

  changeMenuState(open: boolean) {
    if (open) {
      this.openMenu = true;
      this.closeAnim = false;
      return;
    }

    this.closeAnim = true;
    setTimeout(() => {
      this.openMenu = false;
      this.changeRef.markForCheck();
    }, 300);
  }
}
