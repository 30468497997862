import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment/moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Experience } from '../shared/models/activity/experience';
import { Price } from '../shared/models/cart/price';
import { Criteria } from '../shared/models/criteria';
import { Resort } from '../shared/models/resort/resort';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService extends RepositoryService {
  private URL_EXPERIENCE = 'api/webapp/experience';

  constructor(http: HttpClient) {
    super(http);
  }

  getExperienceById(
    criteria: Criteria,
    experienceId: number
  ): Observable<Experience> {
    return this.post(
      this.URL_EXPERIENCE + '/by-id?id=' + experienceId,
      criteria
    ).pipe(map((experience) => new Experience({ ...experience })));
  }

  getExperiencesByResort(
    criteria: Criteria,
    resort: Resort
  ): Observable<Experience[]> {
    return this.post(
      this.URL_EXPERIENCE +
        `/by-criteria?category=GENERAL&resortId=${resort.id}`,
      criteria
    ).pipe(
      map((experiences) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }

  getExperiencesByResortName(resortName: string): Observable<Experience[]> {
    return this.get(
      this.URL_EXPERIENCE + `/by-resort-name?resortName=${resortName}`
    ).pipe(
      map((experiences) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }

  getExperiencesByResortNameAndDates(
    resortName: string,
    startDate: Moment,
    endDate: Moment
  ): Observable<Experience[]> {
    return this.get(
      this.URL_EXPERIENCE +
        `/by-resort-name-and-dates?resortName=${resortName}&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`
    ).pipe(
      map((experiences) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }

  getPricingForExperience(
    experienceId: number,
    participants: string[],
    firstDay: Moment
  ): Observable<Price> {
    return this.get(
      this.URL_EXPERIENCE +
        `/get-price-for-experience?experienceId=${experienceId}&participants=${participants}&firstDay=${firstDay.format('YYYY-MM-DD')}`
    );
  }
}
