import { ParamMap } from '@angular/router';
import { Moment } from 'moment';
import { ItemAccommodation } from '../../shared/models/accommodation/item-accommodation';
import { EcoChart } from '../../shared/models/cart/eco-chart';
import { PromoCode } from '../../shared/models/promo-code';
import { ItemSkiEquipment } from '../../shared/models/ski-equipment/item-ski-equipment';

export class InitCart {
  static readonly type = '[CartState] Initialise cart';

  constructor(public queryParams: ParamMap) {}
}

export class AddToCart {
  static readonly type = '[CartState] Add ski pass to cart';

  constructor(
    public experienceId: number,
    public participants: string[],
    public firstDay: Moment,
    public resort: string,
    public timeSlotId: number | null = null
  ) {}
}

export class AddSkiEquipmentToCart {
  static readonly type = '[CartState] Add ski equipment to cart';

  constructor(public itemSkiEquipment: ItemSkiEquipment) {}
}

export class RemoveSkiEquipmentFromCart {
  static readonly type = '[CartState] Remove ski equipment from cart';

  constructor(
    public sessionId: string,
    public cartItemId: number
  ) {}
}

export class AddAccommodationToCart {
  static readonly type = '[CartState] Add accommodation to cart';

  constructor(public itemAccommodation: ItemAccommodation) {}
}

export class UpdateAccommodationRemarks {
  static readonly type = '[CartState] Update accommodation remarks';

  constructor(
    public sessionId: string,
    public remarks: string
  ) {}
}

export class RemoveItemsToCart {
  static readonly type = '[CartState] Remove items to cart';

  constructor(
    public sessionId: string,
    public itemsToRemove: string[]
  ) {}
}

export class FetchCart {
  static readonly type = '[CartState] Fetch cart session';

  constructor(public sessionId: string) {}
}

export class AddPromoCode {
  static readonly type = '[CartState] Set promotional code';

  constructor(public promoCode: PromoCode) {}
}

export class DepositMode {
  static readonly type = '[CartState] Set mode deposit';

  constructor(public deposit: boolean) {}
}

export class DeletePromoCode {
  static readonly type = '[CartState] Delete promotional code';
}

export class UpdateEcoTourismChart {
  static readonly type = '[CartState] Update eco tourism chart';

  constructor(public value: EcoChart) {}
}

export class DeleteItemForParticipant {
  static readonly type = '[CartState] Delete item for participant';

  constructor(
    public uuid: string,
    public itemId: string
  ) {}
}

export class DeleteEquipment {
  static readonly type = '[CartState] Delete equipment';

  constructor(
    public sessionId: string,
    public id: number
  ) {}
}
