<atc-dropdown (closeChanged)="closeEventChanged.emit()"
              *ngIf="isOpen"
              [classes]="['no-padding']"
              [elementPosition]="_parent"
              [isOpen]="isOpen"
              [left]="'0px'"
              [position]="'bottom-left'"
              [top]="theme === 'thick' ? '60px' : '40px'"
              [width]="'794px'"
              class="hide-small-desktop-and-below">
  <div class="content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</atc-dropdown>

<div class="content hide-small-desktop-and-above">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
  <div class="header hide-small-desktop-and-below">
    <p>Choisissez un forfait</p>
    <p class="tooltip">Vous pourrez ensuite ajouter autant d’activités que vous le souhaitez et vivre une véritable
      expérience en altitude.</p>
  </div>

  <div class="options">
    <!-- TRIP-->
    <button (click)="chooseType('FULL')"
            [ngClass]="{'selected': _searchType === 'FULL'}"
            class="option">
      <div class="icons">
        <img alt="Hébergement" src="assets/new-design/img/search-bar/type/chalet.svg">
        <img alt="Forfaits" src="assets/new-design/img/search-bar/type/forfaits.svg">
        <img alt="Matériel" src="assets/new-design/img/search-bar/type/ski-alpin.svg">
      </div>

      <div class="text">
        <h5>Hébergement + forfaits + matériel</h5>
      </div>
    </button>

    <!-- ACCOMMODATION-->
    <button (click)="chooseType('HALF')"
            [ngClass]="{'selected': _searchType === 'HALF'}"
            class="option">
      <div class="icons">
        <img alt="Hébergement" src="assets/new-design/img/search-bar/type/chalet.svg">
        <img alt="Forfaits" src="assets/new-design/img/search-bar/type/forfaits.svg">
      </div>

      <div class="text">
        <h5>Hébergement + forfaits</h5>
      </div>
    </button>

    <!-- RESORT-->
    <button (click)="chooseType('SINGLE')"
            [ngClass]="{'selected': _searchType === 'SINGLE'}"
            class="option">
      <div class="icons">
        <img alt="Hébergement" src="assets/new-design/img/search-bar/type/chalet.svg">
      </div>

      <div class="text">
        <h5>Hébergement</h5>
      </div>
    </button>
  </div>
</ng-template>
