import { NgForOf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropdownComponent, InputCheckboxComponent } from 'atomic-lib';
import { Participant } from '../../models/participant/participant';

interface ParticipantForm {
  participant: Participant;
  isSelectedForm: FormControl<boolean | null>;
}

@Component({
  selector: 'vsk-participant-dropdown',
  standalone: true,
  imports: [DropdownComponent, NgForOf, InputCheckboxComponent],
  templateUrl: './participant-dropdown.component.html',
  styleUrl: './participant-dropdown.component.scss'
})
export class ParticipantDropdownComponent {
  @ViewChild('participantsButton') buttonElement: ElementRef<HTMLElement>;

  open = false;
  selectedParticipants: Participant[] = [];

  @Output() selectedParticipantsChange: EventEmitter<Participant[]> =
    new EventEmitter<Participant[]>();
  @Input() selectDefault = true;
  @Input() maxParticipant = 10000;

  _participants: ParticipantForm[] = [];

  @Input() set participants(participants: Participant[]) {
    const max = Math.min(this.maxParticipant, participants?.length);
    this._participants = [];

    for (let i = 0; i < participants.length; i++) {
      const control = new FormControl<boolean>(
        i < max ? this.selectDefault : false
      );
      control.valueChanges.subscribe(() =>
        this.addParticipant(participants[i])
      );
      this._participants.push({
        participant: participants[i],
        isSelectedForm: control
      });
    }

    this.selectedParticipants = [
      ...this._participants
        .filter((form) => form.isSelectedForm.value)
        .map((form) => form.participant)
    ];
    this.selectedParticipantsChange.emit(this.selectedParticipants);
  }

  addParticipant(participant: Participant) {
    const alreadySelected = this.selectedParticipants.find(
      (p) => p.uuid === participant.uuid
    );

    if (alreadySelected) {
      if (this.selectedParticipants.length === 1) {
        return;
      }

      this.selectedParticipants = this.selectedParticipants.filter(
        (p) => p.uuid !== participant.uuid
      );
      this.selectedParticipantsChange.emit([...this.selectedParticipants]);
      return;
    }

    this.selectedParticipants.push(participant);
    this.selectedParticipants = this.selectedParticipants.sort((prev, curr) =>
      prev.index < curr.index ? -1 : 1
    );
    this.selectedParticipantsChange.emit([...this.selectedParticipants]);
  }

  getTitle() {
    if (!this.selectedParticipants.length) {
      return 'Pas de voyageurs';
    }

    return this.selectedParticipants
      .map((participant) => `${participant.firstname} (${participant.age} ans)`)
      .join(', ');
  }
}
