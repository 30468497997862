import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  ButtonComponent,
  ScreenPipe,
  SelectComponent,
  WindowResource
} from 'atomic-lib';
import { FormUtils } from '../../utils/form-utils';
import { AccommodationType } from '../models/accommodation/accommodation-type.enum';
import { OrderBy } from '../models/const/order-by';
import { FiltersAccommodationSearch } from '../models/filters/filters-accommodation-search';
import { FiltersInfoAccommodation } from '../models/filters/filters-info-accommodation';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { SearchRegionFormComponent } from '../search-region-form/search-region-form.component';
import { FiltersAccommodationPopupComponent } from './filters-accommocation-popup/filters-accommodation-popup.component';
import { FiltersAccommodationManagerComponent } from './filters-accommodation-manager.component';

@Component({
  selector: 'vsk-filters-accommodation',
  templateUrl: './filters-accommodation.component.html',
  styleUrls: ['./filters-accommodation.component.scss'],
  imports: [
    SearchBarComponent,
    NgIf,
    AsyncPipe,
    ScreenPipe,
    ButtonComponent,
    SelectComponent,
    FiltersAccommodationPopupComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersAccommodationComponent extends FiltersAccommodationManagerComponent {
  isPopupOpen = false;

  constructor(
    protected store: Store,
    protected activatedRoute: ActivatedRoute,
    public windowResource: WindowResource,
    private elementRef: ElementRef
  ) {
    super(store, activatedRoute, windowResource);
  }

  @Input() set filters(filters: FiltersInfoAccommodation) {
    if (filters) {
      this.minPriceDisplay = filters.minPrice;
      this.maxPriceDisplay = filters.maxPrice;

      this._filtersInfo = filters;

      this.regionsElements = FormUtils.upsertControlForFilter(
        this.regionsElements,
        this._filtersInfo.regions,
        this.currentFilters.regions
      );

      if (this.resortsElements?.length) {
        this.resortsElements.forEach((element) => {
          const filterCount = filters.resorts.find(
            (filter) => filter.id === element.id
          );
          FormUtils.enableAndCheckQuantity(element, filterCount);
        });
      }

      this.accommodationTypesElements = FormUtils.upsertControlForFilter(
        this.accommodationTypesElements,
        this._filtersInfo.types,
        this.currentFilters.types
      );

      this.equipmentsFilter.forEach((element) => {
        switch (element.label) {
          case 'Piscine':
            element.quantity = filters.pool;
            FormUtils.disabledControlWithNoQuantity(element, filters.pool);
            break;
          case 'Spa':
            element.quantity = filters.spa;
            FormUtils.disabledControlWithNoQuantity(element, filters.spa);
            break;
          case 'Sauna':
            element.quantity = filters.sauna;
            FormUtils.disabledControlWithNoQuantity(element, filters.sauna);
            break;
          case 'Hammam':
            element.quantity = filters.hammam;
            FormUtils.disabledControlWithNoQuantity(element, filters.hammam);
            break;
          case 'Balcon / Terrasse':
            element.quantity = filters.balconyTerrace;
            FormUtils.disabledControlWithNoQuantity(
              element,
              filters.balconyTerrace
            );
            break;
          case 'TV':
            element.quantity = filters.tv;
            FormUtils.disabledControlWithNoQuantity(element, filters.tv);
            break;
          case 'Cheminée':
            element.quantity = filters.chimney;
            FormUtils.disabledControlWithNoQuantity(element, filters.chimney);
            break;
          case 'Barbecue':
            element.quantity = filters.bbq;
            FormUtils.disabledControlWithNoQuantity(element, filters.bbq);
            break;
        }
      });

      this.servicesFilter.forEach((element) => {
        switch (element.label) {
          case 'Parking':
            element.quantity = filters.parking;
            FormUtils.disabledControlWithNoQuantity(element, filters.parking);
            break;
          case 'Animaux admis':
            element.quantity = filters.animalsAdmitted;
            FormUtils.disabledControlWithNoQuantity(
              element,
              filters.animalsAdmitted
            );
            break;
          case 'Wifi':
            element.quantity = filters.wifi;
            FormUtils.disabledControlWithNoQuantity(element, filters.wifi);
            break;
          case 'Club enfants':
            element.quantity = filters.childrenClub;
            FormUtils.disabledControlWithNoQuantity(
              element,
              filters.childrenClub
            );
            break;
        }
      });

      this.accessFilter.forEach((element) => {
        element.quantity = filters.pmr;
        FormUtils.disabledControlWithNoQuantity(element, filters.pmr);
      });
    }
  }

  @Input() set filtersSearch(filters: FiltersAccommodationSearch) {
    if (filters) {
      this.currentFilters = filters;

      this.accommodationTypesElements.forEach((element) => {
        if (filters.types.includes(element.id as AccommodationType)) {
          element.control?.setValue(true);
        }
      });

      this.resortsElements.forEach((element) => {
        if (filters.resorts.includes(element.id as number)) {
          element.control?.setValue(true);
        }
      });

      this.regionsElements.forEach((element) => {
        if (filters.regions.includes(element.id as number)) {
          element.control?.setValue(true);
        }
      });

      this.regions = SearchRegionFormComponent.mapToRegion(filters.regions);

      // Equipments establishment & room
      this.poolForm.setValue(filters.pool);
      this.spaForm.setValue(filters.spa);
      this.saunaForm.setValue(filters.sauna);
      this.hammamForm.setValue(filters.hammam);
      this.balconyTerraceForm.setValue(filters.balconyTerrace);
      this.tvForm.setValue(filters.tv);
      this.chimneyForm.setValue(filters.chimney);
      this.bbqForm.setValue(filters.bbq);

      // Access
      this.pmrForm.setValue(filters.pmr);

      this.orderBy = filters.orderBy;

      // Services
      this.parkingForm.setValue(filters.parking);
      this.animalsAdmittedForm.setValue(filters.animalsAdmitted);
      this.wifiForm.setValue(filters.wifi);
      this.childrenClubForm.setValue(filters.childrenClub);

      if (
        filters.priceRange &&
        filters.priceRange.min === filters?.priceRange.max
      ) {
        this.currentFilters.priceRange.min = this.minPriceDisplay;
        this.currentFilters.priceRange.max = this.maxPriceDisplay;
      }
    }
  }

  @Input() set orderChange(order: OrderBy | null) {
    if (this.orderBy !== null && this.orderBy !== order) {
      this.orderBy = order as OrderBy;
      this.selectChange();
    }
  }

  public selectChange() {
    super.selectChange();
    this.isPopupOpen = false;
  }

  selectChangePopup(filters: FiltersAccommodationSearch) {
    this.currentFilters = filters;
    this.selectChange();
    this.closePopup();
  }

  openPopup() {
    this.isPopupOpen = true;

    this.elementRef.nativeElement.parentNode.setAttribute(
      'style',
      'z-index: 10'
    );
  }

  closePopup() {
    this.isPopupOpen = false;
    this.elementRef.nativeElement.parentNode.setAttribute(
      'style',
      'z-index: 3'
    );
  }
}
