import { NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  ButtonComponent,
  InputCheckboxComponent,
  InputComponent
} from 'atomic-lib';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AccountService } from '../../service/account.service';
import { NavigateService } from '../../service/navigate.service';
import { SigninComponent } from '../component/signin.component';
import { Account } from '../models/account';
import { Provider } from '../models/const/provider';

@Component({
  selector: 'vsk-register-form',
  standalone: true,
  imports: [
    ButtonComponent,
    InputComponent,
    NgIf,
    InputCheckboxComponent,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './register-form.component.html',
  styleUrl: './register-form.component.scss'
})
export class RegisterFormComponent extends SigninComponent {
  error = '';
  loading = false;

  formTouched = false;
  firstNameForm = new FormControl<string>('', [
    Validators.pattern('^(?!\\s*$).+'),
    Validators.required
  ]);
  lastNameForm = new FormControl<string>('', Validators.required);
  cguCheckedForm = new FormControl<boolean>(false, Validators.required);
  newsletter = new FormControl<boolean>(false, Validators.required);
  passwordConfirmationForm = new FormControl<string>('', Validators.required);
  phoneForm = new FormControl<string>('', [
    Validators.required,
    Validators.pattern('[0-9 ]{14}')
  ]);
  inscriptionForm: UntypedFormGroup;

  @Output() connectedChange: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private accountService: AccountService,
    protected navigateService: NavigateService,
    protected store: Store
  ) {
    super(navigateService, store);
    this.inscriptionForm = new UntypedFormGroup({
      firstname: this.firstNameForm,
      lastname: this.lastNameForm,
      email: this.emailForm,
      phone: this.phoneForm,
      password: this.passwordForm,
      newsletter: this.newsletter
    });

    combineLatest([
      this.inscriptionForm.valueChanges,
      this.passwordConfirmationForm.valueChanges,
      this.cguCheckedForm.valueChanges
    ]).subscribe(() => {
      if (this.registerEnabled()) {
        this.error = '';
      }
    });

    this.passwordConfirmationForm.valueChanges.subscribe((value) => {
      if (value !== this.passwordForm.value) {
        this.passwordConfirmationForm.setErrors(['error']);
      }
    });
  }

  registerEnabled(): boolean {
    return (
      this.inscriptionForm.valid &&
      this.passwordForm.value === this.passwordConfirmationForm.value &&
      !!this.cguCheckedForm.value &&
      !this.loading
    );
  }

  registerAccount(): void {
    if (!this.registerEnabled()) {
      Object.values(this.inscriptionForm.controls).forEach((control) => {
        control.markAsTouched();
        control.markAsDirty();
      });
      this.passwordConfirmationForm.markAsTouched();
      this.passwordConfirmationForm.markAsDirty();
      this.error = 'Certains champs ne sont pas correctement renseignés';
      this.formTouched = true;
      return;
    }

    const account: Account = new Account({
      ...this.inscriptionForm.getRawValue(),
      birthdate: moment().add(-25, 'years'),
      provider: Provider.VERYSKI,
      guestMode: false
    });
    this.error = '';
    this.loading = true;
    this.signup(account);
  }

  signup(account: Account): void {
    this.register(
      this.accountService
        .isEmailExisting(account.email)
        .pipe(
          filter((exist: string) => {
            if (exist.length > 0) {
              this.error = `L'adresse mail ${this.inscriptionForm.get('email')?.value} est déjà associée à un compte`;
              this.loading = false;
            }
            return exist.length === 0;
          }),
          switchMap(() => this.accountService.createAccount(account))
        )
        .subscribe(
          () => this.connexion(),
          (err) => this.afterConnectError(err)
        )
    );
  }
}
