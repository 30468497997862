import { DecimalPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { ButtonComponent, LoaderComponent, PopupComponent } from 'atomic-lib';
import { Moment } from 'moment';
import { SkiPassService } from '../../../../service/ski-pass.service';
import { FirstSkiDateDropdownComponent } from '../../../../shared/dropdown/first-ski-date-dropdown/first-ski-date-dropdown.component';
import { ParticipantDropdownComponent } from '../../../../shared/dropdown/participant-dropdown/participant-dropdown.component';
import {
  SkiCriteria,
  SkiPassDropdownComponent
} from '../../../../shared/dropdown/ski-pass-dropdown/ski-pass-dropdown.component';
import { Price } from '../../../../shared/models/cart/price';
import { Participant } from '../../../../shared/models/participant/participant';
import { Period } from '../../../../shared/models/period';
import { SkiPass } from '../../../../shared/models/skipass/ski-pass';
import { AddToCart } from '../../cart.action';

@Component({
  selector: 'vsk-ski-pass-popup',
  standalone: true,
  imports: [
    PopupComponent,
    SkiPassDropdownComponent,
    FirstSkiDateDropdownComponent,
    ParticipantDropdownComponent,
    ButtonComponent,
    DecimalPipe,
    NgIf,
    LoaderComponent
  ],
  templateUrl: './ski-pass-popup.component.html',
  styleUrl: './ski-pass-popup.component.scss'
})
export class SkiPassPopupComponent {
  skiPassPrice: Price;
  loading = true;
  error: string;

  selectedSkiPass?: SkiPass;
  selectedParticipants: Participant[] = [];
  selectedFirstDay: Moment;
  @Input() criteria: SkiCriteria;
  @Input() period: Period;
  @Input() participants: Participant[] = [];

  @Output() closeChangeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private skiPassService: SkiPassService,
    private store: Store
  ) {}

  changeSkiPass(skiPass: SkiPass | undefined) {
    this.selectedSkiPass = skiPass;
    this.fetchPricing();
  }

  changeSelectedParticipants(participants: Participant[]) {
    this.selectedParticipants = participants;
    this.fetchPricing();
  }

  changeFirstDay(firstDay: Moment) {
    this.selectedFirstDay = firstDay;
    this.fetchPricing();
  }

  fetchPricing() {
    if (!this.selectedSkiPass) {
      return;
    }

    if (!this.selectedParticipants?.length) {
      return;
    }

    if (!this.selectedFirstDay) {
      return;
    }

    const participants = this.selectedParticipants.map((participant) =>
      participant.birthdate.format('YYYY-MM-DD')
    );

    this.skiPassService
      .getPriceSkiPass(
        this.selectedSkiPass.experienceId,
        participants,
        this.selectedFirstDay
      )
      .subscribe((value) => {
        this.skiPassPrice = value;
        this.loading = false;
      });
  }

  addToCart() {
    if (!this.selectedSkiPass) {
      return;
    }

    if (!this.selectedParticipants?.length) {
      return;
    }

    if (!this.selectedFirstDay) {
      return;
    }

    const participants = this.selectedParticipants.map(
      (participant) => participant.uuid
    );

    this.loading = true;

    this.store
      .dispatch(
        new AddToCart(
          this.selectedSkiPass?.experienceId,
          participants,
          this.selectedFirstDay,
          this.criteria.resortName
        )
      )
      .subscribe(
        () => this.closeChangeEvent.emit(),
        () => {
          this.error =
            'Une erreur est survenue, veuillez réessayer dans quelques instants';
          this.loading = false;
        }
      );
  }
}
