import {
  AsyncPipe,
  DecimalPipe,
  NgClass,
  NgForOf,
  NgIf
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import {
  Activity,
  CarouselImagesComponent,
  PopupComponent,
  ScreenPipe,
  WindowResource
} from 'atomic-lib';
import { AccommodationUtils, Feature } from '../../utils/accommodation-utils';
import {
  BadgeInfo,
  GenericOfferSmallCardComponent
} from '../generic-offer-small-card/generic-offer-small-card.component';
import {
  Establishment,
  EstablishmentProposal
} from '../models/accommodation/establishment';
import { Room } from '../models/accommodation/room';

@Component({
  selector: 'vsk-card-accommodation',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    DecimalPipe,
    NgIf,
    GenericOfferSmallCardComponent,
    AsyncPipe,
    ScreenPipe,
    CarouselImagesComponent,
    PopupComponent
  ],
  templateUrl: './card-accommodation.component.html',
  styleUrl: './card-accommodation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardAccommodationComponent {
  @HostBinding('class') pinpointClass = '';
  @Input() establishment: Establishment;
  showPopup = false;

  constructor(
    private decimalPipe: DecimalPipe,
    public windowResource: WindowResource
  ) {}

  _pinpoint = false;

  @Input() set pinpoint(pinpoint: boolean) {
    this._pinpoint = pinpoint;
    this.pinpointClass = pinpoint ? 'pinpoint-display' : '';
  }

  getBadges(room: Room): BadgeInfo[] {
    const badges = [
      {
        label: room.maxPeople + ' pers.',
        icon: 'user-group'
      }
    ];

    if (room.surface) {
      badges.push({
        label: room.surface + 'm²',
        icon: 'surface'
      });
    }

    return badges;
  }

  establishmentId(
    index: number,
    roomCardLinkUnderCard: EstablishmentProposal<Room>
  ) {
    return roomCardLinkUnderCard.value.codeRoom;
  }

  activityId(index: number, activity: Partial<Activity>) {
    return activity.name;
  }

  getTooltip(price: number, establishment: Establishment) {
    let tooltip = `Hébergement : ${this.decimalPipe.transform(price, '1.2-2', 'fr')} €`;

    if (establishment.priceSkiPass) {
      tooltip += `\nForfaits : ${this.decimalPipe.transform(establishment.priceSkiPass, '1.2-2', 'fr')} €`;
    }

    if (establishment.priceMaterial) {
      tooltip += `\nMatériel : ${this.decimalPipe.transform(establishment.priceMaterial, '1.2-2', 'fr')} €`;
    }

    return tooltip;
  }

  getFeaturesEstablishment(establishment: Establishment) {
    return AccommodationUtils.getFeaturesEstablishment(establishment);
  }

  featureId(index: number, feature: Feature) {
    return feature.label;
  }
}
