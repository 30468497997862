<div class="wrapper">
  <button #packButton
          (click)="open = !open"
          class="select">
    <div class="labels">
      <p class="label">Matériel</p>
      <p *ngIf="pack"
         class="sub-label">
        Gamme {{ pack.offer.category }} - {{ pack.equipmentName }} -
        {{ !pack?.offer.minAge ? 'Adulte (+ 13 ans)' : 'Enfant (' + pack?.offer.minAge + ' - ' + pack?.offer.maxAge + ' ans)' }}
      </p>
    </div>

    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#arrow-down'"></use>
    </svg>
  </button>

  <atc-dropdown (closeChanged)="open = false"
                [elementPosition]="buttonElement"
                [isOpen]="open"
                [left]="'0px'"
                [position]="'bottom-right'"
                [top]="'65px'"
                [width]="'100%'">
    <div class="content">
      <button (click)="choosePack(pack)"
              *ngFor="let pack of packs">
        Gamme {{ pack.offer.category }} - {{ pack.equipmentName }} -
        {{ !pack?.offer.minAge ? 'Adulte (+ 13 ans)' : 'Enfant (' + pack?.offer.minAge + ' - ' + pack?.offer.maxAge + ' ans)' }}
      </button>
    </div>
  </atc-dropdown>
</div>
