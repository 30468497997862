import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  CloseBanner,
  ConnectUser,
  DeleteUser,
  DisconnectUser,
  FetchBanner,
  GoToSearch,
  RenewToken,
  TriggerAlert,
  TriggerLoading,
  TriggerPopup,
  UpdateUserData,
  UpdateUserPass
} from './app.action';
import { LocalStorageResource } from './resource/local-storage.resource';
import { AccountService } from './service/account.service';
import { InformationBannerService } from './service/information-banner.service';
import { UserService } from './service/user.service';
import { Alert } from './shared/models/alert';
import { InformationBanner } from './shared/models/information-banner';
import { TokenRefresh } from './shared/models/token-refresh';
import { User } from './shared/models/user';
import { UserSession } from './shared/models/user-session';

const TOKEN_KEY = 'auth-token';
const REFRESH_TOKEN_KEY = 'auth-refresh-token';
const USER_KEY = 'auth-user';
const INVITED_USER_KEY = 'invited-user';
const BANNER_ID = 'banner-id';

export interface AppStateModel {
  user: UserSession | null;
  token: string | null;
  refreshToken: string | null;
  lang: string;
  search: boolean;
  alert: Alert | null;
  loading: boolean;
  popup: boolean;
  informationBanner: InformationBanner | null;
}

@State<AppStateModel>({
  name: 'vsk',
  defaults: {
    user: LocalStorageResource.getUser(),
    token: LocalStorageResource.getToken(),
    refreshToken: LocalStorageResource.getRefreshToken(),
    lang: LocalStorageResource.getLang(),
    search: false,
    alert: null,
    loading: false,
    popup: false,
    informationBanner: null
  }
})
@Injectable()
export class AppState {
  constructor(
    private accountService: AccountService,
    private authService: SocialAuthService,
    private store: Store,
    private userService: UserService,
    private informationBannerService: InformationBannerService
  ) {
    setTimeout(() => {
      if (this.store.selectSnapshot(AppState.isUserLoggedIn)) {
        this.accountService
          .isConnected()
          .pipe(catchError(() => this.store.dispatch(new DisconnectUser())))
          .subscribe();
      }

      this.store.dispatch(new FetchBanner());
    });
  }

  @Selector()
  static user(state: AppStateModel): UserSession | null {
    return state.user;
  }

  @Selector()
  static isUserAdmin(state: AppStateModel): boolean {
    return state.user?.isAdmin || false;
  }

  @Selector()
  static token(state: AppStateModel): string | null {
    return state.token;
  }

  @Selector()
  static lang(state: AppStateModel): string {
    return state.lang;
  }

  @Selector()
  static banner(state: AppStateModel): InformationBanner | null {
    const id =
      localStorage.getItem(BANNER_ID) === null
        ? null
        : Number(localStorage.getItem(BANNER_ID));

    if (state.informationBanner && state.informationBanner.id === id) {
      return null;
    }

    return state.informationBanner;
  }

  @Selector()
  static isUserLoggedIn(state: AppStateModel): boolean {
    return state.user !== null;
  }

  @Selector()
  static goToSearch(state: AppStateModel): boolean {
    return state.search;
  }

  @Selector()
  static alert(state: AppStateModel): Alert | null {
    return state.alert;
  }

  @Selector()
  static loading(state: AppStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static popup(state: AppStateModel): boolean {
    return state.popup;
  }

  @Action(ConnectUser)
  connectUser(
    ctx: StateContext<AppStateModel>,
    action: ConnectUser
  ): Observable<any> {
    return this.accountService.connectUser(action.login).pipe(
      tap((user) => {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.removeItem(REFRESH_TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, user.jwt);
        window.localStorage.setItem(REFRESH_TOKEN_KEY, user.refreshToken);
        window.localStorage.setItem(USER_KEY, JSON.stringify(user));
        ctx.patchState({
          user,
          token: user.jwt
        });
      })
    );
  }

  @Action(UpdateUserData)
  updateUserData(
    ctx: StateContext<AppStateModel>,
    action: UpdateUserData
  ): Observable<User | null> {
    const state = ctx.getState();
    if (state.user == null) {
      return of(null);
    }

    return this.userService
      .updateUser(action.userUpdate, state.user.email)
      .pipe(
        tap(() => {
          const update = action.userUpdate;
          const user = state.user;

          if (user) {
            user.email = update.email;
            user.lastname = update.lastName;
            user.firstname = update.firstName;
            user.birthdate = moment(update.birthdate);
            window.localStorage.removeItem(USER_KEY);
            window.localStorage.setItem(USER_KEY, JSON.stringify(user));
            ctx.setState({
              ...state,
              user
            });
          } else {
            throw new Error('User not connected');
          }
        })
      );
  }

  @Action(UpdateUserPass)
  updateUserPass(
    ctx: StateContext<AppStateModel>,
    action: UpdateUserPass
  ): Observable<User | null> {
    const state = ctx.getState();
    if (state.user == null) {
      return of(null);
    }

    return this.userService.updatePass(action.passSki).pipe(
      tap(() => {
        const update = action.passSki;
        const user = state.user;

        if (user) {
          user.cardAxess = update.axess as string;
          user.cardSkidata = update.skidata as string;
          window.localStorage.removeItem(USER_KEY);
          window.localStorage.setItem(USER_KEY, JSON.stringify(user));
          ctx.setState({
            ...state,
            user
          });
        } else {
          throw new Error('User not connected');
        }
      })
    );
  }

  @Action(DisconnectUser)
  disconnectUser(
    ctx: StateContext<AppStateModel>,
    action: DisconnectUser
  ): void {
    ctx.patchState({
      user: null,
      token: null
    });
    const token = window.localStorage.getItem(TOKEN_KEY);
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(INVITED_USER_KEY, 'false');
    this.authService.signOut();

    if (token && action.reload) {
      window.location.reload();
    }
  }

  @Action(DeleteUser)
  deleteUser(ctx: StateContext<AppStateModel>): void {
    const user: UserSession = ctx.getState().user as UserSession;
    this.accountService
      .deleteAccount(user.email)
      .subscribe(() => this.disconnectUser(ctx, new DisconnectUser()));
  }

  @Action(RenewToken)
  renewToken(ctx: StateContext<AppStateModel>): Observable<TokenRefresh> {
    const state = ctx.getState();
    return this.accountService
      .refreshSession(state.refreshToken as string)
      .pipe(
        tap((newToken) => {
          window.localStorage.removeItem(TOKEN_KEY);
          window.localStorage.setItem(TOKEN_KEY, newToken.accessToken);

          ctx.setState({
            ...state,
            token: newToken.accessToken
          });
        })
      );
  }

  @Action(GoToSearch)
  goToSearch(ctx: StateContext<AppStateModel>, action: GoToSearch): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      search: action.search
    });
  }

  @Action(TriggerAlert)
  triggerAlert(ctx: StateContext<AppStateModel>, action: TriggerAlert): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      alert: action.alert
    });
  }

  @Action(TriggerLoading)
  triggerLoading(
    ctx: StateContext<AppStateModel>,
    action: TriggerLoading
  ): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      loading: action.loading
    });
  }

  @Action(TriggerPopup)
  triggerPopup(ctx: StateContext<AppStateModel>, action: TriggerPopup): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      popup: action.popup
    });
  }

  @Action(CloseBanner)
  closeBanner(ctx: StateContext<AppStateModel>): void {
    const state = ctx.getState();
    localStorage.setItem(
      BANNER_ID,
      state.informationBanner ? String(state.informationBanner.id) : ''
    );

    ctx.setState({
      ...state,
      informationBanner: null
    });
  }

  @Action(FetchBanner)
  fetchBanner(ctx: StateContext<AppStateModel>): void {
    const state = ctx.getState();
    this.informationBannerService.getBanner().subscribe((banner) => {
      ctx.setState({
        ...state,
        informationBanner: banner
      });
    });
  }
}
