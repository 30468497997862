import moment, { Moment } from 'moment';
import { FiltersAccommodation } from '../accommodation/filters-accommodation';
import { OrderBy } from '../const/order-by';
import { Participant } from '../participant/participant';

export class FiltersAccommodationSearch extends FiltersAccommodation {
  resorts: number[] = [];
  startDate: Moment;
  endDate: Moment;
  nbParticipants: number;
  participants: Participant[] = [];
  activities: number[] = [];
  orderBy: OrderBy = OrderBy.PRICEASC;

  constructor(obj?: Partial<FiltersAccommodationSearch>) {
    super(obj);

    if (obj) {
      Object.assign(this, obj);

      if (this.startDate) {
        this.startDate = moment(this.startDate, 'YYYY-MM-DD');
      }

      if (this.endDate) {
        this.endDate = moment(this.endDate, 'YYYY-MM-DD');
      }
    }
  }

  toQueryParams() {
    return {
      resorts: this.resorts.length ? this.resorts : null,
      tags: this.tags.length ? this.tags : null,
      regions: this.regions.length ? this.regions : null,
      labels: this.labels.length ? this.labels : null,
      activities: this.activities.length ? this.activities : null,
      packagesDisplayed: this.packages ? this.packages : null,
      materialDisplayed: this.material ? this.material : null,
      pool: this.pool ? this.pool : null,
      spa: this.spa ? this.spa : null,
      sauna: this.sauna ? this.sauna : null,
      hammam: this.hammam ? this.hammam : null,
      balconyTerrace: this.balconyTerrace ? this.balconyTerrace : null,
      tv: this.tv ? this.tv : null,
      chimney: this.chimney ? this.chimney : null,
      bbq: this.bbq ? this.bbq : null,
      pmr: this.pmr ? this.pmr : null,
      parking: this.parking ? this.parking : null,
      animalsAdmitted: this.animalsAdmitted ? this.animalsAdmitted : null,
      wifi: this.wifi ? this.wifi : null,
      childrenClub: this.childrenClub ? this.childrenClub : null,
      types: this.types.length ? this.types : null,
      orderBy: this.orderBy ? this.orderBy : null
    };
  }
}
