<atc-popup (closeChanged)="closeChanged.emit()"
           [closeAnim]="close"
           [width]="(windowResource.width() | async | screen: 'tablet-above') ? '60vh' : '100%'"
           title="Filtres">
  <p class="text-center" header>Filtres</p>

  <div body class="flex flex-col gap-y-[40px]">
    <div class="flex flex-col gap-y-[20px]">
      <h5>Prix</h5>
      <atc-range-input (valueChange)="selectChangePrices($event)"
                       [displayedMaxValue]="currentFilters.priceRange?.max || 20000"
                       [displayedMinValue]="currentFilters.priceRange?.min || 0"
                       [maxValue]="maxPriceDisplay"
                       [minValue]="minPriceDisplay"
                       [typeOfValues]="'price'">
      </atc-range-input>
    </div>

    <div *ngIf="filterByResorts"
         class="flex flex-col gap-y-[20px]">
      <h5>Massif</h5>
      <vsk-search-region-form (regionsSelectedChanged)="regionsChanged($event)"
                              [regionsSelected]="regions">
      </vsk-search-region-form>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Type d'hébergement</h5>
      <div class="grid grid-cols-2 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of accommodationTypesElements"
                            [control]="getControl(element.control)">
          {{ element.label }} <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Équipements</h5>
      <div class="grid grid-cols-2 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of equipmentsFilter"
                            [control]="getControl(element.control)">
          {{ element.label }} <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Service</h5>
      <div class="grid grid-cols-2 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of servicesFilter"
                            [control]="getControl(element.control)">
          {{ element.label }} <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Accessibilité</h5>
      <div class="grid grid-cols-2 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of accessFilter"
                            [control]="getControl(element.control)">
          {{ element.label }} <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <div *ngIf="filterByResorts"
         class="flex flex-col gap-y-[20px]">
      <h5>Station</h5>
      <div class="grid grid-cols-2 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of resortsElements"
                            [control]="getControl(element.control)">
          {{ element.label }} <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>
  </div>

  <div class="flex flex-1 justify-between items-center" footer>
    <atc-button (click)="clearFilters()"
                color="ternary"
                size="medium">
      Tout effacer
    </atc-button>

    <atc-button (click)="apply()"
                color="secondary"
                icon="checkmark"
                size="large">
      Appliquer
    </atc-button>
  </div>
</atc-popup>
