import { NgForOf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { InputComponent, OptionElement, SelectComponent } from 'atomic-lib';
import moment from 'moment';
import { UpsertParticipant } from '../../../../filters.action';
import { Participant } from '../../../../shared/models/participant/participant';

export interface ParticipantForm {
  participant: Participant;
  firstNameControl: FormControl<string | null>;
  ageControl: FormControl<number | null>;
}

@Component({
  selector: 'vsk-card-participants',
  standalone: true,
  imports: [NgForOf, InputComponent, SelectComponent],
  templateUrl: './card-participants.component.html',
  styleUrl: './card-participants.component.scss'
})
export class CardParticipantsComponent {
  controls: ParticipantForm[] = [];
  ageOptions: OptionElement<number>[] = [
    {
      id: 0,
      label: '0 an'
    },
    {
      id: 1,
      label: '1 an'
    }
  ];
  @Input() sessionId: string;

  constructor(private store: Store) {
    for (let i = 2; i < 100; i++) {
      this.ageOptions.push({
        id: i,
        label: i + ' ans'
      });
    }
  }

  @Input() set participants(participants: Participant[]) {
    if (!participants?.length) {
      return;
    }

    this.controls = participants.map((participant) => {
      const control = {
        participant,
        firstNameControl: new FormControl<string>(
          participant.firstname.toLowerCase().indexOf('voyageur') === -1
            ? participant.firstname
            : ''
        ),
        ageControl: new FormControl<number>(participant.age)
      };

      control.ageControl.valueChanges.subscribe(() =>
        this.changeParticipantAge(control)
      );

      return control;
    });
  }

  changeParticipantAge(control: ParticipantForm) {
    this.store.dispatch(
      new UpsertParticipant(
        new Participant({
          ...control.participant,
          birthdate: moment().add(-(control.ageControl.value || 0), 'years')
        }),
        this.sessionId
      )
    );
  }

  changeParticipantFirstName(control: ParticipantForm) {
    this.store.dispatch(
      new UpsertParticipant(
        new Participant({
          ...control.participant,
          index: control.participant.index,
          firstname:
            control.firstNameControl.value ||
            `Voyageur ${control.participant.index}`
        }),
        this.sessionId
      )
    );
  }
}
