<div class="content">
  <atc-loader *ngIf="loading"
              class="m-auto py-64"
              color="secondary">
  </atc-loader>

  <ng-container *ngIf="!loading && cart">
    <ng-container *ngIf="cart.isEmpty && !paymentIncomplete">
      <div class="thanks-block">
        <h3>Une erreur est survenue lors de la validation de votre commande ...</h3>

        <p>Notre équipe est notifiée, nous revenons vers vous dans les plus brefs délai.</p>
        <p>Nous sommes disponible par email : <b>hello&#64;verymountain.fr</b> ou par téléphone au
          <a class="font-bold"
             href="tel:+33465847469">04 65 84 74 69</a>
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="cart.isEmpty && paymentIncomplete">
      <h3>Ooops, votre paiement n'a pas abouti, vous pouvez réessayer en vous rendant dans le panier</h3>
      <p>Si le problème persiste n'hésitez pas à nous contacter par email : <b>hello&#64;verymountain.fr</b> ou par
        téléphone
        au <a class="font-bold" href="tel:+33465847469">04 65 84 74 69</a></p>
    </ng-container>

    <ng-container *ngIf="!cart.isEmpty && !paymentIncomplete">
      <div class="thanks-block">
        <h5>#{{ cart.sessionId }}</h5>

        <h2>Que l’aventure commence!</h2>

        <p>
          Votre commande a été confirmée avec succès ! Nous vous remercions pour votre réservation.<br>
          Restez à l'affût, vous recevrez bientôt un e-mail avec les détails de votre séjour.
        </p>
      </div>

      <atc-button size="large">
        <a routerLink="/mon-compte/mes-commandes"
           routerLinkActive="true">
          Accéder à mon compte
        </a>
      </atc-button>
    </ng-container>
  </ng-container>
</div>
