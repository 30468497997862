import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { DisconnectUser, RenewToken } from '../app.action';
import { AppState } from '../app.state';
import { FiltersState } from '../filters.state';
import { TokenRefresh } from '../shared/models/token-refresh';

const TOKEN_HEADER_KEY = 'Authorization';
const SESSION_HEADER_KEY = 'x-session-id';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private store: Store
  ) {}

  addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    this.cookieService.set(TOKEN_HEADER_KEY, 'Bearer ' + token);
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.store.selectSnapshot(AppState.token);
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }

    const sessionId = this.store.selectSnapshot(FiltersState.sessionId);
    if (sessionId) {
      this.cookieService.set(SESSION_HEADER_KEY, sessionId);
      authReq = authReq.clone({
        headers: authReq.headers.set(SESSION_HEADER_KEY, sessionId)
      });
    }

    return next.handle(authReq).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (!authReq.url.includes('sign-in') && error.status === 401) {
            return this.handle401Error(authReq, next);
          }
          switch (error.status) {
            case 403:
              this.router.navigate(['/']).then();
              break;
          }
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.store.dispatch(new RenewToken()).pipe(
        switchMap((token: TokenRefresh) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addTokenHeader(request, token.accessToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.store.dispatch(new DisconnectUser());
          return throwError(err);
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) =>
        next.handle(this.addTokenHeader(request, token.accessToken))
      )
    );
  }
}

export const authInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
];
