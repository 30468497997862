<atc-popup (closeChanged)="closeChangeEvent.emit()"
           height="100%"
           position="right"
           width="100%">
  <p class="text-center" header>Cours de ski</p>
  <div body class="content">
    <h3 *ngIf="selectedSkiClass">{{ selectedSkiClass.name }}</h3>

    <div class="select-group">
      <vsk-ski-class-dropdown (selectSkiClassChange)="changeSkiClass($event)"
                              [criteria]="criteria">
      </vsk-ski-class-dropdown>

      <vsk-first-ski-date-dropdown (selectedDateChange)="changeFirstDay($event)"
                                   *ngIf="days.length"
                                   [days]="days"
                                   [firstDay]="selectedFirstDay"
                                   title="Jour (ou premier jour)">
      </vsk-first-ski-date-dropdown>


      <vsk-timeslot-dropdown (selectedTimeSlotChange)="changeTimeSlot($event)"
                             *ngIf="timeSlotsForSelectedDay.length"
                             [selectedTimeSlot]="selectedTimeSlot"
                             [timeSlots]="timeSlotsForSelectedDay">
      </vsk-timeslot-dropdown>

      <vsk-participant-dropdown (selectedParticipantsChange)="changeSelectedParticipants($event)"
                                [participants]="eligibleParticipants">
      </vsk-participant-dropdown>
    </div>

    <p *ngIf="selectedSkiClass"
       [innerHTML]="selectedSkiClass?.description">
    </p>

    <div *ngIf="loading"
         class="loader-wrapper">
      <atc-loader color="secondary"></atc-loader>
    </div>
  </div>

  <div class="footer" footer>
    <div class="price">
      <p class="total-label">
        Total
      </p>

      <p *ngIf="price && price.publicPrice !== price.price"
         class="public-price">
        {{ price.publicPrice | number: '1.2-2' : 'fr' }} €
      </p>

      <h4 *ngIf="price">
        {{ price.price | number: '1.2-2' : 'fr' }} €
      </h4>
    </div>

    <atc-button (click)="addToCart()"
                [disabled]="loading || !price?.price"
                size="large">
      Ajouter au panier
    </atc-button>
  </div>
</atc-popup>
