<atc-popup (closeChanged)="closeChangeEvent.emit()"
           height="100%"
           position="right"
           width="100%">
  <p class="text-center" header>Forfait de ski</p>
  <div body class="content">
    <h3 *ngIf="selectedSkiPass">{{ selectedSkiPass.name }}</h3>

    <div class="select-group">
      <vsk-ski-pass-dropdown (selectSkiPassChange)="changeSkiPass($event)"
                             [criteria]="criteria"
                             [optionEmpty]="false">
      </vsk-ski-pass-dropdown>

      <vsk-first-ski-date-dropdown (selectedDateChange)="changeFirstDay($event)"
                                   [period]="period">
      </vsk-first-ski-date-dropdown>

      <vsk-participant-dropdown (selectedParticipantsChange)="changeSelectedParticipants($event)"
                                [participants]="participants">
      </vsk-participant-dropdown>
    </div>

    <p *ngIf="selectedSkiPass"
       [innerHTML]="selectedSkiPass?.description">
    </p>

    <div *ngIf="loading"
         class="loader-wrapper">
      <atc-loader color="secondary"></atc-loader>
    </div>
  </div>

  <div class="footer" footer>
    <div class="price">
      <p class="total-label">
        Total
      </p>

      <p *ngIf="skiPassPrice && skiPassPrice.publicPrice !== skiPassPrice.price"
         class="public-price">
        {{ skiPassPrice.publicPrice | number: '1.2-2' : 'fr' }} €
      </p>

      <h4 *ngIf="skiPassPrice">
        {{ skiPassPrice.price | number: '1.2-2' : 'fr' }} €
      </h4>
    </div>

    <atc-button (click)="addToCart()"
                [disabled]="loading"
                size="large">
      Ajouter au panier
    </atc-button>
  </div>
</atc-popup>
