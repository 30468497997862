import { ParamMap } from '@angular/router';
import { Criteria } from '../../shared/models/criteria';
import { FiltersAccommodationSearch } from '../../shared/models/filters/filters-accommodation-search';

export class SetFiltersAndFetchEstablishments {
  static readonly type =
    '[AccommodationState] Persists selected filters and fetch establishments';

  constructor(
    public filters: FiltersAccommodationSearch,
    public queryParams: ParamMap
  ) {}
}

export class SetFilterResort {
  static readonly type = '[AccommodationState] Reset filter resort';

  constructor(public resorts: number[]) {}
}

export class FetchMoreEstablishment {
  static readonly type = '[AccommodationState] Fetch more establishments';
}

export class ResetAccommodationState {
  static readonly type = '[AccommodationState] Reset state establishments';
}

export class GetRooms {
  static readonly type = '[AccommodationState] Fetch rooms by criteria';

  constructor(
    public criteria: Criteria,
    public establishmentId: number,
    public partnerCode: string,
    public roomCode: string
  ) {}
}

export class FetchEstablishments {
  static readonly type = `[ResortState] Fetch establishments`;

  constructor(
    public filters: FiltersAccommodationSearch,
    public page: number,
    public reset = true
  ) {}
}
