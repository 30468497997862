import { DecimalPipe, NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  ButtonComponent,
  CarouselImagesComponent,
  DropdownComponent,
  PopupComponent
} from 'atomic-lib';

export interface BadgeInfo {
  label: string;
  icon: string;
}

@Component({
  selector: 'vsk-generic-offer-small-card',
  standalone: true,
  imports: [
    ButtonComponent,
    NgForOf,
    NgIf,
    DecimalPipe,
    RouterLink,
    RouterLinkActive,
    DropdownComponent,
    CarouselImagesComponent,
    PopupComponent
  ],
  templateUrl: './generic-offer-small-card.component.html',
  styleUrl: './generic-offer-small-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericOfferSmallCardComponent {
  @ViewChild('info', { read: ElementRef }) infoElement:
    | ElementRef<HTMLElement>
    | undefined;

  @Input() images: string[] = [];
  @Input() title = '';
  @Input() showImages = true;
  @Input() ctaName = 'Voir en détail';
  @Input() link = '';
  @Input() params: any;
  @Input() target: '_self' | '_blank' = '_blank';
  @Input() price: number;
  @Input() publicPrice: number;
  @Input() isAccommodation = false;
  @Input() badges: BadgeInfo[] = [];
  @Input() tooltip = '';

  showPopup = false;
  showInfo = false;
}
