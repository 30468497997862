<vsk-navbar></vsk-navbar>

<div class="vsk__margin vsk__width-center content">
  <h1 class="vsk__text-center">Conditions générales de vente de VERYMOUNTAIN</h1>
  <h4>Préambule</h4>

  <p>
    Les présentes conditions générales ont pour objet de définir les droits et obligations des parties dans le cadre
    de la commercialisation par la société VERYMOUNTAIN fournies directement par elle ou par des prestataires
    partenaires,
    à destination de personnes ayant la qualité de consommateurs ou non-professionnels au sens du Code de la
    consommation
    ou de voyageur au sens du Code du tourisme et ayant la capacité juridique de contracter.
  </p>

  <h4>Article 1 - Contenu et champ d'application</h4>

  <p>L’offre de vente de voyages régie par les présentes conditions de vente est proposée par la société VERYMOUNTAIN,
    Société par action simplifiée au capital social de 100.000 Euros, immatriculée au Registre du Commerce et de
    l’industrie
    sous le numéro 901 349 167 00014 immatriculée au Registre National des opérateurs touristiques Atout France
    n°IM069230013.
    Le siège social de VERYMOUNTAIN est situé au 16 Rue Paul Chenavard, 69001 LYON.
  </p>
  <p>
    Les présentes Conditions générales de Vente définissent les conditions d’achat de prestations touristiques par
    Internet.
  </p>
  <p>
    Elles s'appliquent à l'exclusion de toutes autres conditions, et notamment celles applicables pour les ventes sur
    internet
    ou au moyen d'autres circuits de distribution et de commercialisation.
  </p>
  <p>
    La vente est réputée conclue à la date d'acceptation de la commande ou à l'achat immédiat par le vendeur.
    Toute commande ou achat immédiat implique l'adhésion sans réserve aux présentes conditions générales de vente qui
    prévalent
    sur toutes autres conditions, à l'exception de celles qui ont été acceptées expressément par le vendeur.
    Elles sont complétées par les Conditions générales de chaque fournisseur et en cas de contrariété de clauses,
    prévalent sur lesdites Conditions des fournisseurs. L'acheteur déclare avoir pris connaissance des présentes
    conditions
    générales de vente et les avoir acceptées avant son achat immédiat ou la passation de sa commande. En validant son
    contrat,
    le client reconnaît expressément avoir pris connaissance des présentes Conditions, ainsi que des descriptifs plus
    spécifiques
    de prestations choisies sur le Site.
  </p>
  <p>
    Le défaut d’acceptation de tout ou partie des Conditions par le Client empêchera la poursuite de la réservation en
    cours.
  </p>

  <h4>Article 2 - Informations précontractuelles</h4>
  <p>Le Client reconnaît avoir eu communication, préalablement à la passation de sa commande et/ou à la conclusion du
    contrat,
    d'une manière lisible et compréhensible, des présentes conditions générales et particulières de vente et de toutes
    les
    informations listées à l'article L. 221-5 du code de la consommation ainsi qu’à l’article R. 211-4 du code du
    tourisme.
  </p>
  <p>
    Le Client reconnaît, de plus, avoir eu communication du formulaire pris en application de l’arrêté du 1er mars 2018
    « fixant le modèle de formulaire d'information pour la vente de voyages et de séjours ».
  </p>
  <p>
    Ces conditions sont portées à la connaissance du Client avant tout engagement de sa part et constituent une partie
    de l’information
    préalable visée par le Code du Tourisme, outre les fiches descriptives des Prestations.
  </p>
  <p>
    Les informations concernant notamment le prix, l’hébergement, le déroulement du séjour ou les conditions
    d’annulations, pourront
    être modifiées après leurs publications sur le site internet. Ces modifications sont portées à la connaissance du
    client avant la signature de son contrat du voyage.
  </p>
  <h4>Article 3 - Commande</h4>
  <p>Par commande, il faut entendre tout ordre portant sur les prestations figurant sur les tarifs du vendeur et accepté
    par lui.
    La commande ne sera définitive qu’après le paiement de la prestation, acceptation des Conditions Générales et
    Particulières de Vente
    et confirmation de la disponibilité par la société VERYMOUNTAIN et ses partenaires.
  </p>

  <h4>Article 4 – exclusion de droit de rétractation </h4>
  <p>Le droit de rétractation ne peut être exercé pour les contrats de prestations de services d’hébergement, autres que
    d’hébergement résidentiel,
    de services de transport de biens, de locations de voiture, de restauration ou d’activités de loisirs qui doivent
    être fournis à une date ou à
    une période déterminée (article L. 221-28 code de la consommation). L’article L. 221-2 du Code de la consommation
    exclut également cette faculté
    pour les transports de personnes et les forfaits touristiques.
  </p>
  <p>
    La société VERYMOUNTAIN se prévaut de cette absence de droit de rétractation pour toutes les prestations entrant
    dans le champ
    d’application de l’article L. 221-28 ou L. 221-2 du Code de la consommation.
  </p>
  <p>
    Le Client consommateur ou non professionnel ne disposera d’aucun droit de rétractation
  </p>
  <h4>Article 5 - Prix</h4>
  <p>Les prix sont fermes et définitifs. Sauf conditions particulières expresses propres à la vente, les prix des
    prestations effectuées sont ceux
    figurant dans le catalogue des prix au jour de la commande. Ils sont exprimés en monnaie légale et stipulés toutes
    taxes comprises.
    Les informations contenues sur le site précisent ce que les prix comprennent ou ne comprennent pas.
  </p>
  <p>
    Le prix est éventuellement calculé en fonction du nombre de participants. Il ne comprend pas l’acheminement, le
    transport sur place, sauf mention contraire,
    et les dépenses à caractère personnel. Dans certains cas, des frais supplémentaires peuvent être payés directement
    sur place auprès des prestataires.
  </p>

  <h4>Article 6 - Paiement</h4>
  <p>Sauf promotions et voyages spécifiques signalées dans le descriptif, le paiement comptant est exigé lors de la
    réservation par le client. En l’absence de paiement comptant, aucune réservation ne sera prise en compte. La
    totalité du prix est exigible dès la réservation.
  </p>

  <p>Conditions de paiement :</p>
  <p>Les moyens de paiements suivants sont acceptés par VERYMOUNTAIN : </p>

  <li>Carte bancaire : Carte Bleue, Visa, Mastercard, American Express</li>
  <h4>Article 7 – Révision du prix</h4>
  <p>
    Les prix prévus aux conditions spécifiques sont révisables tant à la hausse qu’à la baisse pour tenir compte des
    variations :
  </p>
  <li>Des taux de change appliqués au voyage ou au séjour considéré.</li>
  <li>De prix imputées à l’inflation ou au réchauffement climatique.</li>

  <p>L’application éventuelle d’une majoration de prix en application de l’alinéa précédent sera notifiée de manière
    claire et compréhensible au
    Client et assortie d’une justification et d’un calcul, sur support durable, au plus tard vingt jours avant le début
    des prestations.
  </p>

  <p>En cas de hausse supérieure à 8% du prix total de la commande, le voyageur peut accepter la modification proposée,
    ou demander la résiliation du
    contrat sans payer de frais de résiliation et obtenir le remboursement de tous les paiements déjà effectués à
    condition de le notifier par écrit (Mail)
    dans les 72 heures suivant la modification de tarif communiquée par la société VERYMOUNTAIN
  </p>

  <p>Au cours des 20 jours qui précèdent la date de départ prévu, le prix fixé au contrat ne peut faire l’objet d’aucune
    majoration.
  </p>

  <p> Le client aura la possibilité d’accepter la hausse de prix ou d’annuler son voyage sans aucun frais, par lettre
    recommandée avec accusé de
    réception adressée dans les meilleurs délais après qu’il ait eu connaissance de cette modification.
  </p>

  <h4>Article 8 – Réservations</h4>
  <p>Dans le cas où une ou plusieurs prestations sont réservées en ligne, le processus de réservation est le suivant
    :</p>
  <p>Etape 1 : Le Client indique ses dates, les activités souhaitées et la liste des voyageurs pour obtenir les
    destinations qui correspondent à sa demande.</p>
  <p>Etape 2 : il sélectionne alors une destination parmi la liste. Dans cette destination, il a la possibilité de
    réserver une ou plusieurs prestations ainsi qu’un hébergement. Tout est situé à une distance raisonnable du centre
    de la station. Le Client peut choisir un hébergement et une chambre dans cet hébergement, ainsi que réserver des
    options en amont du séjour (pension complète, changements des draps …) il peut ajouter autant d’activités qu’il
    veut, modifier les horaires ou les participants de chaque activité ou encore supprimer des prestations. Pour finir,
    le Client a la possibilité de réserver son matériel de ski et toute autre prestation en rapport avec son séjour.</p>
  <p>Etape 3 : Le client clique ensuite sur « Réserver mon séjour » pour confirmer son panier. Il se connecte et rentre
    alors les informations de chaque participant (poids, âge…)</p>
  <p>Etape 4 : Le Client confirme qu’il a bien pris connaissance des CGV et procède au paiement. Il reçoit ensuite un
    email de confirmation de commande, récapitulatif de l’ensemble des prestations réservées.</p>
  <p>Etape 5 : Les partenaires ont 48h pour accepter une réservation ou proposer une alternative. Une fois que
    l’ensemble des partenaires ont accepté, un mail de confirmation est envoyé au client. La société VERYMOUNTAIN laisse
    48h aux prestataires pour confirmer la réservation. Dans le cas où la prestation demandée est non disponible auprès
    de nos partenaires, la société VERYMOUNTAIN peut proposer une autre prestation au client ou procéder au
    remboursement du client.</p>
  <p>En cas de revente de séjours, la société VERYMOUNTAIN contacte par mail le client ayant réservé en ligne lui
    indiquant être dans l’attente de la confirmation de la part du Partenaire. Une fois que le Partenaire a accepté, un
    mail de confirmation est envoyé au client.</p>
  <p>À noter qu’un prestataire partenaire peut annuler une réservation pour différentes causes (météo, blessure,
    impossibilité…) et que VERYMOUNTAIN n’est nullement responsable de l’annulation. En revanche, le Client sera
    remboursé automatiquement si la réservation est annulée par le partenaire ou VERYMOUNTAIN.</p>
  <p>En cas de réservation moins de 48h avant le début de la prestation ou du séjour, VERYMOUNTAIN et le partenaire se
    gardent le droit d’annuler la réservation automatiquement si le temps pour organiser la prestation est trop court.
    Si le partenaire n’accepte pas la réservation jusqu’à 2h avant le début de la prestation, celle-ci est
    automatiquement considérée comme annulée et le Client est remboursé.</p>

  <h4>Article 9 – Cession du contrat</h4>
  <p>Le voyageur peut, avant le début de prestation, céder le contrat à une personne satisfaisant à toutes les
    conditions applicables à ce contrat. Il doit impérativement informer la société VERYMOUNTAIN de la cession de son
    contrat à un tiers par mail ou autre support durable au plus tard 15 jours avant la date de prestation indiquant
    précisément les nom et adresse du ou des cessionnaires et du ou des participants au voyage et en justifiant que
    ceux-ci remplissent les mêmes conditions que lui pour effectuer le voyage ou le séjour. Le cédant du contrat et le
    cessionnaire sont solidairement responsables du paiement du solde du prix ainsi que des frais, redevances ou autres
    coûts supplémentaires éventuels occasionnés par cette cession.</p>
  <p>La cession entraîne des frais, le montant dépendant des prestations réservées, il est à faire valider par
    VERYMOUNTAIN.</p>

  <h4>Article 10 – Modification du contrat</h4>
  <p>La société VERYMOUNTAIN peut modifier unilatéralement les clauses du contrat après sa conclusion et avant le début
    de la prestation touristique, et ce, sans que le Client puisse s’y opposer, quand cette modification porte sur un
    élément meneur du contrat. La société VERYMOUNTAIN en informe le client d'une manière claire, compréhensible et
    apparente sur un support durable.</p>
  <p>Si la modification proposée par la société concerne un élément essentiel du contrat, la société informe le client
    de la répercussion de cette modification sur le contrat : parmi ces informations figurent celles concernant le prix,
    le délai d'acceptation du client en cas d’une proposition d’une nouvelle prestation et les conséquences de cette
    acceptation ou refus.</p>
  <p>Si la nouvelle prestation proposée par la société en remplaçant la prestation d’origine entraine une baisse de
    qualité de la prestation ou de son prix, le client a le droit à une réduction n'excédant pas la différence entre les
    deux prestations. Si le contrat est résilié et que le client refuse les nouvelles prestations proposées par la
    société, la société VERYMOUNTAIN rembourse le client intégralement et dans un délai raisonnable.</p>
  <p>Les prestations payées par le client, mais non consommées partiellement ou intégralement ou commencées tardivement,
    ne peuvent faire l’objet d’un remboursement de la part de la société VERYMOUNTAIN. La société VERYMOUNTAIN n’est pas
    responsable dans le cas où le Client achète une autre prestation que celle qui est facturée par elle auprès d’un de
    ses partenaires ou si le Client décide de modifier lui-même la prestation.</p>

  <h4>Article 11 - Résolution du contrat</h4>
  <p>Le client peut résoudre le contrat à tout moment avant le début de la prestation. Il faudrait informer la société
    VERYMOUNTAIN de cette annulation par mail.</p>
  <p>La résolution par le client du contrat entraine le paiement par ce dernier des frais selon les modalités suivantes
    :</p>
  <li>Annulation plus de 45 jours avant le début de la prestation : remboursement intégral, hors coût de l’assurance
    souscrite par le client et des éventuels frais de dossier versés ;
  </li>
  <li>Entre 44 et 30 jours avant le début de la prestation : 30% du montant total de la commande sera retenu par la
    société VERYMOUNTAIN, ainsi que le montant de l’assurance et les frais de dossier ;
  </li>
  <li>Entre 29 et 15 jours avant le début de la prestation : 50% du montant total de la commande sera retenu par la
    société VERYMOUNTAIN ainsi que le montant de l’assurance et les frais de dossier ;
  </li>
  <li>Moins de 14 jours avant le début de la prestation : 100% du montant de la commande sera retenu par la société
    VERYMOUNTAIN.
  </li>

  <p>Les frais de résolution ne sont pas dus à la société VERYMOUNTAIN si la résolution est causée par une force
    majeure.</p>

  <h4>Article 12 - Garanties</h4>

  <p>VERYMOUNTAIN est garant de la conformité des prestations au contrat, permettant au client de formuler une demande
    au titre de la garantie légale de conformité prévue aux articles L. 217-11 et suivants du Code de la
    Consommation.</p>
  <p>Cette garantie couvre les défauts de conformité provenant d'un défaut de réalisation des services commandés dans
    les conditions et selon les modalités définies en annexe aux présentes conditions générales de vente.</p>
  <p>Le client doit faire connaître au prestataire, les défauts de conformité dans un délai maximum de 48h à compter de
    la fourniture des services.</p>
  <p>Les défauts donneront lieu à rectification, substitution, réduction de prix ou remboursement dans les meilleurs
    délais, compte tenu de l'importance de la non-conformité et de la valeur de la prestation concernée, sauf si cela
    est impossible ou entraîne des coûts disproportionnés, compte tenu de l'importance de la non-conformité et de la
    valeur des prestations concernés. Si VERYMOUNTAIN propose de remplacer la prestation ou de réduire le prix de la
    prestation de remplacement, le voyageur ne peut refuser les prestations proposées que si elles ne sont pas
    comparables à ce qui avait été prévu dans le contrat ou si la réduction de prix octroyée n'est pas appropriée.</p>
  <p>La garantie du prestataire est limitée au remboursement des services effectivement payés par le client et le
    prestataire ne pourra être considéré comme responsable ni défaillant pour tout retard ou inexécution consécutif à la
    survenance d'un cas de force majeure.</p>

  <h4>Article 13 - Propriété intellectuelle</h4>

  <p>Tous les documents techniques, produits, dessins, photographies remis aux clients demeurent la propriété exclusive
    de VERYMOUNTAIN, seul titulaire des droits de propriété intellectuelle sur ces documents, et doivent lui être rendus
    à sa demande.</p>
  <p>Les clients s'engagent à ne faire aucun usage de ces documents, susceptible de porter atteinte aux droits de
    propriété industrielle ou intellectuelle du fournisseur et s'engagent à ne les divulguer à aucun tiers.</p>

  <h4>Article 14 - Protection des données à caractère personnel</h4>
  <h5>14.1 Collecte des données personnelles</h5>
  <p>Les données à caractère personnel qui sont notamment collectées sur ce site sont les suivantes :</p>
  <p class="bold ">Ouverture de compte : </p>
  <li>Nom</li>
  <li>Prénom</li>
  <li>Adresse électronique</li>
  <li>Numéro de téléphone</li>
  <li>Date de naissance</li>

  <p class="bold ">Connexion :</p>
  <li>Nom</li>
  <li>Prénom</li>
  <li>Données de connexion</li>
  <li>Données d'utilisation</li>
  <li>Données de localisation</li>
  <li>Données relatives au paiement.</li>

  <p class="bold ">Profil</p>

  <li>Adresse</li>
  <li>Numéro de téléphone</li>
  <li>Poids, taille, âge…</li>

  <p class="bold">Paiement</p>
  <p>Dans le cadre du paiement des produits et prestations proposés sur le site, notre prestataire de paiement (Stripe)
    enregistre des données financières relatives au compte bancaire ou à la carte de crédit de l'utilisateur.</p>
  <p class="bold">Communication</p>
  <p>Lorsque le site est utilisé pour communiquer avec d'autres membres, les données concernant les communications de
    l'utilisateur font l'objet d'une conservation temporaire.
  </p>
  <p class="bold">Cookies</p>
  <p>Les cookies sont utilisés dans le cadre de l'utilisation du site. L'utilisateur a la possibilité de désactiver les
    cookies à partir des paramètres de son navigateur ou à son arrivée sur le site.</p>

  <h5>14.2 Utilisation des données personnelles</h5>
  <p>Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services du
    site, leur amélioration et le maintien d'un environnement sécurisé. Plus précisément, les utilisations sont les
    suivantes :</p>

  <li>Accès et utilisation du site par l'utilisateur ;</li>
  <li>Gestion du fonctionnement et optimisation du site ;</li>
  <li>Organisation des conditions d'utilisation des Services de paiement ;</li>
  <li>Vérification, identification et authentification des données transmises par l'utilisateur ;</li>
  <li>Mise en œuvre d'une assistance utilisateurs ;</li>
  <li>Personnalisation des services en affichant des publicités en fonction de l'historique de navigation de
    l'utilisateur, selon ses préférences ;
  </li>
  <li>Prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des
    incidents de sécurité ;
  </li>
  <li>Gestion des éventuels litiges avec les utilisateurs ;</li>
  <li>Envoi d'informations commerciales et publicitaires, en fonction des préférences de l'utilisateur.</li>

  <h5>14.3 Partage des données personnelles avec des tiers</h5>
  <p>Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants :</p>
  <li>Quand l'utilisateur utilise les services de paiement, pour la mise en œuvre de ces services, le site est en
    relation avec des sociétés bancaires et financières tierces avec lesquelles elle a passé des contrats ;
  </li>
  <li>Lorsque l'utilisateur publie, dans les zones de commentaires libres du site, des informations accessibles au
    public ;
  </li>
  <li>Quand l'utilisateur autorise le site web d'un tiers à accéder à ses données ;</li>
  <li>Quand le site recourt aux services de prestataires pour fournir l'assistance utilisateurs, la publicité et les
    services de paiement. Ces prestataires disposent d'un accès limité aux données de l'utilisateur, dans le cadre de
    l'exécution de ces prestations, et ont une obligation contractuelle de les utiliser en conformité avec les
    dispositions de la réglementation applicable en matière protection des données à caractère personnel ;
  </li>
  <li>Si la loi l'exige, le site peut effectuer la transmission de données pour donner suite aux réclamations présentées
    contre le site et se conformer aux procédures administratives et judiciaires ;
  </li>
  <li>Si le site est impliqué dans une opération de fusion, acquisition, cession d'actifs ou procédure de redressement
    judiciaire, il pourra être amené à céder ou partager tout ou partie de ses actifs, y compris les données à caractère
    personnel. Dans ce cas, les utilisateurs seraient informés, avant que les données à caractère personnel ne soient
    transférées à une tierce partie.
  </li>

  <h5>14.4 Sécurité et confidentialité</h5>
  <p>Le site met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du
    numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés.
    Toutefois, il est à signaler qu'internet n'est pas un environnement complètement sécurisé et le site ne peut pas
    garantir la sécurité de la transmission ou du stockage des informations sur internet.</p>

  <h5>14.5 Mise en oeuvre des droits des utilisateurs</h5>
  <p>En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des
    droits suivants :</p>
  <li>Ils peuvent mettre à jour ou supprimer les données qui les concernent en se connectant à leur compte et en
    configurant les paramètres de ce compte ;
  </li>
  <li>Ils peuvent supprimer leur compte, en allant dans la partie “Mon compte”. Il est à noter que les informations
    partagées avec d'autres utilisateurs, comme les publications sur les forums, notes et commentaires, peuvent rester
    visibles du public sur le site, même après la suppression de leur compte ;
  </li>
  <li>Ils peuvent exercer leur droit d'accès, pour connaître les données personnelles les concernant, en écrivant à
    l'adresse électronique suivante : hello&#64;verymountain.fr. Dans ce cas, avant la mise en œuvre de ce droit, le
    site
    peut demander une preuve de l'identité de l'utilisateur afin d'en vérifier l'exactitude ;
  </li>
  <li>Si les données à caractère personnel détenues par le site sont inexactes, ils peuvent demander la mise à jour des
    informations, en écrivant à l'adresse électronique suivante : hello&#64;verymountain.fr ;
  </li>
  <li>Les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois
    applicables en matière de protection des données, en écrivant à l'adresse électronique suivante :
    hello&#64;verymountain.fr.
  </li>

  <h5>14.6 Évolution de la présente clause</h5>
  <p>Le site se réserve le droit d'apporter toute modification à la présente clause relative à la protection des données
    à caractère personnel à tout moment. Si une modification est apportée à la présente clause de protection des données
    à caractère personnel, le site s'engage à publier la nouvelle version. Le site informera également les utilisateurs
    de la modification par messagerie électronique, dans un délai minimum de 15 jours avant la date d'effet. Si
    l'utilisateur n'est pas d'accord avec les termes de la nouvelle rédaction de la clause de protection des données à
    caractère personnel, il a la possibilité de supprimer son compte.
  </p>

  <h4>Article 15 - Force majeure</h4>
  <p>Toutes circonstances indépendantes de la volonté des parties empêchant l'exécution dans des conditions normales de
    leurs obligations sont considérées comme des causes d'exonération des obligations des parties et entraînent leur
    suspension.
  </p>
  <p>La partie qui invoque les circonstances visées ci-dessus doit avertir immédiatement l'autre partie de leur
    survenance, ainsi que de leur disparition.</p>
  <p>Seront considérés comme cas de force majeure tous faits ou circonstances irrésistibles, extérieurs aux parties,
    imprévisibles, inévitables, indépendants de la volonté des parties et qui ne pourront être empêchés par ces
    dernières, malgré tous les efforts raisonnablement possibles. De façon expresse, sont considérés comme cas de force
    majeure ou cas fortuits, outre ceux habituellement retenus par la jurisprudence des cours et des tribunaux français
    : le blocage des moyens de transports ou d'approvisionnements, tremblements de terre, incendies, tempêtes,
    inondations, foudre, l'arrêt des réseaux de télécommunication ou difficultés propres aux réseaux de
    télécommunication externes aux clients.</p>
  <p>Les parties se rapprocheront pour examiner l'incidence de l'événement et convenir des conditions dans lesquelles
    l'exécution du contrat sera poursuivie. Si le cas de force majeur a une durée supérieure à trois mois, les présentes
    conditions générales pourront être résiliées par la partie lésée.</p>

  <h4>Article 16 - Responsabilité</h4>
  <p>La responsabilité de la société VERYMOUNTAIN de services au titre des CGV ne saurait être engagée dans le cas où
    l'inexécution de ses obligations serait imputable au fait d'un tiers, même s'il est prévisible, à la faute du
    Client, ou à la survenance d'un évènement de force majeure tel que défini par les juridictions françaises ou à tout
    autre évènement qui n'a pas été raisonnablement sous le contrôle exclusif la société VERYMOUNTAIN. La société
    VERYMOUNTAIN de services ne saurait être tenue pour responsable eu égard à une utilisation des services non conforme
    à leur utilisation. Dans l'hypothèse où la responsabilité de la société VERYMOUNTAIN devait être établie et retenue
    à raison d'un préjudice subi par le Client et imputable exclusivement au service acheté, celle-ci est limitée à
    trois fois le prix total hors taxes des prestations, à l’exception des préjudices corporels et des dommages causés
    intentionnellement ou par négligence. Toute réclamation déposée par un Client contre la société VERYMOUNTAIN doit
    être formulée dans les 2 ans suivant l'apparition de l'événement objet de la réclamation.
  </p>
  <p>Les réclamations sont à adresser par tout moyen permettant d’en accuser réception à : VERYMOUNTAIN, 16 Rue Paul
    Chenavard, 69001 LYON. Faute de réponse satisfaisante ou absence de réponse dans un délai de 90 jours, le client
    peut saisir le médiateur du Tourisme et du Voyage, dont les coordonnées et modalités de saisine sont disponibles sur
    son site : www.mtv.travel. La saisie du médiateur ne peut se faire qu’après celle du service gérant l’après-vente au
    sein de VERYMOUNTAIN. Les professionnels et les consommateurs sont libres de suivre ou non les avis du médiateur qui
    demeurent confidentiels.
  </p>

  <h4>Article 17 - Exécution des prestations</h4>
  <p>En cas de difficultés dans l’exécution de prestations, la société VERYMOUNTAIN se force à apporter l’aide
    appropriée au regard des circonstances. Le prestataire sera en droit de facturer un prix raisonnable pour cette aide
    si cette difficulté est causée par la faute du client ou par sa négligence. Le prix facturé ne dépassera pas les
    coûts réels supportés par le prestataire.</p>

  <h4>Article 18 - Non renonciation</h4>
  <p>Le fait pour le prestataire de services de ne pas se prévaloir à un moment donné de l'une des clauses des présentes
    ne peut valoir renonciation à se prévaloir ultérieurement de ces mêmes clauses. </p>

  <h4>Article 19 : Assurances spécifiques. </h4>

  <p>La société VERYMOUNTAIN a souscrit une assurance couvrant les conséquences de sa responsabilité civile
    professionnelle auprès de GROUPAMA, qui couvre les dommages corporels, matériels et immatériels à hauteur de
    460.000€.</p>
  <p>Le Client s’engage à détenir et être à jour de son assurance de responsabilité civile pour couvrir les dommages
    qu’il pourrait causer. La société VERYMOUNTAIN n’inclut aucune assurance dans les prix des prestations proposées sur
    le Site sauf en cas d’achat de forfait de ski alpin pour lequel une assurance secours sur piste peut être proposée.
    S’il n’est pas indiqué clairement qu’une assurance a été souscrite et payée sur VERYMOUNTAIN, aucune assurance n’a
    été réellement souscrite. Nous recommandons au Client de souscrire en même temps de sa réservation, un contrat
    d’assurance et/ou d’assistance facultatif couvrant les conséquences de certains cas d’annulation.</p>
  <p>En cas de souscription d’un contrat d’assurance, la police d’assurance souscrite par le Client est accessible,
    avant validation de la commande, par le lien hypertexte vers « conditions d’assurances » figurant sur le
    récapitulatif de la Commande. En tout état de cause, il appartient au Client de prendre complètement connaissance du
    contrat d’assurance, et notamment les clauses d’exclusion, de limitation ou fixant les modalités d’applications de
    l’assurance avant d’y souscrire. En cas d’assurances multiples couvrant un risque déjà assuré (perte, vol et
    endommagement et risques liés au voyage), le client aura la faculté de se rétracter sans frais sous un délai de 14
    jours suite à la réservation, sous réserve qu’aucun sinistre n’ait été déclaré. Le montant de la souscription de
    l’assurance demeure systématiquement acquis et il n’est pas possible de prétendre à son remboursement, sauf en cas
    d’annulation du contrat par VERYMOUNTAIN sans faute de l’utilisateur. Les déclarations de sinistre se font
    directement auprès de la compagnie d’assurance, en respectant les termes et délais du contrat d’assurance
    souscrit.</p>

  <h4>Article 20 - Juridiction compétente</h4>
  <p>Tous les litiges auxquels les opérations d'achat et de vente conclues en application des présentes conditions
    générales de vente pourraient donner lieu, concernant tant leur validité, leur interprétation, leur exécution, leur
    résiliation, leurs conséquences et leurs suites et qui n'auraient pas pu être résolus à l'amiable entre le vendeur
    et le client, seront soumis aux tribunaux compétents (tribunal de LYON) dans les conditions de droit commun.</p>

  <h4>Article 21 - Droit applicable</h4>
  <p>Toute question relative aux présentes conditions générales de vente ainsi qu'aux ventes qu'elles régissent, qui ne
    serait pas traitée par les présentes stipulations contractuelles, sera régie par la loi française à l'exclusion de
    tout autre droit.</p>

  <h4>Article 22 - Langue du contrat</h4>
  <p>Les présentes conditions générales de vente sont rédigées en langue française. Dans le cas où elles seraient
    traduites en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.</p>

  <h4>Article 23 - Médiation</h4>
  <p>Le client peut recourir à une médiation conventionnelle, notamment auprès de la Commission de la médiation de la
    consommation ou auprès des instances de médiation sectorielles existantes, ou à tout mode alternatif de règlement
    des différends (conciliation, par exemple) en cas de contestation.</p>
</div>

<vsk-footer></vsk-footer>
