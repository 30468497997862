<button (click)="showPopup = true"
        *ngIf="showImages && images.length"
        class="pictures">
  <img *ngFor="let img of images; let i = index"
       [alt]="title + ' image ' + i"
       [src]="img">
</button>

<div class="infos">
  <p>{{ title }}</p>

  <div *ngIf="badges?.length"
       class="badges">
    <div *ngFor="let badge of badges" class="badge-info">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#' + badge.icon"></use>
      </svg>
      <p>{{ badge.label }}</p>
    </div>
  </div>
</div>

<div class="wrapper-pricing">
  <div class="pricing">
    <div class="total">
      <p>{{ price | number: '1.2-2' : 'fr' }}€</p>
      <span *ngIf="isAccommodation">/nuits</span>
    </div>
    <p *ngIf="publicPrice && publicPrice !== price">
      {{ publicPrice | number: '1.2-2' : 'fr' }}€
    </p>
  </div>

  <div #info
       (click)="showInfo = !showInfo"
       (mouseenter)="showInfo = true"
       (mouseleave)="showInfo = false"
       *ngIf="tooltip && showImages"
       class="info-price">
    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#information-circle'"></use>
    </svg>

    <atc-dropdown [elementPosition]="infoElement"
                  [isOpen]="showInfo"
                  borderRadius="12px"
                  classes="tooltip"
                  left="-12px"
                  position="bottom-left"
                  top="30px"
                  width="300px">
      <p class="title-info-price">Détail du prix</p>
      <p [innerHTML]="tooltip" class="content-info-price"></p>
    </atc-dropdown>
  </div>

  <a [queryParamsHandling]="'merge'"
     [queryParams]="params"
     [routerLink]="link"
     [target]="target"
     routerLinkActive="true">
    <atc-button [iconRightSide]="true"
                icon="right-arrow">
      {{ ctaName }}
    </atc-button>
  </a>
</div>

<atc-popup (closeChanged)="showPopup = false"
           *ngIf="showPopup"
           width="100%">
  <p [innerHTML]="'Photos · ' + title" header></p>
  <atc-carousel-images [slides]="images"
                       body
                       class="hide-small-desktop-and-below">
  </atc-carousel-images>

  <div body class="carousel-mobile hide-small-desktop-and-above">
    <img *ngFor="let picture of images; let i = index" [alt]="title + ' - Photo ' + i" [src]="picture">
  </div>
</atc-popup>
