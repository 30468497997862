import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ConnectUser } from '../../app.action';
import { NavigateService } from '../../service/navigate.service';
import { PasswordUtils } from '../../utils/password-utils';
import { RxjsComponent } from './rxjs.component';

@Component({
  standalone: true,
  template: ''
})
export class SigninComponent extends RxjsComponent {
  error = '';
  loading = false;
  emailForm = new FormControl<string>('', [
    Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
    Validators.required
  ]);
  passwordForm = new FormControl<string>('', [
    Validators.required,
    PasswordUtils.passwordStrengthValidator()
  ]);

  @Input() redirectHomeOnConnexion = true;
  @Input() redirectionAreLink = true;

  @Output() switchLoginTypeChange: EventEmitter<void> =
    new EventEmitter<void>();

  protected readonly PasswordUtils = PasswordUtils;

  constructor(
    protected navigateService: NavigateService,
    protected store: Store
  ) {
    super();
  }

  protected dispatchConnect(email: string, password: string) {
    return this.store.dispatch(
      new ConnectUser({
        email,
        password
      })
    );
  }

  protected connexion(): void {
    this.register(
      this.store
        .dispatch(
          new ConnectUser({
            email: this.emailForm.value,
            password: this.passwordForm.value
          })
        )
        .subscribe(
          () => this.afterConnect(),
          (err) => this.afterConnectError(err)
        )
    );
  }

  protected afterConnect() {
    this.loading = false;

    if (this.redirectHomeOnConnexion) {
      this.navigateService
        .navigateWithQueryParams('/')
        .then(() => window.location.reload());
    }
  }

  protected afterConnectError(error: any): void {
    this.loading = false;
    switch (error.status) {
      case 401:
        this.error = 'Mot de passe incorrect';
        break;
      case 412:
        this.error = "Le compte associé à cet email n'est pas activé";
        break;
      default:
        this.error =
          'Une erreur est survenue, veuillez réessayer dans quelques instants';
        break;
    }
  }
}
