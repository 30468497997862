import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {
  BreadcrumbComponent,
  BreadcrumbLink,
  ButtonComponent,
  CardMapComponent,
  InputCheckboxComponent,
  OptionElement,
  ScreenPipe,
  SelectComponent,
  WindowResource
} from 'atomic-lib';
import { Observable, switchMap } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  tap
} from 'rxjs/operators';
import {
  Bounds,
  FiltersStationChangeOrderBy,
  GeoBoundsActiveChange
} from '../../filters.action';
import { FiltersState } from '../../filters.state';
import { FiltersService } from '../../service/filters.service';
import { MetaDescriptionService } from '../../service/meta-description.service';
import { ResortLabelService } from '../../service/resort-label.service';
import { ResortService } from '../../service/resort.service';
import { MapComponent } from '../../shared/component/map.component';
import { FooterComponent } from '../../shared/footer/footer.component';
import { OrderBy } from '../../shared/models/const/order-by';
import { FiltersStation } from '../../shared/models/filters/filters-station';
import { FiltersTripInfo } from '../../shared/models/filters/filters-trip-info';
import { Period } from '../../shared/models/period';
import { ResortLabel } from '../../shared/models/resort/resort-label';
import { ResortMap } from '../../shared/models/resort/resort-map';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { SharedModule } from '../../shared/shared.module';
import { UrlUtils } from '../../utils/url-utils';
import { HeaderFiltersComponent } from './header-filters/header-filters.component';

@Component({
  selector: 'vsk-search-trip',
  standalone: true,
  imports: [
    NavbarComponent,
    BreadcrumbComponent,
    AsyncPipe,
    HeaderFiltersComponent,
    CardMapComponent,
    NgForOf,
    GoogleMap,
    InputCheckboxComponent,
    MapInfoWindow,
    MapMarker,
    NgIf,
    SelectComponent,
    NgClass,
    FooterComponent,
    SharedModule,
    ScreenPipe,
    ButtonComponent
  ],
  templateUrl: './search-trip.component.html',
  styleUrl: './search-trip.component.scss'
})
export class SearchTripComponent
  extends MapComponent<ResortMap>
  implements OnInit
{
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  @Select(FiltersState.filtersStation)
  filtersResorts$: Observable<FiltersStation>;
  @Select(FiltersState.period) period$: Observable<Period>;

  filtersMap$: Observable<FiltersTripInfo>;
  resortLabels$: Observable<ResortLabel[]>;

  breadcrumbItems: BreadcrumbLink[] = [
    {
      label: 'Accueil',
      url: '/'
    },
    {
      label: 'Recherche de séjour',
      url: '/recherche-sejours'
    }
  ];
  ordersSelection: OptionElement<OrderBy>[] = [
    {
      id: OrderBy.RELEVANCE,
      label: 'Trier par : Affinité',
      disabled: false
    },
    {
      id: OrderBy.PRICEASC,
      label: 'Trier par : Prix le plus bas',
      disabled: false
    },
    {
      id: OrderBy.PRICEDESC,
      label: 'Trier par : Prix le plus élevé',
      disabled: false
    }
  ];
  orderForm: FormControl<OrderBy | null> = new FormControl<OrderBy | null>(
    this.ordersSelection[0].id
  );
  loading = true;
  zoom = 6;
  center: google.maps.LatLngLiteral = {
    lat: 45.052825927734375,
    lng: 2.7113842964172363
  };
  resorts: ResortMap[] = [];
  listResorts: ResortMap[] = [];
  page = 0;

  constructor(
    private metaDescriptionService: MetaDescriptionService,
    private resortLabelService: ResortLabelService,
    private filtersService: FiltersService,
    private resortService: ResortService,
    private store: Store,
    public windowResource: WindowResource,
    public router: Router
  ) {
    super(windowResource, router);
  }

  ngOnInit(): void {
    // Meta description
    const title = `Trouvez le séjour en montagne qui vous convient · VeryMountain`;
    const description = `Réservez l'intégralité de votre séjour en montagne : Choisissez parmi plus de 159 destinations, 10.000 hébergements et 5.000 expériences à vivre en montagne.`;
    const url = 'recherche-sejours';

    this.metaDescriptionService.updateMeta(title, description, url);

    // Fetch resorts
    this.register(
      this.filtersResorts$
        .pipe(
          debounceTime(50),
          tap(() => (this.loading = true)),
          distinctUntilChanged((prev, curr) => prev.id === curr.id),
          filter((filters) => filters.participantsAreValid()),
          switchMap((filtersStation) => {
            this.filtersMap$ =
              this.filtersService.getFiltersMap(filtersStation);
            return this.resortService.getStationsByFilters(filtersStation);
          })
        )
        .subscribe((resorts) => {
          this.resorts = resorts;
          this.listResorts = resorts.slice(0, 10);
          this.page = 0;

          this.markers = resorts.map((station) => station.marker);
          this.placeMarkersOnMap();
          this.loading = false;
        })
    );

    // Resort labels
    this.resortLabels$ = this.resortLabelService.getLabels();

    // Order select
    this.register(
      this.orderForm.valueChanges.subscribe((selectItem) => {
        if (selectItem) {
          this.store.dispatch(new FiltersStationChangeOrderBy(selectItem));
        }
      })
    );
  }

  loadMore() {
    this.page++;
    const start = this.page * 10;
    const end = start + 10;
    this.listResorts.push(...this.resorts.slice(start, end));
  }

  getLinkResort(resort: string) {
    return `/station/${UrlUtils.encodeToURL(resort)}/hebergements`;
  }

  identity(index: number, resort: ResortMap) {
    return resort.id;
  }

  protected boundsChangedAction(
    bounds: google.maps.LatLngBoundsLiteral,
    refresh: boolean
  ): void {
    this.store.dispatch(new GeoBoundsActiveChange(refresh));

    if (bounds) {
      this.store.dispatch(new Bounds(bounds));
    }
  }
}
