import { AsyncPipe, NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Moment } from 'moment';
import { Observable, combineLatest, filter } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { NewSearch } from '../../filters.action';
import { FiltersState } from '../../filters.state';
import { NavigateService } from '../../service/navigate.service';
import { ResortService } from '../../service/resort.service';
import { UrlUtils } from '../../utils/url-utils';
import { RxjsComponent } from '../component/rxjs.component';
import { Activity } from '../models/activity/activity';
import { Participant } from '../models/participant/participant';
import { Period } from '../models/period';
import { ResortMin } from '../models/resort/resort-min';
import { SearchBarActivitiesComponent } from './search-bar-activities/search-bar-activities.component';
import { SearchBarDatesComponent } from './search-bar-dates/search-bar-dates.component';
import { SearchBarDestinationComponent } from './search-bar-destination/search-bar-destination.component';
import { SearchBarMobileComponent } from './search-bar-mobile/search-bar-mobile.component';
import { SearchBarParticipantsComponent } from './search-bar-participants/search-bar-participants.component';
import { SearchBarTypeComponent } from './search-bar-type/search-bar-type.component';
import {
  AddParticipant,
  ChangeParticipant,
  RemoveParticipant
} from './search-bar.action';
import { SearchBarState, SearchPackage, SearchType } from './search-bar.state';

export type State =
  | 'type'
  | 'destination'
  | 'dates'
  | 'participants'
  | 'activities'
  | 'none';

@Component({
  selector: 'vsk-search-bar',
  standalone: true,
  imports: [
    NgOptimizedImage,
    SearchBarTypeComponent,
    NgClass,
    SearchBarDatesComponent,
    SearchBarDestinationComponent,
    SearchBarParticipantsComponent,
    SearchBarActivitiesComponent,
    AsyncPipe,
    SearchBarMobileComponent,
    NgIf
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent extends RxjsComponent {
  @Select(SearchBarState.searchPackage)
  searchPackage$: Observable<SearchPackage>;
  @Select(SearchBarState.searchType) searchType$: Observable<SearchType>;
  @Select(SearchBarState.participants) participants$: Observable<Participant[]>;
  @Select(SearchBarState.activities) activities$: Observable<Activity[]>;
  @Select(SearchBarState.period) period$: Observable<Period>;
  @Select(SearchBarState.resorts) resorts$: Observable<number[]>;
  @Select(SearchBarState.regions) regions$: Observable<number[]>;
  @Select(SearchBarState.resort) resort$: Observable<string>;
  @Select(SearchBarState.startDate) startDate$: Observable<Moment>;
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;

  @Input() theme: 'thick' | 'thin' = 'thin';
  @Input() styleMobileButton: 'home' | 'navbar' | 'search-accommodation' =
    'home';

  titleSearchType = 'Je recherche';
  titleDestination = "N'importe où";
  titleParticipants = 'Ajouter des voyageurs';
  titleDates = 'Ajouter une date';
  titleActivities = 'Ajouter une activité';

  resortsSelected: ResortMin[] = [];
  resorts: ResortMin[] = [];
  state: State = 'none';
  openDrawerMobile = false;

  constructor(
    private changeRef: ChangeDetectorRef,
    private stationService: ResortService,
    private store: Store,
    private navigateService: NavigateService
  ) {
    super();

    this.register(
      this.startDate$
        .pipe(
          filter((startDate) => startDate?.isValid()),
          distinctUntilChanged(),
          switchMap((startDate) =>
            combineLatest([
              this.stationService.getAllResorts(startDate),
              this.resorts$
            ])
          ),
          tap(
            ([resorts, selectedIds]) =>
              (this.resortsSelected = resorts.filter((resort) =>
                selectedIds.includes(resort.id)
              ))
          )
        )
        .subscribe(([resorts]) => (this.resorts = resorts))
    );
  }

  changeState(state: State, ignore: boolean) {
    if (ignore) {
      return;
    }

    if (this.state === state) {
      this.state = 'none';
      return;
    }

    this.state = state;
    this.changeRef.markForCheck();
  }

  changeTitle(state: State, newTitle: string) {
    switch (state) {
      case 'activities':
        this.titleActivities = newTitle;
        break;
      case 'dates':
        this.titleDates = newTitle;
        break;
      case 'destination':
        this.titleDestination = newTitle;
        break;
      case 'participants':
        this.titleParticipants = newTitle;
        break;
      case 'type':
        this.titleSearchType = newTitle;
        break;
    }

    this.changeRef.detectChanges();
  }

  search() {
    const search = this.store.selectSnapshot(SearchBarState.searchBar);

    if (search.searchType === 'RESORT') {
      search.resorts = [];
      search.regions = [];
    }

    if (search.searchType === 'TRIP') {
      search.resort = null;
    }

    this.store.dispatch(new NewSearch(search)).subscribe(() => {
      this.openDrawerMobile = false;

      if (search.searchType === 'ACCOMMODATION') {
        this.navigateService.navigateWithQueryParams('/recherche-hebergements');
        return;
      }

      if (search.searchType === 'RESORT') {
        this.navigateService.navigateWithQueryParams(
          `/station/${UrlUtils.encodeToURL(search.resort as string)}/hebergements`,
          {
            resort: search.resort
          }
        );
        return;
      }

      this.navigateService.navigateWithQueryParams('/recherche-sejours');
    });
  }

  openDrawer() {
    this.state = 'type';
    this.openDrawerMobile = true;
  }

  addParticipant(participant: Participant) {
    this.store.dispatch(new AddParticipant(participant));
  }

  changeParticipant(participant: Participant) {
    this.store.dispatch(new ChangeParticipant(participant));
  }

  removeParticipant(uuid: string) {
    this.store.dispatch(new RemoveParticipant(uuid));
  }
}
