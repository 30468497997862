import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  public navigateWithQueryParams(
    url: string,
    params?: any,
    withoutParams = false
  ): Promise<boolean> {
    if (withoutParams) {
      return this.router.navigate([url]);
    } else {
      return this.router.navigate([url], {
        relativeTo: this.activatedRoute,
        queryParams: {
          ...params
        },
        queryParamsHandling: 'merge' // remove to replace all query params by provided
      });
    }
  }

  public navigateAndReplaceQueryParams(
    url: string,
    params?: any
  ): Promise<boolean> {
    return this.router.navigate([url], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...params
      }
    });
  }
}
