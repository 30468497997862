<footer>
  <img alt="Monogramme VeryMountain"
       class="background-monogram"
       height="845"
       ngSrc="assets/new-design/svg/monogram-dark.svg"
       width="1376">

  <section class="content">
    <div class="left-block">
      <div class="newsletter">
        <img alt="Logo bleu VeryMountain" class="small-logo" height="28"
             ngSrc="assets/new-design/img/footer/logo-blue.png"
             width="195">

        <p class="description">
          VeryMountain c’est plus de 5000 expériences dans 110 stations françaises.<br>
          Une équipe de passionnés située à Lyon et toujours à votre service<br>
          Une immatriculation Atout France : IMO69230013<br>
          Une garantie financière auprès de Groupama Assurance Crédit et Caution : 4000718190/0<br>
          Une responsabilité civile professionnelle auprès de Groupama : 432374900001
        </p>

        <h3>Soyez les premiers informés</h3>

        <div class="small-block">
          <p *ngIf="error"
             class="error">
            {{ error }}
          </p>

          <div class="wrapper-input">
            <input (keyup.enter)="register()"
                   [formControl]="emailForm"
                   placeholder="Votre adresse email"
                   type="text" />
            <button (click)="register()">S'inscrire</button>
          </div>

          <p>En appuyant sur le bouton “S’inscrire” , vous acceptez notre <a>politique de confidentialité.</a></p>
        </div>
      </div>

      <!--      <img alt="Accréditation Atout France"-->
      <!--           class="ribbon"-->
      <!--           height="88"-->
      <!--           ngSrc="assets/new-design/img/footer/ribbon-atout-france.png"-->
      <!--           width="88">-->
    </div>
    <div class="right-block">
      <div class="big-links">
        <!--        <a routerLink="/recherche-stations" routerLinkActive="true">-->
        <!--          Nos stations-->
        <!--        </a>-->
        <!--        <a routerLink="/recherche-activites" routerLinkActive="true">-->
        <!--          Nos activités-->
        <!--        </a>-->
        <a href="https://blog.verymountain.fr/faq" target="_blank">
          FAQ
        </a>
        <a href="https://blog.verymountain.fr/organiser-seminaire-montagne" target="_blank">
          Demande de groupe
        </a>
        <a href="https://share-eu1.hsforms.com/1sy3mQO1DR3i928TafV9fcQg4b6s" target="_blank">
          Devenir partenaire
        </a>
        <a href="https://blog.verymountain.fr/nos-partenaires" target="_blank">
          Nos partenaires
        </a>
        <a href="https://blog.verymountain.fr/a-propos" target="_blank">
          À propos
        </a>
        <a href="mailto:hello@verymountain.fr">
          Contact
        </a>
        <a href="https://blog.verymountain.fr" target="_blank">
          blog
        </a>
      </div>

      <div class="small-links">
        <a routerLink="/terms-and-conditions" routerLinkActive="true">
          Mentions légales
        </a>
        <a queryParamsHandling="merge" routerLink="/selling-conditions" routerLinkActive="true">
          CGV
        </a>
        <a queryParamsHandling="merge" routerLink="/terms-of-use" routerLinkActive="true">
          CGU
        </a>
        <a href="javascript:openAxeptioCookies()">
          Cookies
        </a>
      </div>

    </div>
  </section>

  <section class="partners">
    <div class="partners-links">
      <a href="https://www.auvieuxcampeur.fr/carteclub/avantages/skipass" target="_blank">
        <img alt="Au vieux campeur" src="assets/new-design/img/footer/partner/vieux-campeur.png">
      </a>
      <img alt="MMV" height="50" ngSrc="assets/new-design/img/footer/partner/mmv.png" width="168">
      <img alt="Mitch" height="50" ngSrc="assets/new-design/img/footer/partner/mitch.png" width="159">
      <img alt="Skiset" height="61" ngSrc="assets/new-design/img/footer/partner/skiset.png" width="61">
      <a href="https://skiif.com/" target="_blank">
        <img alt="Skiif" height="50" ngSrc="assets/new-design/img/footer/partner/skiif.png" width="139">
      </a>
      <a href="https://www.atout-france.fr/" target="_blank">
        <img alt="Atout france" height="50" ngSrc="assets/new-design/img/footer/partner/atout-france.png" width="43">
      </a>
    </div>

    <p>Et bien d’autres partenaires pour un séjour en montagne réussi.</p>
  </section>

  <section class="content gap-mobile">
    <div class="small-block hide-small-desktop-and-below">
      <p>VeryMountain © 2025</p>
    </div>

    <div class="social">
      <a href="https://www.facebook.com/verymountain" target="_blank">
        <img alt="Lien Facebook" height="20" ngSrc="assets/new-design/svg/social/facebook.svg" width="20">
      </a>
      <a href="https://www.linkedin.com/company/verymountain/" target="_blank">
        <img alt="Lien linkedIn" height="20" ngSrc="assets/new-design/svg/social/linkedin.svg" width="20">
      </a>
      <a href="https://www.youtube.com/@verymountain" target="_blank">
        <img alt="Lien YouTube" height="20" ngSrc="assets/new-design/svg/social/youtube.svg" width="20">
      </a>
      <a href="https://www.instagram.com/verymountain_officiel/" target="_blank">
        <img alt="Lien Instagram" height="20" ngSrc="assets/new-design/svg/social/instagram.svg" width="20">
      </a>
    </div>

    <div class="small-block hide-small-desktop-and-above">
      <p>VeryMountain © 2025</p>
    </div>

    <div class="small-block">
      <div class="cb-wrapper">
        <img alt="Méthode de paiement par Carte Bleue" height="32"
             ngSrc="assets/new-design/svg/payment-methods/cb.svg"
             width="48">
      </div>
      <div class="mastercard-wrapper">
        <img alt="Méthode de paiement par Mastercard" height="17"
             ngSrc="assets/new-design/svg/payment-methods/mastercard.svg"
             width="30">
      </div>
      <img alt="Méthode de paiement par Visa" height="32"
           ngSrc="assets/new-design/svg/payment-methods/visa.svg"
           width="46">
    </div>
  </section>
</footer>
