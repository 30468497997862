import { DecimalPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { ButtonComponent } from 'atomic-lib';
import { SkiClassService } from '../../../service/ski-class.service';
import { SkiCriteria } from '../../../shared/dropdown/ski-pass-dropdown/ski-pass-dropdown.component';
import { Cart } from '../../../shared/models/cart/cart';
import { Participant } from '../../../shared/models/participant/participant';
import { Period } from '../../../shared/models/period';
import { PricingUtils } from '../../../utils/pricing-utils';
import { DeleteEquipment, DeleteItemForParticipant } from '../cart.action';
import { CardParticipantsComponent } from './card-participants/card-participants.component';
import { EquipmentPopupComponent } from './equipment-popup/equipment-popup.component';
import { SkiClassPopupComponent } from './ski-class-popup/ski-class-popup.component';
import { SkiPassPopupComponent } from './ski-pass-popup/ski-pass-popup.component';

@Component({
  selector: 'vsk-step-cart-package',
  standalone: true,
  imports: [
    NgForOf,
    DecimalPipe,
    NgIf,
    NgClass,
    SkiPassPopupComponent,
    EquipmentPopupComponent,
    SkiClassPopupComponent,
    CardParticipantsComponent,
    ButtonComponent
  ],
  templateUrl: './step-cart-package.component.html',
  styleUrl: './step-cart-package.component.scss'
})
export class StepCartPackageComponent {
  expanded: string;
  addSkiPass = false;
  addEquipment = false;
  addSkiClass = false;
  hasSkiClasses = false;
  criteria: SkiCriteria;

  @Input() period: Period;
  @Input() sessionId: string;
  @Input() participants: Participant[] = [];

  @Output() nextChange: EventEmitter<void> = new EventEmitter<void>();

  protected readonly PricingUtils = PricingUtils;

  constructor(
    private store: Store,
    private skiClassService: SkiClassService
  ) {}

  _cart: Cart;

  @Input() set cart(cart: Cart) {
    if (!cart || cart.isEmpty) {
      return;
    }

    this._cart = cart;

    const firstDay = this._cart.itemsAccommodation[0].startDate.clone();
    let duration = this._cart.itemsAccommodation[0].endDate.diff(
      this._cart.itemsAccommodation[0].startDate,
      'days'
    );

    if (duration > 3 && duration <= 5) {
      firstDay.add(1, 'days');
      duration -= 1;
    }

    if (duration > 5) {
      firstDay.add(1, 'days');
      duration -= 2;
    }

    this.criteria = {
      resortName: cart.getResort(),
      preselectSkiPass: true,
      skiPassDuration: duration,
      firstDay
    };

    this.checkSkiClass();
  }

  expand(uuid: string) {
    if (uuid === this.expanded) {
      this.expanded = '';
      return;
    }

    this.expanded = uuid;
  }

  deleteActivityForParticipant(uuid: string, id: string) {
    this.store.dispatch(new DeleteItemForParticipant(uuid, id as string));
  }

  deleteEquipmentForParticipant(sessionId: string, id: number) {
    this.store.dispatch(new DeleteEquipment(sessionId, id));
  }

  private checkSkiClass() {
    this.skiClassService
      .getSkiClassForResort(this.criteria.resortName)
      .subscribe((skiClasses) => (this.hasSkiClasses = !!skiClasses?.length));
  }
}
