<atc-dropdown (closeChanged)="closeEventChanged.emit()"
              [classes]="['no-padding']"
              [elementPosition]="_parent"
              [isOpen]="true"
              [left]="theme === 'thick' ? '90px' : '0px'"
              [position]="'bottom-left'"
              [top]="theme === 'thick' ? '60px' : '40px'"
              [width]="'800px'"
              class="hide-small-desktop-and-below">
  <div class="content">
    <div *ngIf="!_isResortSearch"
         class="region-block">
      <p>Recherche par massif</p>
      <ng-container *ngTemplateOutlet="massifs"></ng-container>
    </div>

    <div class="resort-block">
      <div class="resort-header">
        <p>Ou par stations</p>
        <atc-input [control]="search"
                   icon="search"
                   placeholder="Rechercher...">
        </atc-input>
      </div>

      <div class="resorts">
        <button (click)="chooseDestination(resort)"
                *ngFor="let resort of _resortsToShow; trackBy: identity"
                [ngClass]="{'selected': isResortSelected(resort.id)}"
                class="resort">
          <img [alt]="'Logo station ' + resort.name" [src]="resort.urlLogo">
          <p>{{ resort.name }}</p>
        </button>
        <p *ngIf="!_resortsToShow.length">Oops nous ne trouvons pas cette station</p>
      </div>
    </div>
  </div>
</atc-dropdown>

<div class="content hide-small-desktop-and-above">
  <div class="resort-block">
    <div class="resort-header">
      <p>{{ display === 'regions' ? 'Recherche par massif' : 'Nos stations' }}</p>
      <atc-input [control]="search"
                 [ngClass]="{'invisible': display !== 'resorts'}"
                 icon="search"
                 placeholder="Rechercher...">
      </atc-input>
    </div>

    <div *ngIf="!_isResortSearch"
         [ngClass]="{'active': display === 'regions'}"
         class="switch">
      <button (click)="display = 'resorts'"
              [ngClass]="{'selected': display === 'resorts'}">
        Stations
      </button>
      <button (click)="display = 'regions'"
              [ngClass]="{'selected': display === 'regions'}">
        Massifs
      </button>
    </div>

    <div *ngIf="display === 'resorts'" class="resorts">
      <button (click)="chooseDestination(resort)"
              *ngFor="let resort of _resortsToShow; trackBy: identity"
              [ngClass]="{'selected': isResortSelected(resort.id)}"
              class="resort">
        <img [alt]="'Logo station ' + resort.name" [src]="resort.urlLogo">
        <p>{{ resort.name }}</p>
      </button>
      <p *ngIf="!_resortsToShow.length">Oops nous ne trouvons pas cette station</p>
    </div>

    <div *ngIf="display === 'regions'" class="region-block">
      <ng-container *ngTemplateOutlet="massifs"></ng-container>
    </div>
  </div>
</div>

<ng-template #massifs>
  <vsk-search-region-form (regionsSelectedChanged)="dispatchRegions($event)"
                          [regionsSelected]="_regionsSelected">
  </vsk-search-region-form>
</ng-template>

