import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AtomicLibModule, ScreenPipe } from 'atomic-lib';
import { LoadingComponent } from './component/loading.component';
import { MobileComponent } from './component/mobile.component';
import { RxjsComponent } from './component/rxjs.component';
import { DraggableDirective } from './directive/draggable.directive';
import { ScrollVisibilityDirective } from './directive/scroll-visibility.directive';
import { SkeletonLoadDirective } from './directive/skeleton-load.directive';
import { ExperienceFiltersPopupComponent } from './experience-filters/experience-filters-popup/experience-filters-popup.component';
import { ExperienceFiltersComponent } from './experience-filters/experience-filters.component';
import { InformationBannerComponent } from './information-banner/information-banner.component';
import { InputParticipantComponent } from './input-participant/input-participant.component';
import { AgePipe } from './pipe/age.pipe';
import { SortByAgeDescPipe } from './pipe/sort-by-age.pipe';
import { SortByIdPipe } from './pipe/sort-by-id.pipe';
import { SortParticipantsPipe } from './pipe/sort-participants.pipe';
import { ToHourPipe } from './pipe/to-hour.pipe';
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { SearchRegionFormComponent } from './search-region-form/search-region-form.component';

@NgModule({
  declarations: [
    RxjsComponent,
    AgePipe,
    ToHourPipe,
    InputParticipantComponent,
    PromoCodeComponent,
    SkeletonLoadDirective,
    LoadingComponent,
    SortParticipantsPipe,
    SortByIdPipe,
    SortByAgeDescPipe,
    DraggableDirective,
    ScrollVisibilityDirective,
    MobileComponent,
    InformationBannerComponent,
    ExperienceFiltersComponent,
    ExperienceFiltersPopupComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    AtomicLibModule,
    NgOptimizedImage,
    ScreenPipe,
    GoogleSigninButtonModule,
    SearchRegionFormComponent
  ],
  exports: [
    RxjsComponent,
    AgePipe,
    ToHourPipe,
    InputParticipantComponent,
    SkeletonLoadDirective,
    LoadingComponent,
    SortParticipantsPipe,
    PromoCodeComponent,
    SortByIdPipe,
    DraggableDirective,
    ScrollVisibilityDirective,
    MobileComponent,
    SortByAgeDescPipe,
    InformationBannerComponent,
    ExperienceFiltersComponent
  ],
  providers: [DatePipe]
})
export class SharedModule {}
