import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Participant } from '../shared/models/participant/participant';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService extends RepositoryService {
  private URL_PARTICIPANT = 'api/webapp/participant';

  constructor(http: HttpClient) {
    super(http);
  }

  upsert(participant: Participant): Observable<void> {
    return this.post(this.URL_PARTICIPANT + '/upsert', participant);
  }

  upsertAll(participants: Participant[]): Observable<void> {
    return this.post(this.URL_PARTICIPANT + '/upsert-all', participants);
  }

  getParticipants(sessionId: string): Observable<Participant[]> {
    return this.get(
      this.URL_PARTICIPANT +
        '/fetch-participants-by-session-id?sessionId=' +
        sessionId
    );
  }

  deleteParticipant(uuid: string, sessionId: string): Observable<void> {
    return this.delete(
      this.URL_PARTICIPANT +
        '/delete-by-uuid?uuid=' +
        uuid +
        '&sessionId=' +
        sessionId
    );
  }
}
