import { FilterRange } from '../filters/filter-range';
import { AccommodationType } from './accommodation-type.enum';

export class FiltersAccommodation {
  packages = false;
  material = false;
  mapPolygon: google.maps.LatLngBoundsLiteral;
  geoBoundsActive = false;
  priceRange: FilterRange;
  resorts: number[] = [];
  tags: number[] = [];
  regions: number[] = [];
  labels: number[] = [];
  pool = false;
  spa = false;
  sauna = false;
  hammam = false;
  balconyTerrace = false;
  tv = false;
  chimney = false;
  bbq = false;
  pmr = false;
  parking = false;
  animalsAdmitted = false;
  wifi = false;
  childrenClub = false;
  types: AccommodationType[] = [];
  promo = false;

  constructor(obj?: Partial<FiltersAccommodation>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
