import { NgForOf, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  BreadcrumbComponent,
  BreadcrumbLink,
  WindowResource
} from 'atomic-lib';
import { MetaDescriptionService } from '../../service/meta-description.service';
import { ResortService } from '../../service/resort.service';
import { CardResortComponent } from '../../shared/card-resort/card-resort.component';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { FooterComponent } from '../../shared/footer/footer.component';
import { ResortMin } from '../../shared/models/resort/resort-min';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { SharedModule } from '../../shared/shared.module';
import { UrlUtils } from '../../utils/url-utils';

@Component({
  selector: 'vsk-search-resorts',
  templateUrl: './search-resorts.component.html',
  styleUrls: ['./search-resorts.component.scss'],
  imports: [
    NavbarComponent,
    BreadcrumbComponent,
    CardResortComponent,
    NgForOf,
    ReactiveFormsModule,
    FooterComponent,
    SharedModule,
    NgIf
  ],
  standalone: true
})
export class SearchResortsComponent extends RxjsComponent {
  allResorts: ResortMin[] = [];
  filteredResorts: ResortMin[] = [];
  searchControl: FormControl<string | null> = new FormControl<string | null>(
    ''
  );

  breadcrumbItems: BreadcrumbLink[] = [
    {
      label: 'Accueil',
      url: '/'
    },
    {
      label: 'Nos stations',
      url: '/recherche-stations'
    }
  ];

  constructor(
    private stationService: ResortService,
    public windowResource: WindowResource,
    public activatedRoute: ActivatedRoute,
    private metaDescriptionService: MetaDescriptionService
  ) {
    super();

    const title = `Découvrez toutes nos stations de montagne · VeryMountain`;
    const description = `Trouvez la destination de vos rêves pour passer vos vacances en montagne avec VeryMountain`;
    const url = 'recherche-stations';

    this.metaDescriptionService.updateMeta(title, description, url);

    // this.register(
    //   this.stationService
    //     .getAllResorts()
    //     .pipe(
    //       map((resorts) =>
    //         resorts.sort((prev, curr) => (prev.name < curr.name ? -1 : 1))
    //       )
    //     )
    //     .subscribe((resorts) => {
    //       this.allResorts = resorts;
    //       this.filteredResorts = resorts;
    //     })
    // );

    this.register(
      this.searchControl.valueChanges.subscribe((search) => {
        this.filteredResorts = this.allResorts.filter(
          (resort) =>
            UrlUtils.encodeToURL(resort.name).indexOf(
              UrlUtils.encodeToURL(search || '')
            ) !== -1
        );
      })
    );
  }

  trackResortById(index: number, resort: ResortMin) {
    return resort.id;
  }
}
