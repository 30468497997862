<!-- Desktop -->
<div *ngIf="!openDrawerMobile"
     [ngClass]="{'is-active': state !== 'none', 'thin': theme === 'thin'}"
     class="search-wrapper hide-small-desktop-and-below">
  <!-- Search type -->
  <button #type
          (click)="changeState('type', false)"
          [ngClass]="{'is-open': state === 'type'}"
          class="block-search">
    <h3 *ngIf="theme === 'thick'">Vous recherchez</h3>
    <h4>{{ titleSearchType }}</h4>
  </button>
  <vsk-search-bar-type (closeEventChanged)="changeState('none', state !== 'type')"
                       (nextChanged)="changeState('destination', false)"
                       (titleButtonChanged)="changeTitle('type', $event)"
                       [ngClass]="{'hidden' : state !== 'type'}"
                       [parent]="type"
                       [searchPackage]="searchPackage$ | async"
                       [theme]="theme">
  </vsk-search-bar-type>

  <!-- Destination -->
  <button #destination
          (click)="changeState('destination', false)"
          [ngClass]="{'is-open': state === 'destination'}"
          class="block-search">
    <h3 *ngIf="theme === 'thick'">Destination</h3>
    <h4>{{ titleDestination }}</h4>
  </button>
  <vsk-search-bar-destination (closeEventChanged)="changeState('none', state !== 'destination')"
                              (nextChanged)="changeState('dates', false)"
                              (titleButtonChanged)="changeTitle('destination', $event)"
                              [isResortSearch]="(searchType$ | async) === 'RESORT'"
                              [ngClass]="{'hidden' : state !== 'destination'}"
                              [parent]="destination"
                              [regionsSelected]="(regions$ | async) || []"
                              [resort]="resort$ | async"
                              [resortsSelected]="resortsSelected || []"
                              [resorts]="resorts || []"
                              [theme]="theme">
  </vsk-search-bar-destination>

  <!-- Dates -->
  <button #dates
          (click)="changeState('dates', false)"
          [ngClass]="{'is-open': state === 'dates'}"
          class="block-search">
    <h3 *ngIf="theme === 'thick'">Dates</h3>
    <h4>{{ titleDates }}</h4>
  </button>
  <vsk-search-bar-dates (closeEventChanged)="changeState('none', state !== 'dates')"
                        (nextChanged)="changeState('participants', false)"
                        (titleButtonChanged)="changeTitle('dates', $event)"
                        [ngClass]="{'hidden' : state !== 'dates'}"
                        [parent]="dates"
                        [period]="period$ | async"
                        [theme]="theme">
  </vsk-search-bar-dates>

  <!-- Participants -->
  <button #participants
          (click)="changeState('participants', false)"
          [ngClass]="{'is-open': state === 'participants'}"
          class="block-search">
    <h3 *ngIf="theme === 'thick'">Voyageurs</h3>
    <h4>{{ titleParticipants }}</h4>
  </button>
  <vsk-search-bar-participants (addParticipantChange)="addParticipant($event)"
                               (changeParticipantChange)="changeParticipant($event)"
                               (closeEventChanged)="changeState('none', state !== 'participants')"
                               (removeParticipantChange)="removeParticipant($event)"
                               (titleButtonChanged)="changeTitle('participants', $event)"
                               [left]="theme === 'thick' ? '50px' : '0px'"
                               [ngClass]="{'hidden' : state !== 'participants'}"
                               [parent]="participants"
                               [participants]="(participants$ | async) || []"
                               [theme]="theme"
                               [top]="theme === 'thick' ? '60px' : '40px'">
  </vsk-search-bar-participants>

  <!-- Activities -->
  <button #activities
          (click)="changeState('activities', false)"
          [ngClass]="{'is-open': state === 'activities'}"
          class="block-search">
    <h3 *ngIf="theme === 'thick'">Activités</h3>
    <h4>{{ titleActivities }}</h4>
  </button>
  <vsk-search-bar-activities (closeEventChanged)="changeState('none', state !== 'activities')"
                             (titleButtonChanged)="changeTitle('activities', $event)"
                             [activities]="(activities$ | async) || []"
                             [ngClass]="{'hidden' : state !== 'activities'}"
                             [parent]="activities"
                             [theme]="theme">
  </vsk-search-bar-activities>

  <div class="wrapper-search-button">
    <button (click)="search()">
      <img alt="Rechercher" height="24" ngSrc="assets/new-design/svg/icons/search.svg" width="24">
    </button>
  </div>
</div>

<!-- Mobile HOME -->
<button (click)="openDrawer()"
        *ngIf="styleMobileButton === 'home'"
        class="search-wrapper hide-small-desktop-and-above">
  <div class="block-search">
    <h3>Rechercher</h3>
    <h4>Un séjour idéal...</h4>
  </div>

  <div class="wrapper-search-button">
    <button>
      <img alt="Rechercher" height="24" ngSrc="assets/new-design/svg/icons/search.svg" width="24">
    </button>
  </div>
</button>

<!-- Mobile NAVBAR -->
<button (click)="openDrawer()"
        *ngIf="styleMobileButton === 'navbar'"
        class="hide-small-desktop-and-above search-navbar">
  <svg>
    <use [attr.xlink:href]="'assets/icons/icons.svg#search'"></use>
  </svg>
</button>

<!-- Mobile NAVBAR -->
<button (click)="openDrawer()"
        *ngIf="styleMobileButton === 'search-accommodation'"
        class="hide-small-desktop-and-above search-accommodation">
  <p>Modifier ma recherche</p>

  <svg>
    <use [attr.xlink:href]="'assets/icons/icons.svg#search'"></use>
  </svg>
</button>

<vsk-search-bar-mobile (closeChange)="openDrawerMobile = false"
                       (searchChange)="search()"
                       (stateChange)="state = $event"
                       *ngIf="openDrawerMobile"
                       [open]="openDrawerMobile"
                       [sessionId]="(sessionId$ | async) || ''"
                       [state]="state"
                       [titleActivities]="titleActivities"
                       [titleDates]="titleDates"
                       [titleDestination]="titleDestination"
                       [titleParticipants]="titleParticipants"
                       [titleSearchType]="titleSearchType">

  <vsk-search-bar-type (nextChanged)="changeState('destination', false)"
                       (titleButtonChanged)="changeTitle('type', $event)"
                       [isOpen]="false"
                       [searchPackage]="searchPackage$ | async"
                       typesearch>
  </vsk-search-bar-type>

  <vsk-search-bar-destination (nextChanged)="changeState('dates', false)"
                              (titleButtonChanged)="changeTitle('destination', $event)"
                              [isOpen]="false"
                              [isResortSearch]="(searchType$ | async) === 'RESORT'"
                              [regionsSelected]="(regions$ | async) || []"
                              [resort]="resort$ | async"
                              [resortsSelected]="resortsSelected || []"
                              [resorts]="resorts || []"
                              where>
  </vsk-search-bar-destination>

  <vsk-search-bar-dates (nextChanged)="changeState('participants', false)"
                        (titleButtonChanged)="changeTitle('dates', $event)"
                        [period]="period$ | async"
                        dates>
  </vsk-search-bar-dates>

  <vsk-search-bar-participants (addParticipantChange)="addParticipant($event)"
                               (changeParticipantChange)="changeParticipant($event)"
                               (titleButtonChanged)="changeTitle('participants', $event)"
                               [participants]="(participants$ | async) || []"
                               travelers>
  </vsk-search-bar-participants>

  <vsk-search-bar-activities (titleButtonChanged)="changeTitle('activities', $event)"
                             [activities]="(activities$ | async) || []"
                             experiences>
  </vsk-search-bar-activities>
</vsk-search-bar-mobile>

