import { DecimalPipe, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, PopupComponent } from 'atomic-lib';
import { Cart } from '../../../shared/models/cart/cart';
import { Participant } from '../../../shared/models/participant/participant';
import { CartCardSummaryComponent } from '../cart-card-summary/cart-card-summary.component';

@Component({
  selector: 'vsk-cart-card-summary-mobile',
  standalone: true,
  imports: [
    DecimalPipe,
    ButtonComponent,
    PopupComponent,
    NgIf,
    CartCardSummaryComponent,
    NgClass
  ],
  templateUrl: './cart-card-summary-mobile.component.html',
  styleUrl: './cart-card-summary-mobile.component.scss'
})
export class CartCardSummaryMobileComponent {
  @Input() cart: Cart | null;
  @Input() showNext = true;
  @Input() showPaymentInfo = true;
  @Input() participants: Participant[];
  @Output() nextChange: EventEmitter<void> = new EventEmitter<void>();

  openSummary = false;
}
