import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromoCode } from '../shared/models/promo-code';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class PromoService extends RepositoryService {
  private URL_STATION = 'api/webapp/promo';

  constructor(http: HttpClient) {
    super(http);
  }

  setPromoCode(name: string): Observable<PromoCode | null> {
    return this.get(
      this.URL_STATION + '/set-promo-code-by-name?name=' + name
    ).pipe(
      map((promoCode: any) => (promoCode ? new PromoCode(promoCode) : null))
    );
  }

  deletePromoCode(): Observable<void> {
    return this.delete(this.URL_STATION + '/delete-promo-code-session');
  }
}
