<vsk-navbar class="hide-small-desktop-and-above"></vsk-navbar>

<section class="left-side">
  <img alt="Logo VeryMountain"
       class="hide-small-desktop-and-below" height="25"
       ngSrc="assets/new-design/svg/logo-dark.svg" width="173">

  <ng-container *ngIf="cart$ | async as cart">
    <atc-stepper>
      <!-- Step 1 - PACKAGE -->
      <atc-step [done]="isDone(Step.PACKAGE)"
                [ngClass]="{'content': step === Step.PACKAGE}"
                [order]="0"
                [show]="step === Step.PACKAGE"
                label="Panier">
        <vsk-step-cart-package (nextChange)="next()"
                               [cart]="cart"
                               [participants]="(participants$ | async) || []"
                               [period]="period$ | async"
                               [sessionId]="sessionId$ | async">
        </vsk-step-cart-package>
      </atc-step>

      <!-- Step 2 - ACTIVITIES -->
      <atc-step [done]="isDone(Step.ACTIVITIES)"
                [ngClass]="{'content': step === Step.ACTIVITIES}"
                [order]="1"
                [show]="step === Step.ACTIVITIES"
                label="Activités">
        <vsk-step-cart-activity (nextChange)="next()"
                                (previousChange)="previous()"
                                *ngIf="step === Step.ACTIVITIES"
                                [activities]="cart.general"
                                [participants]="(participants$ | async) || []"
                                [resort]="cart.getResort()"
                                [sessionId]="sessionId$ | async">
        </vsk-step-cart-activity>
      </atc-step>

      <!-- Step 3 - PAYMENT -->
      <atc-step [done]="isDone(Step.PAYMENT)"
                [ngClass]="{'content': step === Step.PAYMENT}"
                [order]="2"
                [show]="step === Step.PAYMENT"
                label="Paiement">
        <vsk-cart-step-payment (previousChange)="previous()"
                               *ngIf="step === Step.PAYMENT"
                               [cart]="cart"
                               [startDate]="startDate$ | async">
        </vsk-cart-step-payment>
      </atc-step>

      <!-- Step 4 - CONFIRMATION -->
      <atc-step [done]="isDone(Step.CONFIRMATION)"
                [last]="true"
                [ngClass]="{'content': step === Step.CONFIRMATION}"
                [order]="3"
                [show]="step === Step.CONFIRMATION"
                label="Confirmation">
      </atc-step>
    </atc-stepper>
  </ng-container>
</section>

<section class="right-side hide-small-desktop-and-below">
  <a class="phone"
     href="tel:+33465847469">
    <img alt="Téléphone" height="13" ngSrc="assets/new-design/svg/icons/phone.svg" width="9">
    <span>04 65 84 74 69</span>
  </a>

  <vsk-cart-card-summary (nextChange)="next()"
                         [cart]="cart$ | async"
                         [participants]="(participants$ | async) || []"
                         [showNext]="step !== Step.PAYMENT">
  </vsk-cart-card-summary>
</section>

<vsk-cart-card-summary-mobile (nextChange)="next()"
                              [cart]="cart$ | async"
                              [participants]="(participants$ | async) || []"
                              [showNext]="step !== Step.PAYMENT"
                              class="hide-small-desktop-and-above">
</vsk-cart-card-summary-mobile>

