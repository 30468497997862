<ng-container *ngIf="step === 'description'">
  <ng-container *ngTemplateOutlet="description"></ng-container>
</ng-container>

<ng-container *ngIf="step === 'book'">
  <ng-container *ngTemplateOutlet="book"></ng-container>
</ng-container>

<ng-template #description>
  <atc-popup (closeChanged)="close()"
             height="100%"
             position="right"
             width="100%">
    <p class="text-center" header>En savoir plus sur l’activité</p>
    <div body class="content">
      <ng-container *ngIf="_experience">
        <div class="pictures">
          <img *ngIf="getPictureByIndex(0) as slide"
               [alt]="getDescription(slide)"
               [srcset]="slide"
               atcLazyLoad
               class="big-img" />

          <div class="small-pictures">
            <div class="col-images">
              <img *ngIf="getPictureByIndex(1) as slide"
                   [alt]="getDescription(slide)"
                   [srcset]="slide"
                   atcLazyLoad
                   class="small-img" />

              <img *ngIf="getPictureByIndex(2) as slide"
                   [alt]="getDescription(slide)"
                   [srcset]="slide"
                   atcLazyLoad
                   class="small-img" />
            </div>

            <div class="col-images">
              <img *ngIf="getPictureByIndex(3) as slide"
                   [alt]="getDescription(slide)"
                   [srcset]="slide"
                   atcLazyLoad
                   class="small-img" />

              <img *ngIf="getPictureByIndex(4) as slide"
                   [alt]="getDescription(slide)"
                   [srcset]="slide"
                   atcLazyLoad
                   class="small-img" />
            </div>
          </div>
        </div>

        <atc-button (click)="showPictures = true"
                    color="grey"
                    icon="photo">
          Voir {{ _experience.pictures?.length }} photos
        </atc-button>

        <div class="header-experience">
          <div class="title-group">
            <h5>{{ _experience.partnerName }}</h5>
            <h3>{{ _experience.activity.name }} · {{ _experience.name }}</h3>
          </div>

          <div class="tags">
            <atc-tag color="ternary"
                     icon="clock">
              {{ _experience.duration | toHour }}
            </atc-tag>
            <atc-tag color="ternary"
                     icon="chart-bar">
              {{ 'detail-station.activities.difficulty.' + _experience.difficulty  | translate }}
            </atc-tag>
            <atc-tag color="ternary"
                     icon="group">
              {{ PackageUtils.ageRange(_experience.packages) }}
            </atc-tag>
          </div>
        </div>

        <div *ngIf="_experience.description"
             class="title-group">
          <h6>On vous raconte</h6>
          <p [innerHTML]="_experience.description"></p>
        </div>

        <div *ngIf="_experience.weLove"
             class="title-group">
          <h6>On adore</h6>
          <p [innerHTML]="_experience.weLove"></p>
        </div>

        <div *ngIf="_experience.notIncluded"
             class="title-group">
          <h6>À prévoir</h6>
          <p [innerHTML]="_experience.notIncluded"></p>
        </div>

        <div *ngIf="_experience.included"
             class="title-group">
          <h6>Inclus dans la prestation</h6>
          <p [innerHTML]="_experience.included"></p>
        </div>
      </ng-container>

      <div *ngIf="loading"
           class="loader-wrapper">
        <atc-loader color="secondary"></atc-loader>
      </div>
    </div>

    <div class="footer" footer>
      <div class="price"></div>

      <atc-button (click)="step = 'book'"
                  [disabled]="loading"
                  icon="calendar"
                  size="large">
        Réserver mon créneau
      </atc-button>
    </div>
  </atc-popup>
</ng-template>

<ng-template #book>
  <atc-popup (closeChanged)="closeChangeEvent.emit()"
             (previousChanged)="step = 'description'"
             [showPrevious]="true"
             height="100%"
             position="right"
             width="100%">
    <p class="text-center" header>Réserver mon activité</p>
    <div body class="content">
      <div class="header-experience">
        <div class="title-group">
          <h5>{{ _experience.partnerName }}</h5>
          <h3>{{ _experience.activity.name }} · {{ _experience.name }}</h3>
        </div>

        <div class="tags">
          <atc-tag color="ternary"
                   icon="clock">
            {{ _experience.duration | toHour }}
          </atc-tag>
          <atc-tag color="ternary"
                   icon="chart-bar">
            {{ 'detail-station.activities.difficulty.' + _experience.difficulty  | translate }}
          </atc-tag>
        </div>
      </div>

      <div class="select-group">
        <vsk-first-ski-date-dropdown (selectedDateChange)="changeFirstDay($event)"
                                     *ngIf="days.length"
                                     [days]="days"
                                     [firstDay]="selectedFirstDay"
                                     title="Jour (ou premier jour)">
        </vsk-first-ski-date-dropdown>

        <vsk-timeslot-dropdown (selectedTimeSlotChange)="changeTimeSlot($event)"
                               *ngIf="timeSlotsForSelectedDay.length"
                               [selectedTimeSlot]="selectedTimeSlot"
                               [timeSlots]="timeSlotsForSelectedDay">
        </vsk-timeslot-dropdown>

        <vsk-participant-dropdown (selectedParticipantsChange)="changeSelectedParticipants($event)"
                                  [maxParticipant]="maxParticipants"
                                  [participants]="eligibleParticipants">
        </vsk-participant-dropdown>

        <ng-container *ngIf="remainingSlots() as slots">
          <p *ngIf="slots < eligibleParticipants.length">Place disponible dans le créneau : {{ slots }}</p>
        </ng-container>
      </div>

      <div *ngIf="loading"
           class="loader-wrapper">
        <atc-loader color="secondary"></atc-loader>
      </div>
    </div>

    <div class="footer" footer>
      <div class="price">
        <p class="total-label">
          Total
        </p>

        <p *ngIf="price && price.publicPrice !== price.price"
           class="public-price">
          {{ price.publicPrice | number: '1.2-2' : 'fr' }} €
        </p>

        <h4 *ngIf="price">
          {{ price.price | number: '1.2-2' : 'fr' }} €
        </h4>
      </div>

      <atc-button (click)="addToCart()"
                  [disabled]="loading || !price?.price"
                  icon="cart"
                  size="large">
        Ajouter au panier
      </atc-button>
    </div>
  </atc-popup>
</ng-template>

<atc-popup (closeChanged)="showPictures = false"
           *ngIf="showPictures"
           width="100%">
  <p [innerHTML]="'Photos · ' + _experience?.name" header></p>
  <atc-carousel-images [slides]="_experience.pictures"
                       body
                       class="hide-small-desktop-and-below">
  </atc-carousel-images>

  <div body class="carousel-mobile hide-small-desktop-and-above">
    <img *ngFor="let picture of _experience.pictures; let i = index" [alt]="_experience.name + ' - Photo ' + i"
         [src]="picture">
  </div>
</atc-popup>
