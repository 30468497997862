<ng-container *ngIf="cart">
  <img *ngIf="cart.itemsAccommodation.length"
       [src]="cart.itemsAccommodation[0].establishment.pictures[0]" alt="Photo logement">

  <div *ngIf="cart.itemsAccommodation.length"
       class="content">
    <div class="header">
      <h6 [innerHTML]="cart.itemsAccommodation[0].establishment.name"></h6>
      <h5 [innerHTML]="cart.itemsAccommodation[0].room.name"></h5>

      <div class="location">
        <p class="bold-title">{{ cart.getResort() }}</p>
        <p *ngIf="cart.itemsAccommodation[0].establishment.address">
          {{ cart.itemsAccommodation[0].establishment.address }},
          {{ cart.itemsAccommodation[0].establishment.city }} {{ cart.itemsAccommodation[0].establishment.zip }},
          {{ cart.itemsAccommodation[0].establishment.country }}
        </p>
      </div>

      <div class="line">
        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#calendar'"></use>
        </svg>

        <p>
          {{ cart.itemsAccommodation[0].startDate.format('DD/MM/YYYY') }} au
          {{ cart.itemsAccommodation[0].endDate.format('DD/MM/YYYY') }}
        </p>
      </div>

      <div class="line">
        <svg>
          <use [attr.xlink:href]="'assets/icons/icons.svg#group'"></use>
        </svg>

        <p>
          {{ titleParticipants }}
        </p>
      </div>

      <a [queryParams]="getRouterParamsFromItem(cart.itemsAccommodation[0])"
         [routerLink]="getRouterLinkFromItem(cart.itemsAccommodation[0])"
         routerLinkActive="true"
         target="_blank">
        Détails de l'hébergement
      </a>
    </div>

    <div class="body">
      <div class="expand-block">
        <div class="header-expand-block">
          <p class="big-title">Hébergement</p>

          <svg>
            <use [attr.xlink:href]="'assets/icons/icons.svg#chalet'"></use>
          </svg>
        </div>

        <div class="line-expand-block">
          <div class="small-title">
            <p [innerHTML]="cart.itemsAccommodation[0].establishment.name" class="experience-info"></p>
          </div>

          <p class="quantities">(x1)</p>

          <p class="price">{{ cart.itemsAccommodation[0].price | number: '1.2-2' : 'fr' }} €</p>
        </div>
      </div>

      <ng-container *ngIf="cart.general as activities">
        <div *ngIf="activities.length"
             class="expand-block">

          <div class="header-expand-block">
            <p class="big-title">Activités</p>

            <svg>
              <use [attr.xlink:href]="'assets/icons/icons.svg#activity'"></use>
            </svg>
          </div>

          <div *ngFor="let activity of activities"
               class="line-expand-block">
            <div class="small-title">
              <p class="experience-info">{{ activity.item?.experience.name }} - {{ activity.item?.ageRange.name }}</p>
              <p class="start-date">
                <ng-container *ngIf="!activity.internshipId">{{ activity.startDate.format('dddd DD MMMM') }}
                  de {{ activity.startDate.format('HH:mm') }}
                  à {{ activity.startDate.clone().add(activity.item?.experience.duration, 'minutes').format('HH:mm') }}
                </ng-container>
                <ng-container *ngIf="!!activity.internshipId">{{ activity.startDate.format('dddd DD MMMM') }}
                  - {{ activity.endDate.format('dddd DD MMMM') }}
                </ng-container>
              </p>
            </div>

            <p class="quantities">
              (x{{ activity.participants.length }})
            </p>

            <p class="price">{{ activity.price | number: '1.2-2' : 'fr' }} €</p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="cart.skiPass as skiPasses">
        <div *ngIf="skiPasses.length"
             class="expand-block">

          <div class="header-expand-block">
            <p class="big-title">Forfaits</p>

            <svg>
              <use [attr.xlink:href]="'assets/icons/icons.svg#forfait'"></use>
            </svg>
          </div>

          <div *ngFor="let skiPass of skiPasses"
               class="line-expand-block">
            <div class="small-title">
              <p class="experience-info">{{ skiPass.item?.experience.name }} - {{ skiPass.item?.ageRange.name }}</p>
              <p class="start-date">Premier jour : {{ skiPass.startDate.format('dddd DD MMMM') }}</p>
            </div>

            <p class="quantities">
              (x{{ skiPass.participants.length }})
            </p>

            <p class="price">{{ skiPass.price | number: '1.2-2' : 'fr' }} €</p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="cart.itemsSkiEquipment as skiEquipments">
        <div *ngIf="skiEquipments.length"
             class="expand-block">

          <div class="header-expand-block">
            <p class="big-title">Location de matériel</p>

            <svg>
              <use [attr.xlink:href]="'assets/icons/icons.svg#skis'"></use>
            </svg>
          </div>

          <div *ngFor="let group of groupBy(skiEquipments)"
               class="line-expand-block">
            <div class="small-title">
              <p class="experience-info">
                {{ group.equipment.pack.name }} - {{ group.equipment.duration }} jours -
                ({{ group.equipment.pack.ageRange }})
                <ng-container *ngIf="group.equipment.shoes && !group.equipment.helmet">+ chaussures</ng-container>
                <ng-container *ngIf="group.equipment.helmet && !group.equipment.shoes">+ casque</ng-container>
                <ng-container *ngIf="group.equipment.helmet && group.equipment.shoes">+ chaussures & casque
                </ng-container>
              </p>
              <p class="start-date">Premier jour : {{ group.equipment.startDate.format('dddd DD MMMM') }}</p>
            </div>

            <p class="quantities">(x{{ group.size }})</p>

            <p class="price">{{ group.price | number: '1.2-2' : 'fr' }} €</p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="cart.skiClass as skiClasses">
        <div *ngIf="skiClasses.length"
             class="expand-block">

          <div class="header-expand-block">
            <p class="big-title">Cours de ski</p>

            <svg>
              <use [attr.xlink:href]="'assets/icons/icons.svg#academic-cap'"></use>
            </svg>
          </div>

          <div *ngFor="let skiClass of skiClasses"
               class="line-expand-block">
            <div class="small-title">
              <p class="experience-info">{{ skiClass.item?.experience.name }} - {{ skiClass.item?.ageRange.name }}</p>
              <p class="start-date">Premier jour : {{ skiClass.startDate.format('dddd DD MMMM') }}</p>
            </div>

            <p class="quantities">(x{{ skiClass.participants.length }})</p>

            <p class="price">{{ skiClass.price | number: '1.2-2' : 'fr' }} €</p>
          </div>
        </div>
      </ng-container>

      <vsk-promo-code [cart]="cart"
                      [paymentPage]="showPaymentInfo"
                      [promoCode]="cart.promoCode">
      </vsk-promo-code>
    </div>

    <div class="footer-summary">
      <div class="total-line">
        <p>Total</p>

        <div class="public-and-net-price">
          <p *ngIf="cart.getPublicPrice() !== cart.getPriceWithPromo()">
            {{ cart.getPublicPrice() | number: '1.2-2' : 'fr' }} €
          </p>

          <h5>{{ cart.getPriceWithPromo() | number: '1.2-2' : 'fr' }} €</h5>
        </div>
      </div>

      <atc-button (click)="nextChange.emit()"
                  *ngIf="showNext"
                  class="flex flex-1 w-full"
                  color="primary"
                  size="large">
        Passer à l'étape suivante
      </atc-button>

      <div *ngIf="showPaymentInfo"
           class="payments-means">
        <img alt="Méthode de paiement par Carte Bleue" height="32"
             ngSrc="assets/new-design/svg/payment-methods/cb.svg"
             width="48">

        <div class="mastercard-wrapper">
          <img alt="Méthode de paiement par Mastercard" height="17"
               ngSrc="assets/new-design/svg/payment-methods/mastercard.svg"
               width="30">
        </div>

        <img alt="Méthode de paiement par Visa" height="32"
             ngSrc="assets/new-design/svg/payment-methods/visa.svg"
             width="46">
      </div>

      <p *ngIf="showPaymentInfo" class="self-stretch">Paiement sécurisé et protégé</p>
    </div>
  </div>
</ng-container>
