<div *ngIf="windowResource.width() | async | screen: 'tablet-above'"
     class="filters">
  <atc-select (selectChange)="selectChange()"
              *ngIf="filterByResorts"
              [checkbox]="true"
              [fitLabelWidth]="true"
              [optionElements]="resortsElements"
              [searchable]="true"
              label="Station">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              *ngIf="filterByRegions"
              [checkbox]="true"
              [optionElements]="regionsElements"
              label="Massif">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              [checkbox]="true"
              [optionElements]="accommodationTypesElements"
              label="Type d'hébergement">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
              [checkbox]="true"
              [optionElements]="equipmentsFilter"
              label="Équipement">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
              [checkbox]="true"
              [optionElements]="servicesFilter"
              label="Service">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              *ngIf="windowResource.width() | async | screen: 'desktop'"
              [checkbox]="true"
              [optionElements]="accessFilter"
              label="Accessibilité">
  </atc-select>

  <div class="flex flex-row gap-x-2 items-center ml-auto">
    <atc-button (click)="openPopup()"
                class="relative"
                color="black"
                icon="filters">
      Plus de filtres
    </atc-button>
    <atc-button (click)="clearFilters()"
                color="simple"
                icon="trash">
    </atc-button>
  </div>
</div>

<div *ngIf="!(windowResource.width() | async | screen: 'tablet-above')"
     class="filters-mobile">
  <vsk-search-bar styleMobileButton="search-accommodation">
  </vsk-search-bar>

  <button (click)="openPopup()"
          class="button-filters">
    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#filters'"></use>
    </svg>
  </button>
</div>

<vsk-filters-accommodation-popup (applyFilters)="selectChangePopup($event)"
                                 (closeChanged)="closePopup()"
                                 *ngIf="isPopupOpen"
                                 [accessFilter]="accessFilter"
                                 [accommodationTypesElements]="accommodationTypesElements"
                                 [currentFilters]="currentFilters"
                                 [equipmentsFilter]="equipmentsFilter"
                                 [filterByRegions]="filterByRegions"
                                 [filterByResorts]="filterByResorts"
                                 [maxPriceDisplay]="maxPriceDisplay"
                                 [minPriceDisplay]="minPriceDisplay"
                                 [nbParticipants]="nbParticipants"
                                 [regionsElements]="regionsElements"
                                 [regions]="regions"
                                 [resortsElements]="resortsElements"
                                 [servicesFilter]="servicesFilter">
</vsk-filters-accommodation-popup>
