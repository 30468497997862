import { NgForOf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ButtonComponent,
  InputCheckboxComponent,
  OptionElement,
  PopupComponent,
  Range,
  RangeInputComponent,
  WindowResource
} from 'atomic-lib';
import { AccommodationType } from '../../../../shared/models/accommodation/accommodation-type.enum';
import { FiltersStation } from '../../../../shared/models/filters/filters-station';
import { Region } from '../../../../shared/models/region';
import { SearchRegionFormComponent } from '../../../../shared/search-region-form/search-region-form.component';

@Component({
  selector: 'vsk-search-trip-filters',
  templateUrl: './search-trip-filters.component.html',
  styleUrls: ['./search-trip-filters.component.scss'],
  imports: [
    PopupComponent,
    InputCheckboxComponent,
    NgForOf,
    RangeInputComponent,
    ButtonComponent,
    SearchRegionFormComponent
  ],
  standalone: true
})
export class SearchTripFiltersComponent implements OnChanges {
  @Input() filters: FiltersStation;
  @Input() accommodationTypeFilter: OptionElement<AccommodationType>[];
  @Input() regionsFilter: OptionElement<number>[];
  @Input() regions: Region[];
  @Input() tripIncludeFilter: OptionElement<number>[];
  @Input() equipmentsFilter: OptionElement<number>[];
  @Input() accessFilter: OptionElement<number>[];
  @Input() servicesFilter: OptionElement<number>[];
  @Input() tagsFilter: OptionElement<number>[];
  @Input() resortLabelsFilter: OptionElement<number>[];

  @Output() refreshChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clearFiltersChanged: EventEmitter<void> = new EventEmitter<void>();

  maxValueRange = 10000;
  minValueRange = 0;

  selectedMaxValue: number;
  selectedMinValue: number;

  constructor(public windowResource: WindowResource) {}

  ngOnChanges(): void {
    if (
      this.filters.priceRange?.min != 0 ||
      this.filters.priceRange?.max != 0
    ) {
      this.selectedMaxValue = this.filters.priceRange.max;
      this.selectedMinValue = this.filters.priceRange.min;
    } else {
      this.selectedMaxValue = this.maxValueRange;
      this.selectedMinValue = this.minValueRange;
    }
  }

  regionsChanged(regions: Region[]) {
    const regionsId = regions.map((region) => region.id);
    this.regionsFilter.forEach((region) => {
      region.control?.setValue(regionsId.includes(region.id || -1));
    });
  }

  optionReceived(filter: Range) {
    this.filters.priceRange.min = filter.min;
    this.filters.priceRange.max = filter.max;
  }

  getControl(control: FormControl<boolean | null> | undefined) {
    return control as FormControl<boolean | null>;
  }
}
