import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { DropdownComponent, InputComponent } from 'atomic-lib';
import { debounceTime } from 'rxjs/operators';
import { Region } from '../../models/region';
import { ResortMin } from '../../models/resort/resort-min';
import { SearchRegionFormComponent } from '../../search-region-form/search-region-form.component';
import { DropdownTemplateComponent } from '../dropdown-template.component';
import { SelectResort, SetRegions, SetResorts } from '../search-bar.action';

@Component({
  selector: 'vsk-search-bar-destination',
  standalone: true,
  imports: [
    DropdownComponent,
    NgForOf,
    NgClass,
    NgIf,
    InputComponent,
    NgTemplateOutlet,
    SearchRegionFormComponent
  ],
  templateUrl: './search-bar-destination.component.html',
  styleUrl: './search-bar-destination.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarDestinationComponent extends DropdownTemplateComponent {
  readonly DEFAULT_TITLE = "N'importe où";
  display: 'regions' | 'resorts' = 'resorts';
  hover: Region | 'none' = 'none';
  search: FormControl<string | null> = new FormControl<string>('');
  _resortsToShow: ResortMin[] = [];

  @Input() theme: 'thick' | 'thin' = 'thick';

  constructor(
    private changeRef: ChangeDetectorRef,
    private store: Store
  ) {
    super();
    this.search.valueChanges.pipe(debounceTime(300)).subscribe((search) => {
      if (!search?.length) {
        this._resortsToShow = this._resorts;
        this.changeRef.markForCheck();
        return;
      }

      const searchNormalized = (param: string) => {
        return param
          .toLowerCase() // Mettre en minuscules
          .replace(/'/g, '') // Retirer les apostrophes
          .replace(/\//g, '') // Retirer les apostrophes
          .replace(/ ·/g, '') // Remplacer les espaces par des tirets
          .replace(/\s+/g, '') // Remplacer les espaces par des tirets
          .normalize('NFD') // Normaliser pour enlever les accents
          .replace(/[\u0300-\u036f]/g, ''); // Supprimer les diacritiques
      };

      this._resortsToShow = this._resorts.filter(
        (resort) =>
          searchNormalized(resort.name).indexOf(searchNormalized(search)) !== -1
      );

      this.changeRef.markForCheck();
    });
  }

  _regionsSelected: Region[] = [];

  @Input() set regionsSelected(regions: number[]) {
    this._regionsSelected = SearchRegionFormComponent.mapToRegion(regions);
    this.generateTitle();
  }

  _isResortSearch = false;

  @Input() set isResortSearch(isResortSearch: boolean) {
    this._isResortSearch = isResortSearch;
    this.generateTitle();
  }

  _resort: string | null;

  @Input() set resort(resort: string | null) {
    this._resort = resort;
    this.generateTitle();
  }

  _resorts: ResortMin[] = [];

  @Input() set resorts(resorts: ResortMin[]) {
    this._resorts = resorts;
    this._resortsToShow = resorts;
    this.changeRef.markForCheck();
  }

  _resortsSelected: ResortMin[] = [];

  @Input() set resortsSelected(resorts: ResortMin[]) {
    this._resortsSelected = resorts;
    this.generateTitle();
  }

  identity(index: number, resort: ResortMin) {
    return resort.id;
  }

  chooseDestination(resort: ResortMin) {
    if (this._isResortSearch) {
      this._resort = resort.name;
      this.store.dispatch(new SelectResort(this._resort));
      this.generateTitle();
      this.nextChanged.emit();
      return;
    }

    if (this._resortsSelected.indexOf(resort) !== -1) {
      this._resortsSelected = [
        ...this._resortsSelected.filter(
          (resortSelected) => resortSelected.id !== resort.id
        )
      ];
    } else {
      this._resortsSelected.push(resort);
    }

    const resorts = this._resortsSelected.map((resort) => resort.id);
    this.store.dispatch(new SetResorts(resorts));
  }

  isResortSelected(resortId: number) {
    return !!this._resortsSelected.find((resort) => resort.id === resortId);
  }

  generateTitle(): void {
    if (this._isResortSearch) {
      this.titleButtonChanged.emit(
        this._resort !== null ? this._resort : this.DEFAULT_TITLE
      );
      return;
    }

    if (!this._resortsSelected.length && !this._regionsSelected.length) {
      this.titleButtonChanged.emit(this.DEFAULT_TITLE);
      return;
    }

    this.titleButtonChanged.emit(
      [
        ...this._regionsSelected.map((region) => region.name),
        ...this._resortsSelected.map((resort) => resort.name)
      ].join(', ')
    );
  }

  dispatchRegions(regions: Region[]) {
    const regionsId = regions.map((region) => region.id);
    this.store.dispatch(new SetRegions(regionsId));
  }
}
