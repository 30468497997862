import { NgModule } from '@angular/core';
import { FooterComponent } from '../../shared/footer/footer.component';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '../../store/translate.module';
import { TermsOfUseRoutingModule } from './terms-of-use-routing.module';
import { TermsOfUseComponent } from './terms-of-use.component';

@NgModule({
  declarations: [TermsOfUseComponent],
  imports: [
    NgxTranslateModule,
    SharedModule,
    TermsOfUseRoutingModule,
    FooterComponent,
    NavbarComponent
  ],
  exports: [TermsOfUseComponent]
})
export class TermsOfUseModule {}
