<vsk-navbar></vsk-navbar>

<atc-breadcrumb [items]="breadcrumbItems"></atc-breadcrumb>

<vsk-header-filters [filtersTripInfo]="filtersMap$ | async"
                    [filters]="filtersResorts$ | async"
                    [resortLabels]="resortLabels$ | async">
</vsk-header-filters>

<main>
  <section [ngClass]="{'full-map': fullMap}" class="list-resorts">
    <div *ngIf="loading" class="order-filter min-h-[37.5px] rounded-[36px]" vskSkeletonLoad></div>

    <div *ngIf="!loading" class="order-filter">
      <p><span>{{ resorts?.length }} stations</span> correspondent à votre recherche</p>

      <atc-select [control]="orderForm"
                  [optionElements]="ordersSelection"
                  class="w-[16rem]">
      </atc-select>
    </div>


    <ng-container *ngIf="loading">
      <div *ngFor="let index of [0, 1, 2, 3, 4]"
           class="loading-card">
        <div class="left-side">
          <div class="tag"></div>
        </div>

        <div class="right-side">
          <div class="title"></div>
          <div class="content">
            <div class="large-tags">
              <div class="large-tag"></div>
              <div class="large-tag"></div>
            </div>
            <div class="small-tags">
              <div class="small-tag"></div>
              <div class="small-tag"></div>
              <div class="small-tag"></div>
              <div class="small-tag"></div>
            </div>
          </div>
          <div class="footer">
            <div class="price"></div>
            <div class="cta"></div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading">
      <atc-card-map (mouseenter)="overMarker(resort.marker)"
                    (mouseleave)="outMarker(resort.marker)"
                    *ngFor="let resort of listResorts; trackBy identity"
                    [accommodations]="resort?.totalRooms || 0"
                    [activities]="resort?.activitiesSelected || []"
                    [affinity]="resort.affinity"
                    [link]="getLinkResort(resort.name)"
                    [location]="resort.regionLabel"
                    [logo]="resort.urlLogo"
                    [openInNewTab]="windowResource.width() | async | screen: 'small-desktop-above'"
                    [priceAccommodation]="resort.priceAccommodation"
                    [priceMaterial]="resort.priceMaterial"
                    [pricePackage]="resort.pricePackage"
                    [pricePerPerson]="resort.totalPricePerPerson"
                    [price]="resort.totalPrice"
                    [skiSlopesKm]="resort.skiSlopesKm"
                    [src]="resort.urlCover"
                    [title]="resort.name">
      </atc-card-map>
    </ng-container>

    <div *ngIf="!listResorts?.length && !loading"
         class="no-results">
      <div class="image-no-results"></div>
      <!--      <img alt="Pas de résultats" src="assets/new-design/img/search-trip/home-3D.png">-->
      <h3>Aucune station trouvé...</h3>
      <p>Essayez d'ajuster vos filtres ou de rechercher avec d'autres critères</p>
    </div>

    <atc-button (click)="loadMore()"
                *ngIf="listResorts?.length"
                color="secondary"
                size="large">
      Voir plus de stations
    </atc-button>
  </section>

  <google-map (mapDragend)="boundsChanged()"
              [center]="center"
              [options]="options"
              [zoom]="zoom"
              class="map-search"
              height="100%"
              width="100%">
    <atc-input-checkbox [border]="false"
                        [control]="refreshOnMoveMap"
                        class="refresh-map-on-zoom">
      <p>Rafraîchir les stations en déplaçant la carte</p>
    </atc-input-checkbox>

    <button (click)="fullMap = !fullMap"
            [ngClass]="{'expand': fullMap}"
            class="change-size-map">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#left-arrow'"></use>
      </svg>
      <span>Afficher la liste</span>
    </button>

    <map-marker
      #mapMarker="mapMarker"
      (mapClick)="openInfoWindow(marker, mapMarker)"
      (mapMouseout)="outMarker(marker)"
      (mapMouseover)="overMarker(marker)"
      *ngFor="let marker of markers"
      [icon]="marker.getIcon()!"
      [label]="marker.getLabel()!"
      [options]="getOptions(marker)"
      [position]="marker.getPosition()!"
      [title]="marker.getTitle()!">
    </map-marker>

    <map-info-window>
      <atc-card-map *ngIf="pinpoint"
                    [accommodations]="pinpoint?.totalRooms || 0"
                    [activities]="pinpoint?.activitiesSelected || []"
                    [affinity]="pinpoint.affinity"
                    [isPinpoint]="true"
                    [link]="getLinkResort(pinpoint.name)"
                    [location]="pinpoint.regionLabel"
                    [logo]="pinpoint.urlLogo"
                    [openInNewTab]="windowResource.width() | async | screen: 'small-desktop-above'"
                    [priceAccommodation]="pinpoint.priceAccommodation"
                    [priceMaterial]="pinpoint.priceMaterial"
                    [pricePackage]="pinpoint.pricePackage"
                    [pricePerPerson]="pinpoint.totalPricePerPerson"
                    [price]="pinpoint.totalPrice"
                    [skiSlopesKm]="pinpoint.skiSlopesKm"
                    [src]="pinpoint.urlCover"
                    [title]="pinpoint.name">
      </atc-card-map>
    </map-info-window>
  </google-map>
</main>

<vsk-footer></vsk-footer>
