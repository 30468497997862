import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { OptionElement, Range, WindowResource } from 'atomic-lib';
import { SetFiltersAndFetchEstablishments } from '../../page/search-accommodations/accommodation.action';
import { AccommodationType } from '../models/accommodation/accommodation-type.enum';
import { OrderBy } from '../models/const/order-by';
import { FiltersAccommodationSearch } from '../models/filters/filters-accommodation-search';
import { FiltersInfoAccommodation } from '../models/filters/filters-info-accommodation';
import { Region } from '../models/region';
import { ResortMin } from '../models/resort/resort-min';
import { SetResorts } from '../search-bar/search-bar.action';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class FiltersAccommodationManagerComponent {
  _filtersInfo: FiltersInfoAccommodation;

  // Equipments establishment & room
  poolForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  spaForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  saunaForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  hammamForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  balconyTerraceForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  tvForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  chimneyForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  bbqForm: FormControl<boolean | null> = new FormControl<boolean>(false);

  // Access
  pmrForm: FormControl<boolean | null> = new FormControl<boolean>(false);

  // Services
  parkingForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  animalsAdmittedForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );
  wifiForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  childrenClubForm: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );

  @Input() resort?: number;
  @Input() minPriceDisplay: number;
  @Input() maxPriceDisplay: number;
  @Input() nbParticipants: number;
  @Input() filterByResorts = false;
  @Input() filterByRegions = false;
  @Input() currentFilters: FiltersAccommodationSearch;
  @Input() currentPage = 1;
  @Output() resetChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  orderBy: OrderBy = OrderBy.PRICEASC;
  regionsElements: OptionElement<number>[] = [];
  regions: Region[] = [];
  resortsElements: OptionElement<number>[] = [];
  accommodationTypesElements: OptionElement<AccommodationType>[] = [];
  equipmentsFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Piscine',
      control: this.poolForm
    },
    {
      id: 2,
      label: 'Spa',
      control: this.spaForm
    },
    {
      id: 3,
      label: 'Sauna',
      control: this.saunaForm
    },
    {
      id: 4,
      label: 'Hammam',
      control: this.hammamForm
    },
    {
      id: 5,
      label: 'Balcon / Terrasse',
      control: this.balconyTerraceForm
    },
    {
      id: 6,
      label: 'TV',
      control: this.tvForm
    },
    {
      id: 7,
      label: 'Cheminée',
      control: this.chimneyForm
    },
    {
      id: 8,
      label: 'Barbecue',
      control: this.bbqForm
    }
  ];
  accessFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Accès PMR',
      control: this.pmrForm
    }
  ];
  servicesFilter: OptionElement<number>[] = [
    {
      id: 1,
      label: 'Parking',
      control: this.parkingForm
    },
    {
      id: 2,
      label: 'Animaux admis',
      control: this.animalsAdmittedForm
    },
    {
      id: 3,
      label: 'Wifi',
      control: this.wifiForm
    },
    {
      id: 4,
      label: 'Club enfants',
      control: this.childrenClubForm
    }
  ];

  constructor(
    protected store: Store,
    protected activatedRoute: ActivatedRoute,
    public windowResource: WindowResource
  ) {}

  @Input() set reset(clear: boolean) {
    if (clear) {
      this.clearFilters();
      this.resetChange.emit(false);
    }
  }

  @Input() set resorts(resorts: ResortMin[]) {
    if (resorts?.length) {
      this.resortsElements = resorts.map((resort) => {
        const filterCount = this._filtersInfo?.resorts?.find(
          (filter) => resort.id === filter.id
        );

        if (!filterCount) {
          return {
            id: resort.id,
            label: resort.name,
            control: new FormControl<boolean>(false),
            quantity: 0
          };
        }

        const form = new FormControl<boolean>(
          this.currentFilters.resorts.includes(resort.id)
        );

        if (!filterCount?.quantity) {
          form.disable();
        }

        return {
          id: resort.id,
          label: resort.name,
          control: form,
          quantity: filterCount?.quantity ?? 0
        };
      });
    }
  }

  public selectChange() {
    this.currentFilters = new FiltersAccommodationSearch({
      ...this.currentFilters,
      types: this.accommodationTypesElements
        .filter((element) => !!element.control?.value)
        .map((element) => element.id as AccommodationType),
      resorts: this.resort
        ? [this.resort]
        : this.resortsElements
            .filter((element) => !!element.control?.value)
            .map((element) => element.id as number),
      regions: this.regionsElements
        .filter((element) => !!element.control?.value)
        .map((element) => element.id as number),
      pool: this.poolForm.value as boolean,
      spa: this.spaForm.value as boolean,
      hammam: this.hammamForm.value as boolean,
      sauna: this.saunaForm.value as boolean,
      balconyTerrace: this.balconyTerraceForm.value as boolean,
      tv: this.tvForm.value as boolean,
      chimney: this.chimneyForm.value as boolean,
      bbq: this.bbqForm.value as boolean,
      pmr: this.pmrForm.value as boolean,
      parking: this.parkingForm.value as boolean,
      animalsAdmitted: this.animalsAdmittedForm.value as boolean,
      wifi: this.wifiForm.value as boolean,
      childrenClub: this.childrenClubForm.value as boolean,
      orderBy: this.orderBy
    });
    this.store.dispatch(new SetResorts(this.currentFilters.resorts));
    this.store.dispatch(
      new SetFiltersAndFetchEstablishments(
        this.currentFilters,
        this.activatedRoute.snapshot.queryParamMap
      )
    );
  }

  public selectChangePrices(range: Range) {
    this.currentFilters = new FiltersAccommodationSearch({
      ...this.currentFilters,
      priceRange: {
        min: range.min,
        max: range.max
      }
    });
  }

  public clearFilters() {
    this.accommodationTypesElements.forEach((element) =>
      element.control?.setValue(false)
    );
    this.servicesFilter.forEach((element) => element.control?.setValue(false));
    this.accessFilter.forEach((element) => element.control?.setValue(false));
    this.resortsElements.forEach((element) => element.control?.setValue(false));
    this.regionsElements.forEach((element) => element.control?.setValue(false));
    this.equipmentsFilter.forEach((element) =>
      element.control?.setValue(false)
    );
    this.orderBy = OrderBy.PRICEASC;

    this.currentFilters = new FiltersAccommodationSearch({
      ...this.currentFilters,
      mapPolygon: {
        east: 8.413687158879437,
        north: 48.70087172198533,
        south: 42.13380963168609,
        west: -2.133187841120563
      },
      priceRange: {
        min: this.minPriceDisplay,
        max: this.maxPriceDisplay
      },
      resorts: this.filterByResorts ? [] : this.currentFilters.resorts,
      regions: [],
      types: [],
      pool: false,
      spa: false,
      sauna: false,
      hammam: false,
      balconyTerrace: false,
      tv: false,
      chimney: false,
      bbq: false,
      pmr: false,
      parking: false,
      animalsAdmitted: false,
      wifi: false,
      childrenClub: false,
      promo: false
    });

    this.store.dispatch(new SetResorts([]));
    this.store.dispatch(
      new SetFiltersAndFetchEstablishments(
        this.currentFilters,
        this.activatedRoute.snapshot.queryParamMap
      )
    );
  }
}
