<nav [ngClass]="[theme, position, openMenu ? 'dark-menu' : '']">
  <div class="menu-block">
    <button (click)="changeMenuState(true)"
            *ngIf="!openMenu"
            [ngClass]="{'color-white': theme === 'light', 'color-blue': theme === 'dark'}"
            class="hide-small-desktop-and-above">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#menu'"></use>
      </svg>
    </button>

    <button (click)="changeMenuState(false)"
            *ngIf="openMenu"
            class="hide-small-desktop-and-above close">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#close'"></use>
      </svg>
    </button>

    <a queryParamsHandling="preserve"
       routerLink="/"
       routerLinkActive="true">
      <img *ngIf="theme === 'light' && !openMenu"
           alt="Logo VeryMountain" height="25"
           ngSrc="assets/new-design/svg/logo-light.svg" width="173">
      <img *ngIf="theme === 'dark' || openMenu"
           alt="Logo VeryMountain" height="25"
           ngSrc="assets/new-design/svg/logo-dark.svg" width="173">
    </a>
  </div>

  <vsk-search-bar class="hide-small-desktop-and-above"
                  styleMobileButton="navbar">
  </vsk-search-bar>

  <!--  <section *ngIf="theme === 'light'"-->
  <!--           class="center-block hide-small-desktop-and-below">-->
  <!--    <a queryParamsHandling="merge"-->
  <!--       routerLink="/recherche-stations"-->
  <!--       routerLinkActive="true">Nos stations</a>-->
  <!--    <a queryParamsHandling="merge"-->
  <!--       routerLink="/recherche-stations"-->
  <!--       routerLinkActive="true">Nos activités</a>-->
  <!--    <a href="https://blog.verymountain.fr"-->
  <!--       target="_blank">Blog</a>-->
  <!--  </section>-->

  <section *ngIf="theme === 'dark'"
           class="center-block hide-small-desktop-and-below">
    <vsk-search-bar></vsk-search-bar>
  </section>

  <section class="group-actions hide-small-desktop-and-below">
    <a class="phone"
       href="tel:+33465847469">
      <img alt="Téléphone" height="13" ngSrc="assets/new-design/svg/icons/phone.svg" width="9">
      <span>04 65 84 74 69</span>
    </a>

    <a *ngIf="(user$ | async) === null"
       class="connect"
       queryParamsHandling="preserve"
       routerLink="/connexion"
       routerLinkActive="true">
      Se connecter
    </a>

    <vsk-user-navbar-dropdown *ngIf="(user$ | async) as user">
    </vsk-user-navbar-dropdown>
  </section>
</nav>

<vsk-menu-mobile (closeChange)="changeMenuState(false)"
                 *ngIf="openMenu"
                 [closing]="closeAnim"
                 [connected]="(user$ | async) !== null">
</vsk-menu-mobile>
