<div class="content">
  <button (click)="previousChange.emit()"
          class="back">
    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-left'"></use>
    </svg>

    <p>Retour</p>
  </button>

  <ng-container *ngIf="(isConnected$ | async) === false">
    <vsk-register-form (switchLoginTypeChange)="step = 'sign-in'"
                       *ngIf="step === 'register'"
                       [redirectHomeOnConnexion]="false"
                       [redirectionAreLink]="false">
    </vsk-register-form>
    <vsk-login-form (switchLoginTypeChange)="step = 'register'"
                    *ngIf="step === 'sign-in'"
                    [redirectHomeOnConnexion]="false"
                    [redirectionAreLink]="false">
    </vsk-login-form>
  </ng-container>

  <ng-container *ngIf="(isConnected$ | async)">
    <div *ngIf="user$ | async as user"
         class="group-title">
      <h3>Confirmer et payer</h3>

      <p *ngIf="user">{{ user.firstname }}, la dernière étape avant votre départ en montagne !</p>
    </div>

    <atc-input-radio (changeSelection)="updatePayMode($event)"
                     *ngIf="canPayDeposit()"
                     [controls]="payModeRadios"
                     class="w-full">
    </atc-input-radio>

    <p *ngIf="payMode === 'DEPOSIT' && !loading"
       class="text-center">
      Un complément de l'acompte vous sera envoyé à la date du
      {{ startDate.clone().add(-45, 'day').format('DD/MM/YYYY') }}
    </p>

    <p *ngIf="error" class="error">{{ error }}</p>

    <div class="flex flex-col w-full min-h-[215px] items-center justify-center">
      <atc-loader *ngIf="loading" color="secondary"></atc-loader>
      <div [ngClass]="{'hidden': loading}" class="w-full" id="payment-element"></div>
    </div>

    <atc-button (click)="pay()"
                [disabled]="loading"
                class="flex w-full"
                icon="cart"
                id="id__button-pay"
                size="large">
      Payer mon séjour
    </atc-button>
  </ng-container>
</div>
