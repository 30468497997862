import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SkiClass } from '../shared/models/ski-class/ski-class';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class SkiClassService extends RepositoryService {
  private URL_DOMAIN = 'api/webapp/ski-class';

  constructor(http: HttpClient) {
    super(http);
  }

  getSkiClassForResort(resortName: string): Observable<SkiClass[]> {
    return this.get(
      this.URL_DOMAIN + `/get-ski-class-for-resort?resortName=${resortName}`
    );
  }
}
