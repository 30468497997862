import { NgForOf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Store } from '@ngxs/store';
import { DropdownComponent } from 'atomic-lib';
import moment from 'moment/moment';
import { FiltersState } from '../../../filters.state';
import { Participant } from '../../models/participant/participant';
import { SharedModule } from '../../shared.module';
import { DropdownTemplateComponent } from '../dropdown-template.component';
import { SearchBarParticipantFormComponent } from './search-bar-participant-form/search-bar-participant-form.component';

@Component({
  selector: 'vsk-search-bar-participants',
  standalone: true,
  templateUrl: './search-bar-participants.component.html',
  imports: [
    DropdownComponent,
    NgOptimizedImage,
    NgForOf,
    SharedModule,
    SearchBarParticipantFormComponent,
    NgTemplateOutlet
  ],
  styleUrl: './search-bar-participants.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarParticipantsComponent extends DropdownTemplateComponent {
  readonly DEFAULT_TITLE = 'Ajouter des voyageurs';

  nbAdults = 0;
  nbChildren = 0;

  @Input() theme: 'thick' | 'thin' = 'thick';

  @Output() addParticipantChange: EventEmitter<Participant> =
    new EventEmitter<Participant>();
  @Output() changeParticipantChange: EventEmitter<Participant> =
    new EventEmitter<Participant>();
  @Output() removeParticipantChange: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(private store: Store) {
    super();
  }

  _participants: Participant[] = [];

  @Input() set participants(participants: Participant[]) {
    this._participants = [...participants];
    this.nbAdults = this._participants.filter(
      (participant) => participant.age >= 18
    ).length;
    this.nbChildren = this._participants.filter(
      (participant) => participant.age < 18
    ).length;
    this.generateTitle();
  }

  addParticipant(type: 'child' | 'adult') {
    if (this._participants.length < 20) {
      this.addParticipantChange.emit(
        new Participant({
          index: this._participants.length + 1,
          firstname: `Voyageur ${this._participants.length + 1}`,
          birthdate: moment().add(type === 'child' ? -10 : -25, 'year'),
          sessionId: this.store.selectSnapshot(FiltersState.sessionId)
        })
      );
    }
  }

  newAgeForParticipant(age: number, participant: Participant) {
    participant.birthdate = moment().add(-age, 'year');
    this.changeParticipantChange.emit(participant);
  }

  removeParticipant(participant: Participant) {
    if (this._participants.length > 1) {
      this.removeParticipantChange.emit(participant.uuid);
    }
  }

  participant(index: number, participant: Participant) {
    return participant.uuid;
  }

  protected generateTitle(): void {
    if (!this._participants.length) {
      this.titleButtonChanged.emit(this.DEFAULT_TITLE);
      return;
    }

    let title = '';

    if (this.nbAdults > 0) {
      title += `${this.nbAdults} adulte${this.nbAdults > 1 ? 's' : ''}`;
    }

    if (this.nbChildren > 0) {
      if (this.nbAdults > 0) {
        title += ', ';
      }

      title += `${this.nbChildren} enfant${this.nbChildren > 1 ? 's' : ''}`;
    }

    this.titleButtonChanged.emit(title);
  }
}
