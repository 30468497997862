import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({ standalone: true, template: '' })
export abstract class DropdownTemplateComponent {
  @Input() isOpen = true;
  @Input() position: 'bottom-left' | 'bottom-right' = 'bottom-left';
  @Input() top: string;
  @Input() right: string;
  @Input() left: string;
  @Output() closeEventChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() titleButtonChanged: EventEmitter<string> =
    new EventEmitter<string>();

  _parent: ElementRef<HTMLElement>;

  @Input() set parent(parent: HTMLButtonElement) {
    this._parent = new ElementRef(parent);
  }

  protected abstract generateTitle(): void;
}
