import { LocalStorageResource } from '../../../resource/local-storage.resource';
import { ItemAccommodation } from '../accommodation/item-accommodation';
import { ExperienceCategoryEnum } from '../enum/experience-category.enum';
import { ItemCart } from '../package/item-cart';
import { PromoCode } from '../promo-code';
import { PromoType } from '../promo-type';
import { CartItemSkiEquipment } from '../ski-equipment/cart-item-equipment';
import { EcoChart } from './eco-chart';

export class Cart {
  itemsActivity: ItemCart[] = [];
  itemsAccommodation: ItemAccommodation[] = [];
  itemsSkiEquipment: CartItemSkiEquipment[] = [];
  promoCode: PromoCode | null;
  ecoTourismChart: EcoChart;
  sessionId: string;
  deposit = false;

  constructor(obj: Partial<Cart>) {
    Object.assign(this, obj);
    this.itemsActivity = this.itemsActivity
      .map((item) => new ItemCart(item))
      .sort((prev, curr) =>
        prev.participants
          .map((p) => p.index)
          .reduce((prev, curr) => prev + curr) <
        curr.participants
          .map((p) => p.index)
          .reduce((prev, curr) => prev + curr)
          ? -1
          : 1
      );

    this.itemsAccommodation = this.itemsAccommodation.map(
      (item) => new ItemAccommodation(item)
    );

    this.itemsSkiEquipment = this.itemsSkiEquipment
      .map((item) => new CartItemSkiEquipment(item))
      .sort((prev, curr) =>
        prev.participantSession.index < curr.participantSession.index ? -1 : 1
      );

    if (!this.ecoTourismChart) {
      this.ecoTourismChart = LocalStorageResource.IsEcoChart();
    }
  }

  get isEmpty(): boolean {
    return (
      this.itemsActivity.length === 0 &&
      this.itemsAccommodation.length === 0 &&
      this.itemsSkiEquipment.length === 0
    );
  }

  public get quantityActivities(): number {
    return this.itemsActivity.length;
  }

  public get quantityAccommodation(): number {
    return this.itemsAccommodation.length;
  }

  public get quantitySkiEquipment(): number {
    return this.itemsSkiEquipment.length;
  }

  public get skiPass(): ItemCart[] {
    return this.itemsActivity
      .filter(
        (item) =>
          item.item?.experience.experienceCategory ===
          ExperienceCategoryEnum.SKIPASS
      )
      .sort((prev, curr) =>
        prev.participants
          .map((p) => p.index)
          .reduce((prev, curr) => prev + curr) <
        curr.participants
          .map((p) => p.index)
          .reduce((prev, curr) => prev + curr)
          ? -1
          : 1
      );
  }

  public get skiClass(): ItemCart[] {
    return this.itemsActivity
      .filter(
        (item) =>
          item.item?.experience.experienceCategory ===
          ExperienceCategoryEnum.SKILESSON
      )
      .sort((prev, curr) =>
        prev.participants
          .map((p) => p.index)
          .reduce((prev, curr) => prev + curr) <
        curr.participants
          .map((p) => p.index)
          .reduce((prev, curr) => prev + curr)
          ? -1
          : 1
      );
  }

  public get general(): ItemCart[] {
    return this.itemsActivity
      .filter(
        (item) =>
          item.item?.experience.experienceCategory ===
          ExperienceCategoryEnum.GENERAL
      )
      .sort((prev, curr) =>
        prev.startDate.isSameOrBefore(curr.startDate, 'hours') ? -1 : 1
      );
  }

  get hasPromoCode() {
    return !!this.promoCode && this.getPrice() >= this.promoCode.minPrice;
  }

  public getQuantity(): number {
    return (
      this.quantityActivities +
      this.quantityAccommodation +
      this.quantitySkiEquipment
    );
  }

  public getPrice(): number {
    return (
      (!this.ecoTourismChart.isChecked ? this.ecoTourismChart.price : 0) +
      this.priceActivities() +
      this.priceAccommodation() +
      this.priceSkiEquipment()
    );
  }

  public getPublicPrice(): number {
    return (
      (!this.ecoTourismChart.isChecked ? this.ecoTourismChart.price : 0) +
      this.priceActivities(true) +
      this.priceAccommodation(true) +
      this.priceSkiEquipment(true)
    );
  }

  public priceAccommodation(publicPrice = false): number {
    const itemsFiltered = this.itemsAccommodation.filter(
      (item) => item.selectForm.value
    );

    if (!itemsFiltered.length) {
      return 0;
    }

    return itemsFiltered
      .map((item) => (publicPrice ? item.publicPrice : item.price))
      .reduce((previousValue, currentValue) => currentValue + previousValue);
  }

  public priceSkiEquipment(publicPrice = false): number {
    if (!this.itemsSkiEquipment?.length) {
      return 0;
    }

    return this.itemsSkiEquipment
      .map((item) => (publicPrice ? item.totalPublicPrice : item.totalPrice))
      .reduce((previousValue, currentValue) => currentValue + previousValue);
  }

  public priceActivities(publicPrice = false): number {
    const itemsFiltered = this.itemsActivity.filter(
      (item) => item.selectForm.value
    );

    if (!itemsFiltered.length) {
      return 0;
    }

    return itemsFiltered
      .map((itemCart) => (publicPrice ? itemCart.pricePublic : itemCart.price))
      .reduce((previousValue, currentValue) => currentValue + previousValue);
  }

  public getPriceWithPromo() {
    const price = this.getPrice();
    if (!this.promoCode || price < this.promoCode.minPrice) {
      return price;
    }

    if (this.promoCode.typePromo === PromoType.REDUCTION) {
      return price - price * this.promoCode.applyNumber;
    }

    const newPrice = price - this.promoCode.applyNumber;
    return newPrice < 0 ? 0 : newPrice;
  }

  getResort() {
    if (this.itemsAccommodation.length) {
      return this.itemsAccommodation[0].resort;
    }

    if (this.itemsActivity.length) {
      return this.itemsActivity[0].resort;
    }

    return '';
  }
}
