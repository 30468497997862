<atc-popup (closeChanged)="closeChangeEvent.emit()"
           height="100%"
           position="right"
           width="100%">
  <p class="text-center" header>Location de matériel</p>
  <div body class="content">
    <h3 *ngIf="selectedPack">Gamme {{ selectedPack?.offer.category }} - {{ selectedPack.equipmentName }}
      - {{ !selectedPack?.offer.minAge ? 'Adulte (+ 13 ans)' : 'Enfant (' + selectedPack?.offer.minAge + ' - ' + selectedPack?.offer.maxAge + ' ans)' }}</h3>

    <h3 *ngIf="error">{{ error }}</h3>

    <div *ngIf="!noPack"
         class="select-group">
      <vsk-pack-equipment-dropdown (noPackChange)="noPackAvailable()"
                                   (resortIdChange)="resortId = $event"
                                   (selectPackChange)="changePack($event)"
                                   [criteria]="criteria"
                                   [duration]="selectedDuration">
      </vsk-pack-equipment-dropdown>

      <vsk-duration-equipment-dropdown (selectDaysChange)="changeDuration($event)"
                                       [duration]="criteria.skiPassDuration"
                                       [nbDays]="nbDays"
                                       [optionEmpty]="false"
                                       title="Nombre de jours de location">
      </vsk-duration-equipment-dropdown>

      <vsk-first-ski-date-dropdown (selectedDateChange)="changeFirstDay($event)"
                                   [period]="_period">
      </vsk-first-ski-date-dropdown>

      <vsk-participant-dropdown (selectedParticipantsChange)="changeSelectedParticipants($event)"
                                [participants]="eligibleParticipants">
      </vsk-participant-dropdown>

      <atc-input-checkbox *ngIf="selectedPack?.priceShoes"
                          [border]="false"
                          [control]="shoesForm">
        <p>Je veux des chaussures (+ {{ selectedPack.priceShoes | number: '1.2-2' : 'fr' }} € / pers)</p>
      </atc-input-checkbox>

      <atc-input-checkbox *ngIf="selectedPack?.priceHelmet"
                          [border]="false"
                          [control]="helmetForm">
        <p>Je veux un casque (+ {{ selectedPack.priceHelmet | number: '1.2-2' : 'fr' }} € / pers)</p>
      </atc-input-checkbox>
    </div>

    <p *ngIf="selectedPack"
       [innerHTML]="selectedPack.offer.equipments[0]?.description">
    </p>

    <div *ngIf="loading"
         class="loader-wrapper">
      <atc-loader color="secondary"></atc-loader>
    </div>
  </div>

  <div class="footer" footer>
    <div class="price">
      <p class="total-label">
        Total
      </p>

      <p *ngIf="equipmentPrice && equipmentPrice.publicPrice !== equipmentPrice.price"
         class="public-price">
        {{ equipmentPrice.publicPrice | number: '1.2-2' : 'fr' }} €
      </p>

      <h4 *ngIf="equipmentPrice">
        {{ equipmentPrice.price | number: '1.2-2' : 'fr' }} €
      </h4>
    </div>

    <atc-button (click)="addToCart()"
                [disabled]="loading || !selectedParticipants.length || error?.length > 0"
                size="large">
      Ajouter au panier
    </atc-button>
  </div>
</atc-popup>
