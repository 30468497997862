import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AppState } from '../app.state';
import { FiltersState } from '../filters.state';
import { CartState } from '../page/cart/cart.state';
import { AccommodationState } from '../page/search-accommodations/accommodation.state';
import { SearchBarState } from '../shared/search-bar/search-bar.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forRoot([
      AppState,
      AccommodationState,
      FiltersState,
      CartState,
      SearchBarState
    ])
  ],
  exports: [NgxsModule]
})
export class NgxsStoreModule {}
