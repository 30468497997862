import { Component, ElementRef, ViewChild } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngxs/store';
import { ButtonComponent, DropdownComponent } from 'atomic-lib';
import { DisconnectUser } from '../../../app.action';

@Component({
  selector: 'vsk-user-navbar-dropdown',
  standalone: true,
  imports: [ButtonComponent, DropdownComponent, RouterLink, RouterLinkActive],
  templateUrl: './user-navbar-dropdown.component.html',
  styleUrl: './user-navbar-dropdown.component.scss'
})
export class UserNavbarDropdownComponent {
  @ViewChild('userButton') buttonElement: ElementRef<HTMLElement>;

  open = false;

  constructor(private store: Store) {}

  disconnect() {
    this.store.dispatch(new DisconnectUser());
  }
}
