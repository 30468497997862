<vsk-navbar></vsk-navbar>

<div class="vsk__margin vsk__width-center">
  <h1 class="vsk__text-center">Conditions générales d'utilisation du site verymountain.fr</h1>
  <br>
  <p>La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et conditions
    d'utilisation qui suivent.
    La version actuellement en ligne de ces conditions d'utilisation est la seule opposable pendant toute la durée
    d'utilisation du site et jusqu'à ce qu'une nouvelle version la remplace.</p>

  <h4>Article 1 - Informations légales</h4>

  <p><span class="bold"> 1.1 -</span> Site (ci-après « le site ») : https://verymountain.fr</p>

  <p><span class="bold"> 1.2 -</span> Éditeur (ci-après « l'éditeur ») : SAS VERYMOUNTAIN, société par actions
    simplifiée au capital de 100.000 euros,
    dont le siège social est situé au 16 Rue Paul Chenavard, 69001 LYON. SIRET : RCS Lyon 901 349 167 00014,
    représentée par Martin CLÉMENT, en sa qualité de président.</p>
  Adresse de courrier électronique : hello&#64;verymountain.fr
  Directeur de la publication : Angelo CHIRAT

  <p><span class="bold">1.3 -</span> Conception et réalisation : SAS VERYMOUNTAIN</p>


  <p><span class="bold">1.4 -</span> Hébergeur (ci-après « l'hébergeur ») : OVH, SAS au capital de 10 174 560 €, RCS
    Lille Métropole 424 761 419 00045. Siège social : 2 rue Kellermann - 59100 Roubaix - France.</p>

  <h4>Article 2 - Accès au site</h4>

  <p>
    L'accès au site est réservé aux personnes majeures. L'éditeur se réserve le droit de demander toute justification de
    votre âge,
    notamment si vous utilisez des services de commande en ligne.

    L'accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas
    utiliser ce site et les informations ou données qui y figurent à des fins commerciales, politiques, publicitaires et
    pour toute forme de sollicitation commerciale et particulièrement l'envoi de courriers électroniques non sollicités.
  </p>

  <h4>Article 3 - Contenu du site</h4>

  <p>
    Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non,
    séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées
    pour faire fonctionner ce site et plus généralement tous les éléments reproduits ou utilisés sur le
    site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.

    Ils sont la propriété pleine et entière de l'éditeur, de ses partenaires ou sont libres de droit.
    Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout
    ou partie de ces éléments, y compris les applications informatiques, sans l'accord préalable et écrit de l'éditeur,
    sont strictement interdites. Le fait pour l'éditeur de ne pas engager de procédure dès la prise de connaissance
    de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.

    En ce qui concerne les services de commande en ligne, l'ensemble des informations présentes sur ce site
    sont valables pour la France métropolitaine (Corse incluse) ainsi que le reste de l’Europe et du monde.
  </p>

  <h4>Article 4 - Gestion du site</h4>

  <p>Pour la bonne gestion du site, l'éditeur pourra à tout moment :</p>

  <li>Suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines
    parties du site, à une catégorie déterminée d'internaute ;
  </li>
  <li>Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois
    nationales ou internationales, ou avec les règles de la Nétiquette ;
  </li>
  <li>Suspendre le site afin de procéder à des mises à jour.</li>


  <h4>Article 5 - Responsabilités</h4>

  <p>La responsabilité de l'éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption
    de fonctionnement, empêchant l'accès au site ou à une de ses fonctionnalités.
    Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre
    toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d'attaques virales par
    Internet.
    Vous êtes par ailleurs le seul responsable des sites et données que vous consultez.
    L'éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :
  </p>

  <li>Du fait de l'usage du site ou de tout service accessible via Internet ;</li>
  <li>Du fait du non-respect par vous des présentes conditions générales.</li>

  <p>L'éditeur n'est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait
    de votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait.

    Si l'éditeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de votre utilisation du site,
    il pourra retourner contre vous pour obtenir indemnisation de tous les préjudices, sommes, condamnations et frais
    qui pourraient découler de cette procédure.
  </p>

  <h4>Article 6 - Liens hypertextes</h4>

  <p>La mise en place par vous de tous liens hypertextes vers tout ou partie du site est strictement interdite,
    sauf autorisation préalable et écrite de l'éditeur, sollicitée par courriel à l'adresse suivante :
    hello&#64;verymountain.fr.

    L'éditeur est libre de refuser cette autorisation sans avoir à justifier de quelques manières que ce soit sa
    décision.
    Dans le cas où l'éditeur accorderait son autorisation, celle-ci n'est dans tous les cas que temporaire et pourra
    être retirée à tout moment, sans obligation de justification à la charge de l'éditeur.
    Dans tous les cas, tout lien devra être retiré sur simple demande de l'éditeur.

    Toute information accessible via un lien vers d'autres sites n'est pas sous le contrôle de l'éditeur qui
    décline toute responsabilité quant à leur contenu.
  </p>

  <h4>Article 7 - Protection des données à caractère personnel</h4>

  <h5>7.1 Collecte des données personnelles</h5>
  <p>Les données à caractère personnel qui sont notamment collectées sur ce site sont les suivantes :</p>

  <p class="bold">Ouverture de compte :</p>
  <li>Nom</li>
  <li>Prénom</li>
  <li>Adresse électronique</li>
  <li>Numéro de téléphone</li>
  <li>Date de naissance</li>

  <p class="bold">Connexion : </p>
  <li>Nom</li>
  <li>Prénom</li>
  <li>Données de connexion</li>
  <li>Données d'utilisation</li>
  <li>Données de localisation</li>
  <li>Données relatives au paiement</li>


  <p class="bold">Profil</p>
  <li>Adresse</li>
  <li>Numéro de téléphone</li>
  <li>Poids, taille, âge...</li>


  <p class="bold">Paiement</p>
  <p>Dans le cadre du paiement des produits et prestations proposés sur le site, notre prestataire de paiement (Stripe)
    enregistre des données financières relatives au compte bancaire ou à la carte de crédit de l'utilisateur.
  </p>

  <p class="bold">Communication</p>
  <p>Lorsque le site est utilisé pour communiquer avec d'autres membres, les données concernant les communications de
    l'utilisateur font l'objet d'une conservation temporaire.
  </p>

  <p class="bold">Cookies</p>
  <p>Les cookies sont utilisés dans le cadre de l'utilisation du site.
    L'utilisateur a la possibilité de désactiver les cookies à partir des paramètres de son navigateur ou à son arrivée
    sur le site.
  </p>

  <h5>7.2 Utilisation des données personnelles</h5>

  <p>Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services du
    site,
    leur amélioration et le maintien d'un environnement sécurisé. Plus précisément, les utilisations sont les suivantes
    :
  </p>

  <li>Accès et utilisation du site par l'utilisateur ;</li>
  <li>Gestion du fonctionnement et optimisation du site ;</li>
  <li>Organisation des conditions d'utilisation des services de paiement ;</li>
  <li>Vérification, identification et authentification des données transmises par l'utilisateur ;</li>
  <li>Mise en œuvre d'une assistance utilisateurs ;</li>
  <li>Personnalisation des services en affichant des publicités en fonction de l'historique de navigation de
    l'utilisateur, selon ses préférences ;
  </li>
  <li>Prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des
    incidents de sécurité ;
  </li>
  <li>Gestion des éventuels litiges avec les utilisateurs ;</li>
  <li>Envoi d'informations commerciales et publicitaires, en fonction des préférences de l'utilisateur.</li>

  <h5>7.3 Partage des données personnelles avec des tiers</h5>

  <p>Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants :</p>

  <li>Quand l'utilisateur utilise les services de paiement, pour la mise en œuvre de ces services,
    le site est en relation avec des sociétés bancaires et financières tierces avec lesquelles elle a passé des contrats
    ;
  </li>
  <li>Lorsque l'utilisateur publie, dans les zones de commentaires libres du site, des informations accessibles au
    public ;
  </li>
  <li>Quand l'utilisateur autorise le site web d'un tiers à accéder à ses données ;</li>
  <li>Quand le site recourt aux services de prestataires pour fournir l'assistance utilisateurs, la publicité et les
    services de paiement.
    Ces prestataires disposent d'un accès limité aux données de l'utilisateur, dans le cadre de l'exécution de ces
    prestations,
    et ont une obligation contractuelle de les utiliser en conformité avec les dispositions de la réglementation
    applicable
    en matière protection des données à caractère personnel ;
  </li>
  <li>Si la loi l'exige, le site peut effectuer la transmission de données pour donner suite aux réclamations
    présentées contre le site et se conformer aux procédures administratives et judiciaires ;
  </li>
  <li>Si le site est impliqué dans une opération de fusion, acquisition, cession d'actifs ou procédure de redressement
    judiciaire,
    il pourra être amené à céder ou partager tout ou partie de ses actifs, y compris les données à caractère personnel.
    Dans ce cas, les utilisateurs seraient informés, avant que les données à caractère personnel ne soient transférées à
    une tierce partie.
  </li>

  <h5>7.4 Sécurité et confidentialité</h5>
  <p>
    Le site met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du
    numérique pour protéger
    les données personnelles contre les altérations, destructions et accès non autorisés. Toutefois, il est à signaler
    qu'internet n'est pas un
    environnement complètement sécurisé et le site ne peut pas garantir la sécurité de la transmission ou du stockage
    des informations sur internet.
  </p>


  <h5>7.5 Mise en œuvre des droits des utilisateurs</h5>
  <p>
    En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des
    droits suivants :
  </p>

  <li>Ils peuvent mettre à jour ou supprimer les données qui les concernent en se connectant à leur compte et en
    configurant les paramètres de ce compte ;
  </li>
  <li>Ils peuvent supprimer leur compte, en allant dans la partie “Mon compte”. Il est à noter que les informations
    partagées avec d'autres utilisateurs,
    comme les publications sur les forums, notes et commentaires, peuvent rester visibles du public sur le site, même
    après la suppression de leur compte ;
  </li>
  <li>Ils peuvent exercer leur droit d'accès, pour connaître les données personnelles les concernant, en écrivant à
    l'adresse électronique suivante :
    hello&#64;verymountain.fr. Dans ce cas, avant la mise en œuvre de ce droit, le site peut demander une preuve de
    l'identité de l'utilisateur afin d'en vérifier l'exactitude ;
  </li>
  <li>Si les données à caractère personnel détenues par le site sont inexactes, ils peuvent demander la mise à jour des
    informations,
    en écrivant à l'adresse électronique suivante : hello&#64;verymountain.fr ;
  </li>
  <li>Les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois
    applicables en matière de protection des données,
    en écrivant à l'adresse électronique suivante : hello&#64;verymountain.fr.
  </li>

  <h5>7.6 Évolution de la présente clause</h5>

  <p>Le site se réserve le droit d'apporter toute modification à la présente clause relative à la protection des données
    à caractère personnel à tout moment.
    Si une modification est apportée à la présente clause de protection des données à caractère personnel, le site
    s'engage à publier la nouvelle version.
    Le site informera également les utilisateurs de la modification par messagerie électronique, dans un délai minimum
    de 15 jours avant la date d'effet.
    Si l'utilisateur n'est pas d'accord avec les termes de la nouvelle rédaction de la clause de protection des données
    à caractère personnel,
    il a la possibilité de supprimer son compte.
  </p>

  <h4>Article 8 - Cookies</h4>

  <p>Le cookie est un fichier texte qui ne permet pas d’identifier l’utilisateur, mais sert à enregistrer des
    informations relatives à la navigation de celui-ci sur le site, Le site peut collecter automatiquement des
    informations standards. Toutes les informations collectées indirectement ne seront utilisées que pour suivre le
    volume, le type et la configuration du trafic utilisant ce site, pour en développer la conception et l'agencement et
    à d'autres fins administratives et de planification et plus généralement pour améliorer le service que nous vous
    offrons.
    L’utilisateur peut refuser l’installation de ces cookies en modifiant les paramètres de son navigateur.
  </p>

  <h4>Article 9 - Photographies et représentation des produits</h4>

  <p>Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et n'engagent pas
    l'éditeur.</p>

  <h4>Article 10 - Loi applicable</h4>

  <p>Les présentes conditions d'utilisation du site sont régies par la loi française et soumises à la compétence des
    tribunaux de Lyon, sous réserve d'une attribution de compétence spécifique découlant d'un texte de loi ou
    réglementaire particulier.
  </p>

  <h4>Article 11 - Contactez-nous</h4>

  <p>Pour toute question, information sur les produits présentés sur le site, ou concernant le site lui-même, vous
    pouvez laisser un message à l'adresse suivante : hello&#64;verymountain.fr. </p>
</div>

<vsk-footer></vsk-footer>
