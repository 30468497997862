<h2>Se connecter à VeryMountain</h2>
<p>Connectez-vous à VeryMountain pour découvrir les meilleures offres de réservation de stations, hébergements et
  séjours inoubliables au ski.</p>

<p *ngIf="error" class="error">{{ error }}</p>

<form>
  <atc-input [control]="emailForm"
             errorMessage="Adresse email malformée"
             label="Email"
             placeholder="Votre adresse email"
             type="email">
  </atc-input>

  <atc-input [control]="passwordForm"
             errorMessage="Champs obligatoire"
             label="Mot de passe"
             labelLink="Mot de passe oublié ?"
             link="/mot-de-passe-oublie"
             placeholder="**********"
             type="password">
  </atc-input>
</form>

<div class="cta">
  <atc-button (click)="connect()"
              [disabled]="loading"
              size="large">
    Se connecter
  </atc-button>

  <div class="separator">
    <div class="line"></div>
    <p>OU</p>
    <div class="line"></div>
  </div>

  <asl-google-signin-button
    logo_alignment="center"
    shape="circle"
    size="large"
    text="continue_with"
    theme="outline"
    type="standard">
  </asl-google-signin-button>

  <div class="new-client">
    <p>Vous êtes nouveaux ?</p>
    <a *ngIf="redirectionAreLink"
       class="link"
       queryParamsHandling="preserve"
       routerLink="/inscription"
       routerLinkActive="true">
      Inscrivez-vous
    </a>
    <button (click)="switchLoginTypeChange.emit()"
            *ngIf="!redirectionAreLink"
            class="link">
      Inscrivez-vous
    </button>
  </div>
</div>
