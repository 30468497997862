import { DecimalPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  ButtonComponent,
  InputCheckboxComponent,
  LoaderComponent,
  PopupComponent
} from 'atomic-lib';
import { Moment } from 'moment/moment';
import { combineLatest } from 'rxjs';
import { DurationEquipmentDropdownComponent } from '../../../../shared/dropdown/duration-equipment-dropdown/duration-equipment-dropdown.component';
import { FirstSkiDateDropdownComponent } from '../../../../shared/dropdown/first-ski-date-dropdown/first-ski-date-dropdown.component';
import { PackEquipmentDropdownComponent } from '../../../../shared/dropdown/pack-equipment-dropdown/pack-equipment-dropdown.component';
import { ParticipantDropdownComponent } from '../../../../shared/dropdown/participant-dropdown/participant-dropdown.component';
import { SkiCriteria } from '../../../../shared/dropdown/ski-pass-dropdown/ski-pass-dropdown.component';
import { Price } from '../../../../shared/models/cart/price';
import { Participant } from '../../../../shared/models/participant/participant';
import { Period } from '../../../../shared/models/period';
import { Pack } from '../../../../shared/models/ski-equipment/pack';
import { AddSkiEquipmentToCart } from '../../cart.action';

@Component({
  selector: 'vsk-equipment-popup',
  standalone: true,
  imports: [
    PopupComponent,
    ButtonComponent,
    LoaderComponent,
    NgIf,
    FirstSkiDateDropdownComponent,
    ParticipantDropdownComponent,
    DecimalPipe,
    PackEquipmentDropdownComponent,
    DurationEquipmentDropdownComponent,
    InputCheckboxComponent
  ],
  templateUrl: './equipment-popup.component.html',
  styleUrl: './equipment-popup.component.scss'
})
export class EquipmentPopupComponent implements OnInit {
  loading = true;
  noPack = false;
  error = '';
  equipmentPrice: Price;
  allParticipants: Participant[] = [];
  eligibleParticipants: Participant[] = [];
  nbDays: number[] = [];
  resortId: number;

  selectedPack: Pack;
  selectedDuration: number;
  selectedParticipants: Participant[] = [];
  selectedFirstDay: Moment;

  shoesForm: FormControl<boolean | null> = new FormControl<boolean>(true);
  helmetForm: FormControl<boolean | null> = new FormControl<boolean>(false);

  @Input() criteria: SkiCriteria;
  @Output() closeChangeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private store: Store) {}

  _period: Period;

  @Input() set period(period: Period) {
    if (period) {
      this._period = period;
      const nbDays =
        this._period.endDate.diff(this._period.startDate, 'day') + 1;
      this.nbDays = Array.from(Array(nbDays).keys()).slice(2, nbDays);
    }
  }

  @Input() set participants(participants: Participant[]) {
    this.allParticipants = participants;
    this.eligibleParticipants = participants;
  }

  ngOnInit(): void {
    this.helmetForm.valueChanges.subscribe(() => this.fetchPricing());
    this.shoesForm.valueChanges.subscribe(() => this.fetchPricing());
  }

  changePack(pack: Pack) {
    this.selectedPack = pack;
    this.eligibleParticipants = this.allParticipants.filter(
      (participant) =>
        (this.selectedPack.offer?.minAge || 13 < participant.age) &&
        (this.selectedPack.offer?.maxAge || 99) >= participant.age
    );
    this.fetchPricing();
  }

  changeDuration(duration: number) {
    this.selectedDuration = duration;
    this.fetchPricing();
  }

  changeSelectedParticipants(participants: Participant[]) {
    this.selectedParticipants = participants;
    this.fetchPricing();
  }

  changeFirstDay(firstDay: Moment) {
    this.selectedFirstDay = firstDay;
    this.fetchPricing();
  }

  addToCart() {
    this.loading = true;

    combineLatest(
      this.selectedParticipants.map((participant) => {
        return this.store.dispatch(
          new AddSkiEquipmentToCart({
            participantSession: participant,
            skisetResortId: this.selectedPack.skiSetResortId,
            skisetPackId: this.shoesForm.value
              ? this.selectedPack.shoesPackId
              : this.selectedPack.packId,
            linkedPackId: this.helmetForm.value
              ? this.selectedPack.helmetPackId
              : null,
            resortId: this.resortId,
            firstDay: this.selectedFirstDay,
            duration: this.selectedDuration || this.criteria.skiPassDuration,
            isSelected: true
          })
        );
      })
    ).subscribe(
      () => {
        this.closeChangeEvent.emit();
      },
      () => {
        this.error =
          "Une erreur s'est produite, veuillez réessayer dans quelques instants ...";
        this.loading = false;
      }
    );
  }

  noPackAvailable() {
    this.noPack = true;
    this.error = 'Location de matériel indisponible pour vos dates ...';
    this.loading = false;
  }

  private fetchPricing() {
    if (!this.selectedPack) {
      return;
    }

    this.equipmentPrice = {
      price: this.calculateTotalPrice(this.selectedPack.netPrice),
      publicPrice: this.calculateTotalPrice(this.selectedPack.publicPrice)
    };
    this.loading = false;
  }

  private calculateTotalPrice(price: number): number {
    let total = price;

    if (this.shoesForm.value) {
      total += this.selectedPack.priceShoes;
    }

    if (this.helmetForm.value) {
      total += this.selectedPack.priceHelmet
        ? this.selectedPack.priceHelmet
        : 0;
    }

    return total * this.selectedParticipants.length;
  }
}
