import { DecimalPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  ButtonComponent,
  CarouselImagesComponent,
  LazyLoadImageDirective,
  LoaderComponent,
  PopupComponent,
  TagComponent,
  ToHourPipe
} from 'atomic-lib';
import { Moment } from 'moment';
import { ExperienceService } from '../../../../service/experience.service';
import { FirstSkiDateDropdownComponent } from '../../../../shared/dropdown/first-ski-date-dropdown/first-ski-date-dropdown.component';
import { ParticipantDropdownComponent } from '../../../../shared/dropdown/participant-dropdown/participant-dropdown.component';
import { TimeslotDropdownComponent } from '../../../../shared/dropdown/timeslot-dropdown/timeslot-dropdown.component';
import { Experience } from '../../../../shared/models/activity/experience';
import { Price } from '../../../../shared/models/cart/price';
import { Criteria } from '../../../../shared/models/criteria';
import { Participant } from '../../../../shared/models/participant/participant';
import { Internship } from '../../../../shared/models/partner/internship';
import { TimeSlotExperience } from '../../../../shared/models/partner/time-slot-experience';
import { Period } from '../../../../shared/models/period';
import { DateUtils } from '../../../../utils/date-utils';
import { PackageUtils } from '../../../../utils/package-utils';
import { AddToCart } from '../../cart.action';

@Component({
  selector: 'vsk-experience-popup',
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    PopupComponent,
    DecimalPipe,
    ButtonComponent,
    LazyLoadImageDirective,
    NgTemplateOutlet,
    FirstSkiDateDropdownComponent,
    ParticipantDropdownComponent,
    TimeslotDropdownComponent,
    TagComponent,
    ToHourPipe,
    TranslateModule,
    CarouselImagesComponent,
    NgForOf
  ],
  templateUrl: './experience-popup.component.html',
  styleUrl: './experience-popup.component.scss'
})
export class ExperiencePopupComponent {
  loading = true;
  showPictures = false;
  error = '';
  price?: Price;
  experienceId: number;
  step: 'description' | 'book' = 'description';

  days: Moment[] = [];
  timeSlotsForSelectedDay: (TimeSlotExperience | Internship)[] = [];
  allParticipants: Participant[] = [];
  eligibleParticipants: Participant[] = [];

  selectedParticipants: Participant[] = [];
  selectedFirstDay: Moment;
  selectedTimeSlot: TimeSlotExperience | Internship;
  maxParticipants = 0;

  @Input() resort: string;
  @Output() closeChangeEvent: EventEmitter<void> = new EventEmitter<void>();
  protected readonly PackageUtils = PackageUtils;

  constructor(
    private experienceService: ExperienceService,
    private store: Store
  ) {}

  @Input() set participants(participants: Participant[]) {
    this.allParticipants = participants;
    this.eligibleParticipants = participants;
  }

  _experience: Experience;

  @Input() set experience(experience: Experience | undefined) {
    if (!experience) {
      return;
    }

    this.experienceId = experience.id;
    this.fetchExperience();
  }

  _period: Period;

  @Input() set period(period: Period) {
    if (!period) {
      return;
    }

    this._period = period;
    this.fetchExperience();
  }

  getPictureByIndex(index: number) {
    if (!this._experience?.pictures?.length) {
      return '';
    }

    return this._experience?.pictures[
      index % this._experience?.pictures.length
    ];
  }

  getDescription(slide: string) {
    const url = decodeURI(slide);
    const urlFragments = url.split('?')[0].split('/');
    const namePicture = urlFragments[urlFragments.length - 1].split('%2F')[2];
    return namePicture?.replace(/-/g, ' ')?.replace('.webp', '') || '';
  }

  remainingSlots() {
    if (!this.selectedTimeSlot) {
      return 0;
    }

    if (this.selectedTimeSlot instanceof TimeSlotExperience) {
      return this.selectedTimeSlot.slotsAvailable;
    }

    return this.selectedTimeSlot.slotAvailable;
  }

  changeFirstDay(firstDay: Moment) {
    this.selectedFirstDay = firstDay;
    this.timeSlotsForSelectedDay = DateUtils.combineTimeSlots(
      this._experience.timeSlots,
      this._experience.internships,
      firstDay
    );

    this.selectedTimeSlot = this.timeSlotsForSelectedDay[0];

    if (this.selectedTimeSlot instanceof TimeSlotExperience) {
      this.maxParticipants = this.selectedTimeSlot.slotsAvailable;
    }

    if (this.selectedTimeSlot instanceof Internship) {
      this.maxParticipants = this.selectedTimeSlot.slotAvailable;
    }
  }

  changeTimeSlot(timeSlot: TimeSlotExperience | Internship) {
    this.selectedTimeSlot = timeSlot;
    this.fetchPricing();
  }

  changeSelectedParticipants(participants: Participant[]) {
    this.selectedParticipants = participants;
    this.fetchPricing();
  }

  addToCart() {
    if (!this._experience) {
      return;
    }

    if (!this.selectedParticipants?.length) {
      return;
    }

    if (!this.selectedFirstDay) {
      return;
    }

    if (!this.selectedTimeSlot) {
      return;
    }

    const participants = this.selectedParticipants.map(
      (participant) => participant.uuid
    );

    this.loading = true;

    this.store
      .dispatch(
        new AddToCart(
          this._experience?.id,
          participants,
          this.selectedFirstDay,
          this.resort,
          this.selectedTimeSlot.id ||
            (this.selectedTimeSlot as TimeSlotExperience).timeSlotModelId
        )
      )
      .subscribe(
        () => this.closeChangeEvent.emit(),
        () => {
          this.error =
            'Une erreur est survenue, veuillez réessayer dans quelques instants';
          this.loading = false;
        }
      );
  }

  close() {
    if (!this.showPictures) {
      this.closeChangeEvent.emit();
    }
  }

  private fetchExperience() {
    if (!this.experienceId) {
      return;
    }

    if (!this._period?.isValid) {
      return;
    }

    const criteria = {
      startDate: this._period.startDate,
      endDate: this._period.endDate
    } as Criteria;

    this.experienceService
      .getExperienceById(criteria, this.experienceId)
      .subscribe(
        (exp) => {
          this._experience = exp;
          const dates = [
            ...this._experience.timeSlots.map((timeSlot) => timeSlot.dateStart),
            ...this._experience.internships.map(
              (internship) => internship.dateStart
            )
          ];

          this.days = DateUtils.uniqueMoment(dates, 'DD-MM-YYYY');

          this.eligibleParticipants = this.allParticipants.filter(
            (participant) =>
              !!this._experience.ageRanges.find((ageRange) =>
                DateUtils.isSameCategoryByBirthdate(
                  participant.birthdate,
                  ageRange
                )
              )
          );

          this.changeFirstDay(this.days[0]);
          this.changeSelectedParticipants(this.eligibleParticipants);
        },
        () => (this.loading = false)
      );
  }

  private fetchPricing() {
    if (!this._experience) {
      return;
    }

    if (!this.selectedParticipants?.length) {
      return;
    }

    if (!this.selectedTimeSlot) {
      return;
    }

    this.loading = true;
    this.price = undefined;
    this.experienceService
      .getPricingForExperience(
        this._experience.id,
        this.selectedParticipants.map((participant) =>
          participant.birthdate.format('YYYY-MM-DD')
        ),
        this.selectedFirstDay
      )
      .subscribe(
        (price) => {
          this.price = price;
          this.loading = false;
        },
        () => (this.loading = false)
      );
  }
}
