import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { Observable, filter, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FiltersState } from '../../filters.state';
import { MarkerWrapper } from '../models/marker-wrapper';
import {
  LocationType,
  PointOfInterest
} from '../models/resort/point-of-interest';
import { Resort } from '../models/resort/resort';
import { RxjsComponent } from './rxjs.component';

export interface ElementId {
  id: number | string;
}

export interface MapConfiguration extends google.maps.MapOptions {
  readonly mapId?: string;
}

@Component({
  standalone: true,
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class MapComponent<T extends ElementId>
  extends RxjsComponent
  implements AfterViewInit
{
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  map: GoogleMap;

  @Select(FiltersState.resort) resort$: Observable<Resort>;

  refreshOnMoveMap: FormControl<boolean | null> = new FormControl<boolean>(
    true
  );
  refreshMapTimer: NodeJS.Timeout;
  isManualScroll = false;
  resultsBoundsChangedEvent = true;
  focusOnPinpoint = true;
  pinpoint: T | undefined;
  classPinpoint = 'pinpoint';
  markers: MarkerWrapper<T>[];
  bounds: google.maps.LatLngBounds;
  zoom = 12;
  fullMap = false;
  center: google.maps.LatLngLiteral = {
    lat: 45.052825927734375,
    lng: 2.7113842964172363
  };
  options: MapConfiguration = {
    scaleControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: this.windowResource.isDesktop,
    mapTypeControl: false,
    maxZoom: 20,
    minZoom: 6,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    clickableIcons: false,
    styles: [
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [{ visibility: 'on' }]
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
      }
    ]
  };

  constructor(
    public windowResource: WindowResource,
    public router: Router
  ) {
    super();
  }

  ngAfterViewInit() {
    if (!this.map) {
      setTimeout(() => this.ngAfterViewInit(), 800);
      return;
    }

    if (this.focusOnPinpoint) {
      this.placeMarkersOnMap();
      this.fitBounds();
    }

    merge(
      this.map.mapDragend,
      this.map.zoomChanged.pipe(filter(() => this.zoom !== this.map.getZoom()))
    )
      .pipe(debounceTime(800))
      .subscribe(() => {
        this.zoom = this.map.getZoom() as number;

        if (this.resultsBoundsChangedEvent) {
          this.resultsBoundsChangedEvent = false;
          return;
        }

        this.markers = [];
        this.boundsChanged();
      });
  }

  setZoom(): void {
    this.zoom = this.map?.getZoom() as number;
  }

  getOptions(marker: google.maps.Marker) {
    return (marker as any).options;
  }

  openInfoWindow(marker: MarkerWrapper<T>, markerMap: MapMarker): void {
    if (!marker.value) {
      return;
    }

    if (!marker.canHover && marker.redirect) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([marker.redirect.link], {
          queryParams: marker.redirect.params,
          queryParamsHandling: 'merge'
        })
      );
      window.open(url, '_blank');
      return;
    }

    if (marker.value === this.pinpoint) {
      this.outMarker(marker);
      this.pinpoint = undefined;
      this.infoWindow.close();
    } else {
      this.pinpoint = marker.value;
      this.overMarker(marker);
      this.infoWindow.open(markerMap);
    }
  }

  overMarker(marker: MarkerWrapper<T> | undefined): void {
    if (marker?.canHover) {
      const label: google.maps.MarkerLabel =
        marker?.getLabel() as google.maps.MarkerLabel;
      marker.setLabel({
        color: 'white',
        text: label.text,
        fontWeight: '500',
        fontSize: '11px',
        className: 'pinpoint-hover',
        fontFamily: 'General'
      });

      marker.set('options', { zIndex: 1000 });
      marker.set('zIndex', 1000);
    }
  }

  outMarker(marker: MarkerWrapper<T> | undefined): void {
    if (marker?.value.id === this.pinpoint?.id) {
      return;
    }

    if (marker?.canHover) {
      const label: google.maps.MarkerLabel =
        marker?.getLabel() as google.maps.MarkerLabel;
      marker.setLabel({
        color: '#1f1f1f',
        text: label.text,
        fontWeight: '500',
        fontSize: '11px',
        className: this.classPinpoint,
        fontFamily: 'General'
      });

      marker.set('options', { zIndex: 100 });
      marker.setZIndex(100);
    }
  }

  boundsChanged() {
    const bounds = this.map.getBounds()?.toJSON();

    if (bounds && this.refreshOnMoveMap.value) {
      if (this.refreshMapTimer) {
        clearTimeout(this.refreshMapTimer);
      }

      this.refreshMapTimer = setTimeout(
        () =>
          this.boundsChangedAction(
            bounds,
            this.refreshOnMoveMap.value as boolean
          ),
        300
      );
    }
  }

  protected abstract boundsChangedAction(
    bounds: google.maps.LatLngBoundsLiteral,
    refresh: boolean
  ): void;

  protected placeMarkersOnMap() {
    if (!this.map || !this.markers?.length) {
      return;
    }

    this.bounds = new google.maps.LatLngBounds();
    this.markers.forEach((marker) => {
      const position = (marker as any).position;
      if (position.lat() !== 0 || position.lng() !== 0) {
        this.bounds.extend(position);
      }
    });
  }

  protected fitBounds() {
    this.resultsBoundsChangedEvent = true;
    if (this.bounds) {
      this.map.fitBounds(this.bounds);
    }
  }

  protected refreshPov() {
    setTimeout(() => {
      this.bounds = new google.maps.LatLngBounds();
      this.markers.forEach((marker) => {
        const position = (marker as any).position;
        if (position.lat() !== 0 || position.lng() !== 0) {
          this.bounds.extend(position);
        }
      });
      this.map.fitBounds(this.bounds);
    }, 500);
  }

  protected mapToMarker(points: PointOfInterest[]) {
    return points.map(
      (point) =>
        new MarkerWrapper<PointOfInterest>(point, {
          clickable: true,
          position: {
            lat: point.latitude,
            lng: point.longitude
          },
          icon: {
            url: `../../../../assets/new-design/img/map/${point.type === LocationType.ESF ? 'esf.png' : 'cable-car.png'}`,
            scaledSize: new google.maps.Size(30, 35, 'px', 'px'),
            origin: new google.maps.Point(0, 0)
          },
          title: point.type === LocationType.ESF ? 'ESF' : 'Départ de piste',
          optimized: false,
          zIndex: 2000
        })
    );
  }
}
