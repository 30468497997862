<div class="content">
  <div class="buttons-navigation">
    <button (click)="previousChange.emit()"
            class="back">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#chevron-left'"></use>
      </svg>

      <p>Retour</p>
    </button>

    <atc-button (click)="nextChange.emit()"
                [iconRightSide]="true"
                icon="chevron-right">
      Suivant
    </atc-button>
  </div>

  <h3>Maintenant, vivez un séjour véritablement unique.</h3>

  <div *ngIf="activities.length"
       class="group-title">
    <h5>Mes activités</h5>

    <div *ngFor="let activity of activities"
         class="experiences">
      <div class="experience">
        <img [alt]="activity.item?.experience.name" [src]="activity.item?.experience.pictures[0]">

        <div class="content-experience">
          <div class="titles">
            <p class="activity-name">{{ activity.item?.experience.activity.name }}</p>

            <p class="experience-name">
              {{ activity.item?.experience.name }} ·
              <ng-container *ngIf="!activity.internshipId"> {{ activity.startDate.format('dddd DD MMMM') }}
                de {{ activity.startDate.format('HH:mm') }}
                à {{ activity.startDate.clone().add(activity.item?.experience.duration, 'minutes').format('HH:mm') }}
              </ng-container>
              <ng-container *ngIf="!!activity.internshipId">Du {{ activity.startDate.format('dddd DD MMMM') }}
                au {{ activity.endDate.format('dddd DD MMMM') }}
              </ng-container>
            </p>

            <p class="activity-name">{{ participantsToTitle(activity.participants) }}</p>
          </div>

          <div class="pricing-and-cta">
            <div class="price">
              <h5>{{ activity.price | number: '1.2-2' : 'fr' }} €</h5>
            </div>

            <atc-button (click)="deleteActivity(activity.uuid)"
                        color="white"
                        icon="trash">
            </atc-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="group-title">
    <h5>Expériences à vivre autour de votre hébergement</h5>
  </div>

  <div *ngIf="!loading && allExperiences.length" class="filters-buttons">
    <button (click)="chooseCategory('all')"
            [ngClass]="{'selected': selectedCategory === 'all'}"
            class="tag">
      Tout
    </button>

    <button (click)="chooseCategory(category)"
            *ngFor="let category of categories"
            [ngClass]="{'selected': category === selectedCategory}"
            class="tag">
      {{ category }}
    </button>
  </div>

  <h3 *ngIf="!loading && !allExperiences.length">
    Malheureusement nous n'avons pas encore d'activités à vous proposer pour votre destination ...
  </h3>


  <ng-container *ngIf="loading && !allExperiences?.length">
    <div class="filters-buttons">
      <div *ngFor="let index of [0, 1, 2, 3]" class="tag h-[40px]" vskSkeletonLoad>
        Category
      </div>
    </div>

    <div class="experiences">
      <div *ngFor="let index of [0, 1, 2, 3, 4, 5]"
           class="experience h-[120px] w-full" vskSkeletonLoad>
      </div>
    </div>
  </ng-container>

  <div *ngFor="let experience of filteredExperiences"
       class="experiences">
    <div class="experience">
      <img [alt]="experience.name" [src]="experience.pictures[0]">

      <div class="content-experience">
        <div class="titles">
          <p class="activity-name">{{ experience.activity.name }}</p>
          <p class="experience-name">{{ experience.name }}</p>
        </div>

        <div class="pricing-and-cta">
          <div class="price">
            <h5>
              Dès {{ PackageUtils.findMinPriceForMaxPackage(experience.packages, participants) | number: '1.2-2' : 'fr' }}
              €</h5>
            <p>/par per.</p>
          </div>

          <atc-button (click)="selectedExperience = experience">
            Découvrir
          </atc-button>
        </div>
      </div>
    </div>
  </div>
</div>

<vsk-experience-popup (closeChangeEvent)="selectedExperience = undefined"
                      *ngIf="selectedExperience"
                      [experience]="selectedExperience"
                      [participants]="participants"
                      [period]="period$ | async"
                      [resort]="resort">
</vsk-experience-popup>
