import { AsyncPipe, NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { StepComponent, StepperComponent } from 'atomic-lib';
import { Moment } from 'moment/moment';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { FiltersState } from '../../filters.state';
import { ShopService } from '../../service/shop.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { Cart } from '../../shared/models/cart/cart';
import { Participant } from '../../shared/models/participant/participant';
import { Period } from '../../shared/models/period';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { CartCardSummaryMobileComponent } from './cart-card-summary-mobile/cart-card-summary-mobile.component';
import { CartCardSummaryComponent } from './cart-card-summary/cart-card-summary.component';
import { FetchCart } from './cart.action';
import { CartState } from './cart.state';
import { StepCartActivityComponent } from './step-cart-activity/step-cart-activity.component';
import { StepCartPackageComponent } from './step-cart-package/step-cart-package.component';
import { StepCartPaymentComponent } from './step-cart-payment/step-cart-payment.component';

export enum Step {
  PACKAGE = 0,
  ACTIVITIES = 1,
  PAYMENT = 2,
  CONFIRMATION = 4
}

@Component({
  selector: 'vsk-cart',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    NgOptimizedImage,
    StepComponent,
    StepperComponent,
    CartCardSummaryComponent,
    StepCartPackageComponent,
    StepCartActivityComponent,
    NgClass,
    StepCartPaymentComponent,
    NavbarComponent,
    CartCardSummaryMobileComponent
  ],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss'
})
export class CartComponent extends RxjsComponent {
  @Select(AppState.isUserLoggedIn) isLoggedIn$: Observable<boolean>;
  @Select(CartState.cart) cart$: Observable<Cart>;
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;
  @Select(FiltersState.startDate) startDate$: Observable<Moment>;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;

  readonly Step = Step;

  step: Step = Step.PACKAGE;

  constructor(
    private shopService: ShopService,
    private store: Store
  ) {
    super();

    document.title = 'Récapitulatif de votre séjour - VeryMountain';

    // Stripe script
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    head.insertBefore(script, head.firstChild);

    this.register(
      combineLatest(
        this.sessionId$.pipe(filter((sessionId) => sessionId?.length > 0)),
        this.participants$.pipe(
          filter((participants) => participants.length > 0)
        )
      )
        .pipe(debounceTime(100))
        .subscribe(([sessionId]) =>
          this.store.dispatch(new FetchCart(sessionId))
        )
    );
  }

  isDone(step: Step) {
    return this.step > step;
  }

  next() {
    this.step++;

    if (this.step === Step.PAYMENT) {
      this.register(
        combineLatest([
          this.isLoggedIn$,
          this.cart$.pipe(filter((cart) => !cart.isEmpty)),
          this.sessionId$
        ])
          .pipe(
            filter(([isLogged]) => isLogged),
            switchMap(([, cart, sessionId]) =>
              this.shopService.syncUserAndSession(sessionId, cart.getResort())
            )
          )
          .subscribe()
      );
    }
  }

  previous() {
    this.step--;
  }
}
