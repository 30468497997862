import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { CustomField } from './custom-field';

export class Participant {
  index = 0;
  sessionId: string;
  uuid = uuidv4();
  firstname: string;
  lastname: string;
  birthdate: Moment;
  email: string;
  cardSkidata: string;
  cardAxess: string;
  hasCard: boolean;
  weight: number | null;
  height: number | null;
  shoeSize: number | null;
  student = false;
  customFields: CustomField[] = [];

  constructor(obj: Partial<Participant>) {
    Object.assign(this, obj);
    this.customFields = this.customFields.map(
      (customField) => new CustomField({ ...customField })
    );
    if (this.birthdate) {
      this.birthdate = moment(this.birthdate, 'YYYY-MM-DD');
    }
  }

  get age() {
    if (!this.birthdate) {
      return 0;
    }

    return moment().diff(this.birthdate, 'year');
  }

  isValid(): boolean {
    if (!this.birthdate) {
      return false;
    }

    return this.birthdate.isValid();
  }
}
