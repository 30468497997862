<div *ngFor="let control of controls"
     class="participant">
  <p>Voyageur {{ control.participant.index }}</p>

  <div class="form">
    <atc-input (focusOut)="changeParticipantFirstName(control)"
               [control]="control.firstNameControl"
               [placeholder]="control.participant.firstname"
               type="text">
    </atc-input>

    <atc-select (selectChange)="changeParticipantAge(control)"
                [control]="control.ageControl"
                [optionElements]="ageOptions">
    </atc-select>
  </div>
</div>
