import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { StepComponent, StepperComponent } from 'atomic-lib';
import { Observable, combineLatest, filter } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { GenerateNewSessionId } from '../../filters.action';
import { FiltersState } from '../../filters.state';
import { CartAccommodationService } from '../../service/cart-accommodation.service';
import { CartActivityService } from '../../service/cart-activity.service';
import { CartSkiEquipmentService } from '../../service/cart-ski-equipment.service';
import { ContactService } from '../../service/contact.service';
import { PaymentService } from '../../service/payment.service';
import { PromoService } from '../../service/promo.service';
import { Cart } from '../../shared/models/cart/cart';
import { MessageReclamation } from '../../shared/models/message-reclamation';
import { Participant } from '../../shared/models/participant/participant';
import { Period } from '../../shared/models/period';
import { UserSession } from '../../shared/models/user-session';
import { CartCardSummaryMobileComponent } from '../cart/cart-card-summary-mobile/cart-card-summary-mobile.component';
import { CartCardSummaryComponent } from '../cart/cart-card-summary/cart-card-summary.component';
import { FetchCart } from '../cart/cart.action';
import { CartState } from '../cart/cart.state';
import { StepConfirmationComponent } from './step-confirmation/step-confirmation.component';

@Component({
  selector: 'vsk-confirmation-order',
  standalone: true,
  imports: [
    AsyncPipe,
    CartCardSummaryComponent,
    NgOptimizedImage,
    StepComponent,
    StepperComponent,
    StepConfirmationComponent,
    RouterLink,
    RouterLinkActive,
    CartCardSummaryMobileComponent
  ],
  templateUrl: './confirmation-order.component.html',
  styleUrl: './confirmation-order.component.scss'
})
export class ConfirmationOrderComponent {
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(FiltersState.period) period$: Observable<Period>;

  loading = true;
  sessionId: string;
  promoCode: string;
  isPaymentIncomplete = false;
  cart: Cart = new Cart({});

  constructor(
    private paymentService: PaymentService,
    private store: Store,
    private contactService: ContactService,
    private cartLockService: CartActivityService,
    private cartAccommodationService: CartAccommodationService,
    private cartSkiEquipmentService: CartSkiEquipmentService,
    private promoService: PromoService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParamMap
      .pipe(
        filter((params) => !!params.get('sessionIdPaid')),
        tap(
          (params) => (this.sessionId = params.get('sessionIdPaid') as string)
        ),
        tap(
          (params) =>
            (this.promoCode = params.get('promoCode')
              ? (params.get('promoCode') as string)
              : '')
        ),
        debounceTime(500),
        switchMap((params) => {
          this.sessionId = params.get('sessionIdPaid') as string;
          return this.paymentService.completePayment({
            sessionId: this.sessionId,
            promoCode: params.get('promoCode'),
            paymentIntent: params.get('payment_intent') as string,
            ecoTourismChart: this.store.selectSnapshot(
              CartState.isEcoTourismChartChecked
            )
          });
        }),
        debounceTime(500),
        switchMap(() =>
          combineLatest([
            this.cartLockService.getItems(this.sessionId, true),
            this.cartAccommodationService.getItems(this.sessionId, true),
            this.cartSkiEquipmentService.getItems(this.sessionId, true),
            this.promoService.setPromoCode(this.promoCode),
            this.store.dispatch(new GenerateNewSessionId())
          ])
        )
      )
      .subscribe(
        ([itemsActivity, itemsAccommodation, itemsSkiEquipment, promoCode]) => {
          this.cart = new Cart({
            sessionId: this.sessionId,
            itemsActivity,
            itemsAccommodation,
            itemsSkiEquipment,
            promoCode
          });
          this.store.dispatch(new FetchCart(this.sessionId));
          this.loading = false;
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          let message = 'Erreur lors de la confirmation de commande';
          if (error.status === 402) {
            message = 'Paiement incomplet';
            this.isPaymentIncomplete = true;
          }

          const user = this.store.selectSnapshot(AppState.user) as UserSession;
          this.contactService
            .sendReclamation(
              new MessageReclamation({
                transactionId: this.sessionId,
                firstName: user.firstname,
                lastName: user.lastname,
                userId: user.id,
                message:
                  `${message} (email utilisateur : ` +
                  user.email +
                  ') : \n\n' +
                  JSON.stringify(error)
              })
            )
            .subscribe();
        }
      );
  }
}
