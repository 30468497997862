<a [routerLink]="getLink()"
   queryParamsHandling="merge"
   routerLinkActive="true">
  <img [alt]="name" [src]="background" class="background">

  <div *ngIf="phraseStyle === 'big-first'" class="phrase">
    <h2>{{ bigPhrase }}</h2>
    <p>{{ smallPhrase }}</p>
  </div>

  <div *ngIf="phraseStyle === 'small-first'" class="phrase">
    <p>{{ smallPhrase }}</p>
    <h2>{{ bigPhrase }}</h2>
  </div>

  <div class="info-resort">
    <div class="logo-container">
      <img [alt]="'Logo station ' + name" [src]="icon">
    </div>

    <div class="name-and-region">
      <h5>{{ name }}</h5>
      <p>{{ region }}</p>
    </div>
  </div>
</a>
