import { NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ButtonComponent } from 'atomic-lib';

@Component({
  selector: 'vsk-menu-mobile',
  standalone: true,
  imports: [RouterLink, ButtonComponent, RouterLinkActive, NgIf],
  templateUrl: './menu-mobile.component.html',
  styleUrl: './menu-mobile.component.scss'
})
export class MenuMobileComponent {
  @HostBinding('class') class = '';

  @Input() connected = false;
  @Output() closeChange: EventEmitter<void> = new EventEmitter<void>();

  @Input() set closing(close: boolean) {
    this.class = close ? 'close' : '';
  }
}
