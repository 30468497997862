<atc-popup (closeChanged)="refreshChanged.emit(false)"
           [height]="windowResource.isDesktop ? '600px' : ''"
           [width]="windowResource.isDesktop ? '600px' : ''">
  <p class="text-center" header>Filtres</p>

  <div body class="flex flex-col gap-y-[40px]">
    <div class="flex flex-col gap-y-[20px]">
      <h5>Inclus dans mon séjour</h5>
      <div class="grid grid-cols-2 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of tripIncludeFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Massif</h5>
      <vsk-search-region-form (regionsSelectedChanged)="regionsChanged($event)"
                              [regionsSelected]="regions">
      </vsk-search-region-form>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Type d'hébergement</h5>
      <div class="grid grid-cols-3 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of accommodationTypeFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Prix</h5>
      <atc-range-input
        (valueChange)="optionReceived($event)"
        [displayedMaxValue]="selectedMaxValue"
        [displayedMinValue]="selectedMinValue"
        [maxValue]="maxValueRange"
        [minValue]="minValueRange"
        [typeOfValues]="'price'">
      </atc-range-input>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Équipements</h5>
      <div class="grid grid-cols-3 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of equipmentsFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Service</h5>
      <div class="grid grid-cols-3 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of servicesFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Accessibilité</h5>
      <div class="grid grid-cols-3 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of accessFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>

    <div class="flex flex-col gap-y-[20px]">
      <h5>Label de station</h5>
      <div class="grid grid-cols-3 gap-[8px]">
        <atc-input-checkbox *ngFor="let element of resortLabelsFilter"
                            [control]="getControl(element.control)">
          {{ element.label }}
        </atc-input-checkbox>
      </div>
    </div>
  </div>

  <div class="flex flex-1 justify-between" footer>
    <button (click)="clearFiltersChanged.emit()" class="clear">
      Tout effacer
    </button>

    <atc-button (click)="refreshChanged.emit(true)"
                color="secondary"
                icon="checkmark"
                size="medium">
      Appliquer
    </atc-button>
  </div>
</atc-popup>

