import { Injectable } from '@angular/core';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store
} from '@ngxs/store';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FiltersState } from '../../filters.state';
import { LocalStorageResource } from '../../resource/local-storage.resource';
import { CartAccommodationService } from '../../service/cart-accommodation.service';
import { CartActivityService } from '../../service/cart-activity.service';
import { CartSkiEquipmentService } from '../../service/cart-ski-equipment.service';
import { PromoService } from '../../service/promo.service';
import { Establishment } from '../../shared/models/accommodation/establishment';
import { ItemAccommodation } from '../../shared/models/accommodation/item-accommodation';
import { Cart } from '../../shared/models/cart/cart';
import { EcoChart } from '../../shared/models/cart/eco-chart';
import { Participant } from '../../shared/models/participant/participant';
import { PromoCode } from '../../shared/models/promo-code';
import { DateUtils } from '../../utils/date-utils';
import {
  AddAccommodationToCart,
  AddPromoCode,
  AddSkiEquipmentToCart,
  AddToCart,
  DeleteEquipment,
  DeleteItemForParticipant,
  DeletePromoCode,
  DepositMode,
  FetchCart,
  InitCart,
  RemoveItemsToCart,
  RemoveSkiEquipmentFromCart,
  UpdateAccommodationRemarks,
  UpdateEcoTourismChart
} from './cart.action';

const PROMO = 'promo';

export interface CartStateModel {
  cart: Cart;
  timer: Moment | null;
  promoCode: PromoCode | null;
  ecoTourismChart: EcoChart;
  deposit: boolean;
}

@State<CartStateModel>({
  name: 'cart',
  defaults: {
    cart: new Cart({}),
    timer: null,
    promoCode: null,
    ecoTourismChart: LocalStorageResource.IsEcoChart(),
    deposit: false
  }
})
@Injectable()
export class CartState {
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;

  constructor(
    private store: Store,
    private promoService: PromoService,
    private cartActivityService: CartActivityService,
    private cartSkiEquipmentService: CartSkiEquipmentService,
    private cartAccommodationService: CartAccommodationService
  ) {}

  @Selector()
  static cart(state: CartStateModel): Cart {
    return new Cart({
      ...state.cart,
      promoCode: state.promoCode,
      ecoTourismChart: state.ecoTourismChart
    });
  }

  @Selector()
  static isEcoTourismChartChecked(state: CartStateModel) {
    return state.ecoTourismChart.isChecked;
  }

  @Selector()
  static promoCode(state: CartStateModel): PromoCode | null {
    return state.promoCode;
  }

  @Selector()
  static isDeposit(state: CartStateModel): boolean {
    return state.deposit;
  }

  @Action(InitCart)
  initCart(ctx: StateContext<CartStateModel>, action: InitCart): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      promoCode: window.localStorage.getItem(PROMO)
        ? new PromoCode({
            ...JSON.parse(window.localStorage.getItem(PROMO) as string)
          })
        : null
    });

    if (
      !!action.queryParams.get('promoCode') &&
      !window.localStorage.getItem(PROMO)
    ) {
      this.promoService
        .setPromoCode(action.queryParams.get('promoCode') as string)
        .subscribe((code) => {
          if (!code) {
            return;
          }

          if (
            DateUtils.isBetween(
              moment(),
              code.dateValidityStart,
              code.dateValidityEnd
            ) &&
            code.used < code.usedLimit
          ) {
            this.store.dispatch(new AddPromoCode(code));
          }
        });
    }
  }

  @Action(AddToCart)
  addToCart(ctx: StateContext<CartStateModel>, action: AddToCart) {
    return this.cartActivityService
      .addToCart(
        action.experienceId,
        action.participants,
        action.firstDay,
        action.resort,
        action.timeSlotId
      )
      .pipe(
        tap((items) => {
          const cart = new Cart({
            ...ctx.getState().cart,
            itemsActivity: [...items]
          });
          ctx.patchState({ cart });
        })
      );
  }

  @Action(AddSkiEquipmentToCart)
  addSkiEquipmentToCart(
    ctx: StateContext<CartStateModel>,
    action: AddSkiEquipmentToCart
  ) {
    if (action.itemSkiEquipment) {
      return this.cartSkiEquipmentService.addItem(action.itemSkiEquipment).pipe(
        tap((item) => {
          const currentState = ctx.getState();
          // Ajouter le nouvel élément au tableau existant
          const updatedItemsSkiEquipment = [
            ...currentState.cart.itemsSkiEquipment,
            item
          ];
          // Mettre à jour le panier avec le nouveau tableau d'équipements
          const cart = new Cart({
            ...currentState.cart,
            itemsSkiEquipment: updatedItemsSkiEquipment
          });
          ctx.patchState({ cart });
        })
      );
    }

    return of(EMPTY);
  }

  @Action(RemoveSkiEquipmentFromCart)
  removeSkiEquipmentFromCart(
    ctx: StateContext<CartStateModel>,
    action: RemoveSkiEquipmentFromCart
  ) {
    if (action.cartItemId) {
      return this.cartSkiEquipmentService
        .deleteItem(action.sessionId, action.cartItemId)
        .pipe(
          tap((items) => {
            const cart = new Cart({
              ...ctx.getState().cart,
              itemsSkiEquipment: [...items]
            });

            ctx.patchState({ cart });
          })
        );
    }

    return of(EMPTY);
  }

  @Action(AddAccommodationToCart)
  addAccommodationToCart(
    ctx: StateContext<CartStateModel>,
    action: AddAccommodationToCart
  ) {
    if (action.itemAccommodation) {
      return this.cartAccommodationService
        .addItems(action.itemAccommodation)
        .pipe(
          tap((items) => {
            const cart = new Cart({
              ...ctx.getState().cart,
              itemsAccommodation: [...items]
            });

            ctx.patchState({ cart });
          })
        );
    }

    return of([]);
  }

  @Action(UpdateAccommodationRemarks)
  updateAccommodation(
    ctx: StateContext<CartStateModel>,
    action: UpdateAccommodationRemarks
  ) {
    if (action.remarks) {
      const items = ctx.getState().cart.itemsAccommodation.map((item) => {
        return new ItemAccommodation({
          ...item,
          establishment: new Establishment({
            id: item.establishment.id
          }),
          remarks: action.remarks
        });
      });

      return this.cartAccommodationService
        .updateItems(items, action.sessionId)
        .pipe(
          tap((items) => {
            const cart = new Cart({
              ...ctx.getState().cart,
              itemsAccommodation: [...items]
            });

            ctx.patchState({ cart });
          })
        );
    }

    return of(EMPTY);
  }

  @Action(RemoveItemsToCart)
  removeItemsToCart(
    ctx: StateContext<CartStateModel>,
    action: RemoveItemsToCart
  ) {
    if (action.itemsToRemove.length) {
      return this.cartActivityService
        .removeItems(action.sessionId, action.itemsToRemove)
        .pipe(
          tap((items) => {
            const cart = new Cart({
              ...ctx.getState().cart,
              itemsActivity: [...items]
            });

            ctx.patchState({ cart });

            if (cart.isEmpty) {
              ctx.patchState({ timer: null });
            }
          })
        );
    }

    return of(EMPTY);
  }

  @Action(FetchCart)
  fetchCart(ctx: StateContext<CartStateModel>, action: FetchCart): void {
    combineLatest([
      this.cartActivityService.getItems(action.sessionId),
      this.cartAccommodationService.getItems(action.sessionId),
      this.cartSkiEquipmentService.getItems(action.sessionId, false)
    ]).subscribe(([itemsActivity, itemsAccommodation, itemsSkiEquipment]) => {
      const cart = new Cart({
        itemsActivity,
        itemsAccommodation,
        itemsSkiEquipment,
        sessionId: this.store.selectSnapshot(FiltersState.sessionId)
      });

      ctx.patchState({ cart });
    });
  }

  @Action(AddPromoCode)
  setPromoCode(ctx: StateContext<CartStateModel>, action: AddPromoCode): void {
    const state = ctx.getState();
    window.localStorage.setItem(PROMO, JSON.stringify(action.promoCode));

    ctx.setState({
      ...state,
      promoCode: action.promoCode
    });
  }

  @Action(DepositMode)
  depositMode(ctx: StateContext<CartStateModel>, action: DepositMode): void {
    const state = ctx.getState();

    ctx.patchState({
      cart: new Cart({
        ...state.cart,
        deposit: action.deposit
      }),
      deposit: action.deposit
    });
  }

  @Action(UpdateEcoTourismChart)
  updateEcoTourismChart(
    ctx: StateContext<CartStateModel>,
    action: UpdateEcoTourismChart
  ): void {
    const state = ctx.getState();
    window.localStorage.setItem(
      LocalStorageResource.CHARTE_ECO,
      String(action.value.isChecked)
    );

    ctx.setState({
      ...state,
      ecoTourismChart: action.value
    });
  }

  @Action(DeletePromoCode)
  deletePromoCode(ctx: StateContext<CartStateModel>): void {
    this.promoService.deletePromoCode().subscribe(() => {
      window.localStorage.removeItem(PROMO);

      ctx.patchState({
        promoCode: null
      });
    });
  }

  @Action(DeleteItemForParticipant)
  deleteItemForParticipant(
    ctx: StateContext<CartStateModel>,
    action: DeleteItemForParticipant
  ): void {
    this.cartActivityService
      .deleteItemForParticipant(action.uuid, action.itemId)
      .subscribe((items) => {
        const cart = new Cart({
          ...ctx.getState().cart,
          itemsActivity: [...items]
        });

        ctx.patchState({
          cart
        });
      });
  }

  @Action(DeleteEquipment)
  deleteEquipment(
    ctx: StateContext<CartStateModel>,
    action: DeleteEquipment
  ): void {
    this.cartSkiEquipmentService
      .deleteItem(action.sessionId, action.id)
      .subscribe((items) => {
        const cart = new Cart({
          ...ctx.getState().cart,
          itemsSkiEquipment: [...items]
        });

        ctx.patchState({
          cart
        });
      });
  }
}
