import { NgForOf, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DropdownComponent } from 'atomic-lib';
import { SkiEquipmentService } from '../../../service/ski-equipment.service';
import { Pack } from '../../models/ski-equipment/pack';
import { SkiCriteria } from '../ski-pass-dropdown/ski-pass-dropdown.component';

@Component({
  selector: 'vsk-pack-equipment-dropdown',
  standalone: true,
  imports: [DropdownComponent, NgIf, NgForOf],
  templateUrl: './pack-equipment-dropdown.component.html',
  styleUrl: './pack-equipment-dropdown.component.scss'
})
export class PackEquipmentDropdownComponent {
  @ViewChild('packButton') buttonElement: ElementRef<HTMLElement>;

  open = false;
  pack: Pack;
  packs: Pack[] = [];
  @Output() selectPackChange: EventEmitter<Pack> = new EventEmitter<Pack>();
  @Output() noPackChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() resortIdChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(private skiEquipmentService: SkiEquipmentService) {}

  _criteria: SkiCriteria;

  @Input() set criteria(criteria: SkiCriteria) {
    if (criteria) {
      this._criteria = criteria;
      this.fetchPack();
    }
  }

  @Input() set duration(duration: number) {
    if (duration && duration >= 2) {
      this._criteria.skiPassDuration = duration;
      this.fetchPack();
    }
  }

  fetchPack() {
    this.skiEquipmentService
      .getPacksByStationAndDuration(
        this._criteria.resortName,
        this._criteria.skiPassDuration,
        this._criteria.firstDay
      )
      .subscribe((response) => {
        this.resortIdChange.emit(response.resortId);
        this.packs = response.packs;

        if (!this.packs?.length) {
          this.noPackChange.emit();
          return;
        }

        let pack = this.packs[0];

        if (this.pack) {
          pack = this.packs.find((p) => p.packId === this.pack.packId) || pack;
        }

        this.choosePack(pack);
      });
  }

  choosePack(pack: Pack) {
    this.pack = pack;
    this.selectPackChange.emit(pack);
    this.open = false;
  }
}
