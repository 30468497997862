import { NgModule } from '@angular/core';
import { FooterComponent } from '../../shared/footer/footer.component';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '../../store/translate.module';
import { TermsAndConditionsRoutingModule } from './terms-and-conditions-routing.module';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';

@NgModule({
  declarations: [TermsAndConditionsComponent],
  imports: [
    NgxTranslateModule,
    SharedModule,
    TermsAndConditionsRoutingModule,
    FooterComponent,
    NavbarComponent
  ],
  exports: [TermsAndConditionsComponent]
})
export class TermsAndConditionsModule {}
