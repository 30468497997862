import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FiltersStation } from '../shared/models/filters/filters-station';
import { Resort } from '../shared/models/resort/resort';
import { ResortMap } from '../shared/models/resort/resort-map';
import { ResortMin } from '../shared/models/resort/resort-min';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ResortService extends RepositoryService {
  private URL_STATION = 'api/webapp/resort';

  getStationsByFilters(filters: FiltersStation): Observable<ResortMap[]> {
    return this.post(this.URL_STATION + '/stations', filters).pipe(
      map((resorts: ResortMap[]) =>
        resorts.map((resort: ResortMap) => new ResortMap({ ...resort }))
      )
    );
  }

  getStationByName(name: string, startDate: Moment): Observable<Resort> {
    return this.get(
      this.URL_STATION +
        `/by-name?name=${name}&startDate=${startDate.format('YYYY-MM-DD')}`
    ).pipe(map((station: any) => new Resort(station)));
  }

  getAllResorts(startDate: Moment): Observable<ResortMin[]> {
    return this.get(
      this.URL_STATION +
        `/all-stations?startDate=${startDate.format('YYYY-MM-DD')}`
    ).pipe(
      map((stations: ResortMin[]) =>
        stations.map((station) => new ResortMin(station))
      )
    );
  }
}
