import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import SockJS from 'sockjs-client';
import Stomp, { Message } from 'stompjs';
import { environment } from '../../environments/environment';
import { TriggerAlert } from '../app.action';
import { FiltersState } from '../filters.state';
import { Alert } from '../shared/models/alert';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private serverUrl = `${environment.urlAddress}/socket`;
  private stompClient: Stomp.Client;

  constructor(private store: Store) {
    this.stompClient = Stomp.over(new SockJS(this.serverUrl));
    this.stompClient.debug = () => {};
  }

  init() {
    this.stompClient.connect({}, () => {
      this.stompClient.subscribe(
        `/topic/alert-warning/${this.store.selectSnapshot(FiltersState.sessionId)}`,
        (message: Message) => {
          this.store.dispatch(
            new TriggerAlert(
              new Alert({
                message: message.body,
                timeout: 5000,
                level: 'warning'
              })
            )
          );
        }
      );
    });
  }
}
