import { AsyncPipe, DecimalPipe, NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  StripeElements,
  StripeElementsOptionsClientSecret,
  StripePaymentElement
} from '@stripe/stripe-js';
import {
  ButtonComponent,
  InputRadioComponent,
  LoaderComponent,
  RadioControl,
  WindowResource
} from 'atomic-lib';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { StripeService } from 'ngx-stripe';
import { Observable, combineLatest, filter } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../../app.state';
import { FiltersState } from '../../../filters.state';
import { PaymentService } from '../../../service/payment.service';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { LoginFormComponent } from '../../../shared/login-form/login-form.component';
import { Cart } from '../../../shared/models/cart/cart';
import { PaymentSetup } from '../../../shared/models/stripe/payment-setup';
import { UserSession } from '../../../shared/models/user-session';
import { RegisterFormComponent } from '../../../shared/register-form/register-form.component';
import { DepositMode } from '../cart.action';
import { CartState } from '../cart.state';

@Component({
  selector: 'vsk-cart-step-payment',
  standalone: true,
  templateUrl: './step-cart-payment.component.html',
  imports: [
    RegisterFormComponent,
    NgIf,
    AsyncPipe,
    LoginFormComponent,
    InputRadioComponent,
    LoaderComponent,
    NgClass,
    ButtonComponent
  ],
  styleUrl: './step-cart-payment.component.scss'
})
export class StepCartPaymentComponent extends RxjsComponent {
  @Select(AppState.isUserLoggedIn) isConnected$: Observable<boolean>;
  @Select(AppState.user) user$: Observable<UserSession>;
  @Select(CartState.isDeposit) isDeposit$: Observable<boolean>;

  loading = false;
  error: string | undefined;
  step: 'sign-in' | 'register' = 'register';
  setup: PaymentSetup;
  elements: StripeElements;
  paymentElement: StripePaymentElement;
  payMode: 'DEPOSIT' | 'FULL' = 'FULL';
  payModeRadios: RadioControl<'DEPOSIT' | 'FULL'>[] = [
    {
      name: 'Payer en une fois',
      value: 'FULL',
      selected: true
    },
    {
      name: 'Payer un acompte de 30%',
      value: 'DEPOSIT',
      selected: false
    }
  ];
  @Input() startDate: Moment;
  @Output() previousChange: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private stripeService: StripeService,
    private paymentService: PaymentService,
    public windowResource: WindowResource,
    private store: Store,
    private changeRef: ChangeDetectorRef,
    private decimalPipe: DecimalPipe
  ) {
    super();
    this.register(
      combineLatest([this.isConnected$, this.isDeposit$])
        .pipe(filter(([isConnected]) => isConnected))
        .subscribe(() => {
          this.loading = true;

          const promoCode = this.store.selectSnapshot(CartState.promoCode);
          const sessionId = this.store.selectSnapshot(FiltersState.sessionId);
          const isDeposit = this.payMode === 'DEPOSIT';
          const ecoTourismChart = this.store.selectSnapshot(
            CartState.isEcoTourismChartChecked
          );

          this.paymentService
            .initIntentPayment(
              promoCode?.name as string,
              sessionId as string,
              ecoTourismChart,
              isDeposit
            )
            .subscribe(
              (setup) => {
                if (setup) {
                  this.setup = setup;
                  const options: StripeElementsOptionsClientSecret = {
                    clientSecret: this.setup.paymentIntent,
                    appearance: {
                      theme: 'stripe'
                    },
                    locale: 'fr-FR'
                  };

                  this.stripeService.elements(options).subscribe(
                    (elements) => {
                      this.elements = elements;
                      this.paymentElement = this.elements.create('payment', {
                        paymentMethodOrder: this.getPaymentOptions(isDeposit)
                      });
                      this.paymentElement.mount('#payment-element');
                      this.loading = false;
                      this.changeRef.markForCheck();
                    },
                    () => window.location.reload()
                  );
                }
              },
              (error) => {
                if (error) {
                  // Error creating the token
                  this.error = error?.message;
                }
              }
            );
        })
    );
  }

  _cart: Cart;

  @Input() set cart(cart: Cart) {
    this._cart = cart;

    if (cart) {
      const price = this.decimalPipe.transform(
        cart.getPriceWithPromo() * 0.3,
        '1.2-2',
        'fr'
      );

      this.payModeRadios
        .filter((mode) => mode.value === 'DEPOSIT')
        .forEach(
          (mode) => (mode.name = 'Payer un acompte de 30% (' + price + '€)')
        );
    }
  }

  pay(): void {
    this.error = '';
    this.loading = true;

    const promoCode = this.store.selectSnapshot(CartState.promoCode);
    const sessionId = this.store.selectSnapshot(FiltersState.sessionId);

    let args = '?sessionIdPaid=' + sessionId;

    if (promoCode) {
      args += '&promoCode=' + promoCode.name;
    }

    this.elements.submit().then((value) => {
      if (value.error) {
        this.error = value.error.message;
        this.loading = false;
        this.changeRef.markForCheck();
        return;
      }

      this.register(
        this.stripeService
          .confirmPayment({
            elements: this.elements,
            clientSecret: this.setup.paymentIntent,
            confirmParams: {
              return_url: environment.redirectPayment + args
            }
          })
          .subscribe(
            (value) => {
              this.error = value.error.message;
              this.loading = false;
              this.changeRef.markForCheck();
            },
            () => this.handleErrorPackages()
          )
      );
    });
  }

  handleErrorPackages(): void {
    this.loading = false;
    this.error =
      'Une erreur est survenue lors du paiement, veuillez réessayer dans quelques instants';
    this.changeRef.markForCheck();
  }

  canPayDeposit() {
    return (
      (this._cart.getPriceWithPromo() > 300 &&
        this.startDate.isSameOrAfter(moment().add(46, 'day'))) ||
      this.payMode === 'DEPOSIT'
    );
  }

  updatePayMode(control: RadioControl<'DEPOSIT' | 'FULL'>) {
    this.payMode = control.value;
    this.store.dispatch(new DepositMode(control.value === 'DEPOSIT'));
  }

  private getPaymentOptions(isDeposit: boolean) {
    const options = ['card'];

    if (!isDeposit) {
      options.push('apple_pay', 'google_pay');
    }

    return options;
  }
}
