import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import moment, { Moment } from 'moment';
import { Observable, combineLatest, from, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AddParticipant,
  Bounds,
  ChangeDates,
  FiltersStationChangeOrderBy,
  GeoBoundsActiveChange,
  NewSearch,
  RemoveParticipant,
  SetFiltersAccommodations,
  SetFiltersExperiences,
  SetResort,
  SetWhiteLabel,
  UpdateFilters,
  UpsertParticipant
} from './filters.action';
import { LocalStorageResource } from './resource/local-storage.resource';
import { ParticipantResource } from './resource/participant.resource';
import { UrlManagerResource } from './resource/url-manager.resource';
import { ActivityService } from './service/activity.service';
import { SessionService } from './service/session.service';
import { FiltersAccommodation } from './shared/models/accommodation/filters-accommodation';
import { Activity } from './shared/models/activity/activity';
import { FiltersExperienceSearch } from './shared/models/activity/filters-experience-search';
import { OrderBy } from './shared/models/const/order-by';
import { Criteria } from './shared/models/criteria';
import { WhiteLabel } from './shared/models/enum/white-label.enum';
import { FiltersAccommodationSearch } from './shared/models/filters/filters-accommodation-search';
import { FiltersStation } from './shared/models/filters/filters-station';
import { Participant } from './shared/models/participant/participant';
import { Period } from './shared/models/period';
import { Resort } from './shared/models/resort/resort';
import { Session } from './shared/models/session/session';
import { InitSearchBar } from './shared/search-bar/search-bar.action';
import { ParticipantUtils } from './utils/participant-utils';

export interface FiltersStateModel {
  startDate: Moment | null;
  endDate: Moment | null;
  participants: Participant[];
  activities: Activity[];
  activitiesSelected: Activity[];
  bounds: google.maps.LatLngBoundsLiteral;
  orderBy: OrderBy;
  geoBoundsActive: boolean;
  resort: Resort | null;
  sessionId: string | undefined;
  oldSessionId: string | null;
  whiteLabel: WhiteLabel;
  searchMode: 'all' | 'accommodation' | 'experience';
  filtersExperiences: FiltersExperienceSearch | null;
  filtersAccommodations: FiltersAccommodationSearch;
  skiPass: boolean;
  material: boolean;
}

@State<FiltersStateModel>({
  name: 'filters',
  defaults: {
    startDate: null,
    endDate: null,
    participants: [],
    activities: [],
    activitiesSelected: [],
    bounds: {
      east: 15.279743671417222,
      north: 49.618420074289716,
      south: 41.862059405400565,
      west: -5.814006328582777
    },
    geoBoundsActive: true,
    searchMode: 'all',
    orderBy: OrderBy.RELEVANCE,
    resort: null,
    sessionId: undefined,
    oldSessionId: null,
    whiteLabel: WhiteLabel.NONE,
    filtersExperiences: null,
    filtersAccommodations: new FiltersAccommodationSearch({}),
    skiPass: true,
    material: true
  }
})
@Injectable()
export class FiltersState {
  constructor(
    private participantResource: ParticipantResource,
    private sessionService: SessionService,
    private activityService: ActivityService,
    private router: Router,
    private store: Store,
    private urlManager: UrlManagerResource
  ) {}

  @Selector()
  static getSelectedActivities(activities: Activity[]): number[] {
    if (!activities?.length) {
      return [];
    }

    return activities
      .filter((activity) => activity.selected)
      .map((activity) => activity.id);
  }

  @Selector()
  static startDate(state: FiltersStateModel): Moment | undefined {
    return state.startDate || undefined;
  }

  @Selector()
  static endDate(state: FiltersStateModel): Moment | undefined {
    return state.endDate || undefined;
  }

  @Selector()
  static skiPass(state: FiltersStateModel): boolean {
    return state.skiPass;
  }

  @Selector()
  static material(state: FiltersStateModel): boolean {
    return state.material;
  }

  @Selector()
  static period(state: FiltersStateModel): Period {
    return new Period({
      startDate: FiltersState.startDate(state) as Moment,
      endDate: FiltersState.endDate(state) as Moment
    });
  }

  @Selector()
  static participants(state: FiltersStateModel): Participant[] {
    return state.participants.sort((prev, curr) =>
      prev.index < curr.index ? -1 : 1
    );
  }

  @Selector()
  static activities(state: FiltersStateModel): Activity[] {
    return state.activities.map((activity) => new Activity(activity));
  }

  @Selector()
  static selectedActivities(state: FiltersStateModel): Activity[] {
    if (!state?.activitiesSelected.length) {
      return [];
    }

    return state.activitiesSelected.map((activity) => new Activity(activity));
  }

  @Selector()
  static criteria(state: FiltersStateModel): Criteria {
    return new Criteria({
      startDate: state.startDate ? moment(state.startDate) : undefined,
      endDate: state.endDate ? moment(state.endDate) : undefined,
      participants: state.participants,
      stationName: state.resort?.name as string,
      activities: this.getSelectedActivities(state.activities),
      sessionId: state.sessionId
    });
  }

  @Selector()
  static sessionId(state: FiltersStateModel): string | undefined {
    return state.sessionId;
  }

  @Selector()
  static oldSessionId(state: FiltersStateModel): string | null {
    return state.oldSessionId;
  }

  @Selector()
  static filtersStation(state: FiltersStateModel): FiltersStation {
    return new FiltersStation({
      startDate: state.startDate as Moment,
      endDate: state.endDate as Moment,
      activities: state.activitiesSelected,
      orderBy: state.orderBy,
      ...(state.filtersAccommodations as FiltersAccommodation),
      participants: state.participants,
      mapPolygon: state.bounds as google.maps.LatLngBoundsLiteral,
      geoBoundsActive: state.geoBoundsActive
    });
  }

  @Selector()
  static resort(state: FiltersStateModel): Resort | null {
    return state.resort;
  }

  @Selector()
  static searchMode(
    state: FiltersStateModel
  ): 'all' | 'accommodation' | 'experience' {
    return state.searchMode;
  }

  @Selector()
  static filtersExperiences(
    state: FiltersStateModel
  ): FiltersExperienceSearch | null {
    return new FiltersExperienceSearch({
      ...state.filtersExperiences,
      mapPolygon: state.bounds as google.maps.LatLngBoundsLiteral
    });
  }

  @Selector()
  static filtersAccommodations(state: FiltersStateModel): FiltersAccommodation {
    return state.filtersAccommodations;
  }

  @Selector()
  static bounds(state: FiltersStateModel): google.maps.LatLngBoundsLiteral {
    return state.bounds;
  }

  @Selector()
  static whiteLabel(state: FiltersStateModel): WhiteLabel {
    return state.whiteLabel;
  }

  @Selector()
  static isFoncia(state: FiltersStateModel): boolean {
    return (
      state.whiteLabel === WhiteLabel.FONCIA ||
      state.whiteLabel === WhiteLabel.GSI
    );
  }

  @Action(UpdateFilters)
  updateFilters(ctx: StateContext<FiltersStateModel>, action: UpdateFilters) {
    const lastSession = LocalStorageResource.getLastSessionDate();

    if (lastSession?.isBefore(moment().add(-1, 'days'))) {
      window.localStorage.clear();
      LocalStorageResource.updateLastSessionDate();
      this.router.navigate(['/']);
      window.location.reload();
      return;
    }

    LocalStorageResource.updateLastSessionDate();

    const startDate = action.queryParams.get('startDate')
      ? moment(action.queryParams.get('startDate') as string, 'YYYY-MM-DD')
      : moment().add(1, 'days');
    const endDate = action.queryParams.get('endDate')
      ? moment(action.queryParams.get('endDate') as string, 'YYYY-MM-DD')
      : moment().add(4, 'days');

    const sessionLocal = new Session({
      startDate,
      endDate,
      participants: ParticipantUtils.mapToParticipant(
        Number(action.queryParams.get('nbAdults') || 2),
        this.urlManager.fetchParams(['children'])?.children || []
      )
    });

    const sessionId = action.queryParams.get('sessionId');
    const session$ = sessionId
      ? this.sessionService.getSession(sessionId as string)
      : of(undefined);

    combineLatest(
      this.activityService.getActivitiesForDates(startDate, endDate),
      session$
    ).subscribe(([activities, session]) =>
      this.setupSession(session || sessionLocal, activities, ctx)
    );
  }

  setupSession(
    session: Session,
    activities: Activity[],
    ctx: StateContext<FiltersStateModel>
  ) {
    const activitiesId =
      this.urlManager.fetchParams(['activities'])?.activities || [];
    const activitiesMapped = activities.map((activity) => {
      if (activitiesId.indexOf(activity.id) !== -1) {
        return new Activity({ ...activity, selected: true });
      }
      return activity;
    });
    const activitiesSelected = activitiesMapped.filter(
      (activity) => activity.selected
    );

    ctx.patchState({
      sessionId: session.id,
      startDate: session.startDate,
      endDate: session.endDate,
      participants: session.participants,
      activities: activitiesMapped,
      activitiesSelected,
      skiPass:
        this.urlManager.fetchParams(['searchPackage'])?.searchPackage !==
        'SINGLE',
      material:
        this.urlManager.fetchParams(['searchPackage'])?.searchPackage ===
        'FULL',
      filtersAccommodations: new FiltersAccommodationSearch({
        ...this.urlManager.fetchParams(
          Object.keys(new FiltersAccommodationSearch())
        ),
        regions: this.urlManager.fetchParams(['regions'])?.regions || [],
        resorts: this.urlManager.fetchParams(['resorts'])?.resorts || [],
        packages:
          this.urlManager.fetchParams(['searchPackage']).searchPackage !==
            'SINGLE' || true,
        material:
          this.urlManager.fetchParams(['searchPackage']).searchPackage ===
            'FULL' || true
      })
    });

    this.store.dispatch(
      new InitSearchBar(
        this.urlManager.fetchParams(['searchType'])?.searchType ||
          'ACCOMMODATION',
        this.urlManager.fetchParams(['searchPackage'])?.searchPackage || 'FULL',
        ctx.getState().participants,
        FiltersState.period(ctx.getState()),
        activitiesMapped,
        this.urlManager.fetchParams(['regions'])?.regions || [],
        this.urlManager.fetchParams(['resorts'])?.resorts || [],
        this.urlManager.fetchParams(['resort'])?.resort
      )
    );

    if (session.periodValid && !session.id) {
      this.urlManager.mergeParams({
        startDate: ctx.getState().startDate?.format('YYYY-MM-DD'),
        endDate: ctx.getState().endDate?.format('YYYY-MM-DD')
      });
    }
  }

  @Action(UpsertParticipant)
  upsertParticipant(
    ctx: StateContext<FiltersStateModel>,
    action: UpsertParticipant
  ): Observable<void> {
    return this.participantResource
      .upsert(action.participant, action.sessionId)
      .pipe(
        tap(() => this.addParticipant(ctx, { participant: action.participant }))
      );
  }

  @Action(AddParticipant)
  addParticipant(ctx: StateContext<FiltersStateModel>, action: AddParticipant) {
    ctx.patchState({
      participants: [
        ...ctx
          .getState()
          .participants.filter(
            (participant) => participant.uuid !== action.participant.uuid
          ),
        action.participant
      ]
        .sort((prev, curr) => (prev.index < curr.index ? -1 : 1))
        .map(
          (participant, i) =>
            new Participant({
              ...participant,
              index: i + 1,
              firstname:
                participant.firstname.indexOf('Voyageur') !== -1
                  ? `Voyageur ${i + 1}`
                  : participant.firstname
            })
        )
    });
  }

  @Action(RemoveParticipant)
  removeParticipant(
    ctx: StateContext<FiltersStateModel>,
    action: RemoveParticipant
  ) {
    ctx.patchState({
      participants: ctx
        .getState()
        .participants.filter(
          (participant) => participant.uuid !== action.participant
        )
        .sort((prev, curr) => (prev.index < curr.index ? -1 : 1))
        .map(
          (participant, i) =>
            new Participant({
              ...participant,
              index: i + 1,
              firstname:
                participant.firstname.indexOf('Voyageur') !== -1
                  ? `Voyageur ${i + 1}`
                  : participant.firstname
            })
        )
    });
  }

  @Action(Bounds)
  bounds(ctx: StateContext<FiltersStateModel>, action: Bounds): void {
    ctx.patchState({
      bounds: action.bounds
        ? action.bounds
        : {
            east: 15.279743671417222,
            north: 49.618420074289716,
            south: 41.862059405400565,
            west: -5.814006328582777
          }
    });
  }

  @Action(ChangeDates)
  changeDates(ctx: StateContext<FiltersStateModel>, action: ChangeDates): void {
    ctx.patchState({
      startDate: action.startDate,
      endDate: action.endDate
    });

    this.urlManager.mergeParams({
      startDate: action.startDate?.format('YYYY-MM-DD'),
      endDate: action.endDate?.format('YYYY-MM-DD')
    });
  }

  @Action(NewSearch)
  newSearch(ctx: StateContext<FiltersStateModel>, action: NewSearch) {
    const state = ctx.getState();

    ctx.patchState({
      startDate: action.search.startDate ?? moment().add(1, 'days'),
      endDate: action.search.endDate ?? moment().add(3, 'days'),
      activitiesSelected: action.search.activities,
      participants: action.search.participants,
      skiPass: action.search.searchPackage !== 'SINGLE',
      material: action.search.searchPackage === 'FULL',
      filtersAccommodations: new FiltersAccommodationSearch({
        ...state.filtersAccommodations,
        activities: action.search.activities.map((activity) => activity.id),
        resorts: action.search.resorts,
        regions: action.search.regions,
        packages: action.search.searchPackage !== 'SINGLE',
        material: action.search.searchPackage === 'FULL'
      })
    });

    return from(
      this.urlManager.mergeParams({
        startDate: action.search.startDate?.format('YYYY-MM-DD'),
        endDate: action.search.endDate?.format('YYYY-MM-DD'),
        searchType: action.search.searchType,
        searchPackage: action.search.searchPackage,
        resorts: action.search.resorts,
        regions: action.search.regions,
        activities: action.search.activities.map((activity) => activity.id)
      })
    );
  }

  @Action(FiltersStationChangeOrderBy)
  filtersStationChangeOrderBy(
    ctx: StateContext<FiltersStateModel>,
    action: FiltersStationChangeOrderBy
  ): void {
    ctx.patchState({
      orderBy: action.orderBy
    });
  }

  @Action(GeoBoundsActiveChange)
  filtersStationChangeGeoBoundsActive(
    ctx: StateContext<FiltersStateModel>,
    action: GeoBoundsActiveChange
  ): void {
    ctx.patchState({
      geoBoundsActive: action.isActive,
      filtersExperiences: new FiltersExperienceSearch({
        ...ctx.getState().filtersExperiences,
        geoBoundsActive: action.isActive
      }),
      filtersAccommodations: new FiltersAccommodationSearch({
        ...ctx.getState().filtersAccommodations,
        geoBoundsActive: action.isActive
      })
    });
  }

  @Action(SetResort)
  resort(ctx: StateContext<FiltersStateModel>, action: SetResort): void {
    ctx.patchState({
      resort: action.resort
    });
  }

  @Action(SetFiltersAccommodations)
  setFiltersAccommodations(
    ctx: StateContext<FiltersStateModel>,
    action: SetFiltersAccommodations
  ): void {
    this.urlManager.mergeParams(action.filters.toQueryParams());

    ctx.patchState({
      filtersAccommodations: action.filters
    });
  }

  @Action(SetFiltersExperiences)
  setFiltersExperiences(
    ctx: StateContext<FiltersStateModel>,
    action: SetFiltersExperiences
  ): void {
    this.urlManager.mergeParams(action.filtersExperiences?.toQueryParams());

    ctx.patchState({
      filtersExperiences: action.filtersExperiences
    });
  }

  @Action(SetWhiteLabel)
  setIsWhiteLabel(ctx: StateContext<FiltersStateModel>, action: SetWhiteLabel) {
    const isWhiteLabel =
      action.whiteLabel === WhiteLabel.FONCIA ||
      action.whiteLabel === WhiteLabel.GSI;
    const startDate = isWhiteLabel ? moment() : ctx.getState().startDate;
    const endDate = isWhiteLabel
      ? moment().add(7, 'days')
      : ctx.getState().endDate;

    ctx.patchState({
      whiteLabel: action.whiteLabel,
      startDate: startDate,
      endDate: endDate
    });

    const session = new Session({
      startDate: startDate as Moment,
      endDate: endDate as Moment
    });

    this.sessionService.upsert(session).subscribe();
  }
}
