import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { RegisterFormComponent } from '../../shared/register-form/register-form.component';

@Component({
  selector: 'vsk-register',
  standalone: true,
  imports: [
    GoogleSigninButtonModule,
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    RegisterFormComponent
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {}
