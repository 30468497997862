import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ButtonComponent,
  InputCheckboxComponent,
  OptionElement,
  PopupComponent,
  RangeInputComponent,
  ScreenPipe
} from 'atomic-lib';
import { AccommodationType } from '../../models/accommodation/accommodation-type.enum';
import { FiltersAccommodationSearch } from '../../models/filters/filters-accommodation-search';
import { Region } from '../../models/region';
import { SearchRegionFormComponent } from '../../search-region-form/search-region-form.component';
import { FiltersAccommodationManagerComponent } from '../filters-accommodation-manager.component';

@Component({
  selector: 'vsk-filters-accommodation-popup',
  templateUrl: './filters-accommodation-popup.component.html',
  styleUrls: ['./filters-accommodation-popup.component.scss'],
  imports: [
    PopupComponent,
    RangeInputComponent,
    NgIf,
    SearchRegionFormComponent,
    InputCheckboxComponent,
    NgForOf,
    ButtonComponent,
    AsyncPipe,
    ScreenPipe
  ],
  standalone: true
})
export class FiltersAccommodationPopupComponent extends FiltersAccommodationManagerComponent {
  @Input() resortsElements: OptionElement<number>[] = [];
  @Input() regionsElements: OptionElement<number>[] = [];
  @Input() regions: Region[];
  @Input() accommodationTypesElements: OptionElement<AccommodationType>[] = [];
  @Input() equipmentsFilter: OptionElement<number>[] = [];
  @Input() accessFilter: OptionElement<number>[] = [];
  @Input() servicesFilter: OptionElement<number>[] = [];

  @Output() closeChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() applyFilters: EventEmitter<FiltersAccommodationSearch> =
    new EventEmitter<FiltersAccommodationSearch>();

  close = false;

  getControl(control: FormControl<boolean | null> | undefined) {
    return control as FormControl<boolean | null>;
  }

  regionsChanged(regions: Region[]) {
    const regionsId = regions.map((region) => region.id);
    this.regionsElements.forEach((region) => {
      region.control?.setValue(regionsId.includes(region.id || -1));
    });
  }

  apply() {
    this.close = true;
    setTimeout(() => this.applyFilters.emit(this.currentFilters), 300);
  }
}
