import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OptionElement, WindowResource } from 'atomic-lib';
import hash from 'object-hash';
import { Observable, combineLatest, filter, skip, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FiltersState } from '../../filters.state';
import {
  FetchMoreEstablishment,
  ResetAccommodationState,
  SetFiltersAndFetchEstablishments
} from '../../page/search-accommodations/accommodation.action';
import { AccommodationState } from '../../page/search-accommodations/accommodation.state';
import { FiltersService } from '../../service/filters.service';
import { Establishment } from '../models/accommodation/establishment';
import { Activity } from '../models/activity/activity';
import { OrderBy } from '../models/const/order-by';
import { FiltersAccommodationSearch } from '../models/filters/filters-accommodation-search';
import { FiltersInfoAccommodation } from '../models/filters/filters-info-accommodation';
import { MarkerWrapper } from '../models/marker-wrapper';
import { Participant } from '../models/participant/participant';
import { Period } from '../models/period';
import { Resort } from '../models/resort/resort';
import { MapComponent } from './map.component';

@Component({ standalone: true, template: '' })
export class AccommodationTemplateComponent
  extends MapComponent<Establishment>
  implements OnInit, OnDestroy
{
  init = false;
  loading = true;
  loadingMore = false;
  nbParticipants = 0;
  pictures: string[] = [];
  filtersAccommodation: FiltersInfoAccommodation;
  establishments: Establishment[] = [];
  clearFilters = false;
  ordersSelection: OptionElement<OrderBy>[] = [
    {
      id: OrderBy.PRICEASC,
      label: 'Trier par : Prix le plus bas',
      disabled: false
    },
    {
      id: OrderBy.PRICEDESC,
      label: 'Trier par : Prix le plus élevé',
      disabled: false
    },
    {
      id: OrderBy.RELEVANCE,
      label: 'Trier par : Promotion',
      disabled: false
    }
  ];
  orderForm: FormControl<OrderBy | null> = new FormControl<OrderBy | null>(
    this.ordersSelection[0].id
  );

  @Select(FiltersState.sessionId) sessionId$: Observable<string>;
  @Select(AccommodationState.establishments) establishments$: Observable<
    Establishment[]
  >;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(FiltersState.resort) resort$: Observable<Resort>;
  @Select(FiltersState.selectedActivities) selectedActivities$: Observable<
    Activity[]
  >;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(AccommodationState.filters)
  filtersSearch$: Observable<FiltersAccommodationSearch>;

  constructor(
    public activatedRoute: ActivatedRoute,
    public store: Store,
    protected filtersService: FiltersService,
    public windowResource: WindowResource,
    protected changeRef: ChangeDetectorRef,
    public router: Router
  ) {
    super(windowResource, router);
  }

  ngOnInit(): void {
    this.zoom = 5;

    this.register(
      combineLatest([
        this.period$.pipe(
          filter((period) => period.isValid),
          distinctUntilChanged(
            (prev, curr) =>
              prev.startDate.isSame(curr.startDate, 'day') &&
              prev.endDate.isSame(curr.endDate, 'day')
          )
        ),
        this.selectedActivities$.pipe(
          debounceTime(100),
          distinctUntilChanged(
            (prev, curr) =>
              prev.map((act) => act.id).join(',') ===
              curr.map((act) => act.id).join(',')
          )
        ),
        this.participants$.pipe(
          filter((participants) => participants.length > 0),
          distinctUntilChanged(
            (prev, curr) =>
              hash(prev.map((participant) => participant.uuid)) ===
              hash(curr.map((participant) => participant.uuid))
          ),
          tap((participants) => (this.nbParticipants = participants.length))
        ),
        this.activatedRoute.queryParamMap
      ])
        .pipe(
          debounceTime(100),
          tap(() => (this.loading = true)),
          switchMap(([period, activities, participants, params]) => {
            const bounds = this.store.selectSnapshot(FiltersState.bounds);

            const filters = new FiltersAccommodationSearch({
              ...AccommodationState.filtersAccommodationFromURL(
                new FiltersAccommodationSearch({}),
                params
              ),
              startDate: period.startDate,
              endDate: period.endDate,
              nbParticipants: participants.length,
              geoBoundsActive: this.refreshOnMoveMap.value || false,
              mapPolygon: bounds,
              activities: activities.map((activity) => activity.id),
              participants
            });

            this.init = true;

            return this.store.dispatch(
              new SetFiltersAndFetchEstablishments(filters, params)
            );
          })
        )
        .subscribe(() => {
          this.changeRef.markForCheck();
        })
    );

    this.register(
      this.filtersSearch$
        .pipe(
          skip(1),
          filter(
            (filters) =>
              filters.startDate?.isValid() &&
              filters.endDate?.isValid() &&
              !!filters.mapPolygon
          ),
          distinctUntilChanged((prev, curr) => hash(prev) === hash(curr)),
          tap(() => (this.loading = true)),
          debounceTime(500),
          switchMap((filters) =>
            this.filtersService.getFiltersAccommodationInfo(filters)
          )
        )
        .subscribe((filters) => {
          this.filtersAccommodation = filters;
          this.changeRef.markForCheck();
        })
    );

    this.register(
      this.establishments$
        .pipe(skip(1), debounceTime(100))
        .subscribe((establishments) => {
          this.establishments = establishments;
          const markersEstablishments = this.buildMarkers(establishments);
          this.markers = markersEstablishments.length
            ? [...markersEstablishments]
            : [];
          this.placeMarkersOnMap();
          this.fitBounds();
          this.loadingMore = false;
          this.loading = false;
          this.changeRef.markForCheck();
        })
    );
  }

  loadMoreAccommodation() {
    if (!this.init) {
      return;
    }

    this.loadingMore = true;
    this.store.dispatch(new FetchMoreEstablishment());
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new ResetAccommodationState());
  }

  protected boundsChangedAction(
    bounds: google.maps.LatLngBoundsLiteral,
    refresh: boolean
  ): void {
    const filtersStore = this.store.selectSnapshot(
      FiltersState.filtersAccommodations
    );

    const filters = new FiltersAccommodationSearch({
      ...filtersStore,
      mapPolygon: bounds,
      geoBoundsActive: refresh
    });

    this.store.dispatch(
      new SetFiltersAndFetchEstablishments(
        filters,
        this.activatedRoute.snapshot.queryParamMap
      )
    );
  }

  private buildMarkers(establishments: Establishment[]) {
    return establishments.map((establishment) => {
      const price = establishment.minPrice
        ? Math.round(
            establishment.minPrice +
              establishment.priceMaterial +
              establishment.priceSkiPass
          )
        : 0;
      const text = price ? price + ' €' : establishment.name;
      const marker = new MarkerWrapper<Establishment>(establishment, {
        clickable: true,
        position: {
          lat: Number(establishment.location.lat),
          lng: Number(establishment.location.lon)
        },
        title: establishment.name + ' - ' + establishment.partnerCode,
        label: {
          color: '#1f1f1f',
          text: text,
          fontWeight: '500',
          fontSize: '11px',
          className: 'pinpoint',
          fontFamily: 'General'
        },
        icon: {
          url: '../assets/svg/pinpoint.svg',
          scaledSize: new google.maps.Size(
            text.length * 5 + 40,
            36,
            'px',
            'px'
          ),
          origin: new google.maps.Point(-13, -5)
        },
        anchorPoint: new google.maps.Point(0, 0),
        optimized: false,
        zIndex: 100
      });
      establishment.marker = marker;
      return marker;
    });
  }
}
