import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { Price } from '../shared/models/cart/price';
import { EquipmentResponse } from '../shared/models/ski-equipment/equipment-response';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class SkiEquipmentService extends RepositoryService {
  private URL_DOMAIN = 'api/webapp/skiset';

  constructor(http: HttpClient) {
    super(http);
  }

  getPacksByStationAndDuration(
    stationName: string,
    duration: number,
    firstSkiDate: Moment
  ): Observable<EquipmentResponse> {
    return this.get(
      `${this.URL_DOMAIN}/get-packs-by-station-and-duration?stationName=${stationName}&duration=${duration}&firstSkiDate=${firstSkiDate.format('YYYY-MM-DD')}`
    );
  }

  getMinPriceMaterial(
    participants: Moment[],
    firstDay: Moment,
    duration: number,
    resortId: number
  ): Observable<Price> {
    const birthdays = participants.map((day) => day.format('YYYY-MM-DD'));
    return this.get(
      this.URL_DOMAIN +
        `/get-min-price-material?participants=${birthdays}&firstDay=${firstDay.format('YYYY-MM-DD')}&duration=${duration}&resortId=${resortId}`
    );
  }
}
