import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetFiltersAccommodations } from '../../filters.action';
import { AccommodationService } from '../../service/accommodation.service';
import { AccommodationType } from '../../shared/models/accommodation/accommodation-type.enum';
import { Establishment } from '../../shared/models/accommodation/establishment';
import { FiltersAccommodation } from '../../shared/models/accommodation/filters-accommodation';
import { Room } from '../../shared/models/accommodation/room';
import { FiltersAccommodationSearch } from '../../shared/models/filters/filters-accommodation-search';
import { SearchPackage } from '../../shared/search-bar/search-bar.state';
import {
  FetchEstablishments,
  FetchMoreEstablishment,
  GetRooms,
  ResetAccommodationState,
  SetFilterResort,
  SetFiltersAndFetchEstablishments
} from './accommodation.action';

export interface AccommodationStateModel {
  filters: FiltersAccommodationSearch;
  establishment: Establishment | null;
  room: Room | null;
  rooms: Room[];
  page: number;
  bounds: google.maps.LatLngBoundsLiteral;
  geoBoundsActive: boolean;
  establishments: Establishment[];
}

@State<AccommodationStateModel>({
  name: 'accommodation',
  defaults: {
    filters: new FiltersAccommodationSearch(),
    establishment: null,
    room: null,
    rooms: [],
    page: 0,
    bounds: {
      east: 8.413687158879437,
      north: 48.70087172198533,
      south: 42.13380963168609,
      west: -2.133187841120563
    },
    geoBoundsActive: true,
    establishments: []
  }
})
@Injectable()
export class AccommodationState {
  constructor(
    private store: Store,
    private accommodationService: AccommodationService
  ) {}

  @Selector()
  static filters(
    state: AccommodationStateModel
  ): FiltersAccommodationSearch | null {
    return new FiltersAccommodationSearch({
      ...state.filters,
      mapPolygon: state.filters.mapPolygon || state.bounds,
      geoBoundsActive: state.geoBoundsActive
    });
  }

  @Selector()
  static rooms(state: AccommodationStateModel): Room[] {
    return state.rooms;
  }

  @Selector()
  static room(state: AccommodationStateModel): Room | null {
    return state.room;
  }

  @Selector()
  static establishment(state: AccommodationStateModel): Establishment | null {
    return state.establishment;
  }

  @Selector()
  static establishments(state: AccommodationStateModel): Establishment[] {
    return state.establishments;
  }

  public static filtersAccommodationFromURL(
    filters: FiltersAccommodation,
    queryParams: ParamMap
  ) {
    const searchPackage = queryParams.get('searchPackage') as SearchPackage;

    return new FiltersAccommodationSearch({
      ...filters,
      packages: searchPackage !== 'SINGLE' || false,
      material: searchPackage === 'FULL' || false,
      pool: JSON.parse(queryParams.get('pool') as string) || false,
      spa: JSON.parse(queryParams.get('spa') as string) || false,
      sauna: JSON.parse(queryParams.get('sauna') as string) || false,
      hammam: JSON.parse(queryParams.get('hammam') as string) || false,
      balconyTerrace:
        JSON.parse(queryParams.get('balconyTerrace') as string) || false,
      tv: JSON.parse(queryParams.get('tv') as string) || false,
      chimney: JSON.parse(queryParams.get('chimney') as string) || false,
      bbq: JSON.parse(queryParams.get('bbq') as string) || false,
      pmr: JSON.parse(queryParams.get('pmr') as string) || false,
      parking: JSON.parse(queryParams.get('parking') as string) || false,
      promo: JSON.parse(queryParams.get('promo') as string) || false,
      animalsAdmitted:
        JSON.parse(queryParams.get('animalsAdmitted') as string) || false,
      wifi: JSON.parse(queryParams.get('wifi') as string) || false,
      childrenClub:
        JSON.parse(queryParams.get('childrenClub') as string) || false,
      resorts:
        JSON.parse(queryParams.get('resorts') ?? '[]').map((val: number) =>
          Number(val)
        ) || [],
      tags:
        JSON.parse(queryParams.get('tags') ?? '[]').map((val: number) =>
          Number(val)
        ) || [],
      regions:
        JSON.parse(queryParams.get('regions') ?? '[]').map((val: number) =>
          Number(val)
        ) || [],
      labels:
        JSON.parse(queryParams.get('labels') ?? '[]').map((val: number) =>
          Number(val)
        ) || [],
      types:
        JSON.parse(queryParams.get('types') ?? '[]').map(
          (val: AccommodationType) => val as AccommodationType
        ) || []
    });
  }

  @Action(SetFiltersAndFetchEstablishments)
  setFiltersAndFetchEstablishments(
    ctx: StateContext<AccommodationStateModel>,
    action: SetFiltersAndFetchEstablishments
  ): void {
    ctx.patchState({
      filters: action.filters,
      page: 0
    });

    this.store.dispatch(new SetFiltersAccommodations(ctx.getState().filters));
    this.store.dispatch(
      new FetchEstablishments(ctx.getState().filters, ctx.getState().page)
    );
  }

  @Action(SetFilterResort)
  setFilterResort(
    ctx: StateContext<AccommodationStateModel>,
    action: SetFilterResort
  ) {
    const filters = new FiltersAccommodationSearch({
      ...ctx.getState().filters,
      resorts: action.resorts
    });

    setTimeout(
      () => this.store.dispatch(new SetFiltersAccommodations(filters)),
      100
    );
  }

  @Action(ResetAccommodationState)
  resetAccommodationState(ctx: StateContext<AccommodationStateModel>) {
    ctx.patchState({
      page: 0,
      filters: new FiltersAccommodationSearch()
    });
  }

  @Action(GetRooms)
  getRooms(
    ctx: StateContext<AccommodationStateModel>,
    action: GetRooms
  ): Observable<Room[]> {
    return this.accommodationService
      .getRoomsByCriteria(
        action.criteria,
        action.establishmentId,
        action.partnerCode
      )
      .pipe(
        tap((rooms) => {
          ctx.patchState({
            room: rooms.find((room) => room.codeRoom === action.roomCode),
            rooms: rooms.filter((room) => room.codeRoom !== action.roomCode)
          });
        })
      );
  }

  @Action(FetchMoreEstablishment)
  fetchMoreEstablishment(ctx: StateContext<AccommodationStateModel>): void {
    ctx.patchState({
      page: ctx.getState().page + 1
    });

    this.store.dispatch(
      new FetchEstablishments(
        ctx.getState().filters,
        ctx.getState().page,
        false
      )
    );
  }

  @Action(FetchEstablishments)
  fetchEstablishments(
    ctx: StateContext<AccommodationStateModel>,
    action: FetchEstablishments
  ) {
    return this.accommodationService
      .getEstablishmentsByFilters(action.filters, action.page)
      .pipe(
        tap((establishments) => {
          const establishmentsResult = action.reset
            ? [...establishments]
            : [...ctx.getState().establishments, ...establishments];
          ctx.patchState({
            establishments: establishmentsResult
          });
        })
      );
  }
}
