import { Activity } from 'atomic-lib';
import { UrlUtils } from '../../../utils/url-utils';
import { Location } from '../location';
import { MarkerWrapper } from '../marker-wrapper';
import { Resort } from '../resort/resort';
import { AccommodationAgeRange } from './accommodation-age-range';
import { Room } from './room';

export interface EstablishmentProposal<T> {
  value: T;
  link: string;
  params: any;
}

export class Establishment {
  id: number;
  partnerCode: string;
  partnerName: string;
  name: string;
  address: string;
  city: string;
  zip: string;
  country: string;
  location: Location;
  description: string;
  access: string;
  facilities: string;
  information: string;
  pictures: string[];
  ages: AccommodationAgeRange[];
  minPrice: number;
  totalRooms: number;
  resorts: Resort[] = [];
  rooms: Room[] = [];
  linksCard: EstablishmentProposal<Room>[];
  activities: Partial<Activity>[];
  marker?: MarkerWrapper<Establishment>;
  pool: boolean;
  spa: boolean;
  sauna: boolean;
  hammam: boolean;
  balconyTerrace: boolean;
  tv: boolean;
  chimney: boolean;
  bbq: boolean;
  pmr: boolean;
  parking: boolean;
  animalsAdmitted: boolean;
  wifi: boolean;
  childrenClub: boolean;
  priceSkiPass: number;
  priceMaterial: number;

  constructor(obj: Partial<Establishment>, mapLinkCard = true) {
    Object.assign(this, obj);

    if (this.rooms && mapLinkCard) {
      this.rooms = this.rooms.map((room) => new Room({ ...room }));
      this.mapLinkCard();
    }

    if (this.resorts) {
      this.resorts = this.resorts.map((resort) => new Resort({ ...resort }));
    }
  }

  mapLinkCard(resortName?: string) {
    this.linksCard = [];
    this.rooms.forEach((room) =>
      this.linksCard.push(this.toCardLink(room, resortName))
    );

    return this.linksCard;
  }

  toCardLink(room: Room, resortName?: string): EstablishmentProposal<Room> {
    return {
      value: room,
      link: `/station/${UrlUtils.encodeToURL(resortName ?? this.resorts[0].name)}/logement/${UrlUtils.encodeToURL(this.name || '')}`,
      params: {
        establishmentId: this.id,
        roomCode: room.codeRoom,
        partnerCode: this.partnerCode
      }
    };
  }
}
