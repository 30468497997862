<atc-dropdown (closeChanged)="closeEventChanged.emit()"
              [classes]="['no-padding']"
              [elementPosition]="_parent"
              [isOpen]="true"
              [left]="left"
              [position]="position"
              [right]="right"
              [top]="top"
              [width]="'880px'"
              class="hide-small-desktop-and-below">
  <div class="content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</atc-dropdown>

<div class="content hide-small-desktop-and-above">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
  <div class="block">
    <p>Ajouter des voyageurs</p>

    <div class="add-participants">
      <!-- ADULT -->
      <div class="add-participants-line">
        <div class="img-wrapper">
          <img alt="Quantité Adulte"
               height="23"
               ngSrc="assets/new-design/img/search-bar/participant/adult.svg"
               width="22">
        </div>

        <div class="text-block">
          <p class="title">Adultes</p>
          <p class="subtitle">+ 17 ans</p>
        </div>

        <div class="add-remove-block">
          <p>{{ nbAdults }}</p>

          <button (click)="addParticipant('adult')">
            <img alt="Ajouter un adulte"
                 height="9"
                 ngSrc="assets/new-design/img/search-bar/participant/plus.svg"
                 width="10">
          </button>
        </div>
      </div>

      <!-- CHILD -->
      <div class="add-participants-line">
        <div class="img-wrapper">
          <img alt="Quantité Enfant"
               height="23"
               ngSrc="assets/new-design/img/search-bar/participant/child.svg"
               width="22">
        </div>

        <div class="text-block">
          <p class="title">Enfants</p>
          <p class="subtitle">Jusqu'à 17 ans</p>
        </div>

        <div class="add-remove-block">
          <p>{{ nbChildren }}</p>

          <button (click)="addParticipant('child')">
            <img alt="Ajouter un enfant"
                 height="9"
                 ngSrc="assets/new-design/img/search-bar/participant/plus.svg"
                 width="10">
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="block wrap">
    <p>Vos voyageurs</p>
    <div class="your-participants">
      <vsk-search-bar-participant-form (ageChanged)="newAgeForParticipant($event, participant)"
                                       (removeChanged)="removeParticipant(participant)"
                                       *ngFor="let participant of _participants; let i = index; trackBy: participant"
                                       [participant]="participant">
      </vsk-search-bar-participant-form>
    </div>
  </div>
</ng-template>
