import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { isArray } from 'lodash-es';
import { FiltersState } from '../filters.state';
import { ParticipantUtils } from '../utils/participant-utils';

@Injectable()
export class UrlManagerResource {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {}

  mergeParams(params: any, reset = false): Promise<boolean> {
    const participants = this.store.selectSnapshot(FiltersState.participants);
    const nbAdults = ParticipantUtils.getNbAdults(participants);
    const children = ParticipantUtils.getAgeChildren(participants);

    const fuseParams = {
      nbAdults,
      children,
      ...params
    };

    const paramsToSetInURL = Object.fromEntries(
      Object.keys(fuseParams).map((key) => {
        if (isArray(fuseParams[key])) {
          return [key, JSON.stringify(fuseParams[key])];
        }

        return [key, fuseParams[key]];
      })
    );

    return this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: paramsToSetInURL,
      queryParamsHandling: reset ? '' : 'merge',
      replaceUrl: true
    });
  }

  fetchParams(paramsKey: string[], excludeActivities: boolean = false): any {
    const currentParams = this.activatedRoute.snapshot.queryParamMap;
    return Object.fromEntries(
      paramsKey
        .map((key) => {
          const value = currentParams.get(key);

          if (value == 'true') {
            return [key, Boolean(value)];
          }

          if (!value) {
            return [key, null];
          }

          if (!isNaN(value as any)) {
            return [key, Number(value)];
          }

          if (value?.startsWith('[')) {
            if (excludeActivities && key === 'activities') {
              return [key, []];
            }
            return [
              key,
              Array.from(JSON.parse(value)).map((valueArray: any) => {
                return isNaN(valueArray)
                  ? String(valueArray)
                  : Number(valueArray);
              })
            ];
          }

          return [key, String(value)];
        })
        .filter((value) => !!value[1])
    );
  }
}
