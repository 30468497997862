import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParticipantService } from '../service/participant.service';
import { Participant } from '../shared/models/participant/participant';

/**
 * Service handling the participant state
 */
@Injectable()
export class ParticipantResource {
  constructor(private participantService: ParticipantService) {}

  upsert(participant: Participant, sessionId: string): Observable<void> {
    return this.participantService.upsert(
      new Participant({
        ...participant,
        sessionId
      })
    );
  }

  deleteParticipant(uuid: string, sessionId: string): Observable<void> {
    return this.participantService.deleteParticipant(uuid, sessionId);
  }
}
