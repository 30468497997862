import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { BreadcrumbLink, WindowResource } from 'atomic-lib';
import { Moment } from 'moment';
import { Observable, filter, switchMap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FiltersState } from '../../filters.state';
import { FiltersService } from '../../service/filters.service';
import { MetaDescriptionService } from '../../service/meta-description.service';
import { ResortService } from '../../service/resort.service';
import { AccommodationTemplateComponent } from '../../shared/component/accommodation-template.component';
import { Establishment } from '../../shared/models/accommodation/establishment';
import { FiltersAccommodationSearch } from '../../shared/models/filters/filters-accommodation-search';
import { ResortMin } from '../../shared/models/resort/resort-min';

@Component({
  selector: 'vsk-search-accommodations',
  templateUrl: './search-accommodations.component.html',
  styleUrls: ['./search-accommodations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchAccommodationsComponent extends AccommodationTemplateComponent {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @Select(FiltersState.startDate) startDate$: Observable<Moment>;

  allResorts$: Observable<ResortMin[]>;
  breadcrumbItems: BreadcrumbLink[] = [
    {
      label: 'Accueil',
      url: '/'
    },
    {
      label: 'Nos hébergements',
      url: '/recherche-hebergements'
    }
  ];

  constructor(
    public activatedRoute: ActivatedRoute,
    public store: Store,
    protected filtersService: FiltersService,
    public windowResource: WindowResource,
    protected stationService: ResortService,
    protected changeRef: ChangeDetectorRef,
    private metaDescriptionService: MetaDescriptionService,
    public router: Router
  ) {
    super(
      activatedRoute,
      store,
      filtersService,
      windowResource,
      changeRef,
      router
    );

    const title = `Découvrez nos résidences et locations en montagne · VeryMountain`;
    const description = `Trouvez la résidence idéale en montagne avec VeryMountain parmi 20.000 hébergements : chalets, résidences all-inclusive ou appartements de charme`;
    const url = 'recherche-hebergements';

    this.metaDescriptionService.updateMeta(title, description, url);

    this.allResorts$ = this.startDate$.pipe(
      filter((startDate) => startDate?.isValid()),
      distinctUntilChanged(),
      switchMap((startDate) => this.stationService.getAllResorts(startDate)),
      map((resorts) =>
        resorts.sort((prev, curr) => (prev.name < curr.name ? -1 : 1))
      )
    );
  }

  identify(index: number, establishment: Establishment) {
    return establishment.id;
  }

  getPackageSearchTitle(filters: FiltersAccommodationSearch | null) {
    if (!filters) {
      return 'Hébergement';
    }

    if (filters.packages && filters.material) {
      return 'Hébergement + Forfaits + Matériel';
    }

    if (filters.packages) {
      return 'Hébergement + Forfaits';
    }

    return 'Hébergement';
  }

  showMoreEnable() {
    if (!this.establishments?.length) {
      return false;
    }

    return (
      this.establishments
        .map((establishment) => establishment.rooms.length)
        .reduce((prev, curr) => prev + curr) !==
      this.filtersAccommodation?.total
    );
  }
}
