<vsk-navbar></vsk-navbar>

<atc-breadcrumb [items]="breadcrumbItems"></atc-breadcrumb>

<vsk-filters-accommodation [(reset)]="clearFilters"
                           [filterByRegions]="true"
                           [filterByResorts]="true"
                           [filtersSearch]="filtersSearch$ | async"
                           [filters]="filtersAccommodation"
                           [nbParticipants]="nbParticipants"
                           [orderChange]="orderForm.value"
                           [resorts]="allResorts$ | async">
</vsk-filters-accommodation>

<main>
  <section [ngClass]="{'full-map': fullMap}" class="list-resorts">
    <div *ngIf="loading" class="order-filter min-h-[37.5px] rounded-[36px]" vskSkeletonLoad></div>

    <div *ngIf="!loading" class="order-filter">
      <p><span>{{ filtersAccommodation?.total }} hébergements</span> correspondent à votre
        recherche : {{ getPackageSearchTitle(filtersSearch$ | async) }}</p>

      <atc-select [control]="orderForm"
                  [optionElements]="ordersSelection"
                  class="w-[16rem]">
      </atc-select>
    </div>


    <ng-container *ngIf="loading">
      <div *ngFor="let index of [0, 1, 2, 3, 4]"
           class="loading-card">
        <div class="left-side">
          <div class="tag"></div>
        </div>

        <div class="right-side">
          <div class="title"></div>
          <div class="content">
            <div class="large-tags">
              <div class="large-tag"></div>
              <div class="large-tag"></div>
            </div>
            <div class="small-tags">
              <div class="small-tag"></div>
              <div class="small-tag"></div>
              <div class="small-tag"></div>
              <div class="small-tag"></div>
            </div>
          </div>
          <div class="footer">
            <div class="price"></div>
            <div class="cta"></div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading">
      <vsk-card-accommodation (mouseenter)="overMarker(establishment.marker)"
                              (mouseleave)="outMarker(establishment.marker)"
                              *ngFor="let establishment of establishments; trackBy: identify"
                              [establishment]="establishment">
      </vsk-card-accommodation>
    </ng-container>

    <div *ngIf="!establishments?.length && !loadingMore && !loading"
         class="no-results">
      <div class="image-no-results"></div>
      <h3>Aucun hébergement trouvé...</h3>
      <p>Essayez d'ajuster vos filtres ou de rechercher avec d'autres critères</p>
    </div>

    <atc-button (click)="loadMoreAccommodation()"
                *ngIf="showMoreEnable()"
                [disabled]="loadingMore"
                color="secondary"
                size="large">
      Voir plus d'hébergements
    </atc-button>
  </section>

  <google-map (mapDragend)="boundsChanged()"
              [center]="center"
              [options]="options"
              [zoom]="zoom"
              class="map-search hide-small-desktop-and-below"
              height="100%"
              width="100%">
    <atc-input-checkbox [border]="false"
                        [control]="refreshOnMoveMap"
                        class="refresh-map-on-zoom">
      <p>Rafraîchir les stations en déplaçant la carte</p>
    </atc-input-checkbox>

    <button (click)="fullMap = !fullMap"
            [ngClass]="{'expand': fullMap}"
            class="change-size-map">
      <svg>
        <use [attr.xlink:href]="'assets/icons/icons.svg#left-arrow'"></use>
      </svg>
      <span>Afficher la liste</span>
    </button>

    <map-marker
      #mapMarker="mapMarker"
      (mapClick)="openInfoWindow(marker, mapMarker)"
      (mapMouseout)="outMarker(marker)"
      (mapMouseover)="overMarker(marker)"
      *ngFor="let marker of markers"
      [icon]="marker.getIcon()!"
      [label]="marker.getLabel()!"
      [options]="getOptions(marker)"
      [position]="marker.getPosition()!"
      [title]="marker.getTitle()!">
    </map-marker>

    <map-info-window>
      <vsk-card-accommodation *ngIf="pinpoint"
                              [establishment]="pinpoint"
                              [pinpoint]="true">
      </vsk-card-accommodation>
    </map-info-window>
  </google-map>
</main>

<vsk-footer></vsk-footer>
