import { NgClass, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { ExpansionCardComponent } from 'atomic-lib';
import { ClearSearchBar } from '../search-bar.action';
import { State } from '../search-bar.component';

@Component({
  selector: 'vsk-search-bar-mobile',
  standalone: true,
  imports: [ExpansionCardComponent, NgClass, NgOptimizedImage],
  templateUrl: './search-bar-mobile.component.html',
  styleUrl: './search-bar-mobile.component.scss'
})
export class SearchBarMobileComponent {
  closing = false;

  @Input() titleSearchType = 'Choisir une expérience';
  @Input() titleDestination = "N'importe où";
  @Input() titleParticipants = 'Ajouter des voyageurs';
  @Input() titleDates = 'Ajouter une date';
  @Input() titleActivities = 'Ajouter une activité';
  @Input() open = false;
  @Input() sessionId: string;
  @Input() state: State = 'none';

  @Output() closeChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() searchChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() stateChange: EventEmitter<State> = new EventEmitter<State>();

  constructor(private store: Store) {}

  closeDrawer() {
    this.closing = true;
    setTimeout(() => this.closeChange.emit(), 600);
  }

  clear() {
    this.store.dispatch(new ClearSearchBar(this.sessionId));
  }

  search() {
    this.closing = true;
    setTimeout(() => this.closeChange.emit(), 600);
    this.searchChange.emit();
  }
}
