import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CartItemSkiEquipment } from '../shared/models/ski-equipment/cart-item-equipment';
import { ItemSkiEquipment } from '../shared/models/ski-equipment/item-ski-equipment';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CartSkiEquipmentService extends RepositoryService {
  private URL_MATERIAL_CART = 'api/webapp/cart-ski-equipment';

  constructor(http: HttpClient) {
    super(http);
  }

  addItem(
    ItemSkiEquipment: ItemSkiEquipment
  ): Observable<CartItemSkiEquipment> {
    return this.post(
      `${this.URL_MATERIAL_CART}/add-ski-equipment`,
      ItemSkiEquipment
    ).pipe(map((item) => new CartItemSkiEquipment({ ...item })));
  }

  deleteItem(
    sessionId: string,
    id: number
  ): Observable<CartItemSkiEquipment[]> {
    return this.delete(
      `${this.URL_MATERIAL_CART}/delete-ski-equipment?sessionId=${sessionId}&id=${id}`
    ).pipe(
      map((items) =>
        items.map(
          (item: CartItemSkiEquipment) => new CartItemSkiEquipment({ ...item })
        )
      )
    );
  }

  getItems(
    sessionId: string,
    isPaid: boolean
  ): Observable<CartItemSkiEquipment[]> {
    return this.get(
      `${this.URL_MATERIAL_CART}/get-all-ski-equipment?sessionId=${sessionId}&isPaid=${isPaid}`
    ).pipe(
      map((items) =>
        items.map(
          (item: CartItemSkiEquipment) => new CartItemSkiEquipment({ ...item })
        )
      )
    );
  }
}
