import { NgForOf, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DropdownComponent } from 'atomic-lib';
import { Moment } from 'moment';
import { SkiPassService } from '../../../service/ski-pass.service';
import { Price } from '../../models/cart/price';
import { Participant } from '../../models/participant/participant';
import { SkiPass } from '../../models/skipass/ski-pass';

export interface SkiCriteria {
  skiPassDuration: number;
  resortName: string;
  preselectSkiPass: boolean;
  firstDay: Moment;
}

@Component({
  selector: 'vsk-ski-pass-dropdown',
  standalone: true,
  imports: [DropdownComponent, NgForOf, NgIf],
  templateUrl: './ski-pass-dropdown.component.html',
  styleUrl: './ski-pass-dropdown.component.scss'
})
export class SkiPassDropdownComponent {
  @ViewChild('skiPass') buttonElement: ElementRef<HTMLElement>;

  open = false;
  skiPassDuration: number;
  skiPassPrice?: Price;
  skiPasses: SkiPass[] = [];
  selectedSkiPass?: SkiPass;

  @Output() selectSkiPassChange: EventEmitter<SkiPass | undefined> =
    new EventEmitter<SkiPass | undefined>();
  @Output() skiPassPriceChange: EventEmitter<Price | undefined> =
    new EventEmitter<Price | undefined>();
  @Input() participants: Participant[] = [];
  @Input() optionEmpty = true;
  @Input() calculatePricing = true;

  constructor(private skiPassService: SkiPassService) {}

  _criteria: SkiCriteria;

  @Input() set criteria(criteria: SkiCriteria) {
    if (!criteria) {
      return;
    }

    this._criteria = criteria;
    this.skiPassDuration = this._criteria.skiPassDuration;
    this.setSkiPass();
  }

  changeSkiPass(skiPass?: SkiPass) {
    this.selectedSkiPass = skiPass;
    this.open = false;

    if (!skiPass) {
      this.skiPassPrice = undefined;
      this.skiPassDuration = 0;
      this.skiPassPriceChange.emit(this.skiPassPrice);
      return;
    }

    this.skiPassDuration = skiPass.duration / 1440;
    this.selectSkiPassChange.emit(this.selectedSkiPass);
    this.getPriceSkiPass();
  }

  getPriceSkiPass() {
    if (!this.calculatePricing) {
      return;
    }

    this.skiPassService
      .getMinPriceSkiPass(
        this.participants.map((participant) => participant.birthdate),
        this._criteria.firstDay,
        this.skiPassDuration,
        this._criteria.resortName
      )
      .subscribe((skiPassPrice) => {
        if (!skiPassPrice) {
          this.skiPassPrice = undefined;
          this.skiPassDuration = 0;
          this.skiPassPriceChange.emit(this.skiPassPrice);
          return;
        }

        this.skiPassPrice = skiPassPrice;
        this.skiPassPriceChange.emit(this.skiPassPrice);
      });
  }

  private setSkiPass() {
    this.skiPassService
      .getSkiPassForResort(
        this._criteria.resortName,
        this._criteria.skiPassDuration + 1,
        this._criteria.firstDay
      )
      .subscribe((skiPasses) => {
        this.skiPasses = skiPasses;

        if (!this.skiPasses?.length) {
          this.skiPassPrice = undefined;
          this.skiPassDuration = 0;
          this.skiPassPriceChange.emit(this.skiPassPrice);
          return;
        }

        if (!this._criteria.preselectSkiPass) {
          return;
        }

        this.selectedSkiPass = this.skiPasses.find(
          (skiPass) => skiPass.duration / 1440 === this.skiPassDuration
        );

        if (!this.selectedSkiPass) {
          this.selectedSkiPass = this.skiPasses.reduce((prev, curr) =>
            prev.duration > curr.duration ? prev : curr
          );
        }

        this.selectSkiPassChange.emit(this.selectedSkiPass);
        this.getPriceSkiPass();
      });
  }
}
