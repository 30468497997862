import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ButtonComponent, LoaderComponent } from 'atomic-lib';
import { Cart } from '../../../shared/models/cart/cart';

@Component({
  selector: 'vsk-step-confirmation',
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    ButtonComponent,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './step-confirmation.component.html',
  styleUrl: './step-confirmation.component.scss'
})
export class StepConfirmationComponent {
  @Input() loading = true;
  @Input() cart: Cart;
  @Input() paymentIncomplete = false;
}
