<div class="wrapper">
  <button #timeSlotButton
          (click)="open = !open"
          class="select">
    <div class="labels">
      <p class="label">{{ getTitle(selectedTimeSlot) }}</p>
      <p class="sub-label">
        {{ getSubLabel(selectedTimeSlot) }}
      </p>
    </div>

    <svg>
      <use [attr.xlink:href]="'assets/icons/icons.svg#arrow-down'"></use>
    </svg>
  </button>

  <atc-dropdown (closeChanged)="open = false"
                [elementPosition]="buttonElement"
                [isOpen]="open"
                [left]="'0px'"
                [position]="'bottom-right'"
                [top]="'65px'"
                [width]="'100%'">
    <div class="content">
      <button (click)="changeTimeSlot(timeSlot)"
              *ngFor="let timeSlot of timeSlots">
        {{ getSubLabel(timeSlot) }}
      </button>
    </div>
  </atc-dropdown>
</div>
