import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { Price } from '../shared/models/cart/price';
import { SkiPass } from '../shared/models/skipass/ski-pass';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class SkiPassService extends RepositoryService {
  private URL_DOMAIN = 'api/webapp/ski-pass';

  constructor(http: HttpClient) {
    super(http);
  }

  getSkiPassForResort(
    resortName: string,
    duration: number,
    startDate: Moment
  ): Observable<SkiPass[]> {
    return this.get(
      this.URL_DOMAIN +
        `/get-ski-pass-for-resort?resortName=${resortName}&duration=${duration}&startDate=${startDate.format('YYYY-MM-DD')}`
    );
  }

  getMinPriceSkiPass(
    participants: Moment[],
    firstDay: Moment,
    duration: number,
    resortName: string
  ): Observable<Price> {
    const birthdays = participants.map((day) => day.format('YYYY-MM-DD'));
    return this.get(
      this.URL_DOMAIN +
        `/get-min-price-ski-pass?participants=${birthdays}&firstDay=${firstDay.format('YYYY-MM-DD')}&duration=${duration}&resortName=${resortName}`
    );
  }

  getPriceSkiPass(
    experienceId: number,
    participants: string[],
    firstDay: Moment
  ): Observable<Price> {
    return this.get(
      this.URL_DOMAIN +
        `/get-price-ski-pass?experienceId=${experienceId}&participants=${participants}&firstDay=${firstDay.format('YYYY-MM-DD')}`
    );
  }
}
