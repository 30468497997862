import { NgModule } from '@angular/core';
import { FooterComponent } from '../../shared/footer/footer.component';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '../../store/translate.module';
import { SellingConditionsRoutingModule } from './selling-conditions-routing.module';
import { SellingConditionsComponent } from './selling-conditions.component';

@NgModule({
  declarations: [SellingConditionsComponent],
  imports: [
    NgxTranslateModule,
    SharedModule,
    SellingConditionsRoutingModule,
    FooterComponent,
    NavbarComponent
  ],
  exports: [SellingConditionsComponent]
})
export class SellingConditionsModule {}
